export const districts = [
    {
      value: "Благоевград",
      label: "Благоевград",
      list: [
        {
          value: "Банско",
          label: "Банско",
          list: [
            {
              value: "Банско",
              label: "Банско"
            },
            {
              value: "Гостун",
              label: "Гостун"
            },
            {
              value: "Добринище",
              label: "Добринище"
            },
            {
              value: "Кремен",
              label: "Кремен"
            },
            {
              value: "Места",
              label: "Места"
            },
            {
              value: "Обидим",
              label: "Обидим"
            },
            {
              value: "Осеново",
              label: "Осеново"
            },
            {
              value: "Филипово",
              label: "Филипово"
            }
          ]
        },
        {
          value: "Белица",
          label: "Белица",
          list: [
            {
              value: "Бабяк",
              label: "Бабяк"
            },
            {
              value: "Белица",
              label: "Белица"
            },
            {
              value: "Горно Краище",
              label: "Горно Краище"
            },
            {
              value: "Гълъбово",
              label: "Гълъбово"
            },
            {
              value: "Дагоново",
              label: "Дагоново"
            },
            {
              value: "Златарица",
              label: "Златарица"
            },
            {
              value: "Краище",
              label: "Краище"
            },
            {
              value: "Кузьово",
              label: "Кузьово"
            },
            {
              value: "Лютово",
              label: "Лютово"
            },
            {
              value: "Орцево",
              label: "Орцево"
            },
            {
              value: "Палатник",
              label: "Палатник"
            },
            {
              value: "Черешово",
              label: "Черешово"
            }
          ]
        },
        {
          value: "Благоевград",
          label: "Благоевград",
          list: [
            {
              value: "Бело поле",
              label: "Бело поле"
            },
            {
              value: "Бистрица",
              label: "Бистрица"
            },
            {
              value: "Благоевград",
              label: "Благоевград"
            },
            {
              value: "Бучино",
              label: "Бучино"
            },
            {
              value: "Българчево",
              label: "Българчево"
            },
            {
              value: "Габрово",
              label: "Габрово"
            },
            {
              value: "Горно Хърсово",
              label: "Горно Хърсово"
            },
            {
              value: "Дебочица",
              label: "Дебочица"
            },
            {
              value: "Делвино",
              label: "Делвино"
            },
            {
              value: "Дренково",
              label: "Дренково"
            },
            {
              value: "Дъбрава",
              label: "Дъбрава"
            },
            {
              value: "Еленово",
              label: "Еленово"
            },
            {
              value: "Зелен дол",
              label: "Зелен дол"
            },
            {
              value: "Изгрев",
              label: "Изгрев"
            },
            {
              value: "Клисура",
              label: "Клисура"
            },
            {
              value: "Лешко",
              label: "Лешко"
            },
            {
              value: "Лисия",
              label: "Лисия"
            },
            {
              value: "Логодаж",
              label: "Логодаж"
            },
            {
              value: "Марулево",
              label: "Марулево"
            },
            {
              value: "Мощанец",
              label: "Мощанец"
            },
            {
              value: "Обел",
              label: "Обел"
            },
            {
              value: "Падеш",
              label: "Падеш"
            },
            {
              value: "Покровник",
              label: "Покровник"
            },
            {
              value: "Рилци",
              label: "Рилци"
            },
            {
              value: "Селище",
              label: "Селище"
            },
            {
              value: "Церово",
              label: "Церово"
            }
          ]
        },
        {
          value: "Гоце Делчев",
          label: "Гоце Делчев",
          list: [
            {
              value: "Баничан",
              label: "Баничан"
            },
            {
              value: "Борово",
              label: "Борово"
            },
            {
              value: "Брезница",
              label: "Брезница"
            },
            {
              value: "Буково",
              label: "Буково"
            },
            {
              value: "Господинци",
              label: "Господинци"
            },
            {
              value: "Гоце Делчев",
              label: "Гоце Делчев"
            },
            {
              value: "Делчево",
              label: "Делчево"
            },
            {
              value: "Добротино",
              label: "Добротино"
            },
            {
              value: "Корница",
              label: "Корница"
            },
            {
              value: "Лъжница",
              label: "Лъжница"
            },
            {
              value: "Мосомище",
              label: "Мосомище"
            },
            {
              value: "Попови ливади",
              label: "Попови ливади"
            }
          ]
        },
        {
          value: "Гърмен",
          label: "Гърмен",
          list: [
            {
              value: "Балдево",
              label: "Балдево"
            },
            {
              value: "Горно Дряново",
              label: "Горно Дряново"
            },
            {
              value: "Гърмен",
              label: "Гърмен"
            },
            {
              value: "Дебрен",
              label: "Дебрен"
            },
            {
              value: "Долно Дряново",
              label: "Долно Дряново"
            },
            {
              value: "Дъбница",
              label: "Дъбница"
            },
            {
              value: "Ковачевица",
              label: "Ковачевица"
            },
            {
              value: "Крушево",
              label: "Крушево"
            },
            {
              value: "Лещен",
              label: "Лещен"
            },
            {
              value: "Марчево",
              label: "Марчево"
            },
            {
              value: "Огняново",
              label: "Огняново"
            },
            {
              value: "Ореше",
              label: "Ореше"
            },
            {
              value: "Осиково",
              label: "Осиково"
            },
            {
              value: "Рибново",
              label: "Рибново"
            },
            {
              value: "Скребатно",
              label: "Скребатно"
            },
            {
              value: "Хвостяне",
              label: "Хвостяне"
            }
          ]
        },
        {
          value: "Кресна",
          label: "Кресна",
          list: [
            {
              value: "Влахи",
              label: "Влахи"
            },
            {
              value: "Горна Брезница",
              label: "Горна Брезница"
            },
            {
              value: "Долна Градешница",
              label: "Долна Градешница"
            },
            {
              value: "Кресна",
              label: "Кресна"
            },
            {
              value: "Ощава",
              label: "Ощава"
            },
            {
              value: "Сливница",
              label: "Сливница"
            },
            {
              value: "Стара Кресна",
              label: "Стара Кресна"
            }
          ]
        },
        {
          value: "Петрич",
          label: "Петрич",
          list: [
            {
              value: "Баскалци",
              label: "Баскалци"
            },
            {
              value: "Беласица",
              label: "Беласица"
            },
            {
              value: "Богородица",
              label: "Богородица"
            },
            {
              value: "Боровичене",
              label: "Боровичене"
            },
            {
              value: "Вишлене",
              label: "Вишлене"
            },
            {
              value: "Волно",
              label: "Волно"
            },
            {
              value: "Габрене",
              label: "Габрене"
            },
            {
              value: "Гега",
              label: "Гега"
            },
            {
              value: "Генерал Тодоров",
              label: "Генерал Тодоров"
            },
            {
              value: "Горчево",
              label: "Горчево"
            },
            {
              value: "Долене",
              label: "Долене"
            },
            {
              value: "Долна Крушица",
              label: "Долна Крушица"
            },
            {
              value: "Долна Рибница",
              label: "Долна Рибница"
            },
            {
              value: "Долно Спанчево",
              label: "Долно Спанчево"
            },
            {
              value: "Драгуш",
              label: "Драгуш"
            },
            {
              value: "Дрангово",
              label: "Дрангово"
            },
            {
              value: "Дреновица",
              label: "Дреновица"
            },
            {
              value: "Дреново",
              label: "Дреново"
            },
            {
              value: "Зойчене",
              label: "Зойчене"
            },
            {
              value: "Иваново",
              label: "Иваново"
            },
            {
              value: "Кавракирово",
              label: "Кавракирово"
            },
            {
              value: "Камена",
              label: "Камена"
            },
            {
              value: "Капатово",
              label: "Капатово"
            },
            {
              value: "Кладенци",
              label: "Кладенци"
            },
            {
              value: "Ключ",
              label: "Ключ"
            },
            {
              value: "Коларово",
              label: "Коларово"
            },
            {
              value: "Кромидово",
              label: "Кромидово"
            },
            {
              value: "Крънджилица",
              label: "Крънджилица"
            },
            {
              value: "Кукурахцево",
              label: "Кукурахцево"
            },
            {
              value: "Кулата",
              label: "Кулата"
            },
            {
              value: "Кърналово",
              label: "Кърналово"
            },
            {
              value: "Марикостиново",
              label: "Марикостиново"
            },
            {
              value: "Марино поле",
              label: "Марино поле"
            },
            {
              value: "Мендово",
              label: "Мендово"
            },
            {
              value: "Митино",
              label: "Митино"
            },
            {
              value: "Михнево",
              label: "Михнево"
            },
            {
              value: "Ново Кономлади",
              label: "Ново Кономлади"
            },
            {
              value: "Петрич",
              label: "Петрич"
            },
            {
              value: "Право бърдо",
              label: "Право бърдо"
            },
            {
              value: "Първомай",
              label: "Първомай"
            },
            {
              value: "Рибник",
              label: "Рибник"
            },
            {
              value: "Рупите",
              label: "Рупите"
            },
            {
              value: "Ръждак",
              label: "Ръждак"
            },
            {
              value: "Самуилова крепост",
              label: "Самуилова крепост"
            },
            {
              value: "Самуилово",
              label: "Самуилово"
            },
            {
              value: "Скрът",
              label: "Скрът"
            },
            {
              value: "Старчево",
              label: "Старчево"
            },
            {
              value: "Струмешница",
              label: "Струмешница"
            },
            {
              value: "Тонско дабе",
              label: "Тонско дабе"
            },
            {
              value: "Тополница",
              label: "Тополница"
            },
            {
              value: "Чурилово",
              label: "Чурилово"
            },
            {
              value: "Чуричени",
              label: "Чуричени"
            },
            {
              value: "Чучулигово",
              label: "Чучулигово"
            },
            {
              value: "Яворница",
              label: "Яворница"
            },
            {
              value: "Яково",
              label: "Яково"
            }
          ]
        },
        {
          value: "Разлог",
          label: "Разлог",
          list: [
            {
              value: "Баня",
              label: "Баня"
            },
            {
              value: "Бачево",
              label: "Бачево"
            },
            {
              value: "Годлево",
              label: "Годлево"
            },
            {
              value: "Горно Драглище",
              label: "Горно Драглище"
            },
            {
              value: "Добърско",
              label: "Добърско"
            },
            {
              value: "Долно Драглище",
              label: "Долно Драглище"
            },
            {
              value: "Елешница",
              label: "Елешница"
            },
            {
              value: "Разлог",
              label: "Разлог"
            }
          ]
        },
        {
          value: "Сандански",
          label: "Сандански",
          list: [
            {
              value: "Белевехчево",
              label: "Белевехчево"
            },
            {
              value: "Бельово",
              label: "Бельово"
            },
            {
              value: "Бождово",
              label: "Бождово"
            },
            {
              value: "Виногради",
              label: "Виногради"
            },
            {
              value: "Вихрен",
              label: "Вихрен"
            },
            {
              value: "Враня",
              label: "Враня"
            },
            {
              value: "Вълково",
              label: "Вълково"
            },
            {
              value: "Голем Цалим",
              label: "Голем Цалим"
            },
            {
              value: "Голешово",
              label: "Голешово"
            },
            {
              value: "Горна Сушица",
              label: "Горна Сушица"
            },
            {
              value: "Горно Спанчево",
              label: "Горно Спанчево"
            },
            {
              value: "Дамяница",
              label: "Дамяница"
            },
            {
              value: "Дебрене",
              label: "Дебрене"
            },
            {
              value: "Джигурово",
              label: "Джигурово"
            },
            {
              value: "Долени",
              label: "Долени"
            },
            {
              value: "Златолист",
              label: "Златолист"
            },
            {
              value: "Зорница",
              label: "Зорница"
            },
            {
              value: "Калиманци",
              label: "Калиманци"
            },
            {
              value: "Катунци",
              label: "Катунци"
            },
            {
              value: "Кашина",
              label: "Кашина"
            },
            {
              value: "Ковачево",
              label: "Ковачево"
            },
            {
              value: "Кръстилци",
              label: "Кръстилци"
            },
            {
              value: "Кърланово",
              label: "Кърланово"
            },
            {
              value: "Ладарево",
              label: "Ладарево"
            },
            {
              value: "Ласкарево",
              label: "Ласкарево"
            },
            {
              value: "Лебница",
              label: "Лебница"
            },
            {
              value: "Левуново",
              label: "Левуново"
            },
            {
              value: "Лехово",
              label: "Лехово"
            },
            {
              value: "Лешница",
              label: "Лешница"
            },
            {
              value: "Лиляново",
              label: "Лиляново"
            },
            {
              value: "Лозеница",
              label: "Лозеница"
            },
            {
              value: "Любовище",
              label: "Любовище"
            },
            {
              value: "Любовка",
              label: "Любовка"
            },
            {
              value: "Малки Цалим",
              label: "Малки Цалим"
            },
            {
              value: "Мелник",
              label: "Мелник"
            },
            {
              value: "Ново Делчево",
              label: "Ново Делчево"
            },
            {
              value: "Ново Ходжово",
              label: "Ново Ходжово"
            },
            {
              value: "Петрово",
              label: "Петрово"
            },
            {
              value: "Пиперица",
              label: "Пиперица"
            },
            {
              value: "Пирин",
              label: "Пирин"
            },
            {
              value: "Плоски",
              label: "Плоски"
            },
            {
              value: "Поленица",
              label: "Поленица"
            },
            {
              value: "Рожен",
              label: "Рожен"
            },
            {
              value: "Сандански",
              label: "Сандански"
            },
            {
              value: "Склаве",
              label: "Склаве"
            },
            {
              value: "Спатово",
              label: "Спатово"
            },
            {
              value: "Стожа",
              label: "Стожа"
            },
            {
              value: "Струма",
              label: "Струма"
            },
            {
              value: "Сугарево",
              label: "Сугарево"
            },
            {
              value: "Хотово",
              label: "Хотово"
            },
            {
              value: "Храсна",
              label: "Храсна"
            },
            {
              value: "Хърсово",
              label: "Хърсово"
            },
            {
              value: "Черешница",
              label: "Черешница"
            },
            {
              value: "Яново",
              label: "Яново"
            }
          ]
        },
        {
          value: "Сатовча",
          label: "Сатовча",
          list: [
            {
              value: "Боголин",
              label: "Боголин"
            },
            {
              value: "Ваклиново",
              label: "Ваклиново"
            },
            {
              value: "Вълкосел",
              label: "Вълкосел"
            },
            {
              value: "Годешево",
              label: "Годешево"
            },
            {
              value: "Долен",
              label: "Долен"
            },
            {
              value: "Жижево",
              label: "Жижево"
            },
            {
              value: "Кочан",
              label: "Кочан"
            },
            {
              value: "Крибул",
              label: "Крибул"
            },
            {
              value: "Осина",
              label: "Осина"
            },
            {
              value: "Плетена",
              label: "Плетена"
            },
            {
              value: "Сатовча",
              label: "Сатовча"
            },
            {
              value: "Слащен",
              label: "Слащен"
            },
            {
              value: "Туховища",
              label: "Туховища"
            },
            {
              value: "Фъргово",
              label: "Фъргово"
            }
          ]
        },
        {
          value: "Симитли",
          label: "Симитли",
          list: [
            {
              value: "Брежани",
              label: "Брежани"
            },
            {
              value: "Брестово",
              label: "Брестово"
            },
            {
              value: "Горно Осеново",
              label: "Горно Осеново"
            },
            {
              value: "Градево",
              label: "Градево"
            },
            {
              value: "Докатичево",
              label: "Докатичево"
            },
            {
              value: "Долно Осеново",
              label: "Долно Осеново"
            },
            {
              value: "Железница",
              label: "Железница"
            },
            {
              value: "Крупник",
              label: "Крупник"
            },
            {
              value: "Мечкул",
              label: "Мечкул"
            },
            {
              value: "Полена",
              label: "Полена"
            },
            {
              value: "Полето",
              label: "Полето"
            },
            {
              value: "Ракитна",
              label: "Ракитна"
            },
            {
              value: "Сенокос",
              label: "Сенокос"
            },
            {
              value: "Симитли",
              label: "Симитли"
            },
            {
              value: "Сухострел",
              label: "Сухострел"
            },
            {
              value: "Сушица",
              label: "Сушица"
            },
            {
              value: "Тросково",
              label: "Тросково"
            },
            {
              value: "Черниче",
              label: "Черниче"
            }
          ]
        },
        {
          value: "Струмяни",
          label: "Струмяни",
          list: [
            {
              value: "Велющец",
              label: "Велющец"
            },
            {
              value: "Вракуповица",
              label: "Вракуповица"
            },
            {
              value: "Гореме",
              label: "Гореме"
            },
            {
              value: "Горна Крушица",
              label: "Горна Крушица"
            },
            {
              value: "Горна Рибница",
              label: "Горна Рибница"
            },
            {
              value: "Добри лаки",
              label: "Добри лаки"
            },
            {
              value: "Драката",
              label: "Драката"
            },
            {
              value: "Игралище",
              label: "Игралище"
            },
            {
              value: "Илинденци",
              label: "Илинденци"
            },
            {
              value: "Каменица",
              label: "Каменица"
            },
            {
              value: "Клепало",
              label: "Клепало"
            },
            {
              value: "Колибите",
              label: "Колибите"
            },
            {
              value: "Кърпелово",
              label: "Кърпелово"
            },
            {
              value: "Махалата",
              label: "Махалата"
            },
            {
              value: "Микрево",
              label: "Микрево"
            },
            {
              value: "Никудин",
              label: "Никудин"
            },
            {
              value: "Палат",
              label: "Палат"
            },
            {
              value: "Раздол",
              label: "Раздол"
            },
            {
              value: "Седелец",
              label: "Седелец"
            },
            {
              value: "Струмяни",
              label: "Струмяни"
            },
            {
              value: "Цапарево",
              label: "Цапарево"
            }
          ]
        },
        {
          value: "Хаджидимово",
          label: "Хаджидимово",
          list: [
            {
              value: "Абланица",
              label: "Абланица"
            },
            {
              value: "Беслен",
              label: "Беслен"
            },
            {
              value: "Блатска",
              label: "Блатска"
            },
            {
              value: "Гайтаниново",
              label: "Гайтаниново"
            },
            {
              value: "Илинден",
              label: "Илинден"
            },
            {
              value: "Копривлен",
              label: "Копривлен"
            },
            {
              value: "Лъки",
              label: "Лъки"
            },
            {
              value: "Нова Ловча",
              label: "Нова Ловча"
            },
            {
              value: "Ново Лески",
              label: "Ново Лески"
            },
            {
              value: "Парил",
              label: "Парил"
            },
            {
              value: "Петрелик",
              label: "Петрелик"
            },
            {
              value: "Садово",
              label: "Садово"
            },
            {
              value: "Теплен",
              label: "Теплен"
            },
            {
              value: "Тешово",
              label: "Тешово"
            },
            {
              value: "Хаджидимово",
              label: "Хаджидимово"
            }
          ]
        },
        {
          value: "Якоруда",
          label: "Якоруда",
          list: [
            {
              value: "Аврамово",
              label: "Аврамово"
            },
            {
              value: "Бел Камен",
              label: "Бел Камен"
            },
            {
              value: "Бунцево",
              label: "Бунцево"
            },
            {
              value: "Конарско",
              label: "Конарско"
            },
            {
              value: "Смолево",
              label: "Смолево"
            },
            {
              value: "Черна Места",
              label: "Черна Места"
            },
            {
              value: "Юруково",
              label: "Юруково"
            },
            {
              value: "Якоруда",
              label: "Якоруда"
            }
          ]
        }
      ]
    },
    {
      value: "Бургас",
      label: "Бургас",
      list: [
        {
          value: "Айтос",
          label: "Айтос",
          list: [
            {
              value: "Айтос",
              label: "Айтос"
            },
            {
              value: "Дрянковец",
              label: "Дрянковец"
            },
            {
              value: "Зетьово",
              label: "Зетьово"
            },
            {
              value: "Карагеоргиево",
              label: "Карагеоргиево"
            },
            {
              value: "Караново",
              label: "Караново"
            },
            {
              value: "Лясково",
              label: "Лясково"
            },
            {
              value: "Малка поляна",
              label: "Малка поляна"
            },
            {
              value: "Мъглен",
              label: "Мъглен"
            },
            {
              value: "Пещерско",
              label: "Пещерско"
            },
            {
              value: "Пирне",
              label: "Пирне"
            },
            {
              value: "Поляново",
              label: "Поляново"
            },
            {
              value: "Раклиново",
              label: "Раклиново"
            },
            {
              value: "Съдиево",
              label: "Съдиево"
            },
            {
              value: "Тополица",
              label: "Тополица"
            },
            {
              value: "Черна могила",
              label: "Черна могила"
            },
            {
              value: "Черноград",
              label: "Черноград"
            },
            {
              value: "Чукарка",
              label: "Чукарка"
            }
          ]
        },
        {
          value: "Бургас",
          label: "Бургас",
          list: [
            {
              value: "Банево",
              label: "Банево"
            },
            {
              value: "Братово",
              label: "Братово"
            },
            {
              value: "Брястовец",
              label: "Брястовец"
            },
            {
              value: "Бургас",
              label: "Бургас"
            },
            {
              value: "Бургаски мин. бани",
              label: "Бургаски мин. бани"
            },
            {
              value: "Българово",
              label: "Българово"
            },
            {
              value: "Ветрен",
              label: "Ветрен"
            },
            {
              value: "Димчево",
              label: "Димчево"
            },
            {
              value: "Драганово",
              label: "Драганово"
            },
            {
              value: "Изворище",
              label: "Изворище"
            },
            {
              value: "Маринка",
              label: "Маринка"
            },
            {
              value: "Мина Черно море",
              label: "Мина Черно море"
            },
            {
              value: "Миролюбово",
              label: "Миролюбово"
            },
            {
              value: "Равнец",
              label: "Равнец"
            },
            {
              value: "Рудник",
              label: "Рудник"
            },
            {
              value: "Твърдица",
              label: "Твърдица"
            }
          ]
        },
        {
          value: "Камено",
          label: "Камено",
          list: [
            {
              value: "Винарско",
              label: "Винарско"
            },
            {
              value: "Вратица",
              label: "Вратица"
            },
            {
              value: "Желязово",
              label: "Желязово"
            },
            {
              value: "Камено",
              label: "Камено"
            },
            {
              value: "Константиново",
              label: "Константиново"
            },
            {
              value: "Кръстина",
              label: "Кръстина"
            },
            {
              value: "Ливада",
              label: "Ливада"
            },
            {
              value: "Полски извор",
              label: "Полски извор"
            },
            {
              value: "Русокастро",
              label: "Русокастро"
            },
            {
              value: "Свобода",
              label: "Свобода"
            },
            {
              value: "Трояново",
              label: "Трояново"
            },
            {
              value: "Тръстиково",
              label: "Тръстиково"
            },
            {
              value: "Черни връх",
              label: "Черни връх"
            }
          ]
        },
        {
          value: "Карнобат",
          label: "Карнобат",
          list: [
            {
              value: "Аспарухово",
              label: "Аспарухово"
            },
            {
              value: "Венец",
              label: "Венец"
            },
            {
              value: "Глумче",
              label: "Глумче"
            },
            {
              value: "Деветак",
              label: "Деветак"
            },
            {
              value: "Деветинци",
              label: "Деветинци"
            },
            {
              value: "Детелина",
              label: "Детелина"
            },
            {
              value: "Добриново",
              label: "Добриново"
            },
            {
              value: "Драганци",
              label: "Драганци"
            },
            {
              value: "Драгово",
              label: "Драгово"
            },
            {
              value: "Екзарх Антимово",
              label: "Екзарх Антимово"
            },
            {
              value: "Железник",
              label: "Железник"
            },
            {
              value: "Житосвят",
              label: "Житосвят"
            },
            {
              value: "Зимен",
              label: "Зимен"
            },
            {
              value: "Искра",
              label: "Искра"
            },
            {
              value: "Карнобат",
              label: "Карнобат"
            },
            {
              value: "Кликач",
              label: "Кликач"
            },
            {
              value: "Козаре",
              label: "Козаре"
            },
            {
              value: "Крумово градище",
              label: "Крумово градище"
            },
            {
              value: "Крушово",
              label: "Крушово"
            },
            {
              value: "Мъдрино",
              label: "Мъдрино"
            },
            {
              value: "Невестино",
              label: "Невестино"
            },
            {
              value: "Раклица",
              label: "Раклица"
            },
            {
              value: "Сан Стефано",
              label: "Сан Стефано"
            },
            {
              value: "Сигмен",
              label: "Сигмен"
            },
            {
              value: "Смолник",
              label: "Смолник"
            },
            {
              value: "Соколово",
              label: "Соколово"
            },
            {
              value: "Сърнево",
              label: "Сърнево"
            },
            {
              value: "Хаджиите",
              label: "Хаджиите"
            },
            {
              value: "Церковски",
              label: "Церковски"
            },
            {
              value: "Черково",
              label: "Черково"
            }
          ]
        },
        {
          value: "Малко Търново",
          label: "Малко Търново",
          list: [
            {
              value: "Близнак",
              label: "Близнак"
            },
            {
              value: "Бръшлян",
              label: "Бръшлян"
            },
            {
              value: "Бяла вода",
              label: "Бяла вода"
            },
            {
              value: "Визица",
              label: "Визица"
            },
            {
              value: "Граматиково",
              label: "Граматиково"
            },
            {
              value: "Евренезово",
              label: "Евренезово"
            },
            {
              value: "Заберново",
              label: "Заберново"
            },
            {
              value: "Звездец",
              label: "Звездец"
            },
            {
              value: "Калово",
              label: "Калово"
            },
            {
              value: "Малко Търново",
              label: "Малко Търново"
            },
            {
              value: "Младежко",
              label: "Младежко"
            },
            {
              value: "Сливарово",
              label: "Сливарово"
            },
            {
              value: "Стоилово",
              label: "Стоилово"
            }
          ]
        },
        {
          value: "Несебър",
          label: "Несебър",
          list: [
            {
              value: "Баня",
              label: "Баня"
            },
            {
              value: "Гюльовца",
              label: "Гюльовца"
            },
            {
              value: "Емона",
              label: "Емона"
            },
            {
              value: "Козница",
              label: "Козница"
            },
            {
              value: "Кошарица",
              label: "Кошарица"
            },
            {
              value: "Несебър",
              label: "Несебър"
            },
            {
              value: "Обзор",
              label: "Обзор"
            },
            {
              value: "Оризаре",
              label: "Оризаре"
            },
            {
              value: "Паницово",
              label: "Паницово"
            },
            {
              value: "Приселци",
              label: "Приселци"
            },
            {
              value: "Равда",
              label: "Равда"
            },
            {
              value: "Раковсково",
              label: "Раковсково"
            },
            {
              value: "Свети Влас",
              label: "Свети Влас"
            },
            {
              value: "Слънчев бряг",
              label: "Слънчев бряг"
            },
            {
              value: "Тънково",
              label: "Тънково"
            }
          ]
        },
        {
          value: "Поморие",
          label: "Поморие",
          list: [
            {
              value: "Александрово",
              label: "Александрово"
            },
            {
              value: "Ахелой",
              label: "Ахелой"
            },
            {
              value: "Бата",
              label: "Бата"
            },
            {
              value: "Белодол",
              label: "Белодол"
            },
            {
              value: "Габерово",
              label: "Габерово"
            },
            {
              value: "Горица",
              label: "Горица"
            },
            {
              value: "Гълъбец",
              label: "Гълъбец"
            },
            {
              value: "Дъбник",
              label: "Дъбник"
            },
            {
              value: "Каблешково",
              label: "Каблешково"
            },
            {
              value: "Каменар",
              label: "Каменар"
            },
            {
              value: "Козичино",
              label: "Козичино"
            },
            {
              value: "Косовец",
              label: "Косовец"
            },
            {
              value: "Лъка",
              label: "Лъка"
            },
            {
              value: "Медово",
              label: "Медово"
            },
            {
              value: "Поморие",
              label: "Поморие"
            },
            {
              value: "Порой",
              label: "Порой"
            },
            {
              value: "Страцин",
              label: "Страцин"
            }
          ]
        },
        {
          value: "Приморско",
          label: "Приморско",
          list: [
            {
              value: "Веселие",
              label: "Веселие"
            },
            {
              value: "Китен",
              label: "Китен"
            },
            {
              value: "Ново Паничарево",
              label: "Ново Паничарево"
            },
            {
              value: "Писменово",
              label: "Писменово"
            },
            {
              value: "Приморско",
              label: "Приморско"
            },
            {
              value: "Ясна поляна",
              label: "Ясна поляна"
            }
          ]
        },
        {
          value: "Руен",
          label: "Руен",
          list: [
            {
              value: "Билка",
              label: "Билка"
            },
            {
              value: "Вишна",
              label: "Вишна"
            },
            {
              value: "Вресово",
              label: "Вресово"
            },
            {
              value: "Добра поляна",
              label: "Добра поляна"
            },
            {
              value: "Добромир",
              label: "Добромир"
            },
            {
              value: "Дропла",
              label: "Дропла"
            },
            {
              value: "Дъскотна",
              label: "Дъскотна"
            },
            {
              value: "Дюля",
              label: "Дюля"
            },
            {
              value: "Заимчево",
              label: "Заимчево"
            },
            {
              value: "Зайчар",
              label: "Зайчар"
            },
            {
              value: "Звезда",
              label: "Звезда"
            },
            {
              value: "Каменяк",
              label: "Каменяк"
            },
            {
              value: "Каравельово",
              label: "Каравельово"
            },
            {
              value: "Китка",
              label: "Китка"
            },
            {
              value: "Листец",
              label: "Листец"
            },
            {
              value: "Люляково",
              label: "Люляково"
            },
            {
              value: "Мрежичко",
              label: "Мрежичко"
            },
            {
              value: "Планиница",
              label: "Планиница"
            },
            {
              value: "Подгорец",
              label: "Подгорец"
            },
            {
              value: "Преображенци",
              label: "Преображенци"
            },
            {
              value: "Припек",
              label: "Припек"
            },
            {
              value: "Просеник",
              label: "Просеник"
            },
            {
              value: "Разбойна",
              label: "Разбойна"
            },
            {
              value: "Речица",
              label: "Речица"
            },
            {
              value: "Рожден",
              label: "Рожден"
            },
            {
              value: "Рудина",
              label: "Рудина"
            },
            {
              value: "Руен",
              label: "Руен"
            },
            {
              value: "Рупча",
              label: "Рупча"
            },
            {
              value: "Ръжица",
              label: "Ръжица"
            },
            {
              value: "Сини рид",
              label: "Сини рид"
            },
            {
              value: "Скалак",
              label: "Скалак"
            },
            {
              value: "Снежа",
              label: "Снежа"
            },
            {
              value: "Снягово",
              label: "Снягово"
            },
            {
              value: "Соколец",
              label: "Соколец"
            },
            {
              value: "Средна махала",
              label: "Средна махала"
            },
            {
              value: "Струя",
              label: "Струя"
            },
            {
              value: "Топчийско",
              label: "Топчийско"
            },
            {
              value: "Трънак",
              label: "Трънак"
            },
            {
              value: "Череша",
              label: "Череша"
            },
            {
              value: "Шиварово",
              label: "Шиварово"
            },
            {
              value: "Ябълчево",
              label: "Ябълчево"
            },
            {
              value: "Ясеново",
              label: "Ясеново"
            }
          ]
        },
        {
          value: "Созопол",
          label: "Созопол",
          list: [
            {
              value: "Атия",
              label: "Атия"
            },
            {
              value: "Вършило",
              label: "Вършило"
            },
            {
              value: "Габър",
              label: "Габър"
            },
            {
              value: "Зидарово",
              label: "Зидарово"
            },
            {
              value: "Извор",
              label: "Извор"
            },
            {
              value: "Индже войвода",
              label: "Индже войвода"
            },
            {
              value: "Каваците",
              label: "Каваците"
            },
            {
              value: "Крушевец",
              label: "Крушевец"
            },
            {
              value: "Присад",
              label: "Присад"
            },
            {
              value: "Равадиново",
              label: "Равадиново"
            },
            {
              value: "Равна гора",
              label: "Равна гора"
            },
            {
              value: "Росен",
              label: "Росен"
            },
            {
              value: "Созопол",
              label: "Созопол"
            },
            {
              value: "Черноморец",
              label: "Черноморец"
            }
          ]
        },
        {
          value: "Средец",
          label: "Средец",
          list: [
            {
              value: "Белеврен",
              label: "Белеврен"
            },
            {
              value: "Белила",
              label: "Белила"
            },
            {
              value: "Бистрец",
              label: "Бистрец"
            },
            {
              value: "Богданово",
              label: "Богданово"
            },
            {
              value: "Варовник",
              label: "Варовник"
            },
            {
              value: "Вълчаново",
              label: "Вълчаново"
            },
            {
              value: "Голямо Буково",
              label: "Голямо Буково"
            },
            {
              value: "Горно Ябълково",
              label: "Горно Ябълково"
            },
            {
              value: "Гранитец",
              label: "Гранитец"
            },
            {
              value: "Граничар",
              label: "Граничар"
            },
            {
              value: "Дебелт",
              label: "Дебелт"
            },
            {
              value: "Долно Ябълково",
              label: "Долно Ябълково"
            },
            {
              value: "Драка",
              label: "Драка"
            },
            {
              value: "Драчево",
              label: "Драчево"
            },
            {
              value: "Дюлево",
              label: "Дюлево"
            },
            {
              value: "Загорци",
              label: "Загорци"
            },
            {
              value: "Зорница",
              label: "Зорница"
            },
            {
              value: "Кирово",
              label: "Кирово"
            },
            {
              value: "Кубадин",
              label: "Кубадин"
            },
            {
              value: "Малина",
              label: "Малина"
            },
            {
              value: "Момина църква",
              label: "Момина църква"
            },
            {
              value: "Орлинци",
              label: "Орлинци"
            },
            {
              value: "Проход",
              label: "Проход"
            },
            {
              value: "Пънчево",
              label: "Пънчево"
            },
            {
              value: "Радойново",
              label: "Радойново"
            },
            {
              value: "Росеново",
              label: "Росеново"
            },
            {
              value: "Светлина",
              label: "Светлина"
            },
            {
              value: "Синьо Камене",
              label: "Синьо Камене"
            },
            {
              value: "Сливово",
              label: "Сливово"
            },
            {
              value: "Средец",
              label: "Средец"
            },
            {
              value: "Суходол",
              label: "Суходол"
            },
            {
              value: "Тракийци",
              label: "Тракийци"
            },
            {
              value: "Факия",
              label: "Факия"
            }
          ]
        },
        {
          value: "Сунгурларе",
          label: "Сунгурларе",
          list: [
            {
              value: "Бероново",
              label: "Бероново"
            },
            {
              value: "Босилково",
              label: "Босилково"
            },
            {
              value: "Ведрово",
              label: "Ведрово"
            },
            {
              value: "Везенково",
              label: "Везенково"
            },
            {
              value: "Велислав",
              label: "Велислав"
            },
            {
              value: "Вълчин",
              label: "Вълчин"
            },
            {
              value: "Гара Завет",
              label: "Гара Завет"
            },
            {
              value: "Горово",
              label: "Горово"
            },
            {
              value: "Грозден",
              label: "Грозден"
            },
            {
              value: "Дъбовица",
              label: "Дъбовица"
            },
            {
              value: "Есен",
              label: "Есен"
            },
            {
              value: "Камчия",
              label: "Камчия"
            },
            {
              value: "Климаш",
              label: "Климаш"
            },
            {
              value: "Костен",
              label: "Костен"
            },
            {
              value: "Лозарево",
              label: "Лозарево"
            },
            {
              value: "Лозица",
              label: "Лозица"
            },
            {
              value: "Манолич",
              label: "Манолич"
            },
            {
              value: "Огнен",
              label: "Огнен"
            },
            {
              value: "Подвис",
              label: "Подвис"
            },
            {
              value: "Прилеп",
              label: "Прилеп"
            },
            {
              value: "Пчелин",
              label: "Пчелин"
            },
            {
              value: "Садово",
              label: "Садово"
            },
            {
              value: "Скала",
              label: "Скала"
            },
            {
              value: "Славянци",
              label: "Славянци"
            },
            {
              value: "Сунгурларе",
              label: "Сунгурларе"
            },
            {
              value: "Съединение",
              label: "Съединение"
            },
            {
              value: "Терзийско",
              label: "Терзийско"
            },
            {
              value: "Черница",
              label: "Черница"
            },
            {
              value: "Чубра",
              label: "Чубра"
            }
          ]
        },
        {
          value: "Царево",
          label: "Царево",
          list: [
            {
              value: "Ахтопол",
              label: "Ахтопол"
            },
            {
              value: "Бродилово",
              label: "Бродилово"
            },
            {
              value: "Българи",
              label: "Българи"
            },
            {
              value: "Варвара",
              label: "Варвара"
            },
            {
              value: "Велика",
              label: "Велика"
            },
            {
              value: "Изгрев",
              label: "Изгрев"
            },
            {
              value: "Кондолово",
              label: "Кондолово"
            },
            {
              value: "Кости",
              label: "Кости"
            },
            {
              value: "Лозенец",
              label: "Лозенец"
            },
            {
              value: "Резово",
              label: "Резово"
            },
            {
              value: "Синеморец",
              label: "Синеморец"
            },
            {
              value: "Фазаново",
              label: "Фазаново"
            },
            {
              value: "Царево",
              label: "Царево"
            }
          ]
        }
      ]
    },
    {
      value: "Варна",
      label: "Варна",
      list: [
        {
          value: "Аврен",
          label: "Аврен",
          list: [
            {
              value: "Аврен",
              label: "Аврен"
            },
            {
              value: "Бенковски",
              label: "Бенковски"
            },
            {
              value: "Близнаци",
              label: "Близнаци"
            },
            {
              value: "Болярци",
              label: "Болярци"
            },
            {
              value: "Добри дол",
              label: "Добри дол"
            },
            {
              value: "Дъбравино",
              label: "Дъбравино"
            },
            {
              value: "Здравец",
              label: "Здравец"
            },
            {
              value: "Казашка река",
              label: "Казашка река"
            },
            {
              value: "Камчия",
              label: "Камчия"
            },
            {
              value: "Китка",
              label: "Китка"
            },
            {
              value: "Круша",
              label: "Круша"
            },
            {
              value: "Приселци",
              label: "Приселци"
            },
            {
              value: "Равна гора",
              label: "Равна гора"
            },
            {
              value: "Садово",
              label: "Садово"
            },
            {
              value: "Синдел",
              label: "Синдел"
            },
            {
              value: "Тръстиково",
              label: "Тръстиково"
            },
            {
              value: "Царевци",
              label: "Царевци"
            },
            {
              value: "Юнак",
              label: "Юнак"
            }
          ]
        },
        {
          value: "Аксаково",
          label: "Аксаково",
          list: [
            {
              value: "Аксаково",
              label: "Аксаково"
            },
            {
              value: "Ботево",
              label: "Ботево"
            },
            {
              value: "Водица",
              label: "Водица"
            },
            {
              value: "Въглен",
              label: "Въглен"
            },
            {
              value: "Генерал Кантарджиево",
              label: "Генерал Кантарджиево"
            },
            {
              value: "Доброглед",
              label: "Доброглед"
            },
            {
              value: "Долище",
              label: "Долище"
            },
            {
              value: "Засмяно",
              label: "Засмяно"
            },
            {
              value: "Зорница",
              label: "Зорница"
            },
            {
              value: "Игнатиево",
              label: "Игнатиево"
            },
            {
              value: "Изворско",
              label: "Изворско"
            },
            {
              value: "Кичево",
              label: "Кичево"
            },
            {
              value: "Климентово",
              label: "Климентово"
            },
            {
              value: "Крумово",
              label: "Крумово"
            },
            {
              value: "Куманово",
              label: "Куманово"
            },
            {
              value: "Любен Каравелово",
              label: "Любен Каравелово"
            },
            {
              value: "Новаково",
              label: "Новаково"
            },
            {
              value: "Орешак",
              label: "Орешак"
            },
            {
              value: "Осеново",
              label: "Осеново"
            },
            {
              value: "Припек",
              label: "Припек"
            },
            {
              value: "Радево",
              label: "Радево"
            },
            {
              value: "Слънчево",
              label: "Слънчево"
            },
            {
              value: "Яребична",
              label: "Яребична"
            }
          ]
        },
        {
          value: "Белослав",
          label: "Белослав",
          list: [
            {
              value: "Белослав",
              label: "Белослав"
            },
            {
              value: "Езерово",
              label: "Езерово"
            },
            {
              value: "Разделна",
              label: "Разделна"
            },
            {
              value: "Страшимирово",
              label: "Страшимирово"
            }
          ]
        },
        {
          value: "Бяла",
          label: "Бяла",
          list: [
            {
              value: "Бяла",
              label: "Бяла"
            },
            {
              value: "Горица",
              label: "Горица"
            },
            {
              value: "Господиново",
              label: "Господиново"
            },
            {
              value: "Дюлино",
              label: "Дюлино"
            },
            {
              value: "Попович",
              label: "Попович"
            },
            {
              value: "Самотино",
              label: "Самотино"
            }
          ]
        },
        {
          value: "Варна",
          label: "Варна",
          list: [
            {
              value: "Варна",
              label: "Варна"
            },
            {
              value: "Звездица",
              label: "Звездица"
            },
            {
              value: "Златни пясъци",
              label: "Златни пясъци"
            },
            {
              value: "Казашко",
              label: "Казашко"
            },
            {
              value: "Каменар",
              label: "Каменар"
            },
            {
              value: "Константиново",
              label: "Константиново"
            },
            {
              value: "Свети Константин и Елена",
              label: "Свети Константин и Елена"
            },
            {
              value: "Тополи",
              label: "Тополи"
            }
          ]
        },
        {
          value: "Ветрино",
          label: "Ветрино",
          list: [
            {
              value: "Белоградец",
              label: "Белоградец"
            },
            {
              value: "Ветрино",
              label: "Ветрино"
            },
            {
              value: "Габърница",
              label: "Габърница"
            },
            {
              value: "Доброплодно",
              label: "Доброплодно"
            },
            {
              value: "Млада гвардия",
              label: "Млада гвардия"
            },
            {
              value: "Момчилово",
              label: "Момчилово"
            },
            {
              value: "Невша",
              label: "Невша"
            },
            {
              value: "Неофит Рилски",
              label: "Неофит Рилски"
            },
            {
              value: "Средно село",
              label: "Средно село"
            },
            {
              value: "Ягнило",
              label: "Ягнило"
            }
          ]
        },
        {
          value: "Вълчи дол",
          label: "Вълчи дол",
          list: [
            {
              value: "Бояна",
              label: "Бояна"
            },
            {
              value: "Брестак",
              label: "Брестак"
            },
            {
              value: "Войводино",
              label: "Войводино"
            },
            {
              value: "Вълчи дол",
              label: "Вълчи дол"
            },
            {
              value: "Генерал Киселово",
              label: "Генерал Киселово"
            },
            {
              value: "Генерал Колево",
              label: "Генерал Колево"
            },
            {
              value: "Добротич",
              label: "Добротич"
            },
            {
              value: "Есеница",
              label: "Есеница"
            },
            {
              value: "Звънец",
              label: "Звънец"
            },
            {
              value: "Изворник",
              label: "Изворник"
            },
            {
              value: "Искър",
              label: "Искър"
            },
            {
              value: "Калоян",
              label: "Калоян"
            },
            {
              value: "Караманите",
              label: "Караманите"
            },
            {
              value: "Кракра",
              label: "Кракра"
            },
            {
              value: "Метличина",
              label: "Метличина"
            },
            {
              value: "Михалич",
              label: "Михалич"
            },
            {
              value: "Оборище",
              label: "Оборище"
            },
            {
              value: "Радан войвода",
              label: "Радан войвода"
            },
            {
              value: "Стефан Караджа",
              label: "Стефан Караджа"
            },
            {
              value: "Страхил",
              label: "Страхил"
            },
            {
              value: "Червенци",
              label: "Червенци"
            },
            {
              value: "Щипско",
              label: "Щипско"
            }
          ]
        },
        {
          value: "Девня",
          label: "Девня",
          list: [
            {
              value: "Девня",
              label: "Девня"
            },
            {
              value: "Кипра",
              label: "Кипра"
            },
            {
              value: "Падина",
              label: "Падина"
            }
          ]
        },
        {
          value: "Долни чифлик",
          label: "Долни чифлик",
          list: [
            {
              value: "Булаир",
              label: "Булаир"
            },
            {
              value: "Бърдарево",
              label: "Бърдарево"
            },
            {
              value: "Венелин",
              label: "Венелин"
            },
            {
              value: "Голица",
              label: "Голица"
            },
            {
              value: "Горен Чифлик",
              label: "Горен Чифлик"
            },
            {
              value: "Гроздьово",
              label: "Гроздьово"
            },
            {
              value: "Детелина",
              label: "Детелина"
            },
            {
              value: "Долни чифлик",
              label: "Долни чифлик"
            },
            {
              value: "Кривини",
              label: "Кривини"
            },
            {
              value: "Нова Шипка",
              label: "Нова Шипка"
            },
            {
              value: "Ново Оряхово",
              label: "Ново Оряхово"
            },
            {
              value: "Пчелник",
              label: "Пчелник"
            },
            {
              value: "Рудник",
              label: "Рудник"
            },
            {
              value: "Солник",
              label: "Солник"
            },
            {
              value: "Старо Оряхово",
              label: "Старо Оряхово"
            },
            {
              value: "Шкорпиловци",
              label: "Шкорпиловци"
            },
            {
              value: "Юнец",
              label: "Юнец"
            }
          ]
        },
        {
          value: "Дългопол",
          label: "Дългопол",
          list: [
            {
              value: "Арковна",
              label: "Арковна"
            },
            {
              value: "Аспарухово",
              label: "Аспарухово"
            },
            {
              value: "Боряна",
              label: "Боряна"
            },
            {
              value: "Величково",
              label: "Величково"
            },
            {
              value: "Дебелец",
              label: "Дебелец"
            },
            {
              value: "Дългопол",
              label: "Дългопол"
            },
            {
              value: "Камен дял",
              label: "Камен дял"
            },
            {
              value: "Комунари",
              label: "Комунари"
            },
            {
              value: "Красимир",
              label: "Красимир"
            },
            {
              value: "Лопушна",
              label: "Лопушна"
            },
            {
              value: "Медовец",
              label: "Медовец"
            },
            {
              value: "Партизани",
              label: "Партизани"
            },
            {
              value: "Поляците",
              label: "Поляците"
            },
            {
              value: "Рояк",
              label: "Рояк"
            },
            {
              value: "Сава",
              label: "Сава"
            },
            {
              value: "Сладка вода",
              label: "Сладка вода"
            },
            {
              value: "Цонево",
              label: "Цонево"
            }
          ]
        },
        {
          value: "Провадия",
          label: "Провадия",
          list: [
            {
              value: "Блъсково",
              label: "Блъсково"
            },
            {
              value: "Бозвелийско",
              label: "Бозвелийско"
            },
            {
              value: "Бързица",
              label: "Бързица"
            },
            {
              value: "Венчан",
              label: "Венчан"
            },
            {
              value: "Градинарово",
              label: "Градинарово"
            },
            {
              value: "Добрина",
              label: "Добрина"
            },
            {
              value: "Житница",
              label: "Житница"
            },
            {
              value: "Златина",
              label: "Златина"
            },
            {
              value: "Китен",
              label: "Китен"
            },
            {
              value: "Комарево",
              label: "Комарево"
            },
            {
              value: "Кривня",
              label: "Кривня"
            },
            {
              value: "Манастир",
              label: "Манастир"
            },
            {
              value: "Неново",
              label: "Неново"
            },
            {
              value: "Овчага",
              label: "Овчага"
            },
            {
              value: "Петров дол",
              label: "Петров дол"
            },
            {
              value: "Провадия",
              label: "Провадия"
            },
            {
              value: "Равна",
              label: "Равна"
            },
            {
              value: "Славейково",
              label: "Славейково"
            },
            {
              value: "Снежина",
              label: "Снежина"
            },
            {
              value: "Староселец",
              label: "Староселец"
            },
            {
              value: "Тутраканци",
              label: "Тутраканци"
            },
            {
              value: "Храброво",
              label: "Храброво"
            },
            {
              value: "Чайка",
              label: "Чайка"
            },
            {
              value: "Черковна",
              label: "Черковна"
            },
            {
              value: "Черноок",
              label: "Черноок"
            }
          ]
        },
        {
          value: "Суворово",
          label: "Суворово",
          list: [
            {
              value: "Баново",
              label: "Баново"
            },
            {
              value: "Дръндар",
              label: "Дръндар"
            },
            {
              value: "Изгрев",
              label: "Изгрев"
            },
            {
              value: "Калиманци",
              label: "Калиманци"
            },
            {
              value: "Левски",
              label: "Левски"
            },
            {
              value: "Николаевка",
              label: "Николаевка"
            },
            {
              value: "Просечен",
              label: "Просечен"
            },
            {
              value: "Суворово",
              label: "Суворово"
            },
            {
              value: "Чернево",
              label: "Чернево"
            }
          ]
        }
      ]
    },
    {
      value: "Велико Търново",
      label: "Велико Търново",
      list: [
        {
          value: "Велико Търново",
          label: "Велико Търново",
          list: [
            {
              value: "Арбанаси",
              label: "Арбанаси"
            },
            {
              value: "Балван",
              label: "Балван"
            },
            {
              value: "Белчевци",
              label: "Белчевци"
            },
            {
              value: "Беляковец",
              label: "Беляковец"
            },
            {
              value: "Бижовци",
              label: "Бижовци"
            },
            {
              value: "Бойчеви колиби",
              label: "Бойчеви колиби"
            },
            {
              value: "Бойчовци",
              label: "Бойчовци"
            },
            {
              value: "Бочковци",
              label: "Бочковци"
            },
            {
              value: "Бояновци",
              label: "Бояновци"
            },
            {
              value: "Бранковци",
              label: "Бранковци"
            },
            {
              value: "Буковец",
              label: "Буковец"
            },
            {
              value: "Велико Търново",
              label: "Велико Търново"
            },
            {
              value: "Велчево",
              label: "Велчево"
            },
            {
              value: "Ветринци",
              label: "Ветринци"
            },
            {
              value: "Виларе",
              label: "Виларе"
            },
            {
              value: "Водолей",
              label: "Водолей"
            },
            {
              value: "Войнежа",
              label: "Войнежа"
            },
            {
              value: "Вонеща вода",
              label: "Вонеща вода"
            },
            {
              value: "Въглевци",
              label: "Въглевци"
            },
            {
              value: "Върлинка",
              label: "Върлинка"
            },
            {
              value: "Габровци",
              label: "Габровци"
            },
            {
              value: "Гашевци",
              label: "Гашевци"
            },
            {
              value: "Големаните",
              label: "Големаните"
            },
            {
              value: "Горановци",
              label: "Горановци"
            },
            {
              value: "Горен Еневец",
              label: "Горен Еневец"
            },
            {
              value: "Дебелец",
              label: "Дебелец"
            },
            {
              value: "Деветаците",
              label: "Деветаците"
            },
            {
              value: "Дечковци",
              label: "Дечковци"
            },
            {
              value: "Димитровци",
              label: "Димитровци"
            },
            {
              value: "Димовци",
              label: "Димовци"
            },
            {
              value: "Дичин",
              label: "Дичин"
            },
            {
              value: "Дойновци",
              label: "Дойновци"
            },
            {
              value: "Долен Еневец",
              label: "Долен Еневец"
            },
            {
              value: "Долни Дамяновци",
              label: "Долни Дамяновци"
            },
            {
              value: "Дунавци",
              label: "Дунавци"
            },
            {
              value: "Емен",
              label: "Емен"
            },
            {
              value: "Ивановци",
              label: "Ивановци"
            },
            {
              value: "Илевци",
              label: "Илевци"
            },
            {
              value: "Йовчевци",
              label: "Йовчевци"
            },
            {
              value: "Килифарево",
              label: "Килифарево"
            },
            {
              value: "Кисьовци",
              label: "Кисьовци"
            },
            {
              value: "Кладни дял",
              label: "Кладни дял"
            },
            {
              value: "Клъшка река",
              label: "Клъшка река"
            },
            {
              value: "Куцаровци",
              label: "Куцаровци"
            },
            {
              value: "Къпиново",
              label: "Къпиново"
            },
            {
              value: "Лагерите",
              label: "Лагерите"
            },
            {
              value: "Леденик",
              label: "Леденик"
            },
            {
              value: "Малки чифлик",
              label: "Малки чифлик"
            },
            {
              value: "Малчовци",
              label: "Малчовци"
            },
            {
              value: "Марговци",
              label: "Марговци"
            },
            {
              value: "Миндя",
              label: "Миндя"
            },
            {
              value: "Мишеморков хан",
              label: "Мишеморков хан"
            },
            {
              value: "Момин сбор",
              label: "Момин сбор"
            },
            {
              value: "Нацовци",
              label: "Нацовци"
            },
            {
              value: "Никюп",
              label: "Никюп"
            },
            {
              value: "Ново село",
              label: "Ново село"
            },
            {
              value: "Осенарите",
              label: "Осенарите"
            },
            {
              value: "Пирамидата",
              label: "Пирамидата"
            },
            {
              value: "Плаково",
              label: "Плаково"
            },
            {
              value: "Пожерник",
              label: "Пожерник"
            },
            {
              value: "Поповци",
              label: "Поповци"
            },
            {
              value: "Преображенски манастир",
              label: "Преображенски манастир"
            },
            {
              value: "Присово",
              label: "Присово"
            },
            {
              value: "Продановци",
              label: "Продановци"
            },
            {
              value: "Пушево",
              label: "Пушево"
            },
            {
              value: "Пчелище",
              label: "Пчелище"
            },
            {
              value: "Пъровци",
              label: "Пъровци"
            },
            {
              value: "Радковци",
              label: "Радковци"
            },
            {
              value: "Райковци",
              label: "Райковци"
            },
            {
              value: "Рашевци",
              label: "Рашевци"
            },
            {
              value: "Ресен",
              label: "Ресен"
            },
            {
              value: "Русаля",
              label: "Русаля"
            },
            {
              value: "Русковци",
              label: "Русковци"
            },
            {
              value: "Самоводене",
              label: "Самоводене"
            },
            {
              value: "Самсиите",
              label: "Самсиите"
            },
            {
              value: "Сеймените",
              label: "Сеймените"
            },
            {
              value: "Семковци",
              label: "Семковци"
            },
            {
              value: "Суха река",
              label: "Суха река"
            },
            {
              value: "Сърненци",
              label: "Сърненци"
            },
            {
              value: "Терзиите",
              label: "Терзиите"
            },
            {
              value: "Тодоровци",
              label: "Тодоровци"
            },
            {
              value: "Ушевци",
              label: "Ушевци"
            },
            {
              value: "Хотница",
              label: "Хотница"
            },
            {
              value: "Цепераните",
              label: "Цепераните"
            },
            {
              value: "Церова кория",
              label: "Церова кория"
            },
            {
              value: "Цонковци",
              label: "Цонковци"
            },
            {
              value: "Шемшево",
              label: "Шемшево"
            },
            {
              value: "Шереметя",
              label: "Шереметя"
            },
            {
              value: "Шодековци",
              label: "Шодековци"
            },
            {
              value: "Ялово",
              label: "Ялово"
            }
          ]
        },
        {
          value: "Горна Оряховица",
          label: "Горна Оряховица",
          list: [
            {
              value: "Върбица",
              label: "Върбица"
            },
            {
              value: "Гара Горна Оряховица",
              label: "Гара Горна Оряховица"
            },
            {
              value: "Горна Оряховица",
              label: "Горна Оряховица"
            },
            {
              value: "Горски горен Тръмбеш",
              label: "Горски горен Тръмбеш"
            },
            {
              value: "Горски долен Тръмбеш",
              label: "Горски долен Тръмбеш"
            },
            {
              value: "Долна Оряховица",
              label: "Долна Оряховица"
            },
            {
              value: "Драганово",
              label: "Драганово"
            },
            {
              value: "Крушето",
              label: "Крушето"
            },
            {
              value: "Паисий",
              label: "Паисий"
            },
            {
              value: "Писарево",
              label: "Писарево"
            },
            {
              value: "Поликраище",
              label: "Поликраище"
            },
            {
              value: "Правда",
              label: "Правда"
            },
            {
              value: "Първомайци",
              label: "Първомайци"
            },
            {
              value: "Стрелец",
              label: "Стрелец"
            },
            {
              value: "Янтра",
              label: "Янтра"
            }
          ]
        },
        {
          value: "Елена",
          label: "Елена",
          list: [
            {
              value: "Аплаци",
              label: "Аплаци"
            },
            {
              value: "Багалевци",
              label: "Багалевци"
            },
            {
              value: "Бадевци",
              label: "Бадевци"
            },
            {
              value: "Баевци",
              label: "Баевци"
            },
            {
              value: "Баждари",
              label: "Баждари"
            },
            {
              value: "Балуци",
              label: "Балуци"
            },
            {
              value: "Беброво",
              label: "Беброво"
            },
            {
              value: "Бейковци",
              label: "Бейковци"
            },
            {
              value: "Берковци",
              label: "Берковци"
            },
            {
              value: "Блъсковци",
              label: "Блъсковци"
            },
            {
              value: "Богданско",
              label: "Богданско"
            },
            {
              value: "Бойковци",
              label: "Бойковци"
            },
            {
              value: "Босевци",
              label: "Босевци"
            },
            {
              value: "Брезово",
              label: "Брезово"
            },
            {
              value: "Бръчковци",
              label: "Бръчковци"
            },
            {
              value: "Буйновци",
              label: "Буйновци"
            },
            {
              value: "Бялковци",
              label: "Бялковци"
            },
            {
              value: "Валето",
              label: "Валето"
            },
            {
              value: "Велковци",
              label: "Велковци"
            },
            {
              value: "Велювци",
              label: "Велювци"
            },
            {
              value: "Веселина",
              label: "Веселина"
            },
            {
              value: "Високовци",
              label: "Високовци"
            },
            {
              value: "Вълчовци",
              label: "Вълчовци"
            },
            {
              value: "Вълчовци",
              label: "Вълчовци"
            },
            {
              value: "Вързилковци",
              label: "Вързилковци"
            },
            {
              value: "Габрака",
              label: "Габрака"
            },
            {
              value: "Ганев Дол",
              label: "Ганев Дол"
            },
            {
              value: "Глоговец",
              label: "Глоговец"
            },
            {
              value: "Големани",
              label: "Големани"
            },
            {
              value: "Горни Край",
              label: "Горни Край"
            },
            {
              value: "Горни Танчевци",
              label: "Горни Танчевци"
            },
            {
              value: "Горска",
              label: "Горска"
            },
            {
              value: "Граматици",
              label: "Граматици"
            },
            {
              value: "Гърдевци",
              label: "Гърдевци"
            },
            {
              value: "Давери",
              label: "Давери"
            },
            {
              value: "Дайновци",
              label: "Дайновци"
            },
            {
              value: "Дебели рът",
              label: "Дебели рът"
            },
            {
              value: "Добревци",
              label: "Добревци"
            },
            {
              value: "Долни Марян",
              label: "Долни Марян"
            },
            {
              value: "Долни Танчевци",
              label: "Долни Танчевци"
            },
            {
              value: "Донковци",
              label: "Донковци"
            },
            {
              value: "Драгановци",
              label: "Драгановци"
            },
            {
              value: "Драганосковци",
              label: "Драганосковци"
            },
            {
              value: "Драгийци",
              label: "Драгийци"
            },
            {
              value: "Драгневци",
              label: "Драгневци"
            },
            {
              value: "Дрента",
              label: "Дрента"
            },
            {
              value: "Дуковци",
              label: "Дуковци"
            },
            {
              value: "Дърлевци",
              label: "Дърлевци"
            },
            {
              value: "Елена",
              label: "Елена"
            },
            {
              value: "Зеленик",
              label: "Зеленик"
            },
            {
              value: "Иванивановци",
              label: "Иванивановци"
            },
            {
              value: "Игнатовци",
              label: "Игнатовци"
            },
            {
              value: "Илаков рът",
              label: "Илаков рът"
            },
            {
              value: "Илиювци",
              label: "Илиювци"
            },
            {
              value: "Каменари",
              label: "Каменари"
            },
            {
              value: "Кантари",
              label: "Кантари"
            },
            {
              value: "Караиванци",
              label: "Караиванци"
            },
            {
              value: "Карандили",
              label: "Карандили"
            },
            {
              value: "Киревци",
              label: "Киревци"
            },
            {
              value: "Кожлювци",
              label: "Кожлювци"
            },
            {
              value: "Козя река",
              label: "Козя река"
            },
            {
              value: "Колари",
              label: "Колари"
            },
            {
              value: "Константин",
              label: "Константин"
            },
            {
              value: "Косевци",
              label: "Косевци"
            },
            {
              value: "Костел",
              label: "Костел"
            },
            {
              value: "Котуци",
              label: "Котуци"
            },
            {
              value: "Крилювци",
              label: "Крилювци"
            },
            {
              value: "Крумчевци",
              label: "Крумчевци"
            },
            {
              value: "Лазарци",
              label: "Лазарци"
            },
            {
              value: "Лесиче",
              label: "Лесиче"
            },
            {
              value: "Майско",
              label: "Майско"
            },
            {
              value: "Марафелци",
              label: "Марафелци"
            },
            {
              value: "Мариновци",
              label: "Мариновци"
            },
            {
              value: "Марян",
              label: "Марян"
            },
            {
              value: "Махалници",
              label: "Махалници"
            },
            {
              value: "Мийковци",
              label: "Мийковци"
            },
            {
              value: "Миневци",
              label: "Миневци"
            },
            {
              value: "Мирчовци",
              label: "Мирчовци"
            },
            {
              value: "Мъртвината",
              label: "Мъртвината"
            },
            {
              value: "Недялковци",
              label: "Недялковци"
            },
            {
              value: "Нешевци",
              label: "Нешевци"
            },
            {
              value: "Николовци",
              label: "Николовци"
            },
            {
              value: "Николчовци",
              label: "Николчовци"
            },
            {
              value: "Ничовци",
              label: "Ничовци"
            },
            {
              value: "Нюшковци",
              label: "Нюшковци"
            },
            {
              value: "Палици",
              label: "Палици"
            },
            {
              value: "Папратлива",
              label: "Папратлива"
            },
            {
              value: "Пейковци",
              label: "Пейковци"
            },
            {
              value: "Петковци",
              label: "Петковци"
            },
            {
              value: "Попрусевци",
              label: "Попрусевци"
            },
            {
              value: "Попска",
              label: "Попска"
            },
            {
              value: "Радовци",
              label: "Радовци"
            },
            {
              value: "Райновци",
              label: "Райновци"
            },
            {
              value: "Ралиновци",
              label: "Ралиновци"
            },
            {
              value: "Раювци",
              label: "Раювци"
            },
            {
              value: "Ребревци",
              label: "Ребревци"
            },
            {
              value: "Руховци",
              label: "Руховци"
            },
            {
              value: "Светославци",
              label: "Светославци"
            },
            {
              value: "Средни колиби",
              label: "Средни колиби"
            },
            {
              value: "Стойчевци",
              label: "Стойчевци"
            },
            {
              value: "Стояновци",
              label: "Стояновци"
            },
            {
              value: "Султани",
              label: "Султани"
            },
            {
              value: "Събковци",
              label: "Събковци"
            },
            {
              value: "Титевци",
              label: "Титевци"
            },
            {
              value: "Тодювци",
              label: "Тодювци"
            },
            {
              value: "Томбето",
              label: "Томбето"
            },
            {
              value: "Топузи",
              label: "Топузи"
            },
            {
              value: "Трънковци",
              label: "Трънковци"
            },
            {
              value: "Тумбевци",
              label: "Тумбевци"
            },
            {
              value: "Тънки рът",
              label: "Тънки рът"
            },
            {
              value: "Търкашени",
              label: "Търкашени"
            },
            {
              value: "Угорляковци",
              label: "Угорляковци"
            },
            {
              value: "Харваловци",
              label: "Харваловци"
            },
            {
              value: "Христовци",
              label: "Христовци"
            },
            {
              value: "Хъневци",
              label: "Хъневци"
            },
            {
              value: "Цвеклювци",
              label: "Цвеклювци"
            },
            {
              value: "Чавдарци",
              label: "Чавдарци"
            },
            {
              value: "Чакали",
              label: "Чакали"
            },
            {
              value: "Червенковци",
              label: "Червенковци"
            },
            {
              value: "Черни Дял",
              label: "Черни Дял"
            },
            {
              value: "Шиливери",
              label: "Шиливери"
            },
            {
              value: "Шилковци",
              label: "Шилковци"
            },
            {
              value: "Шубеци",
              label: "Шубеци"
            },
            {
              value: "Яковци",
              label: "Яковци"
            }
          ]
        },
        {
          value: "Златарица",
          label: "Златарица",
          list: [
            {
              value: "Горна Хаджийска",
              label: "Горна Хаджийска"
            },
            {
              value: "Горско ново село",
              label: "Горско ново село"
            },
            {
              value: "Горско Писарево",
              label: "Горско Писарево"
            },
            {
              value: "Дедина",
              label: "Дедина"
            },
            {
              value: "Дединци",
              label: "Дединци"
            },
            {
              value: "Делова махала",
              label: "Делова махала"
            },
            {
              value: "Долно Шивачево",
              label: "Долно Шивачево"
            },
            {
              value: "Дуровци",
              label: "Дуровци"
            },
            {
              value: "Дълги припек",
              label: "Дълги припек"
            },
            {
              value: "Златарица",
              label: "Златарица"
            },
            {
              value: "Калайджии",
              label: "Калайджии"
            },
            {
              value: "Новогорци",
              label: "Новогорци"
            },
            {
              value: "Овощна",
              label: "Овощна"
            },
            {
              value: "Равново",
              label: "Равново"
            },
            {
              value: "Разсоха",
              label: "Разсоха"
            },
            {
              value: "Резач",
              label: "Резач"
            },
            {
              value: "Рекичка",
              label: "Рекичка"
            },
            {
              value: "Родина",
              label: "Родина"
            },
            {
              value: "Росно",
              label: "Росно"
            },
            {
              value: "Сливовица",
              label: "Сливовица"
            },
            {
              value: "Средно село",
              label: "Средно село"
            },
            {
              value: "Чешма",
              label: "Чешма"
            },
            {
              value: "Чистово",
              label: "Чистово"
            },
            {
              value: "Чуката",
              label: "Чуката"
            }
          ]
        },
        {
          value: "Лясковец",
          label: "Лясковец",
          list: [
            {
              value: "Джулюница",
              label: "Джулюница"
            },
            {
              value: "Добри дял",
              label: "Добри дял"
            },
            {
              value: "Драгижево",
              label: "Драгижево"
            },
            {
              value: "Козаревец",
              label: "Козаревец"
            },
            {
              value: "Лясковец",
              label: "Лясковец"
            },
            {
              value: "Мерданя",
              label: "Мерданя"
            }
          ]
        },
        {
          value: "Павликени",
          label: "Павликени",
          list: [
            {
              value: "Батак",
              label: "Батак"
            },
            {
              value: "Бутово",
              label: "Бутово"
            },
            {
              value: "Бяла черква",
              label: "Бяла черква"
            },
            {
              value: "Вишовград",
              label: "Вишовград"
            },
            {
              value: "Върбовка",
              label: "Върбовка"
            },
            {
              value: "Горна Липница",
              label: "Горна Липница"
            },
            {
              value: "Димча",
              label: "Димча"
            },
            {
              value: "Долна Липница",
              label: "Долна Липница"
            },
            {
              value: "Дъскот",
              label: "Дъскот"
            },
            {
              value: "Караисен",
              label: "Караисен"
            },
            {
              value: "Лесичери",
              label: "Лесичери"
            },
            {
              value: "Михалци",
              label: "Михалци"
            },
            {
              value: "Мусина",
              label: "Мусина"
            },
            {
              value: "Недан",
              label: "Недан"
            },
            {
              value: "Павликени",
              label: "Павликени"
            },
            {
              value: "Паскалевец",
              label: "Паскалевец"
            },
            {
              value: "Патреш",
              label: "Патреш"
            },
            {
              value: "Росица",
              label: "Росица"
            },
            {
              value: "Сломер",
              label: "Сломер"
            },
            {
              value: "Стамболово",
              label: "Стамболово"
            }
          ]
        },
        {
          value: "Полски Тръмбеш",
          label: "Полски Тръмбеш",
          list: [
            {
              value: "Вързулица",
              label: "Вързулица"
            },
            {
              value: "Иванча",
              label: "Иванча"
            },
            {
              value: "Каранци",
              label: "Каранци"
            },
            {
              value: "Климентово",
              label: "Климентово"
            },
            {
              value: "Куцина",
              label: "Куцина"
            },
            {
              value: "Масларево",
              label: "Масларево"
            },
            {
              value: "Обединение",
              label: "Обединение"
            },
            {
              value: "Орловец",
              label: "Орловец"
            },
            {
              value: "Павел",
              label: "Павел"
            },
            {
              value: "Петко Каравелово",
              label: "Петко Каравелово"
            },
            {
              value: "Полски Сеновец",
              label: "Полски Сеновец"
            },
            {
              value: "Полски Тръмбеш",
              label: "Полски Тръмбеш"
            },
            {
              value: "Раданово",
              label: "Раданово"
            },
            {
              value: "Стефан Стамболово",
              label: "Стефан Стамболово"
            },
            {
              value: "Страхилово",
              label: "Страхилово"
            }
          ]
        },
        {
          value: "Свищов",
          label: "Свищов",
          list: [
            {
              value: "Алеково",
              label: "Алеково"
            },
            {
              value: "Александрово",
              label: "Александрово"
            },
            {
              value: "Българско Сливово",
              label: "Българско Сливово"
            },
            {
              value: "Вардим",
              label: "Вардим"
            },
            {
              value: "Горна Студена",
              label: "Горна Студена"
            },
            {
              value: "Деляновци",
              label: "Деляновци"
            },
            {
              value: "Драгомирово",
              label: "Драгомирово"
            },
            {
              value: "Козловец",
              label: "Козловец"
            },
            {
              value: "Морава",
              label: "Морава"
            },
            {
              value: "Овча могила",
              label: "Овча могила"
            },
            {
              value: "Ореш",
              label: "Ореш"
            },
            {
              value: "Свищов",
              label: "Свищов"
            },
            {
              value: "Совата",
              label: "Совата"
            },
            {
              value: "Хаджидимитрово",
              label: "Хаджидимитрово"
            },
            {
              value: "Царевец",
              label: "Царевец"
            },
            {
              value: "Червена",
              label: "Червена"
            }
          ]
        },
        {
          value: "Стражица",
          label: "Стражица",
          list: [
            {
              value: "Асеново",
              label: "Асеново"
            },
            {
              value: "Балканци",
              label: "Балканци"
            },
            {
              value: "Благоево",
              label: "Благоево"
            },
            {
              value: "Бряговица",
              label: "Бряговица"
            },
            {
              value: "Виноград",
              label: "Виноград"
            },
            {
              value: "Владислав",
              label: "Владислав"
            },
            {
              value: "Водно",
              label: "Водно"
            },
            {
              value: "Горски Сеновец",
              label: "Горски Сеновец"
            },
            {
              value: "Железарци",
              label: "Железарци"
            },
            {
              value: "Кавлак",
              label: "Кавлак"
            },
            {
              value: "Камен",
              label: "Камен"
            },
            {
              value: "Кесарево",
              label: "Кесарево"
            },
            {
              value: "Лозен",
              label: "Лозен"
            },
            {
              value: "Любенци",
              label: "Любенци"
            },
            {
              value: "Мирово",
              label: "Мирово"
            },
            {
              value: "Николаево",
              label: "Николаево"
            },
            {
              value: "Нова Върбовка",
              label: "Нова Върбовка"
            },
            {
              value: "Ново градище",
              label: "Ново градище"
            },
            {
              value: "Стражица",
              label: "Стражица"
            },
            {
              value: "Сушица",
              label: "Сушица"
            },
            {
              value: "Теменуга",
              label: "Теменуга"
            },
            {
              value: "Царски извор",
              label: "Царски извор"
            }
          ]
        },
        {
          value: "Сухиндол",
          label: "Сухиндол",
          list: [
            {
              value: "Бяла река",
              label: "Бяла река"
            },
            {
              value: "Горско Калугерово",
              label: "Горско Калугерово"
            },
            {
              value: "Горско Косово",
              label: "Горско Косово"
            },
            {
              value: "Коевци",
              label: "Коевци"
            },
            {
              value: "Красно градище",
              label: "Красно градище"
            },
            {
              value: "Сухиндол",
              label: "Сухиндол"
            }
          ]
        }
      ]
    },
    {
      value: "Видин",
      label: "Видин",
      list: [
        {
          value: "Белоградчик",
          label: "Белоградчик",
          list: [
            {
              value: "Белоградчик",
              label: "Белоградчик"
            },
            {
              value: "Боровица",
              label: "Боровица"
            },
            {
              value: "Вещица",
              label: "Вещица"
            },
            {
              value: "Върба",
              label: "Върба"
            },
            {
              value: "Гранитово",
              label: "Гранитово"
            },
            {
              value: "Граничак",
              label: "Граничак"
            },
            {
              value: "Дъбравка",
              label: "Дъбравка"
            },
            {
              value: "Крачимир",
              label: "Крачимир"
            },
            {
              value: "Ошане",
              label: "Ошане"
            },
            {
              value: "Праужда",
              label: "Праужда"
            },
            {
              value: "Пролазница",
              label: "Пролазница"
            },
            {
              value: "Рабиша",
              label: "Рабиша"
            },
            {
              value: "Раяновци",
              label: "Раяновци"
            },
            {
              value: "Салаш",
              label: "Салаш"
            },
            {
              value: "Сливовник",
              label: "Сливовник"
            },
            {
              value: "Стакевци",
              label: "Стакевци"
            },
            {
              value: "Струиндол",
              label: "Струиндол"
            },
            {
              value: "Чифлик",
              label: "Чифлик"
            }
          ]
        },
        {
          value: "Бойница",
          label: "Бойница",
          list: [
            {
              value: "Бойница",
              label: "Бойница"
            },
            {
              value: "Бориловец",
              label: "Бориловец"
            },
            {
              value: "Градсковски колиби",
              label: "Градсковски колиби"
            },
            {
              value: "Каниц",
              label: "Каниц"
            },
            {
              value: "Периловец",
              label: "Периловец"
            },
            {
              value: "Раброво",
              label: "Раброво"
            },
            {
              value: "Шипикова махала",
              label: "Шипикова махала"
            },
            {
              value: "Шишенци",
              label: "Шишенци"
            }
          ]
        },
        {
          value: "Брегово",
          label: "Брегово",
          list: [
            {
              value: "Балей",
              label: "Балей"
            },
            {
              value: "Брегово",
              label: "Брегово"
            },
            {
              value: "Връв",
              label: "Връв"
            },
            {
              value: "Гъмзово",
              label: "Гъмзово"
            },
            {
              value: "Делейна",
              label: "Делейна"
            },
            {
              value: "Калина",
              label: "Калина"
            },
            {
              value: "Косово",
              label: "Косово"
            },
            {
              value: "Куделин",
              label: "Куделин"
            },
            {
              value: "Ракитница",
              label: "Ракитница"
            },
            {
              value: "Тияновци",
              label: "Тияновци"
            }
          ]
        },
        {
          value: "Видин",
          label: "Видин",
          list: [
            {
              value: "Акациево",
              label: "Акациево"
            },
            {
              value: "Антимово",
              label: "Антимово"
            },
            {
              value: "Бела Рада",
              label: "Бела Рада"
            },
            {
              value: "Ботево",
              label: "Ботево"
            },
            {
              value: "Буковец",
              label: "Буковец"
            },
            {
              value: "Видин",
              label: "Видин"
            },
            {
              value: "Войница",
              label: "Войница"
            },
            {
              value: "Въртоп",
              label: "Въртоп"
            },
            {
              value: "Гайтанци",
              label: "Гайтанци"
            },
            {
              value: "Генерал Мариново",
              label: "Генерал Мариново"
            },
            {
              value: "Гомотарци",
              label: "Гомотарци"
            },
            {
              value: "Градец",
              label: "Градец"
            },
            {
              value: "Динковица",
              label: "Динковица"
            },
            {
              value: "Долни Бошняк",
              label: "Долни Бошняк"
            },
            {
              value: "Дружба",
              label: "Дружба"
            },
            {
              value: "Дунавци",
              label: "Дунавци"
            },
            {
              value: "Жеглица",
              label: "Жеглица"
            },
            {
              value: "Ивановци",
              label: "Ивановци"
            },
            {
              value: "Иново",
              label: "Иново"
            },
            {
              value: "Каленик",
              label: "Каленик"
            },
            {
              value: "Капитановци",
              label: "Капитановци"
            },
            {
              value: "Кошава",
              label: "Кошава"
            },
            {
              value: "Кутово",
              label: "Кутово"
            },
            {
              value: "Майор Узуново",
              label: "Майор Узуново"
            },
            {
              value: "Новоселци",
              label: "Новоселци"
            },
            {
              value: "Пешаково",
              label: "Пешаково"
            },
            {
              value: "Плакудер",
              label: "Плакудер"
            },
            {
              value: "Покрайна",
              label: "Покрайна"
            },
            {
              value: "Рупци",
              label: "Рупци"
            },
            {
              value: "Синаговци",
              label: "Синаговци"
            },
            {
              value: "Слана бара",
              label: "Слана бара"
            },
            {
              value: "Сланотрън",
              label: "Сланотрън"
            },
            {
              value: "Търняне",
              label: "Търняне"
            },
            {
              value: "Цар Симеоново",
              label: "Цар Симеоново"
            }
          ]
        },
        {
          value: "Грамада",
          label: "Грамада",
          list: [
            {
              value: "Бояново",
              label: "Бояново"
            },
            {
              value: "Бранковци",
              label: "Бранковци"
            },
            {
              value: "Водна",
              label: "Водна"
            },
            {
              value: "Грамада",
              label: "Грамада"
            },
            {
              value: "Медешевци",
              label: "Медешевци"
            },
            {
              value: "Милчина лъка",
              label: "Милчина лъка"
            },
            {
              value: "Срацимирово",
              label: "Срацимирово"
            },
            {
              value: "Тошевци",
              label: "Тошевци"
            }
          ]
        },
        {
          value: "Димово",
          label: "Димово",
          list: [
            {
              value: "Арчар",
              label: "Арчар"
            },
            {
              value: "Бела",
              label: "Бела"
            },
            {
              value: "Владиченци",
              label: "Владиченци"
            },
            {
              value: "Воднянци",
              label: "Воднянци"
            },
            {
              value: "Върбовчец",
              label: "Върбовчец"
            },
            {
              value: "Гара Орешец",
              label: "Гара Орешец"
            },
            {
              value: "Димово",
              label: "Димово"
            },
            {
              value: "Дълго поле",
              label: "Дълго поле"
            },
            {
              value: "Държаница",
              label: "Държаница"
            },
            {
              value: "Извор",
              label: "Извор"
            },
            {
              value: "Карбинци",
              label: "Карбинци"
            },
            {
              value: "Кладоруб",
              label: "Кладоруб"
            },
            {
              value: "Костичовци",
              label: "Костичовци"
            },
            {
              value: "Лагошевци",
              label: "Лагошевци"
            },
            {
              value: "Мали Дреновец",
              label: "Мали Дреновец"
            },
            {
              value: "Медовница",
              label: "Медовница"
            },
            {
              value: "Орешец",
              label: "Орешец"
            },
            {
              value: "Острокапци",
              label: "Острокапци"
            },
            {
              value: "Септемврийци",
              label: "Септемврийци"
            },
            {
              value: "Скомля",
              label: "Скомля"
            },
            {
              value: "Шипот",
              label: "Шипот"
            },
            {
              value: "Яньовец",
              label: "Яньовец"
            },
            {
              value: "Ярловица",
              label: "Ярловица"
            }
          ]
        },
        {
          value: "Кула",
          label: "Кула",
          list: [
            {
              value: "Големаново",
              label: "Големаново"
            },
            {
              value: "Извор махала",
              label: "Извор махала"
            },
            {
              value: "Коста Перчево",
              label: "Коста Перчево"
            },
            {
              value: "Кула",
              label: "Кула"
            },
            {
              value: "Полетковци",
              label: "Полетковци"
            },
            {
              value: "Старопатица",
              label: "Старопатица"
            },
            {
              value: "Тополовец",
              label: "Тополовец"
            },
            {
              value: "Цар Петрово",
              label: "Цар Петрово"
            },
            {
              value: "Чичил",
              label: "Чичил"
            }
          ]
        },
        {
          value: "Макреш",
          label: "Макреш",
          list: [
            {
              value: "Вълчек",
              label: "Вълчек"
            },
            {
              value: "Киреево",
              label: "Киреево"
            },
            {
              value: "Макреш",
              label: "Макреш"
            },
            {
              value: "Подгорe",
              label: "Подгорe"
            },
            {
              value: "Раковица",
              label: "Раковица"
            },
            {
              value: "Толовица",
              label: "Толовица"
            },
            {
              value: "Цар Шишманово",
              label: "Цар Шишманово"
            }
          ]
        },
        {
          value: "Ново село",
          label: "Ново село",
          list: [
            {
              value: "Винарово",
              label: "Винарово"
            },
            {
              value: "Неговановци",
              label: "Неговановци"
            },
            {
              value: "Ново село",
              label: "Ново село"
            },
            {
              value: "Флорентин",
              label: "Флорентин"
            },
            {
              value: "Ясен",
              label: "Ясен"
            }
          ]
        },
        {
          value: "Ружинци",
          label: "Ружинци",
          list: [
            {
              value: "Бело поле",
              label: "Бело поле"
            },
            {
              value: "Гюргич",
              label: "Гюргич"
            },
            {
              value: "Динково",
              label: "Динково"
            },
            {
              value: "Дражинци",
              label: "Дражинци"
            },
            {
              value: "Дреновец",
              label: "Дреновец"
            },
            {
              value: "Плешивец",
              label: "Плешивец"
            },
            {
              value: "Роглец",
              label: "Роглец"
            },
            {
              value: "Ружинци",
              label: "Ружинци"
            },
            {
              value: "Тополовец",
              label: "Тополовец"
            },
            {
              value: "Черно поле",
              label: "Черно поле"
            }
          ]
        },
        {
          value: "Чупрене",
          label: "Чупрене",
          list: [
            {
              value: "Върбово",
              label: "Върбово"
            },
            {
              value: "Горни Лом",
              label: "Горни Лом"
            },
            {
              value: "Долни Лом",
              label: "Долни Лом"
            },
            {
              value: "Протопопинци",
              label: "Протопопинци"
            },
            {
              value: "Репляна",
              label: "Репляна"
            },
            {
              value: "Средогрив",
              label: "Средогрив"
            },
            {
              value: "Търговище",
              label: "Търговище"
            },
            {
              value: "Чупрене",
              label: "Чупрене"
            }
          ]
        }
      ]
    },
    {
      value: "Враца",
      label: "Враца",
      list: [
        {
          value: "Борован",
          label: "Борован",
          list: [
            {
              value: "Борован",
              label: "Борован"
            },
            {
              value: "Добролево",
              label: "Добролево"
            },
            {
              value: "Малорад",
              label: "Малорад"
            },
            {
              value: "Нивянин",
              label: "Нивянин"
            },
            {
              value: "Сираково",
              label: "Сираково"
            }
          ]
        },
        {
          value: "Бяла Слатина",
          label: "Бяла Слатина",
          list: [
            {
              value: "Алтимир",
              label: "Алтимир"
            },
            {
              value: "Буковец",
              label: "Буковец"
            },
            {
              value: "Бърдарски геран",
              label: "Бърдарски геран"
            },
            {
              value: "Бъркачево",
              label: "Бъркачево"
            },
            {
              value: "Бяла Слатина",
              label: "Бяла Слатина"
            },
            {
              value: "Враняк",
              label: "Враняк"
            },
            {
              value: "Габаре",
              label: "Габаре"
            },
            {
              value: "Галиче",
              label: "Галиче"
            },
            {
              value: "Драшан",
              label: "Драшан"
            },
            {
              value: "Комарево",
              label: "Комарево"
            },
            {
              value: "Попица",
              label: "Попица"
            },
            {
              value: "Соколаре",
              label: "Соколаре"
            },
            {
              value: "Тлачене",
              label: "Тлачене"
            },
            {
              value: "Търнава",
              label: "Търнава"
            },
            {
              value: "Търнак",
              label: "Търнак"
            }
          ]
        },
        {
          value: "Враца",
          label: "Враца",
          list: [
            {
              value: "Баница",
              label: "Баница"
            },
            {
              value: "Бели извор",
              label: "Бели извор"
            },
            {
              value: "Веслец",
              label: "Веслец"
            },
            {
              value: "Вировско",
              label: "Вировско"
            },
            {
              value: "Власатица",
              label: "Власатица"
            },
            {
              value: "Враца",
              label: "Враца"
            },
            {
              value: "Върбица",
              label: "Върбица"
            },
            {
              value: "Голямо Пещене",
              label: "Голямо Пещене"
            },
            {
              value: "Горно Пещене",
              label: "Горно Пещене"
            },
            {
              value: "Девене",
              label: "Девене"
            },
            {
              value: "Згориград",
              label: "Згориград"
            },
            {
              value: "Костелево",
              label: "Костелево"
            },
            {
              value: "Леденика",
              label: "Леденика"
            },
            {
              value: "Лиляче",
              label: "Лиляче"
            },
            {
              value: "Лютаджик",
              label: "Лютаджик"
            },
            {
              value: "Мало Пещене",
              label: "Мало Пещене"
            },
            {
              value: "Мраморен",
              label: "Мраморен"
            },
            {
              value: "Нефела",
              label: "Нефела"
            },
            {
              value: "Оходен",
              label: "Оходен"
            },
            {
              value: "Паволче",
              label: "Паволче"
            },
            {
              value: "Тишевица",
              label: "Тишевица"
            },
            {
              value: "Три кладенци",
              label: "Три кладенци"
            },
            {
              value: "Челопек",
              label: "Челопек"
            },
            {
              value: "Чирен",
              label: "Чирен"
            }
          ]
        },
        {
          value: "Козлодуй",
          label: "Козлодуй",
          list: [
            {
              value: "Бутан",
              label: "Бутан"
            },
            {
              value: "Гложене",
              label: "Гложене"
            },
            {
              value: "Козлодуй",
              label: "Козлодуй"
            },
            {
              value: "Крива бара",
              label: "Крива бара"
            },
            {
              value: "Хърлец",
              label: "Хърлец"
            }
          ]
        },
        {
          value: "Криводол",
          label: "Криводол",
          list: [
            {
              value: "Баурене",
              label: "Баурене"
            },
            {
              value: "Ботуня",
              label: "Ботуня"
            },
            {
              value: "Галатин",
              label: "Галатин"
            },
            {
              value: "Главаци",
              label: "Главаци"
            },
            {
              value: "Големо Бабино",
              label: "Големо Бабино"
            },
            {
              value: "Градешница",
              label: "Градешница"
            },
            {
              value: "Добруша",
              label: "Добруша"
            },
            {
              value: "Краводер",
              label: "Краводер"
            },
            {
              value: "Криводол",
              label: "Криводол"
            },
            {
              value: "Лесура",
              label: "Лесура"
            },
            {
              value: "Осен",
              label: "Осен"
            },
            {
              value: "Пудрия",
              label: "Пудрия"
            },
            {
              value: "Ракево",
              label: "Ракево"
            },
            {
              value: "Уровене",
              label: "Уровене"
            },
            {
              value: "Фурен",
              label: "Фурен"
            }
          ]
        },
        {
          value: "Мездра",
          label: "Мездра",
          list: [
            {
              value: "Боденец",
              label: "Боденец"
            },
            {
              value: "Брусен",
              label: "Брусен"
            },
            {
              value: "Върбешница",
              label: "Върбешница"
            },
            {
              value: "Гара Черепиш",
              label: "Гара Черепиш"
            },
            {
              value: "Горна Бешовица",
              label: "Горна Бешовица"
            },
            {
              value: "Горна Кремена",
              label: "Горна Кремена"
            },
            {
              value: "Долна Кремена",
              label: "Долна Кремена"
            },
            {
              value: "Дърманци",
              label: "Дърманци"
            },
            {
              value: "Елисейна",
              label: "Елисейна"
            },
            {
              value: "Зверино",
              label: "Зверино"
            },
            {
              value: "Злидол",
              label: "Злидол"
            },
            {
              value: "Игнатица",
              label: "Игнатица"
            },
            {
              value: "Кален",
              label: "Кален"
            },
            {
              value: "Крапец",
              label: "Крапец"
            },
            {
              value: "Крета",
              label: "Крета"
            },
            {
              value: "Лик",
              label: "Лик"
            },
            {
              value: "Лютиброд",
              label: "Лютиброд"
            },
            {
              value: "Лютидол",
              label: "Лютидол"
            },
            {
              value: "Мездра",
              label: "Мездра"
            },
            {
              value: "Моравица",
              label: "Моравица"
            },
            {
              value: "Оселна",
              label: "Оселна"
            },
            {
              value: "Ослен Криводол",
              label: "Ослен Криводол"
            },
            {
              value: "Очиндол",
              label: "Очиндол"
            },
            {
              value: "Ребърково",
              label: "Ребърково"
            },
            {
              value: "Руска Бела",
              label: "Руска Бела"
            },
            {
              value: "Старо село",
              label: "Старо село"
            },
            {
              value: "Типченица",
              label: "Типченица"
            },
            {
              value: "Цаконица",
              label: "Цаконица"
            },
            {
              value: "Царевец",
              label: "Царевец"
            }
          ]
        },
        {
          value: "Мизия",
          label: "Мизия",
          list: [
            {
              value: "Войводово",
              label: "Войводово"
            },
            {
              value: "Крушовица",
              label: "Крушовица"
            },
            {
              value: "Липница",
              label: "Липница"
            },
            {
              value: "Мизия",
              label: "Мизия"
            },
            {
              value: "Сараево",
              label: "Сараево"
            },
            {
              value: "Софрониево",
              label: "Софрониево"
            }
          ]
        },
        {
          value: "Оряхово",
          label: "Оряхово",
          list: [
            {
              value: "Галово",
              label: "Галово"
            },
            {
              value: "Горни Вадин",
              label: "Горни Вадин"
            },
            {
              value: "Долни Вадин",
              label: "Долни Вадин"
            },
            {
              value: "Лесковец",
              label: "Лесковец"
            },
            {
              value: "Оряхово",
              label: "Оряхово"
            },
            {
              value: "Остров",
              label: "Остров"
            },
            {
              value: "Селановци",
              label: "Селановци"
            }
          ]
        },
        {
          value: "Роман",
          label: "Роман",
          list: [
            {
              value: "Долна Бешовица",
              label: "Долна Бешовица"
            },
            {
              value: "Камено поле",
              label: "Камено поле"
            },
            {
              value: "Караш",
              label: "Караш"
            },
            {
              value: "Кунино",
              label: "Кунино"
            },
            {
              value: "Курново",
              label: "Курново"
            },
            {
              value: "Марково равнище",
              label: "Марково равнище"
            },
            {
              value: "Радовене",
              label: "Радовене"
            },
            {
              value: "Роман",
              label: "Роман"
            },
            {
              value: "Синьо бърдо",
              label: "Синьо бърдо"
            },
            {
              value: "Средни рът",
              label: "Средни рът"
            },
            {
              value: "Стояновци",
              label: "Стояновци"
            },
            {
              value: "Струпец",
              label: "Струпец"
            },
            {
              value: "Хубавене",
              label: "Хубавене"
            }
          ]
        },
        {
          value: "Хайредин",
          label: "Хайредин",
          list: [
            {
              value: "Ботево",
              label: "Ботево"
            },
            {
              value: "Бързина",
              label: "Бързина"
            },
            {
              value: "Манастирище",
              label: "Манастирище"
            },
            {
              value: "Михайлово",
              label: "Михайлово"
            },
            {
              value: "Рогозен",
              label: "Рогозен"
            },
            {
              value: "Хайредин",
              label: "Хайредин"
            }
          ]
        }
      ]
    },
    {
      value: "Габрово",
      label: "Габрово",
      list: [
        {
          value: "Габрово",
          label: "Габрово",
          list: [
            {
              value: "Ангелов",
              label: "Ангелов"
            },
            {
              value: "Армените",
              label: "Армените"
            },
            {
              value: "Баевци",
              label: "Баевци"
            },
            {
              value: "Баланите",
              label: "Баланите"
            },
            {
              value: "Балиновци",
              label: "Балиновци"
            },
            {
              value: "Банковци",
              label: "Банковци"
            },
            {
              value: "Бекриите",
              label: "Бекриите"
            },
            {
              value: "Беломъжите",
              label: "Беломъжите"
            },
            {
              value: "Бобевци",
              label: "Бобевци"
            },
            {
              value: "Богданчовци",
              label: "Богданчовци"
            },
            {
              value: "Боженците",
              label: "Боженците"
            },
            {
              value: "Бойновци",
              label: "Бойновци"
            },
            {
              value: "Бойчета",
              label: "Бойчета"
            },
            {
              value: "Болтата",
              label: "Болтата"
            },
            {
              value: "Борики",
              label: "Борики"
            },
            {
              value: "Борското",
              label: "Борското"
            },
            {
              value: "Брънеците",
              label: "Брънеците"
            },
            {
              value: "Бялково",
              label: "Бялково"
            },
            {
              value: "Велковци",
              label: "Велковци"
            },
            {
              value: "Ветрово",
              label: "Ветрово"
            },
            {
              value: "Влайчовци",
              label: "Влайчовци"
            },
            {
              value: "Влаховци",
              label: "Влаховци"
            },
            {
              value: "Врабците",
              label: "Врабците"
            },
            {
              value: "Враниловци",
              label: "Враниловци"
            },
            {
              value: "Вълков дол",
              label: "Вълков дол"
            },
            {
              value: "Габрово",
              label: "Габрово"
            },
            {
              value: "Гайкини",
              label: "Гайкини"
            },
            {
              value: "Гайтаните",
              label: "Гайтаните"
            },
            {
              value: "Гарван",
              label: "Гарван"
            },
            {
              value: "Геновци",
              label: "Геновци"
            },
            {
              value: "Генчовци",
              label: "Генчовци"
            },
            {
              value: "Гергини",
              label: "Гергини"
            },
            {
              value: "Гледаци",
              label: "Гледаци"
            },
            {
              value: "Горнова могила",
              label: "Горнова могила"
            },
            {
              value: "Гръблевци",
              label: "Гръблевци"
            },
            {
              value: "Гъбене",
              label: "Гъбене"
            },
            {
              value: "Дебел дял",
              label: "Дебел дял"
            },
            {
              value: "Джумриите",
              label: "Джумриите"
            },
            {
              value: "Дивеци",
              label: "Дивеци"
            },
            {
              value: "Донино",
              label: "Донино"
            },
            {
              value: "Драгановци",
              label: "Драгановци"
            },
            {
              value: "Драганчетата",
              label: "Драганчетата"
            },
            {
              value: "Драгиевци",
              label: "Драгиевци"
            },
            {
              value: "Драгомани",
              label: "Драгомани"
            },
            {
              value: "Думници",
              label: "Думници"
            },
            {
              value: "Езерото",
              label: "Езерото"
            },
            {
              value: "Живко",
              label: "Живко"
            },
            {
              value: "Жълтеш",
              label: "Жълтеш"
            },
            {
              value: "Здравковец",
              label: "Здравковец"
            },
            {
              value: "Зелено дърво",
              label: "Зелено дърво"
            },
            {
              value: "Златевци",
              label: "Златевци"
            },
            {
              value: "Иванили",
              label: "Иванили"
            },
            {
              value: "Иванковци",
              label: "Иванковци"
            },
            {
              value: "Иглика",
              label: "Иглика"
            },
            {
              value: "Източник",
              label: "Източник"
            },
            {
              value: "Калчовци",
              label: "Калчовци"
            },
            {
              value: "Камещица",
              label: "Камещица"
            },
            {
              value: "Карали",
              label: "Карали"
            },
            {
              value: "Киевци",
              label: "Киевци"
            },
            {
              value: "Кметовци",
              label: "Кметовци"
            },
            {
              value: "Кметчета",
              label: "Кметчета"
            },
            {
              value: "Кози рог",
              label: "Кози рог"
            },
            {
              value: "Колишовци",
              label: "Колишовци"
            },
            {
              value: "Копчелиите",
              label: "Копчелиите"
            },
            {
              value: "Костадините",
              label: "Костадините"
            },
            {
              value: "Костенковци",
              label: "Костенковци"
            },
            {
              value: "Лесичарка",
              label: "Лесичарка"
            },
            {
              value: "Лоза",
              label: "Лоза"
            },
            {
              value: "Люляците",
              label: "Люляците"
            },
            {
              value: "Малини",
              label: "Малини"
            },
            {
              value: "Малуша",
              label: "Малуша"
            },
            {
              value: "Междени",
              label: "Междени"
            },
            {
              value: "Мечковица",
              label: "Мечковица"
            },
            {
              value: "Милковци",
              label: "Милковци"
            },
            {
              value: "Михайловци",
              label: "Михайловци"
            },
            {
              value: "Мичковци",
              label: "Мичковци"
            },
            {
              value: "Моровеците",
              label: "Моровеците"
            },
            {
              value: "Мрахори",
              label: "Мрахори"
            },
            {
              value: "Музга",
              label: "Музга"
            },
            {
              value: "Николчовци",
              label: "Николчовци"
            },
            {
              value: "Новаковци",
              label: "Новаковци"
            },
            {
              value: "Овощарци",
              label: "Овощарци"
            },
            {
              value: "Орловци",
              label: "Орловци"
            },
            {
              value: "Парчовци",
              label: "Парчовци"
            },
            {
              value: "Пейовци",
              label: "Пейовци"
            },
            {
              value: "Пенковци",
              label: "Пенковци"
            },
            {
              value: "Петровци",
              label: "Петровци"
            },
            {
              value: "Пецовци",
              label: "Пецовци"
            },
            {
              value: "Попари",
              label: "Попари"
            },
            {
              value: "Поповци",
              label: "Поповци"
            },
            {
              value: "Поток",
              label: "Поток"
            },
            {
              value: "Прахали",
              label: "Прахали"
            },
            {
              value: "Продановци",
              label: "Продановци"
            },
            {
              value: "Пъртевци",
              label: "Пъртевци"
            },
            {
              value: "Райновци",
              label: "Райновци"
            },
            {
              value: "Раховци",
              label: "Раховци"
            },
            {
              value: "Рачевци",
              label: "Рачевци"
            },
            {
              value: "Редешковци",
              label: "Редешковци"
            },
            {
              value: "Руйчовци",
              label: "Руйчовци"
            },
            {
              value: "Рязковци",
              label: "Рязковци"
            },
            {
              value: "Свинарски дол",
              label: "Свинарски дол"
            },
            {
              value: "Седянковци",
              label: "Седянковци"
            },
            {
              value: "Сейковци",
              label: "Сейковци"
            },
            {
              value: "Семерджиите",
              label: "Семерджиите"
            },
            {
              value: "Смиловци",
              label: "Смиловци"
            },
            {
              value: "Солари",
              label: "Солари"
            },
            {
              value: "Спанци",
              label: "Спанци"
            },
            {
              value: "Спасовци",
              label: "Спасовци"
            },
            {
              value: "Старилковци",
              label: "Старилковци"
            },
            {
              value: "Стефаново",
              label: "Стефаново"
            },
            {
              value: "Стоевци",
              label: "Стоевци"
            },
            {
              value: "Стойковци",
              label: "Стойковци"
            },
            {
              value: "Стойчовци",
              label: "Стойчовци"
            },
            {
              value: "Стоманеците",
              label: "Стоманеците"
            },
            {
              value: "Съботковци",
              label: "Съботковци"
            },
            {
              value: "Тодоровци",
              label: "Тодоровци"
            },
            {
              value: "Тодорчета",
              label: "Тодорчета"
            },
            {
              value: "Торбалъжите",
              label: "Торбалъжите"
            },
            {
              value: "Трапесковци",
              label: "Трапесковци"
            },
            {
              value: "Трънито",
              label: "Трънито"
            },
            {
              value: "Узуните",
              label: "Узуните"
            },
            {
              value: "Фърговци",
              label: "Фърговци"
            },
            {
              value: "Харачерите",
              label: "Харачерите"
            },
            {
              value: "Цвятковци",
              label: "Цвятковци"
            },
            {
              value: "Чавеи",
              label: "Чавеи"
            },
            {
              value: "Чарково",
              label: "Чарково"
            },
            {
              value: "Червена локва",
              label: "Червена локва"
            },
            {
              value: "Черневци",
              label: "Черневци"
            },
            {
              value: "Читаковци",
              label: "Читаковци"
            },
            {
              value: "Чукилите",
              label: "Чукилите"
            },
            {
              value: "Шарани",
              label: "Шарани"
            },
            {
              value: "Шипчените",
              label: "Шипчените"
            },
            {
              value: "Яворец",
              label: "Яворец"
            },
            {
              value: "Янковци",
              label: "Янковци"
            },
            {
              value: "Ясените",
              label: "Ясените"
            }
          ]
        },
        {
          value: "Дряново",
          label: "Дряново",
          list: [
            {
              value: "Балалея",
              label: "Балалея"
            },
            {
              value: "Балванците",
              label: "Балванците"
            },
            {
              value: "Банари",
              label: "Банари"
            },
            {
              value: "Билкини",
              label: "Билкини"
            },
            {
              value: "Бучуковци",
              label: "Бучуковци"
            },
            {
              value: "Ганчовец",
              label: "Ганчовец"
            },
            {
              value: "Геня",
              label: "Геня"
            },
            {
              value: "Геша",
              label: "Геша"
            },
            {
              value: "Глушка",
              label: "Глушка"
            },
            {
              value: "Гоздейка",
              label: "Гоздейка"
            },
            {
              value: "Големи Българени",
              label: "Големи Българени"
            },
            {
              value: "Горни Върпища",
              label: "Горни Върпища"
            },
            {
              value: "Горни Драгойча",
              label: "Горни Драгойча"
            },
            {
              value: "Гостилица",
              label: "Гостилица"
            },
            {
              value: "Гърня",
              label: "Гърня"
            },
            {
              value: "Денчевци",
              label: "Денчевци"
            },
            {
              value: "Джуровци",
              label: "Джуровци"
            },
            {
              value: "Длъгня",
              label: "Длъгня"
            },
            {
              value: "Добрените",
              label: "Добрените"
            },
            {
              value: "Долни Върпища",
              label: "Долни Върпища"
            },
            {
              value: "Долни Драгойча",
              label: "Долни Драгойча"
            },
            {
              value: "Доча",
              label: "Доча"
            },
            {
              value: "Дряново",
              label: "Дряново"
            },
            {
              value: "Дурча",
              label: "Дурча"
            },
            {
              value: "Еленците",
              label: "Еленците"
            },
            {
              value: "Зая",
              label: "Зая"
            },
            {
              value: "Игнатовци",
              label: "Игнатовци"
            },
            {
              value: "Искра",
              label: "Искра"
            },
            {
              value: "Каломен",
              label: "Каломен"
            },
            {
              value: "Караиванца",
              label: "Караиванца"
            },
            {
              value: "Катранджии",
              label: "Катранджии"
            },
            {
              value: "Керека",
              label: "Керека"
            },
            {
              value: "Косарка",
              label: "Косарка"
            },
            {
              value: "Косилка",
              label: "Косилка"
            },
            {
              value: "Крънча",
              label: "Крънча"
            },
            {
              value: "Кукля",
              label: "Кукля"
            },
            {
              value: "Куманите",
              label: "Куманите"
            },
            {
              value: "Къртипъня",
              label: "Къртипъня"
            },
            {
              value: "Малки Българени",
              label: "Малки Българени"
            },
            {
              value: "Маноя",
              label: "Маноя"
            },
            {
              value: "Муця",
              label: "Муця"
            },
            {
              value: "Нейчовци",
              label: "Нейчовци"
            },
            {
              value: "Пейна",
              label: "Пейна"
            },
            {
              value: "Петковци",
              label: "Петковци"
            },
            {
              value: "Плачка",
              label: "Плачка"
            },
            {
              value: "Пърша",
              label: "Пърша"
            },
            {
              value: "Раданчето",
              label: "Раданчето"
            },
            {
              value: "Радовци",
              label: "Радовци"
            },
            {
              value: "Ритя",
              label: "Ритя"
            },
            {
              value: "Руня",
              label: "Руня"
            },
            {
              value: "Русиновци",
              label: "Русиновци"
            },
            {
              value: "Саласука",
              label: "Саласука"
            },
            {
              value: "Скалско",
              label: "Скалско"
            },
            {
              value: "Славейково",
              label: "Славейково"
            },
            {
              value: "Соколово",
              label: "Соколово"
            },
            {
              value: "Станча",
              label: "Станча"
            },
            {
              value: "Сухолоевци",
              label: "Сухолоевци"
            },
            {
              value: "Сяровци",
              label: "Сяровци"
            },
            {
              value: "Туркинча",
              label: "Туркинча"
            },
            {
              value: "Царева ливада",
              label: "Царева ливада"
            },
            {
              value: "Чуково",
              label: "Чуково"
            },
            {
              value: "Шушня",
              label: "Шушня"
            },
            {
              value: "Янтра",
              label: "Янтра"
            }
          ]
        },
        {
          value: "Севлиево",
          label: "Севлиево",
          list: [
            {
              value: "Агатово",
              label: "Агатово"
            },
            {
              value: "Батошево",
              label: "Батошево"
            },
            {
              value: "Бериево",
              label: "Бериево"
            },
            {
              value: "Боазът",
              label: "Боазът"
            },
            {
              value: "Богатово",
              label: "Богатово"
            },
            {
              value: "Буря",
              label: "Буря"
            },
            {
              value: "Валевци",
              label: "Валевци"
            },
            {
              value: "Войнишка",
              label: "Войнишка"
            },
            {
              value: "Горна Росица",
              label: "Горна Росица"
            },
            {
              value: "Градище",
              label: "Градище"
            },
            {
              value: "Градница",
              label: "Градница"
            },
            {
              value: "Дамяново",
              label: "Дамяново"
            },
            {
              value: "Дебелцово",
              label: "Дебелцово"
            },
            {
              value: "Дисманица",
              label: "Дисманица"
            },
            {
              value: "Добромирка",
              label: "Добромирка"
            },
            {
              value: "Душево",
              label: "Душево"
            },
            {
              value: "Душевски колиби",
              label: "Душевски колиби"
            },
            {
              value: "Енев рът",
              label: "Енев рът"
            },
            {
              value: "Идилево",
              label: "Идилево"
            },
            {
              value: "Карамичевци",
              label: "Карамичевци"
            },
            {
              value: "Кастел",
              label: "Кастел"
            },
            {
              value: "Корията",
              label: "Корията"
            },
            {
              value: "Кормянско",
              label: "Кормянско"
            },
            {
              value: "Крамолин",
              label: "Крамолин"
            },
            {
              value: "Крушево",
              label: "Крушево"
            },
            {
              value: "Кръвеник",
              label: "Кръвеник"
            },
            {
              value: "Купен",
              label: "Купен"
            },
            {
              value: "Ловнидол",
              label: "Ловнидол"
            },
            {
              value: "Малки Вършец",
              label: "Малки Вършец"
            },
            {
              value: "Младен",
              label: "Младен"
            },
            {
              value: "Млечево",
              label: "Млечево"
            },
            {
              value: "Петко Славейков",
              label: "Петко Славейков"
            },
            {
              value: "Попска",
              label: "Попска"
            },
            {
              value: "Ряховците",
              label: "Ряховците"
            },
            {
              value: "Севлиево",
              label: "Севлиево"
            },
            {
              value: "Селище",
              label: "Селище"
            },
            {
              value: "Сенник",
              label: "Сенник"
            },
            {
              value: "Стоките",
              label: "Стоките"
            },
            {
              value: "Столът",
              label: "Столът"
            },
            {
              value: "Табашка",
              label: "Табашка"
            },
            {
              value: "Тумбалово",
              label: "Тумбалово"
            },
            {
              value: "Търхово",
              label: "Търхово"
            },
            {
              value: "Угорелец",
              label: "Угорелец"
            },
            {
              value: "Хирево",
              label: "Хирево"
            },
            {
              value: "Шопите",
              label: "Шопите"
            },
            {
              value: "Шумата",
              label: "Шумата"
            }
          ]
        },
        {
          value: "Трявна",
          label: "Трявна",
          list: [
            {
              value: "Азманите",
              label: "Азманите"
            },
            {
              value: "Армянковци",
              label: "Армянковци"
            },
            {
              value: "Бангейци",
              label: "Бангейци"
            },
            {
              value: "Бахреци",
              label: "Бахреци"
            },
            {
              value: "Белица",
              label: "Белица"
            },
            {
              value: "Бижовци",
              label: "Бижовци"
            },
            {
              value: "Брежниците",
              label: "Брежниците"
            },
            {
              value: "Бърдарите",
              label: "Бърдарите"
            },
            {
              value: "Бърдени",
              label: "Бърдени"
            },
            {
              value: "Веленци",
              label: "Веленци"
            },
            {
              value: "Велково",
              label: "Велково"
            },
            {
              value: "Велчовци",
              label: "Велчовци"
            },
            {
              value: "Владовци",
              label: "Владовци"
            },
            {
              value: "Власатили",
              label: "Власатили"
            },
            {
              value: "Войниците",
              label: "Войниците"
            },
            {
              value: "Вълковци",
              label: "Вълковци"
            },
            {
              value: "Гайдари",
              label: "Гайдари"
            },
            {
              value: "Гара Кръстец",
              label: "Гара Кръстец"
            },
            {
              value: "Генчовци",
              label: "Генчовци"
            },
            {
              value: "Глутниците",
              label: "Глутниците"
            },
            {
              value: "Големи Станчовци",
              label: "Големи Станчовци"
            },
            {
              value: "Горни Дамяновци",
              label: "Горни Дамяновци"
            },
            {
              value: "Горни Маренци",
              label: "Горни Маренци"
            },
            {
              value: "Горни Радковци",
              label: "Горни Радковци"
            },
            {
              value: "Горни Цоневци",
              label: "Горни Цоневци"
            },
            {
              value: "Горяни",
              label: "Горяни"
            },
            {
              value: "Даевци",
              label: "Даевци"
            },
            {
              value: "Димиевци",
              label: "Димиевци"
            },
            {
              value: "Добревци",
              label: "Добревци"
            },
            {
              value: "Долни Маренци",
              label: "Долни Маренци"
            },
            {
              value: "Долни Радковци",
              label: "Долни Радковци"
            },
            {
              value: "Долни Томчевци",
              label: "Долни Томчевци"
            },
            {
              value: "Донкино",
              label: "Донкино"
            },
            {
              value: "Дончовци",
              label: "Дончовци"
            },
            {
              value: "Драгневци",
              label: "Драгневци"
            },
            {
              value: "Драндарите",
              label: "Драндарите"
            },
            {
              value: "Дървари",
              label: "Дървари"
            },
            {
              value: "Дъскарите",
              label: "Дъскарите"
            },
            {
              value: "Енчовци",
              label: "Енчовци"
            },
            {
              value: "Зеленика",
              label: "Зеленика"
            },
            {
              value: "Иван Димов",
              label: "Иван Димов"
            },
            {
              value: "Йововци",
              label: "Йововци"
            },
            {
              value: "Иринеци",
              label: "Иринеци"
            },
            {
              value: "Кашенци",
              label: "Кашенци"
            },
            {
              value: "Керените",
              label: "Керените"
            },
            {
              value: "Киселковци",
              label: "Киселковци"
            },
            {
              value: "Кисийците",
              label: "Кисийците"
            },
            {
              value: "Коевци",
              label: "Коевци"
            },
            {
              value: "Койчовци",
              label: "Койчовци"
            },
            {
              value: "Колю Ганев",
              label: "Колю Ганев"
            },
            {
              value: "Конарското",
              label: "Конарското"
            },
            {
              value: "Креслювци",
              label: "Креслювци"
            },
            {
              value: "Кръстеняците",
              label: "Кръстеняците"
            },
            {
              value: "Кръстец",
              label: "Кръстец"
            },
            {
              value: "Малки Станчовци",
              label: "Малки Станчовци"
            },
            {
              value: "Малчовци",
              label: "Малчовци"
            },
            {
              value: "Маневци",
              label: "Маневци"
            },
            {
              value: "Маруцековци",
              label: "Маруцековци"
            },
            {
              value: "Матешовци",
              label: "Матешовци"
            },
            {
              value: "Милевци",
              label: "Милевци"
            },
            {
              value: "Миховци",
              label: "Миховци"
            },
            {
              value: "Могилите",
              label: "Могилите"
            },
            {
              value: "Мръзеци",
              label: "Мръзеци"
            },
            {
              value: "Недялковци",
              label: "Недялковци"
            },
            {
              value: "Неновци",
              label: "Неновци"
            },
            {
              value: "Никачковци",
              label: "Никачковци"
            },
            {
              value: "Николаево",
              label: "Николаево"
            },
            {
              value: "Ножерите",
              label: "Ножерите"
            },
            {
              value: "Носеите",
              label: "Носеите"
            },
            {
              value: "Околиите",
              label: "Околиите"
            },
            {
              value: "Ошаните",
              label: "Ошаните"
            },
            {
              value: "Павлевци",
              label: "Павлевци"
            },
            {
              value: "Планинци",
              label: "Планинци"
            },
            {
              value: "Плачковци",
              label: "Плачковци"
            },
            {
              value: "Побък",
              label: "Побък"
            },
            {
              value: "Попгергевци",
              label: "Попгергевци"
            },
            {
              value: "Попрайковци",
              label: "Попрайковци"
            },
            {
              value: "Престой",
              label: "Престой"
            },
            {
              value: "Пържиграх",
              label: "Пържиграх"
            },
            {
              value: "Радевци",
              label: "Радевци"
            },
            {
              value: "Радино",
              label: "Радино"
            },
            {
              value: "Радоевци",
              label: "Радоевци"
            },
            {
              value: "Раевци",
              label: "Раевци"
            },
            {
              value: "Райнушковци",
              label: "Райнушковци"
            },
            {
              value: "Ралевци",
              label: "Ралевци"
            },
            {
              value: "Рачовци",
              label: "Рачовци"
            },
            {
              value: "Рашовите",
              label: "Рашовите"
            },
            {
              value: "Руевци",
              label: "Руевци"
            },
            {
              value: "Свирци",
              label: "Свирци"
            },
            {
              value: "Сечен камък",
              label: "Сечен камък"
            },
            {
              value: "Скорците",
              label: "Скорците"
            },
            {
              value: "Сливово",
              label: "Сливово"
            },
            {
              value: "Стайновци",
              label: "Стайновци"
            },
            {
              value: "Станчов хан",
              label: "Станчов хан"
            },
            {
              value: "Стражата",
              label: "Стражата"
            },
            {
              value: "Стръмци",
              label: "Стръмци"
            },
            {
              value: "Тодореците",
              label: "Тодореците"
            },
            {
              value: "Томчевци",
              label: "Томчевци"
            },
            {
              value: "Трявна",
              label: "Трявна"
            },
            {
              value: "Урвата",
              label: "Урвата"
            },
            {
              value: "Фъревци",
              label: "Фъревци"
            },
            {
              value: "Фъртуни",
              label: "Фъртуни"
            },
            {
              value: "Христовци",
              label: "Христовци"
            },
            {
              value: "Чакалите",
              label: "Чакалите"
            },
            {
              value: "Черновръх",
              label: "Черновръх"
            },
            {
              value: "Ябълковци",
              label: "Ябълковци"
            },
            {
              value: "Явор",
              label: "Явор"
            }
          ]
        }
      ]
    },
    {
      value: "Добрич",
      label: "Добрич",
      list: [
        {
          value: "Балчик",
          label: "Балчик",
          list: [
            {
              value: "Албена",
              label: "Албена"
            },
            {
              value: "Балчик",
              label: "Балчик"
            },
            {
              value: "Безводица",
              label: "Безводица"
            },
            {
              value: "Бобовец",
              label: "Бобовец"
            },
            {
              value: "Брястово",
              label: "Брястово"
            },
            {
              value: "Гурково",
              label: "Гурково"
            },
            {
              value: "Дропла",
              label: "Дропла"
            },
            {
              value: "Дъбрава",
              label: "Дъбрава"
            },
            {
              value: "Змеево",
              label: "Змеево"
            },
            {
              value: "Карвуна",
              label: "Карвуна"
            },
            {
              value: "Кранево",
              label: "Кранево"
            },
            {
              value: "Кремена",
              label: "Кремена"
            },
            {
              value: "Ляхово",
              label: "Ляхово"
            },
            {
              value: "Оброчище",
              label: "Оброчище"
            },
            {
              value: "Преспа",
              label: "Преспа"
            },
            {
              value: "Рогачево",
              label: "Рогачево"
            },
            {
              value: "Сенокос",
              label: "Сенокос"
            },
            {
              value: "Соколово",
              label: "Соколово"
            },
            {
              value: "Стражица",
              label: "Стражица"
            },
            {
              value: "Тригорци",
              label: "Тригорци"
            },
            {
              value: "Тузлата",
              label: "Тузлата"
            },
            {
              value: "Храброво",
              label: "Храброво"
            },
            {
              value: "Царичино",
              label: "Царичино"
            },
            {
              value: "Църква",
              label: "Църква"
            }
          ]
        },
        {
          value: "Генерал Тошево",
          label: "Генерал Тошево",
          list: [
            {
              value: "Александър Стамболийски",
              label: "Александър Стамболийски"
            },
            {
              value: "Балканци",
              label: "Балканци"
            },
            {
              value: "Бежаново",
              label: "Бежаново"
            },
            {
              value: "Василево",
              label: "Василево"
            },
            {
              value: "Великово",
              label: "Великово"
            },
            {
              value: "Вичово",
              label: "Вичово"
            },
            {
              value: "Генерал Тошево",
              label: "Генерал Тошево"
            },
            {
              value: "Горица",
              label: "Горица"
            },
            {
              value: "Градини",
              label: "Градини"
            },
            {
              value: "Дъбовик",
              label: "Дъбовик"
            },
            {
              value: "Житен",
              label: "Житен"
            },
            {
              value: "Зограф",
              label: "Зограф"
            },
            {
              value: "Изворово",
              label: "Изворово"
            },
            {
              value: "Йовково",
              label: "Йовково"
            },
            {
              value: "Калина",
              label: "Калина"
            },
            {
              value: "Кардам",
              label: "Кардам"
            },
            {
              value: "Конаре",
              label: "Конаре"
            },
            {
              value: "Краище",
              label: "Краище"
            },
            {
              value: "Красен",
              label: "Красен"
            },
            {
              value: "Къпиново",
              label: "Къпиново"
            },
            {
              value: "Лозница",
              label: "Лозница"
            },
            {
              value: "Люляково",
              label: "Люляково"
            },
            {
              value: "Малина",
              label: "Малина"
            },
            {
              value: "Огражден",
              label: "Огражден"
            },
            {
              value: "Петлешково",
              label: "Петлешково"
            },
            {
              value: "Писарово",
              label: "Писарово"
            },
            {
              value: "Пленимир",
              label: "Пленимир"
            },
            {
              value: "Преселенци",
              label: "Преселенци"
            },
            {
              value: "Присад",
              label: "Присад"
            },
            {
              value: "Пчеларово",
              label: "Пчеларово"
            },
            {
              value: "Равнец",
              label: "Равнец"
            },
            {
              value: "Рогозина",
              label: "Рогозина"
            },
            {
              value: "Росен",
              label: "Росен"
            },
            {
              value: "Росица",
              label: "Росица"
            },
            {
              value: "Сираково",
              label: "Сираково"
            },
            {
              value: "Сноп",
              label: "Сноп"
            },
            {
              value: "Снягово",
              label: "Снягово"
            },
            {
              value: "Спасово",
              label: "Спасово"
            },
            {
              value: "Средина",
              label: "Средина"
            },
            {
              value: "Сърнино",
              label: "Сърнино"
            },
            {
              value: "Узово",
              label: "Узово"
            },
            {
              value: "Чернооково",
              label: "Чернооково"
            }
          ]
        },
        {
          value: "Добрич",
          label: "Добрич",
          list: [
            {
              value: "Алцек",
              label: "Алцек"
            },
            {
              value: "Батово",
              label: "Батово"
            },
            {
              value: "Бдинци",
              label: "Бдинци"
            },
            {
              value: "Бенковски",
              label: "Бенковски"
            },
            {
              value: "Богдан",
              label: "Богдан"
            },
            {
              value: "Божурово",
              label: "Божурово"
            },
            {
              value: "Бранище",
              label: "Бранище"
            },
            {
              value: "Ведрина",
              label: "Ведрина"
            },
            {
              value: "Владимирово",
              label: "Владимирово"
            },
            {
              value: "Воднянци",
              label: "Воднянци"
            },
            {
              value: "Вратарите",
              label: "Вратарите"
            },
            {
              value: "Врачанци",
              label: "Врачанци"
            },
            {
              value: "Генерал Колево",
              label: "Генерал Колево"
            },
            {
              value: "Гешаново",
              label: "Гешаново"
            },
            {
              value: "Дебрене",
              label: "Дебрене"
            },
            {
              value: "Добрево",
              label: "Добрево"
            },
            {
              value: "Добрич",
              label: "Добрич"
            },
            {
              value: "Долина",
              label: "Долина"
            },
            {
              value: "Дончево",
              label: "Дончево"
            },
            {
              value: "Драганово",
              label: "Драганово"
            },
            {
              value: "Дряновец",
              label: "Дряновец"
            },
            {
              value: "Енево",
              label: "Енево"
            },
            {
              value: "Житница",
              label: "Житница"
            },
            {
              value: "Златия",
              label: "Златия"
            },
            {
              value: "Камен",
              label: "Камен"
            },
            {
              value: "Карапелит",
              label: "Карапелит"
            },
            {
              value: "Козлодуйци",
              label: "Козлодуйци"
            },
            {
              value: "Котленци",
              label: "Котленци"
            },
            {
              value: "Крагулево",
              label: "Крагулево"
            },
            {
              value: "Ловчанци",
              label: "Ловчанци"
            },
            {
              value: "Ломница",
              label: "Ломница"
            },
            {
              value: "Лясково",
              label: "Лясково"
            },
            {
              value: "Малка Смолница",
              label: "Малка Смолница"
            },
            {
              value: "Медово",
              label: "Медово"
            },
            {
              value: "Методиево",
              label: "Методиево"
            },
            {
              value: "Миладиновци",
              label: "Миладиновци"
            },
            {
              value: "Ново Ботево",
              label: "Ново Ботево"
            },
            {
              value: "Овчарово",
              label: "Овчарово"
            },
            {
              value: "Одринци",
              label: "Одринци"
            },
            {
              value: "Одърци",
              label: "Одърци"
            },
            {
              value: "Опанец",
              label: "Опанец"
            },
            {
              value: "Орлова могила",
              label: "Орлова могила"
            },
            {
              value: "Паскалево",
              label: "Паскалево"
            },
            {
              value: "Плачидол",
              label: "Плачидол"
            },
            {
              value: "Победа",
              label: "Победа"
            },
            {
              value: "Подслон",
              label: "Подслон"
            },
            {
              value: "Полковник Иваново",
              label: "Полковник Иваново"
            },
            {
              value: "Полковник Минково",
              label: "Полковник Минково"
            },
            {
              value: "Полковник Свещарово",
              label: "Полковник Свещарово"
            },
            {
              value: "Попгригорово",
              label: "Попгригорово"
            },
            {
              value: "Прилеп",
              label: "Прилеп"
            },
            {
              value: "Приморци",
              label: "Приморци"
            },
            {
              value: "Пчелино",
              label: "Пчелино"
            },
            {
              value: "Пчелник",
              label: "Пчелник"
            },
            {
              value: "Росеново",
              label: "Росеново"
            },
            {
              value: "Самуилово",
              label: "Самуилово"
            },
            {
              value: "Свобода",
              label: "Свобода"
            },
            {
              value: "Славеево",
              label: "Славеево"
            },
            {
              value: "Сливенци",
              label: "Сливенци"
            },
            {
              value: "Смолница",
              label: "Смолница"
            },
            {
              value: "Соколник",
              label: "Соколник"
            },
            {
              value: "Стефан Караджа",
              label: "Стефан Караджа"
            },
            {
              value: "Стефаново",
              label: "Стефаново"
            },
            {
              value: "Стожер",
              label: "Стожер"
            },
            {
              value: "Тянево",
              label: "Тянево"
            },
            {
              value: "Фелдфебел Дянково",
              label: "Фелдфебел Дянково"
            },
            {
              value: "Хитово",
              label: "Хитово"
            },
            {
              value: "Царевец",
              label: "Царевец"
            },
            {
              value: "Черна",
              label: "Черна"
            }
          ]
        },
        {
          value: "Каварна",
          label: "Каварна",
          list: [
            {
              value: "Белгун",
              label: "Белгун"
            },
            {
              value: "Било",
              label: "Било"
            },
            {
              value: "Божурец",
              label: "Божурец"
            },
            {
              value: "Българево",
              label: "Българево"
            },
            {
              value: "Видно",
              label: "Видно"
            },
            {
              value: "Вранино",
              label: "Вранино"
            },
            {
              value: "Иречек",
              label: "Иречек"
            },
            {
              value: "Каварна",
              label: "Каварна"
            },
            {
              value: "Камен бряг",
              label: "Камен бряг"
            },
            {
              value: "Крупен",
              label: "Крупен"
            },
            {
              value: "Могилище",
              label: "Могилище"
            },
            {
              value: "Нейково",
              label: "Нейково"
            },
            {
              value: "Поручик Чунчево",
              label: "Поручик Чунчево"
            },
            {
              value: "Раковски",
              label: "Раковски"
            },
            {
              value: "Русалка",
              label: "Русалка"
            },
            {
              value: "Свети Никола",
              label: "Свети Никола"
            },
            {
              value: "Селце",
              label: "Селце"
            },
            {
              value: "Септемврийци",
              label: "Септемврийци"
            },
            {
              value: "Топола",
              label: "Топола"
            },
            {
              value: "Травник",
              label: "Травник"
            },
            {
              value: "Хаджи Димитър",
              label: "Хаджи Димитър"
            },
            {
              value: "Челопечене",
              label: "Челопечене"
            }
          ]
        },
        {
          value: "Крушари",
          label: "Крушари",
          list: [
            {
              value: "Абрит",
              label: "Абрит"
            },
            {
              value: "Александрия",
              label: "Александрия"
            },
            {
              value: "Бистрец",
              label: "Бистрец"
            },
            {
              value: "Габер",
              label: "Габер"
            },
            {
              value: "Добрин",
              label: "Добрин"
            },
            {
              value: "Ефрейтор Бакалово",
              label: "Ефрейтор Бакалово"
            },
            {
              value: "Загорци",
              label: "Загорци"
            },
            {
              value: "Земенци",
              label: "Земенци"
            },
            {
              value: "Зимница",
              label: "Зимница"
            },
            {
              value: "Капитан Димитрово",
              label: "Капитан Димитрово"
            },
            {
              value: "Коритен",
              label: "Коритен"
            },
            {
              value: "Крушари",
              label: "Крушари"
            },
            {
              value: "Лозенец",
              label: "Лозенец"
            },
            {
              value: "Огняново",
              label: "Огняново"
            },
            {
              value: "Полковник Дяково",
              label: "Полковник Дяково"
            },
            {
              value: "Поручик Кърджиево",
              label: "Поручик Кърджиево"
            },
            {
              value: "Северняк",
              label: "Северняк"
            },
            {
              value: "Северци",
              label: "Северци"
            },
            {
              value: "Телериг",
              label: "Телериг"
            }
          ]
        },
        {
          value: "Тервел",
          label: "Тервел",
          list: [
            {
              value: "Ангеларий",
              label: "Ангеларий"
            },
            {
              value: "Балик",
              label: "Балик"
            },
            {
              value: "Безмер",
              label: "Безмер"
            },
            {
              value: "Божан",
              label: "Божан"
            },
            {
              value: "Бонево",
              label: "Бонево"
            },
            {
              value: "Брестница",
              label: "Брестница"
            },
            {
              value: "Войниково",
              label: "Войниково"
            },
            {
              value: "Главанци",
              label: "Главанци"
            },
            {
              value: "Градница",
              label: "Градница"
            },
            {
              value: "Гуслар",
              label: "Гуслар"
            },
            {
              value: "Жегларци",
              label: "Жегларци"
            },
            {
              value: "Зърнево",
              label: "Зърнево"
            },
            {
              value: "Каблешково",
              label: "Каблешково"
            },
            {
              value: "Кладенци",
              label: "Кладенци"
            },
            {
              value: "Коларци",
              label: "Коларци"
            },
            {
              value: "Кочмар",
              label: "Кочмар"
            },
            {
              value: "Мали извор",
              label: "Мали извор"
            },
            {
              value: "Нова Камена",
              label: "Нова Камена"
            },
            {
              value: "Оногур",
              label: "Оногур"
            },
            {
              value: "Орляк",
              label: "Орляк"
            },
            {
              value: "Полковник Савово",
              label: "Полковник Савово"
            },
            {
              value: "Попгруево",
              label: "Попгруево"
            },
            {
              value: "Професор Златарски",
              label: "Професор Златарски"
            },
            {
              value: "Сърнец",
              label: "Сърнец"
            },
            {
              value: "Тервел",
              label: "Тервел"
            },
            {
              value: "Честименско",
              label: "Честименско"
            }
          ]
        },
        {
          value: "Шабла",
          label: "Шабла",
          list: [
            {
              value: "Божаново",
              label: "Божаново"
            },
            {
              value: "Ваклино",
              label: "Ваклино"
            },
            {
              value: "Горичане",
              label: "Горичане"
            },
            {
              value: "Горун",
              label: "Горун"
            },
            {
              value: "Граничар",
              label: "Граничар"
            },
            {
              value: "Дуранкулак",
              label: "Дуранкулак"
            },
            {
              value: "Езерец",
              label: "Езерец"
            },
            {
              value: "Захари Стояново",
              label: "Захари Стояново"
            },
            {
              value: "Крапец",
              label: "Крапец"
            },
            {
              value: "Пролез",
              label: "Пролез"
            },
            {
              value: "Смин",
              label: "Смин"
            },
            {
              value: "Стаевци",
              label: "Стаевци"
            },
            {
              value: "Твърдица",
              label: "Твърдица"
            },
            {
              value: "Тюленово",
              label: "Тюленово"
            },
            {
              value: "Черноморци",
              label: "Черноморци"
            },
            {
              value: "Шабла",
              label: "Шабла"
            }
          ]
        }
      ]
    },
    {
      value: "Кърджали",
      label: "Кърджали",
      list: [
        {
          value: "Ардино",
          label: "Ардино",
          list: [
            {
              value: "Аврамово",
              label: "Аврамово"
            },
            {
              value: "Ардино",
              label: "Ардино"
            },
            {
              value: "Ахрянско",
              label: "Ахрянско"
            },
            {
              value: "Башево",
              label: "Башево"
            },
            {
              value: "Бистроглед",
              label: "Бистроглед"
            },
            {
              value: "Богатино",
              label: "Богатино"
            },
            {
              value: "Боровица",
              label: "Боровица"
            },
            {
              value: "Брезен",
              label: "Брезен"
            },
            {
              value: "Бял извор",
              label: "Бял извор"
            },
            {
              value: "Главник",
              label: "Главник"
            },
            {
              value: "Голобрад",
              label: "Голобрад"
            },
            {
              value: "Горно Прахово",
              label: "Горно Прахово"
            },
            {
              value: "Гърбище",
              label: "Гърбище"
            },
            {
              value: "Дедино",
              label: "Дедино"
            },
            {
              value: "Дойранци",
              label: "Дойранци"
            },
            {
              value: "Долно Прахово",
              label: "Долно Прахово"
            },
            {
              value: "Дядовци",
              label: "Дядовци"
            },
            {
              value: "Еньовче",
              label: "Еньовче"
            },
            {
              value: "Жълтуша",
              label: "Жълтуша"
            },
            {
              value: "Искра",
              label: "Искра"
            },
            {
              value: "Китница",
              label: "Китница"
            },
            {
              value: "Кроячево",
              label: "Кроячево"
            },
            {
              value: "Латинка",
              label: "Латинка"
            },
            {
              value: "Левци",
              label: "Левци"
            },
            {
              value: "Ленище",
              label: "Ленище"
            },
            {
              value: "Любино",
              label: "Любино"
            },
            {
              value: "Мак",
              label: "Мак"
            },
            {
              value: "Млечино",
              label: "Млечино"
            },
            {
              value: "Мусево",
              label: "Мусево"
            },
            {
              value: "Падина",
              label: "Падина"
            },
            {
              value: "Паспал",
              label: "Паспал"
            },
            {
              value: "Песнопой",
              label: "Песнопой"
            },
            {
              value: "Правдолюб",
              label: "Правдолюб"
            },
            {
              value: "Рибарци",
              label: "Рибарци"
            },
            {
              value: "Родопско",
              label: "Родопско"
            },
            {
              value: "Русалско",
              label: "Русалско"
            },
            {
              value: "Светулка",
              label: "Светулка"
            },
            {
              value: "Седларци",
              label: "Седларци"
            },
            {
              value: "Синчец",
              label: "Синчец"
            },
            {
              value: "Сполука",
              label: "Сполука"
            },
            {
              value: "Срънско",
              label: "Срънско"
            },
            {
              value: "Стар читак",
              label: "Стар читак"
            },
            {
              value: "Стояново",
              label: "Стояново"
            },
            {
              value: "Сухово",
              label: "Сухово"
            },
            {
              value: "Теменуга",
              label: "Теменуга"
            },
            {
              value: "Търна",
              label: "Търна"
            },
            {
              value: "Търносливка",
              label: "Търносливка"
            },
            {
              value: "Хромица",
              label: "Хромица"
            },
            {
              value: "Червена скала",
              label: "Червена скала"
            },
            {
              value: "Чернигово",
              label: "Чернигово"
            },
            {
              value: "Чубрика",
              label: "Чубрика"
            },
            {
              value: "Ябълковец",
              label: "Ябълковец"
            }
          ]
        },
        {
          value: "Джебел",
          label: "Джебел",
          list: [
            {
              value: "Албанци",
              label: "Албанци"
            },
            {
              value: "Брежана",
              label: "Брежана"
            },
            {
              value: "Великденче",
              label: "Великденче"
            },
            {
              value: "Воденичарско",
              label: "Воденичарско"
            },
            {
              value: "Вълкович",
              label: "Вълкович"
            },
            {
              value: "Генерал Гешево",
              label: "Генерал Гешево"
            },
            {
              value: "Джебел",
              label: "Джебел"
            },
            {
              value: "Добринци",
              label: "Добринци"
            },
            {
              value: "Душинково",
              label: "Душинково"
            },
            {
              value: "Желъдово",
              label: "Желъдово"
            },
            {
              value: "Жълти рид",
              label: "Жълти рид"
            },
            {
              value: "Жълтика",
              label: "Жълтика"
            },
            {
              value: "Илийско",
              label: "Илийско"
            },
            {
              value: "Казаците",
              label: "Казаците"
            },
            {
              value: "Каменяне",
              label: "Каменяне"
            },
            {
              value: "Козица",
              label: "Козица"
            },
            {
              value: "Контил",
              label: "Контил"
            },
            {
              value: "Купците",
              label: "Купците"
            },
            {
              value: "Лебед",
              label: "Лебед"
            },
            {
              value: "Мишевско",
              label: "Мишевско"
            },
            {
              value: "Мрежичко",
              label: "Мрежичко"
            },
            {
              value: "Овчево",
              label: "Овчево"
            },
            {
              value: "Папрат",
              label: "Папрат"
            },
            {
              value: "Плазище",
              label: "Плазище"
            },
            {
              value: "Подвръх",
              label: "Подвръх"
            },
            {
              value: "Полянец",
              label: "Полянец"
            },
            {
              value: "Поточе",
              label: "Поточе"
            },
            {
              value: "Припек",
              label: "Припек"
            },
            {
              value: "Ридино",
              label: "Ридино"
            },
            {
              value: "Рогозари",
              label: "Рогозари"
            },
            {
              value: "Рогозче",
              label: "Рогозче"
            },
            {
              value: "Рожденско",
              label: "Рожденско"
            },
            {
              value: "Рът",
              label: "Рът"
            },
            {
              value: "Сипец",
              label: "Сипец"
            },
            {
              value: "Скалина",
              label: "Скалина"
            },
            {
              value: "Слънчоглед",
              label: "Слънчоглед"
            },
            {
              value: "Софийци",
              label: "Софийци"
            },
            {
              value: "Телчарка",
              label: "Телчарка"
            },
            {
              value: "Търновци",
              label: "Търновци"
            },
            {
              value: "Тютюнче",
              label: "Тютюнче"
            },
            {
              value: "Устрен",
              label: "Устрен"
            },
            {
              value: "Цвятово",
              label: "Цвятово"
            },
            {
              value: "Църквица",
              label: "Църквица"
            },
            {
              value: "Чакалци",
              label: "Чакалци"
            },
            {
              value: "Черешка",
              label: "Черешка"
            },
            {
              value: "Щерна",
              label: "Щерна"
            },
            {
              value: "Ямино",
              label: "Ямино"
            }
          ]
        },
        {
          value: "Кирково",
          label: "Кирково",
          list: [
            {
              value: "Априлци",
              label: "Априлци"
            },
            {
              value: "Бенковски",
              label: "Бенковски"
            },
            {
              value: "Брегово",
              label: "Брегово"
            },
            {
              value: "Вълчанка",
              label: "Вълчанка"
            },
            {
              value: "Върбен",
              label: "Върбен"
            },
            {
              value: "Върли дол",
              label: "Върли дол"
            },
            {
              value: "Горно Кирково",
              label: "Горно Кирково"
            },
            {
              value: "Горно Къпиново",
              label: "Горно Къпиново"
            },
            {
              value: "Горски извор",
              label: "Горски извор"
            },
            {
              value: "Гривяк",
              label: "Гривяк"
            },
            {
              value: "Дедец",
              label: "Дедец"
            },
            {
              value: "Делвино",
              label: "Делвино"
            },
            {
              value: "Джерово",
              label: "Джерово"
            },
            {
              value: "Добромирци",
              label: "Добромирци"
            },
            {
              value: "Долно Къпиново",
              label: "Долно Къпиново"
            },
            {
              value: "Домище",
              label: "Домище"
            },
            {
              value: "Дрангово",
              label: "Дрангово"
            },
            {
              value: "Дружинци",
              label: "Дружинци"
            },
            {
              value: "Дрянова глава",
              label: "Дрянова глава"
            },
            {
              value: "Дюлица",
              label: "Дюлица"
            },
            {
              value: "Еровете",
              label: "Еровете"
            },
            {
              value: "Завоя",
              label: "Завоя"
            },
            {
              value: "Загорски",
              label: "Загорски"
            },
            {
              value: "Здравчец",
              label: "Здравчец"
            },
            {
              value: "Каялоба",
              label: "Каялоба"
            },
            {
              value: "Кирково",
              label: "Кирково"
            },
            {
              value: "Китна",
              label: "Китна"
            },
            {
              value: "Козлево",
              label: "Козлево"
            },
            {
              value: "Костурино",
              label: "Костурино"
            },
            {
              value: "Кран",
              label: "Кран"
            },
            {
              value: "Кремен",
              label: "Кремен"
            },
            {
              value: "Крилатица",
              label: "Крилатица"
            },
            {
              value: "Кукуряк",
              label: "Кукуряк"
            },
            {
              value: "Кърчовско",
              label: "Кърчовско"
            },
            {
              value: "Лозенградци",
              label: "Лозенградци"
            },
            {
              value: "Малкоч",
              label: "Малкоч"
            },
            {
              value: "Медевци",
              label: "Медевци"
            },
            {
              value: "Метличина",
              label: "Метличина"
            },
            {
              value: "Метличка",
              label: "Метличка"
            },
            {
              value: "Могиляне",
              label: "Могиляне"
            },
            {
              value: "Мъглене",
              label: "Мъглене"
            },
            {
              value: "Нане",
              label: "Нане"
            },
            {
              value: "Орлица",
              label: "Орлица"
            },
            {
              value: "Островец",
              label: "Островец"
            },
            {
              value: "Пловка",
              label: "Пловка"
            },
            {
              value: "Подкова",
              label: "Подкова"
            },
            {
              value: "Пресека",
              label: "Пресека"
            },
            {
              value: "Първенци",
              label: "Първенци"
            },
            {
              value: "Първица",
              label: "Първица"
            },
            {
              value: "Растник",
              label: "Растник"
            },
            {
              value: "Самодива",
              label: "Самодива"
            },
            {
              value: "Самокитка",
              label: "Самокитка"
            },
            {
              value: "Светлен",
              label: "Светлен"
            },
            {
              value: "Секирка",
              label: "Секирка"
            },
            {
              value: "Средско",
              label: "Средско"
            },
            {
              value: "Старейшино",
              label: "Старейшино"
            },
            {
              value: "Старово",
              label: "Старово"
            },
            {
              value: "Стоманци",
              label: "Стоманци"
            },
            {
              value: "Стрижба",
              label: "Стрижба"
            },
            {
              value: "Тихомир",
              label: "Тихомир"
            },
            {
              value: "Фотиново",
              label: "Фотиново"
            },
            {
              value: "Хаджийско",
              label: "Хаджийско"
            },
            {
              value: "Царино",
              label: "Царино"
            },
            {
              value: "Чавка",
              label: "Чавка"
            },
            {
              value: "Чакаларово",
              label: "Чакаларово"
            },
            {
              value: "Чичево",
              label: "Чичево"
            },
            {
              value: "Чорбаджийско",
              label: "Чорбаджийско"
            },
            {
              value: "Шипок",
              label: "Шипок"
            },
            {
              value: "Шопци",
              label: "Шопци"
            },
            {
              value: "Шумнатица",
              label: "Шумнатица"
            },
            {
              value: "Яковица",
              label: "Яковица"
            },
            {
              value: "Янино",
              label: "Янино"
            }
          ]
        },
        {
          value: "Крумовград",
          label: "Крумовград",
          list: [
            {
              value: "Аврен",
              label: "Аврен"
            },
            {
              value: "Багрилци",
              label: "Багрилци"
            },
            {
              value: "Бараци",
              label: "Бараци"
            },
            {
              value: "Благун",
              label: "Благун"
            },
            {
              value: "Бойник",
              label: "Бойник"
            },
            {
              value: "Бряговец",
              label: "Бряговец"
            },
            {
              value: "Бук",
              label: "Бук"
            },
            {
              value: "Вранско",
              label: "Вранско"
            },
            {
              value: "Голям Девесил",
              label: "Голям Девесил"
            },
            {
              value: "Голяма Чинка",
              label: "Голяма Чинка"
            },
            {
              value: "Голямо Каменяне",
              label: "Голямо Каменяне"
            },
            {
              value: "Горна кула",
              label: "Горна кула"
            },
            {
              value: "Горни Юруци",
              label: "Горни Юруци"
            },
            {
              value: "Гривка",
              label: "Гривка"
            },
            {
              value: "Гулийка",
              label: "Гулийка"
            },
            {
              value: "Гулия",
              label: "Гулия"
            },
            {
              value: "Девесилица",
              label: "Девесилица"
            },
            {
              value: "Девесилово",
              label: "Девесилово"
            },
            {
              value: "Джанка",
              label: "Джанка"
            },
            {
              value: "Доборско",
              label: "Доборско"
            },
            {
              value: "Долна кула",
              label: "Долна кула"
            },
            {
              value: "Долни Юруци",
              label: "Долни Юруци"
            },
            {
              value: "Дъждовник",
              label: "Дъждовник"
            },
            {
              value: "Егрек",
              label: "Егрек"
            },
            {
              value: "Едрино",
              label: "Едрино"
            },
            {
              value: "Звънарка",
              label: "Звънарка"
            },
            {
              value: "Зиморница",
              label: "Зиморница"
            },
            {
              value: "Златолист",
              label: "Златолист"
            },
            {
              value: "Калайджиево",
              label: "Калайджиево"
            },
            {
              value: "Каменка",
              label: "Каменка"
            },
            {
              value: "Кандилка",
              label: "Кандилка"
            },
            {
              value: "Качулка",
              label: "Качулка"
            },
            {
              value: "Ковил",
              label: "Ковил"
            },
            {
              value: "Кожухарци",
              label: "Кожухарци"
            },
            {
              value: "Котлари",
              label: "Котлари"
            },
            {
              value: "Красино",
              label: "Красино"
            },
            {
              value: "Крумовград",
              label: "Крумовград"
            },
            {
              value: "Къклица",
              label: "Къклица"
            },
            {
              value: "Лещарка",
              label: "Лещарка"
            },
            {
              value: "Лимец",
              label: "Лимец"
            },
            {
              value: "Луличка",
              label: "Луличка"
            },
            {
              value: "Малка Чинка",
              label: "Малка Чинка"
            },
            {
              value: "Малко Каменяне",
              label: "Малко Каменяне"
            },
            {
              value: "Малък Девeсил",
              label: "Малък Девeсил"
            },
            {
              value: "Метлика",
              label: "Метлика"
            },
            {
              value: "Морянци",
              label: "Морянци"
            },
            {
              value: "Овчари",
              label: "Овчари"
            },
            {
              value: "Орех",
              label: "Орех"
            },
            {
              value: "Орешари",
              label: "Орешари"
            },
            {
              value: "Падало",
              label: "Падало"
            },
            {
              value: "Пашинци",
              label: "Пашинци"
            },
            {
              value: "Пелин",
              label: "Пелин"
            },
            {
              value: "Перуника",
              label: "Перуника"
            },
            {
              value: "Подрумче",
              label: "Подрумче"
            },
            {
              value: "Полковник Желязово",
              label: "Полковник Желязово"
            },
            {
              value: "Поточарка",
              label: "Поточарка"
            },
            {
              value: "Поточница",
              label: "Поточница"
            },
            {
              value: "Раличево",
              label: "Раличево"
            },
            {
              value: "Рибино",
              label: "Рибино"
            },
            {
              value: "Рогач",
              label: "Рогач"
            },
            {
              value: "Ручей",
              label: "Ручей"
            },
            {
              value: "Самовила",
              label: "Самовила"
            },
            {
              value: "Сбор",
              label: "Сбор"
            },
            {
              value: "Синигер",
              label: "Синигер"
            },
            {
              value: "Скалак",
              label: "Скалак"
            },
            {
              value: "Сладкодум",
              label: "Сладкодум"
            },
            {
              value: "Сливарка",
              label: "Сливарка"
            },
            {
              value: "Стари чал",
              label: "Стари чал"
            },
            {
              value: "Стражец",
              label: "Стражец"
            },
            {
              value: "Странджево",
              label: "Странджево"
            },
            {
              value: "Студен кладенец",
              label: "Студен кладенец"
            },
            {
              value: "Сърнак",
              label: "Сърнак"
            },
            {
              value: "Тинтява",
              label: "Тинтява"
            },
            {
              value: "Токачка",
              label: "Токачка"
            },
            {
              value: "Тополка",
              label: "Тополка"
            },
            {
              value: "Хисар",
              label: "Хисар"
            },
            {
              value: "Храстово",
              label: "Храстово"
            },
            {
              value: "Чал",
              label: "Чал"
            },
            {
              value: "Черничево",
              label: "Черничево"
            },
            {
              value: "Чернооки",
              label: "Чернооки"
            }
          ]
        },
        {
          value: "Кърджали",
          label: "Кърджали",
          list: [
            {
              value: "Айрово",
              label: "Айрово"
            },
            {
              value: "Багра",
              label: "Багра"
            },
            {
              value: "Бащино",
              label: "Бащино"
            },
            {
              value: "Бели пласт",
              label: "Бели пласт"
            },
            {
              value: "Бленика",
              label: "Бленика"
            },
            {
              value: "Божак",
              label: "Божак"
            },
            {
              value: "Бойно",
              label: "Бойно"
            },
            {
              value: "Болярци",
              label: "Болярци"
            },
            {
              value: "Брош",
              label: "Брош"
            },
            {
              value: "Бяла поляна",
              label: "Бяла поляна"
            },
            {
              value: "Бялка",
              label: "Бялка"
            },
            {
              value: "Велешани",
              label: "Велешани"
            },
            {
              value: "Висока",
              label: "Висока"
            },
            {
              value: "Висока поляна",
              label: "Висока поляна"
            },
            {
              value: "Вишеград",
              label: "Вишеград"
            },
            {
              value: "Воловарци",
              label: "Воловарци"
            },
            {
              value: "Върбенци",
              label: "Върбенци"
            },
            {
              value: "Главатарци",
              label: "Главатарци"
            },
            {
              value: "Глухар",
              label: "Глухар"
            },
            {
              value: "Гняздово",
              label: "Гняздово"
            },
            {
              value: "Голяма бара",
              label: "Голяма бара"
            },
            {
              value: "Горна крепост",
              label: "Горна крепост"
            },
            {
              value: "Гъсково",
              label: "Гъсково"
            },
            {
              value: "Добриново",
              label: "Добриново"
            },
            {
              value: "Долище",
              label: "Долище"
            },
            {
              value: "Долна крепост",
              label: "Долна крепост"
            },
            {
              value: "Дъждино",
              label: "Дъждино"
            },
            {
              value: "Дъждовница",
              label: "Дъждовница"
            },
            {
              value: "Дънгово",
              label: "Дънгово"
            },
            {
              value: "Енчец",
              label: "Енчец"
            },
            {
              value: "Жинзифово",
              label: "Жинзифово"
            },
            {
              value: "Житарник",
              label: "Житарник"
            },
            {
              value: "Зайчино",
              label: "Зайчино"
            },
            {
              value: "Звезделина",
              label: "Звезделина"
            },
            {
              value: "Звезден",
              label: "Звезден"
            },
            {
              value: "Звиница",
              label: "Звиница"
            },
            {
              value: "Звъника",
              label: "Звъника"
            },
            {
              value: "Звънче",
              label: "Звънче"
            },
            {
              value: "Зелениково",
              label: "Зелениково"
            },
            {
              value: "Зимзелен",
              label: "Зимзелен"
            },
            {
              value: "Зорница",
              label: "Зорница"
            },
            {
              value: "Иванци",
              label: "Иванци"
            },
            {
              value: "Илиница",
              label: "Илиница"
            },
            {
              value: "Калинка",
              label: "Калинка"
            },
            {
              value: "Калоянци",
              label: "Калоянци"
            },
            {
              value: "Каменарци",
              label: "Каменарци"
            },
            {
              value: "Кобиляне",
              label: "Кобиляне"
            },
            {
              value: "Кокиче",
              label: "Кокиче"
            },
            {
              value: "Кокошане",
              label: "Кокошане"
            },
            {
              value: "Конево",
              label: "Конево"
            },
            {
              value: "Костино",
              label: "Костино"
            },
            {
              value: "Крайно село",
              label: "Крайно село"
            },
            {
              value: "Крин",
              label: "Крин"
            },
            {
              value: "Крушевска",
              label: "Крушевска"
            },
            {
              value: "Крушка",
              label: "Крушка"
            },
            {
              value: "Кърджали",
              label: "Кърджали"
            },
            {
              value: "Кьосево",
              label: "Кьосево"
            },
            {
              value: "Лисиците",
              label: "Лисиците"
            },
            {
              value: "Лъвово",
              label: "Лъвово"
            },
            {
              value: "Люляково",
              label: "Люляково"
            },
            {
              value: "Майсторово",
              label: "Майсторово"
            },
            {
              value: "Македонци",
              label: "Македонци"
            },
            {
              value: "Мартино",
              label: "Мартино"
            },
            {
              value: "Миладиново",
              label: "Миладиново"
            },
            {
              value: "Мост",
              label: "Мост"
            },
            {
              value: "Мургово",
              label: "Мургово"
            },
            {
              value: "Мъдрец",
              label: "Мъдрец"
            },
            {
              value: "Невестино",
              label: "Невестино"
            },
            {
              value: "Ненково",
              label: "Ненково"
            },
            {
              value: "Опълченско",
              label: "Опълченско"
            },
            {
              value: "Орешница",
              label: "Орешница"
            },
            {
              value: "Островица",
              label: "Островица"
            },
            {
              value: "Охлювец",
              label: "Охлювец"
            },
            {
              value: "Панчево",
              label: "Панчево"
            },
            {
              value: "Пеньово",
              label: "Пеньово"
            },
            {
              value: "Пепелище",
              label: "Пепелище"
            },
            {
              value: "Перперек",
              label: "Перперек"
            },
            {
              value: "Петлино",
              label: "Петлино"
            },
            {
              value: "Повет",
              label: "Повет"
            },
            {
              value: "Прилепци",
              label: "Прилепци"
            },
            {
              value: "Пропаст",
              label: "Пропаст"
            },
            {
              value: "Пъдарци",
              label: "Пъдарци"
            },
            {
              value: "Рани Лист",
              label: "Рани Лист"
            },
            {
              value: "Резбарци",
              label: "Резбарци"
            },
            {
              value: "Ридово",
              label: "Ридово"
            },
            {
              value: "Рудина",
              label: "Рудина"
            },
            {
              value: "Сватбаре",
              label: "Сватбаре"
            },
            {
              value: "Севдалина",
              label: "Севдалина"
            },
            {
              value: "Седловина",
              label: "Седловина"
            },
            {
              value: "Сестринско",
              label: "Сестринско"
            },
            {
              value: "Сипей",
              label: "Сипей"
            },
            {
              value: "Скалище",
              label: "Скалище"
            },
            {
              value: "Скална глава",
              label: "Скална глава"
            },
            {
              value: "Скърбино",
              label: "Скърбино"
            },
            {
              value: "Снежинка",
              label: "Снежинка"
            },
            {
              value: "Соколско",
              label: "Соколско"
            },
            {
              value: "Соколяне",
              label: "Соколяне"
            },
            {
              value: "Солище",
              label: "Солище"
            },
            {
              value: "Срединка",
              label: "Срединка"
            },
            {
              value: "Старо място",
              label: "Старо място"
            },
            {
              value: "Стражевци",
              label: "Стражевци"
            },
            {
              value: "Страхил войвода",
              label: "Страхил войвода"
            },
            {
              value: "Стремово",
              label: "Стремово"
            },
            {
              value: "Стремци",
              label: "Стремци"
            },
            {
              value: "Татково",
              label: "Татково"
            },
            {
              value: "Тополчане",
              label: "Тополчане"
            },
            {
              value: "Три Могили",
              label: "Три Могили"
            },
            {
              value: "Ходжовци",
              label: "Ходжовци"
            },
            {
              value: "Царевец",
              label: "Царевец"
            },
            {
              value: "Чеганци",
              label: "Чеганци"
            },
            {
              value: "Черешица",
              label: "Черешица"
            },
            {
              value: "Черна скала",
              label: "Черна скала"
            },
            {
              value: "Черньовци",
              label: "Черньовци"
            },
            {
              value: "Чилик",
              label: "Чилик"
            },
            {
              value: "Чифлик",
              label: "Чифлик"
            },
            {
              value: "Широко поле",
              label: "Широко поле"
            },
            {
              value: "Яребица",
              label: "Яребица"
            },
            {
              value: "Ястреб",
              label: "Ястреб"
            }
          ]
        },
        {
          value: "Момчилград",
          label: "Момчилград",
          list: [
            {
              value: "Ауста",
              label: "Ауста"
            },
            {
              value: "Багрянка",
              label: "Багрянка"
            },
            {
              value: "Балабаново",
              label: "Балабаново"
            },
            {
              value: "Биволяне",
              label: "Биволяне"
            },
            {
              value: "Врело",
              label: "Врело"
            },
            {
              value: "Върхари",
              label: "Върхари"
            },
            {
              value: "Горско Дюлево",
              label: "Горско Дюлево"
            },
            {
              value: "Груево",
              label: "Груево"
            },
            {
              value: "Гургулица",
              label: "Гургулица"
            },
            {
              value: "Девинци",
              label: "Девинци"
            },
            {
              value: "Джелепско",
              label: "Джелепско"
            },
            {
              value: "Друмче",
              label: "Друмче"
            },
            {
              value: "Загорско",
              label: "Загорско"
            },
            {
              value: "Звездел",
              label: "Звездел"
            },
            {
              value: "Каменец",
              label: "Каменец"
            },
            {
              value: "Карамфил",
              label: "Карамфил"
            },
            {
              value: "Конче",
              label: "Конче"
            },
            {
              value: "Кос",
              label: "Кос"
            },
            {
              value: "Кременец",
              label: "Кременец"
            },
            {
              value: "Лале",
              label: "Лале"
            },
            {
              value: "Летовник",
              label: "Летовник"
            },
            {
              value: "Манчево",
              label: "Манчево"
            },
            {
              value: "Момина сълза",
              label: "Момина сълза"
            },
            {
              value: "Момчилград",
              label: "Момчилград"
            },
            {
              value: "Нановица",
              label: "Нановица"
            },
            {
              value: "Неофит Бозвелиево",
              label: "Неофит Бозвелиево"
            },
            {
              value: "Обичник",
              label: "Обичник"
            },
            {
              value: "Пазарци",
              label: "Пазарци"
            },
            {
              value: "Пиявец",
              label: "Пиявец"
            },
            {
              value: "Плешници",
              label: "Плешници"
            },
            {
              value: "Постник",
              label: "Постник"
            },
            {
              value: "Прогрес",
              label: "Прогрес"
            },
            {
              value: "Птичар",
              label: "Птичар"
            },
            {
              value: "Равен",
              label: "Равен"
            },
            {
              value: "Ралица",
              label: "Ралица"
            },
            {
              value: "Садовица",
              label: "Садовица"
            },
            {
              value: "Свобода",
              label: "Свобода"
            },
            {
              value: "Седефче",
              label: "Седефче"
            },
            {
              value: "Седлари",
              label: "Седлари"
            },
            {
              value: "Сенце",
              label: "Сенце"
            },
            {
              value: "Синделци",
              label: "Синделци"
            },
            {
              value: "Соколино",
              label: "Соколино"
            },
            {
              value: "Сярци",
              label: "Сярци"
            },
            {
              value: "Татул",
              label: "Татул"
            },
            {
              value: "Чайка",
              label: "Чайка"
            },
            {
              value: "Чобанка",
              label: "Чобанка"
            },
            {
              value: "Чомаково",
              label: "Чомаково"
            },
            {
              value: "Чуково",
              label: "Чуково"
            },
            {
              value: "Юнаци",
              label: "Юнаци"
            }
          ]
        },
        {
          value: "Челноочене",
          label: "Челноочене",
          list: [
            {
              value: "Паничково",
              label: "Паничково"
            }
          ]
        },
        {
          value: "Черноочене",
          label: "Черноочене",
          list: [
            {
              value: "Бакалите",
              label: "Бакалите"
            },
            {
              value: "Безводно",
              label: "Безводно"
            },
            {
              value: "Бели вир",
              label: "Бели вир"
            },
            {
              value: "Беснурка",
              label: "Беснурка"
            },
            {
              value: "Божурци",
              label: "Божурци"
            },
            {
              value: "Боровско",
              label: "Боровско"
            },
            {
              value: "Босилица",
              label: "Босилица"
            },
            {
              value: "Бостанци",
              label: "Бостанци"
            },
            {
              value: "Бърза река",
              label: "Бърза река"
            },
            {
              value: "Верско",
              label: "Верско"
            },
            {
              value: "Водач",
              label: "Водач"
            },
            {
              value: "Вождово",
              label: "Вождово"
            },
            {
              value: "Войново",
              label: "Войново"
            },
            {
              value: "Възел",
              label: "Възел"
            },
            {
              value: "Габрово",
              label: "Габрово"
            },
            {
              value: "Даскалово",
              label: "Даскалово"
            },
            {
              value: "Драганово",
              label: "Драганово"
            },
            {
              value: "Душка",
              label: "Душка"
            },
            {
              value: "Дядовско",
              label: "Дядовско"
            },
            {
              value: "Железник",
              label: "Железник"
            },
            {
              value: "Женда",
              label: "Женда"
            },
            {
              value: "Житница",
              label: "Житница"
            },
            {
              value: "Йончово",
              label: "Йончово"
            },
            {
              value: "Каблешково",
              label: "Каблешково"
            },
            {
              value: "Каняк",
              label: "Каняк"
            },
            {
              value: "Комунига",
              label: "Комунига"
            },
            {
              value: "Копитник",
              label: "Копитник"
            },
            {
              value: "Куцово",
              label: "Куцово"
            },
            {
              value: "Лясково",
              label: "Лясково"
            },
            {
              value: "Минзухар",
              label: "Минзухар"
            },
            {
              value: "Мурга",
              label: "Мурга"
            },
            {
              value: "Небеска",
              label: "Небеска"
            },
            {
              value: "Нови пазар",
              label: "Нови пазар"
            },
            {
              value: "Новоселище",
              label: "Новоселище"
            },
            {
              value: "Ночево",
              label: "Ночево"
            },
            {
              value: "Патица",
              label: "Патица"
            },
            {
              value: "Петелово",
              label: "Петелово"
            },
            {
              value: "Пряпорец",
              label: "Пряпорец"
            },
            {
              value: "Пчеларово",
              label: "Пчеларово"
            },
            {
              value: "Русалина",
              label: "Русалина"
            },
            {
              value: "Свободиново",
              label: "Свободиново"
            },
            {
              value: "Соколите",
              label: "Соколите"
            },
            {
              value: "Среднево",
              label: "Среднево"
            },
            {
              value: "Средска",
              label: "Средска"
            },
            {
              value: "Стражница",
              label: "Стражница"
            },
            {
              value: "Черна нива",
              label: "Черна нива"
            },
            {
              value: "Черноочене",
              label: "Черноочене"
            },
            {
              value: "Ябълчени",
              label: "Ябълчени"
            },
            {
              value: "Яворово",
              label: "Яворово"
            }
          ]
        }
      ]
    },
    {
      value: "Кюстендил",
      label: "Кюстендил",
      list: [
        {
          value: "Бобов дол",
          label: "Бобов дол",
          list: [
            {
              value: "Бабино",
              label: "Бабино"
            },
            {
              value: "Бабинска река",
              label: "Бабинска река"
            },
            {
              value: "Блато",
              label: "Блато"
            },
            {
              value: "Бобов дол",
              label: "Бобов дол"
            },
            {
              value: "Голема Фуча",
              label: "Голема Фуча"
            },
            {
              value: "Големо село",
              label: "Големо село"
            },
            {
              value: "Голям Върбовник",
              label: "Голям Върбовник"
            },
            {
              value: "Горна Козница",
              label: "Горна Козница"
            },
            {
              value: "Долистово",
              label: "Долистово"
            },
            {
              value: "Коркина",
              label: "Коркина"
            },
            {
              value: "Локвата",
              label: "Локвата"
            },
            {
              value: "Мала Фуча",
              label: "Мала Фуча"
            },
            {
              value: "Мали Върбовник",
              label: "Мали Върбовник"
            },
            {
              value: "Мало село",
              label: "Мало село"
            },
            {
              value: "Мламолово",
              label: "Мламолово"
            },
            {
              value: "Новоселяне",
              label: "Новоселяне"
            },
            {
              value: "Паничарево",
              label: "Паничарево"
            },
            {
              value: "Шатрово",
              label: "Шатрово"
            }
          ]
        },
        {
          value: "Бобошево",
          label: "Бобошево",
          list: [
            {
              value: "Бадино",
              label: "Бадино"
            },
            {
              value: "Блажиево",
              label: "Блажиево"
            },
            {
              value: "Бобошево",
              label: "Бобошево"
            },
            {
              value: "Висока могила",
              label: "Висока могила"
            },
            {
              value: "Вуково",
              label: "Вуково"
            },
            {
              value: "Доброво",
              label: "Доброво"
            },
            {
              value: "Каменик",
              label: "Каменик"
            },
            {
              value: "Скрино",
              label: "Скрино"
            },
            {
              value: "Слатино",
              label: "Слатино"
            },
            {
              value: "Сопово",
              label: "Сопово"
            },
            {
              value: "Усойка",
              label: "Усойка"
            },
            {
              value: "Циклово",
              label: "Циклово"
            }
          ]
        },
        {
          value: "Дупница",
          label: "Дупница",
          list: [
            {
              value: "Баланово",
              label: "Баланово"
            },
            {
              value: "Бистрица",
              label: "Бистрица"
            },
            {
              value: "Блатино",
              label: "Блатино"
            },
            {
              value: "Грамаде",
              label: "Грамаде"
            },
            {
              value: "Делян",
              label: "Делян"
            },
            {
              value: "Джерман",
              label: "Джерман"
            },
            {
              value: "Дупница",
              label: "Дупница"
            },
            {
              value: "Дяково",
              label: "Дяково"
            },
            {
              value: "Крайни дол",
              label: "Крайни дол"
            },
            {
              value: "Крайници",
              label: "Крайници"
            },
            {
              value: "Кременик",
              label: "Кременик"
            },
            {
              value: "Палатово",
              label: "Палатово"
            },
            {
              value: "Пиперево",
              label: "Пиперево"
            },
            {
              value: "Самораново",
              label: "Самораново"
            },
            {
              value: "Тополница",
              label: "Тополница"
            },
            {
              value: "Червен брег",
              label: "Червен брег"
            },
            {
              value: "Яхиново",
              label: "Яхиново"
            }
          ]
        },
        {
          value: "Кочериново",
          label: "Кочериново",
          list: [
            {
              value: "Бараково",
              label: "Бараково"
            },
            {
              value: "Боровец",
              label: "Боровец"
            },
            {
              value: "Бураново",
              label: "Бураново"
            },
            {
              value: "Драгодан",
              label: "Драгодан"
            },
            {
              value: "Кочериново",
              label: "Кочериново"
            },
            {
              value: "Крумово",
              label: "Крумово"
            },
            {
              value: "Мурсалево",
              label: "Мурсалево"
            },
            {
              value: "Пороминово",
              label: "Пороминово"
            },
            {
              value: "Стоб",
              label: "Стоб"
            },
            {
              value: "Фролош",
              label: "Фролош"
            },
            {
              value: "Цървище",
              label: "Цървище"
            }
          ]
        },
        {
          value: "Кюстендил",
          label: "Кюстендил",
          list: [
            {
              value: "Багренци",
              label: "Багренци"
            },
            {
              value: "Берсин",
              label: "Берсин"
            },
            {
              value: "Блатец",
              label: "Блатец"
            },
            {
              value: "Бобешино",
              label: "Бобешино"
            },
            {
              value: "Богослов",
              label: "Богослов"
            },
            {
              value: "Буново",
              label: "Буново"
            },
            {
              value: "Вратца",
              label: "Вратца"
            },
            {
              value: "Гирчевци",
              label: "Гирчевци"
            },
            {
              value: "Горановци",
              label: "Горановци"
            },
            {
              value: "Горна Брестница",
              label: "Горна Брестница"
            },
            {
              value: "Горна Гращица",
              label: "Горна Гращица"
            },
            {
              value: "Горно Уйно",
              label: "Горно Уйно"
            },
            {
              value: "Грамаджано",
              label: "Грамаджано"
            },
            {
              value: "Граница",
              label: "Граница"
            },
            {
              value: "Гурбановци",
              label: "Гурбановци"
            },
            {
              value: "Гърбино",
              label: "Гърбино"
            },
            {
              value: "Гърляно",
              label: "Гърляно"
            },
            {
              value: "Гюешево",
              label: "Гюешево"
            },
            {
              value: "Дворище",
              label: "Дворище"
            },
            {
              value: "Дождевица",
              label: "Дождевица"
            },
            {
              value: "Долна Гращица",
              label: "Долна Гращица"
            },
            {
              value: "Долно село",
              label: "Долно село"
            },
            {
              value: "Долно Уйно",
              label: "Долно Уйно"
            },
            {
              value: "Драговищица",
              label: "Драговищица"
            },
            {
              value: "Жабокрът",
              label: "Жабокрът"
            },
            {
              value: "Жеравино",
              label: "Жеравино"
            },
            {
              value: "Жиленци",
              label: "Жиленци"
            },
            {
              value: "Ивановци",
              label: "Ивановци"
            },
            {
              value: "Каменичка Скакавица",
              label: "Каменичка Скакавица"
            },
            {
              value: "Катрище",
              label: "Катрище"
            },
            {
              value: "Коняво",
              label: "Коняво"
            },
            {
              value: "Копиловци",
              label: "Копиловци"
            },
            {
              value: "Коприва",
              label: "Коприва"
            },
            {
              value: "Кутугерци",
              label: "Кутугерци"
            },
            {
              value: "Кършалево",
              label: "Кършалево"
            },
            {
              value: "Кюстендил",
              label: "Кюстендил"
            },
            {
              value: "Лелинци",
              label: "Лелинци"
            },
            {
              value: "Леска",
              label: "Леска"
            },
            {
              value: "Лисец",
              label: "Лисец"
            },
            {
              value: "Лозно",
              label: "Лозно"
            },
            {
              value: "Ломница",
              label: "Ломница"
            },
            {
              value: "Мазарачево",
              label: "Мазарачево"
            },
            {
              value: "Николичевци",
              label: "Николичевци"
            },
            {
              value: "Нови чифлик",
              label: "Нови чифлик"
            },
            {
              value: "Ново село",
              label: "Ново село"
            },
            {
              value: "Пиперков чифлик",
              label: "Пиперков чифлик"
            },
            {
              value: "Полетинци",
              label: "Полетинци"
            },
            {
              value: "Полска Скакавица",
              label: "Полска Скакавица"
            },
            {
              value: "Преколница",
              label: "Преколница"
            },
            {
              value: "Радловци",
              label: "Радловци"
            },
            {
              value: "Раждавица",
              label: "Раждавица"
            },
            {
              value: "Раненци",
              label: "Раненци"
            },
            {
              value: "Режинци",
              label: "Режинци"
            },
            {
              value: "Ръсово",
              label: "Ръсово"
            },
            {
              value: "Савойски",
              label: "Савойски"
            },
            {
              value: "Сажденик",
              label: "Сажденик"
            },
            {
              value: "Скриняно",
              label: "Скриняно"
            },
            {
              value: "Слокощица",
              label: "Слокощица"
            },
            {
              value: "Соволяно",
              label: "Соволяно"
            },
            {
              value: "Стенско",
              label: "Стенско"
            },
            {
              value: "Таваличево",
              label: "Таваличево"
            },
            {
              value: "Търновлаг",
              label: "Търновлаг"
            },
            {
              value: "Търсино",
              label: "Търсино"
            },
            {
              value: "Церовица",
              label: "Церовица"
            },
            {
              value: "Црешново",
              label: "Црешново"
            },
            {
              value: "Цървена ябълка",
              label: "Цървена ябълка"
            },
            {
              value: "Цървендол",
              label: "Цървендол"
            },
            {
              value: "Цървеняно",
              label: "Цървеняно"
            },
            {
              value: "Чудинци",
              label: "Чудинци"
            },
            {
              value: "Шипочано",
              label: "Шипочано"
            },
            {
              value: "Шишковци",
              label: "Шишковци"
            },
            {
              value: "Ябълково",
              label: "Ябълково"
            }
          ]
        },
        {
          value: "Невестино",
          label: "Невестино",
          list: [
            {
              value: "Ваксево",
              label: "Ваксево"
            },
            {
              value: "Ветрен",
              label: "Ветрен"
            },
            {
              value: "Длъхчево - Сабляр",
              label: "Длъхчево - Сабляр"
            },
            {
              value: "Долна Козница",
              label: "Долна Козница"
            },
            {
              value: "Друмохар",
              label: "Друмохар"
            },
            {
              value: "Еремия",
              label: "Еремия"
            },
            {
              value: "Згурово",
              label: "Згурово"
            },
            {
              value: "Илия",
              label: "Илия"
            },
            {
              value: "Кадровица",
              label: "Кадровица"
            },
            {
              value: "Лиляч",
              label: "Лиляч"
            },
            {
              value: "Мърводол",
              label: "Мърводол"
            },
            {
              value: "Невестино",
              label: "Невестино"
            },
            {
              value: "Неделкова Гращица",
              label: "Неделкова Гращица"
            },
            {
              value: "Пастух",
              label: "Пастух"
            },
            {
              value: "Пелатиково",
              label: "Пелатиково"
            },
            {
              value: "Раково",
              label: "Раково"
            },
            {
              value: "Рашка Гращица",
              label: "Рашка Гращица"
            },
            {
              value: "Смоличано",
              label: "Смоличано"
            },
            {
              value: "Страдалово",
              label: "Страдалово"
            },
            {
              value: "Тишаново",
              label: "Тишаново"
            },
            {
              value: "Църварица",
              label: "Църварица"
            },
            {
              value: "Чеканец",
              label: "Чеканец"
            },
            {
              value: "Четирци",
              label: "Четирци"
            }
          ]
        },
        {
          value: "Рила",
          label: "Рила",
          list: [
            {
              value: "Падала",
              label: "Падала"
            },
            {
              value: "Пастра",
              label: "Пастра"
            },
            {
              value: "Рила",
              label: "Рила"
            },
            {
              value: "Рилски манастир",
              label: "Рилски манастир"
            },
            {
              value: "Смочево",
              label: "Смочево"
            }
          ]
        },
        {
          value: "Сапарева баня",
          label: "Сапарева баня",
          list: [
            {
              value: "Овчарци",
              label: "Овчарци"
            },
            {
              value: "Паничище",
              label: "Паничище"
            },
            {
              value: "Ресилово",
              label: "Ресилово"
            },
            {
              value: "Сапарева баня",
              label: "Сапарева баня"
            },
            {
              value: "Сапарево",
              label: "Сапарево"
            }
          ]
        },
        {
          value: "Трекляно",
          label: "Трекляно",
          list: [
            {
              value: "Брест",
              label: "Брест"
            },
            {
              value: "Бъзовица",
              label: "Бъзовица"
            },
            {
              value: "Габрешевци",
              label: "Габрешевци"
            },
            {
              value: "Горни Коритен",
              label: "Горни Коритен"
            },
            {
              value: "Горно Кобиле",
              label: "Горно Кобиле"
            },
            {
              value: "Добри дол",
              label: "Добри дол"
            },
            {
              value: "Долни Коритен",
              label: "Долни Коритен"
            },
            {
              value: "Долно Кобиле",
              label: "Долно Кобиле"
            },
            {
              value: "Драгойчинци",
              label: "Драгойчинци"
            },
            {
              value: "Злогош",
              label: "Злогош"
            },
            {
              value: "Киселица",
              label: "Киселица"
            },
            {
              value: "Косово",
              label: "Косово"
            },
            {
              value: "Метохия",
              label: "Метохия"
            },
            {
              value: "Побит камък",
              label: "Побит камък"
            },
            {
              value: "Средорек",
              label: "Средорек"
            },
            {
              value: "Сушица",
              label: "Сушица"
            },
            {
              value: "Трекляно",
              label: "Трекляно"
            },
            {
              value: "Уши",
              label: "Уши"
            },
            {
              value: "Чешлянци",
              label: "Чешлянци"
            }
          ]
        }
      ]
    },
    {
      value: "Ловеч",
      label: "Ловеч",
      list: [
        {
          value: "Априлци",
          label: "Априлци",
          list: [
            {
              value: "Априлци",
              label: "Априлци"
            },
            {
              value: "Велчево",
              label: "Велчево"
            },
            {
              value: "Драшкова поляна",
              label: "Драшкова поляна"
            },
            {
              value: "Скандалото",
              label: "Скандалото"
            }
          ]
        },
        {
          value: "Летница",
          label: "Летница",
          list: [
            {
              value: "Горско Сливово",
              label: "Горско Сливово"
            },
            {
              value: "Крушуна",
              label: "Крушуна"
            },
            {
              value: "Кърпачево",
              label: "Кърпачево"
            },
            {
              value: "Летница",
              label: "Летница"
            }
          ]
        },
        {
          value: "Ловеч",
          label: "Ловеч",
          list: [
            {
              value: "Абланица",
              label: "Абланица"
            },
            {
              value: "Александрово",
              label: "Александрово"
            },
            {
              value: "Баховица",
              label: "Баховица"
            },
            {
              value: "Брестово",
              label: "Брестово"
            },
            {
              value: "Българене",
              label: "Българене"
            },
            {
              value: "Владиня",
              label: "Владиня"
            },
            {
              value: "Горан",
              label: "Горан"
            },
            {
              value: "Горно Павликене",
              label: "Горно Павликене"
            },
            {
              value: "Гостиня",
              label: "Гостиня"
            },
            {
              value: "Деветаки",
              label: "Деветаки"
            },
            {
              value: "Дойренци",
              label: "Дойренци"
            },
            {
              value: "Дренов",
              label: "Дренов"
            },
            {
              value: "Дъбрава",
              label: "Дъбрава"
            },
            {
              value: "Изворче",
              label: "Изворче"
            },
            {
              value: "Йоглав",
              label: "Йоглав"
            },
            {
              value: "Казачево",
              label: "Казачево"
            },
            {
              value: "Къкрина",
              label: "Къкрина"
            },
            {
              value: "Лешница",
              label: "Лешница"
            },
            {
              value: "Лисец",
              label: "Лисец"
            },
            {
              value: "Ловеч",
              label: "Ловеч"
            },
            {
              value: "Малиново",
              label: "Малиново"
            },
            {
              value: "Прелом",
              label: "Прелом"
            },
            {
              value: "Пресяка",
              label: "Пресяка"
            },
            {
              value: "Радювене",
              label: "Радювене"
            },
            {
              value: "Скобелево",
              label: "Скобелево"
            },
            {
              value: "Славяни",
              label: "Славяни"
            },
            {
              value: "Слатина",
              label: "Слатина"
            },
            {
              value: "Сливек",
              label: "Сливек"
            },
            {
              value: "Смочан",
              label: "Смочан"
            },
            {
              value: "Соколово",
              label: "Соколово"
            },
            {
              value: "Стефаново",
              label: "Стефаново"
            },
            {
              value: "Тепава",
              label: "Тепава"
            },
            {
              value: "Умаревци",
              label: "Умаревци"
            },
            {
              value: "Хлевене",
              label: "Хлевене"
            },
            {
              value: "Чавдарци",
              label: "Чавдарци"
            }
          ]
        },
        {
          value: "Луковит",
          label: "Луковит",
          list: [
            {
              value: "Бежаново",
              label: "Бежаново"
            },
            {
              value: "Беленци",
              label: "Беленци"
            },
            {
              value: "Дерманци",
              label: "Дерманци"
            },
            {
              value: "Дъбен",
              label: "Дъбен"
            },
            {
              value: "Карлуково",
              label: "Карлуково"
            },
            {
              value: "Луковит",
              label: "Луковит"
            },
            {
              value: "Петревене",
              label: "Петревене"
            },
            {
              value: "Пещерна",
              label: "Пещерна"
            },
            {
              value: "Румянцево",
              label: "Румянцево"
            },
            {
              value: "Тодоричене",
              label: "Тодоричене"
            },
            {
              value: "Торос",
              label: "Торос"
            },
            {
              value: "Ъглен",
              label: "Ъглен"
            }
          ]
        },
        {
          value: "Тетевен",
          label: "Тетевен",
          list: [
            {
              value: "Бабинци",
              label: "Бабинци"
            },
            {
              value: "Български извор",
              label: "Български извор"
            },
            {
              value: "Васильово",
              label: "Васильово"
            },
            {
              value: "Галата",
              label: "Галата"
            },
            {
              value: "Глогово",
              label: "Глогово"
            },
            {
              value: "Гложене",
              label: "Гложене"
            },
            {
              value: "Голям извор",
              label: "Голям извор"
            },
            {
              value: "Градежница",
              label: "Градежница"
            },
            {
              value: "Дивчовото",
              label: "Дивчовото"
            },
            {
              value: "Малка Желязна",
              label: "Малка Желязна"
            },
            {
              value: "Рибарица",
              label: "Рибарица"
            },
            {
              value: "Тетевен",
              label: "Тетевен"
            },
            {
              value: "Черни Вит",
              label: "Черни Вит"
            }
          ]
        },
        {
          value: "Троян",
          label: "Троян",
          list: [
            {
              value: "Балабанско",
              label: "Балабанско"
            },
            {
              value: "Балканец",
              label: "Балканец"
            },
            {
              value: "Беклемето",
              label: "Беклемето"
            },
            {
              value: "Бели Осъм",
              label: "Бели Осъм"
            },
            {
              value: "Белиш",
              label: "Белиш"
            },
            {
              value: "Борима",
              label: "Борима"
            },
            {
              value: "Врабево",
              label: "Врабево"
            },
            {
              value: "Голяма Желязна",
              label: "Голяма Желязна"
            },
            {
              value: "Горно трапе",
              label: "Горно трапе"
            },
            {
              value: "Гумощник",
              label: "Гумощник"
            },
            {
              value: "Дебнево",
              label: "Дебнево"
            },
            {
              value: "Добродан",
              label: "Добродан"
            },
            {
              value: "Дълбок дол",
              label: "Дълбок дол"
            },
            {
              value: "Калейца",
              label: "Калейца"
            },
            {
              value: "Ломец",
              label: "Ломец"
            },
            {
              value: "Орешак",
              label: "Орешак"
            },
            {
              value: "Патрешко",
              label: "Патрешко"
            },
            {
              value: "Старо село",
              label: "Старо село"
            },
            {
              value: "Терзийско",
              label: "Терзийско"
            },
            {
              value: "Троян",
              label: "Троян"
            },
            {
              value: "Черни Осъм",
              label: "Черни Осъм"
            },
            {
              value: "Чифлик",
              label: "Чифлик"
            },
            {
              value: "Шипково",
              label: "Шипково"
            }
          ]
        },
        {
          value: "Угърчин",
          label: "Угърчин",
          list: [
            {
              value: "Голец",
              label: "Голец"
            },
            {
              value: "Драгана",
              label: "Драгана"
            },
            {
              value: "Каленик",
              label: "Каленик"
            },
            {
              value: "Катунец",
              label: "Катунец"
            },
            {
              value: "Кирчево",
              label: "Кирчево"
            },
            {
              value: "Лесидрен",
              label: "Лесидрен"
            },
            {
              value: "Микре",
              label: "Микре"
            },
            {
              value: "Орляне",
              label: "Орляне"
            },
            {
              value: "Славщица",
              label: "Славщица"
            },
            {
              value: "Сопот",
              label: "Сопот"
            },
            {
              value: "Угърчин",
              label: "Угърчин"
            }
          ]
        },
        {
          value: "Ябланица",
          label: "Ябланица",
          list: [
            {
              value: "Батулци",
              label: "Батулци"
            },
            {
              value: "Брестница",
              label: "Брестница"
            },
            {
              value: "Голяма Брестница",
              label: "Голяма Брестница"
            },
            {
              value: "Добревци",
              label: "Добревци"
            },
            {
              value: "Дъбравата",
              label: "Дъбравата"
            },
            {
              value: "Златна Панега",
              label: "Златна Панега"
            },
            {
              value: "Малък извор",
              label: "Малък извор"
            },
            {
              value: "Орешене",
              label: "Орешене"
            },
            {
              value: "Ябланица",
              label: "Ябланица"
            }
          ]
        }
      ]
    },
    {
      value: "Монтана",
      label: "Монтана",
      list: [
        {
          value: "Берковица",
          label: "Берковица",
          list: [
            {
              value: "Балювица",
              label: "Балювица"
            },
            {
              value: "Берковица",
              label: "Берковица"
            },
            {
              value: "Бистрилица",
              label: "Бистрилица"
            },
            {
              value: "Бокиловци",
              label: "Бокиловци"
            },
            {
              value: "Боровци",
              label: "Боровци"
            },
            {
              value: "Бързия",
              label: "Бързия"
            },
            {
              value: "Гаганица",
              label: "Гаганица"
            },
            {
              value: "Замфирово",
              label: "Замфирово"
            },
            {
              value: "Комарево",
              label: "Комарево"
            },
            {
              value: "Костенци",
              label: "Костенци"
            },
            {
              value: "Котеновци",
              label: "Котеновци"
            },
            {
              value: "Лесковец",
              label: "Лесковец"
            },
            {
              value: "Мездрея",
              label: "Мездрея"
            },
            {
              value: "Песочница",
              label: "Песочница"
            },
            {
              value: "Петрохан",
              label: "Петрохан"
            },
            {
              value: "Пърличево",
              label: "Пърличево"
            },
            {
              value: "Рашовица",
              label: "Рашовица"
            },
            {
              value: "Слатина",
              label: "Слатина"
            },
            {
              value: "Цветкова бара",
              label: "Цветкова бара"
            },
            {
              value: "Черешовица",
              label: "Черешовица"
            },
            {
              value: "Ягодово",
              label: "Ягодово"
            }
          ]
        },
        {
          value: "Бойчиновци",
          label: "Бойчиновци",
          list: [
            {
              value: "Бели брег",
              label: "Бели брег"
            },
            {
              value: "Бели брод",
              label: "Бели брод"
            },
            {
              value: "Бойчиновци",
              label: "Бойчиновци"
            },
            {
              value: "Владимирово",
              label: "Владимирово"
            },
            {
              value: "Громшин",
              label: "Громшин"
            },
            {
              value: "Ерден",
              label: "Ерден"
            },
            {
              value: "Кобиляк",
              label: "Кобиляк"
            },
            {
              value: "Лехчево",
              label: "Лехчево"
            },
            {
              value: "Мадан",
              label: "Мадан"
            },
            {
              value: "Мърчево",
              label: "Мърчево"
            },
            {
              value: "Охрид",
              label: "Охрид"
            },
            {
              value: "Палилула",
              label: "Палилула"
            },
            {
              value: "Портитовци",
              label: "Портитовци"
            }
          ]
        },
        {
          value: "Брусарци",
          label: "Брусарци",
          list: [
            {
              value: "Брусарци",
              label: "Брусарци"
            },
            {
              value: "Буковец",
              label: "Буковец"
            },
            {
              value: "Василовци",
              label: "Василовци"
            },
            {
              value: "Дондуково",
              label: "Дондуково"
            },
            {
              value: "Дъбова махала",
              label: "Дъбова махала"
            },
            {
              value: "Киселево",
              label: "Киселево"
            },
            {
              value: "Княжева махала",
              label: "Княжева махала"
            },
            {
              value: "Крива бара",
              label: "Крива бара"
            },
            {
              value: "Одоровци",
              label: "Одоровци"
            },
            {
              value: "Смирненски",
              label: "Смирненски"
            }
          ]
        },
        {
          value: "Вълчедръм",
          label: "Вълчедръм",
          list: [
            {
              value: "Ботево",
              label: "Ботево"
            },
            {
              value: "Бъзовец",
              label: "Бъзовец"
            },
            {
              value: "Вълчедръм",
              label: "Вълчедръм"
            },
            {
              value: "Горни Цибър",
              label: "Горни Цибър"
            },
            {
              value: "Долни Цибър",
              label: "Долни Цибър"
            },
            {
              value: "Златия",
              label: "Златия"
            },
            {
              value: "Игнатово",
              label: "Игнатово"
            },
            {
              value: "Мокреш",
              label: "Мокреш"
            },
            {
              value: "Разград",
              label: "Разград"
            },
            {
              value: "Септемврийци",
              label: "Септемврийци"
            },
            {
              value: "Черни връх",
              label: "Черни връх"
            }
          ]
        },
        {
          value: "Вършец",
          label: "Вършец",
          list: [
            {
              value: "Вършец",
              label: "Вършец"
            },
            {
              value: "Горна Бела речка",
              label: "Горна Бела речка"
            },
            {
              value: "Горно Озирово",
              label: "Горно Озирово"
            },
            {
              value: "Долна Бела речка",
              label: "Долна Бела речка"
            },
            {
              value: "Долно Озирово",
              label: "Долно Озирово"
            },
            {
              value: "Драганица",
              label: "Драганица"
            },
            {
              value: "Клисурски манастир",
              label: "Клисурски манастир"
            },
            {
              value: "Спанчевци",
              label: "Спанчевци"
            },
            {
              value: "Стояново",
              label: "Стояново"
            },
            {
              value: "Черкаски",
              label: "Черкаски"
            }
          ]
        },
        {
          value: "Георги Дамяново",
          label: "Георги Дамяново",
          list: [
            {
              value: "Видлица",
              label: "Видлица"
            },
            {
              value: "Гаврил Геново",
              label: "Гаврил Геново"
            },
            {
              value: "Георги Дамяново",
              label: "Георги Дамяново"
            },
            {
              value: "Главановци",
              label: "Главановци"
            },
            {
              value: "Говежда",
              label: "Говежда"
            },
            {
              value: "Дива Слатина",
              label: "Дива Слатина"
            },
            {
              value: "Дълги дел",
              label: "Дълги дел"
            },
            {
              value: "Еловица",
              label: "Еловица"
            },
            {
              value: "Каменна Рикса",
              label: "Каменна Рикса"
            },
            {
              value: "Копиловци",
              label: "Копиловци"
            },
            {
              value: "Меляне",
              label: "Меляне"
            },
            {
              value: "Помеждин",
              label: "Помеждин"
            },
            {
              value: "Чемиш",
              label: "Чемиш"
            }
          ]
        },
        {
          value: "Лом",
          label: "Лом",
          list: [
            {
              value: "Добри дол",
              label: "Добри дол"
            },
            {
              value: "Долно Линево",
              label: "Долно Линево"
            },
            {
              value: "Замфир",
              label: "Замфир"
            },
            {
              value: "Ковачица",
              label: "Ковачица"
            },
            {
              value: "Лом",
              label: "Лом"
            },
            {
              value: "Орсоя",
              label: "Орсоя"
            },
            {
              value: "Сливата",
              label: "Сливата"
            },
            {
              value: "Сталийска махала",
              label: "Сталийска махала"
            },
            {
              value: "Станево",
              label: "Станево"
            },
            {
              value: "Трайково",
              label: "Трайково"
            }
          ]
        },
        {
          value: "Медковец",
          label: "Медковец",
          list: [
            {
              value: "Аспарухово",
              label: "Аспарухово"
            },
            {
              value: "Медковец",
              label: "Медковец"
            },
            {
              value: "Пишурка",
              label: "Пишурка"
            },
            {
              value: "Расово",
              label: "Расово"
            },
            {
              value: "Сливовик",
              label: "Сливовик"
            }
          ]
        },
        {
          value: "Монтана",
          label: "Монтана",
          list: [
            {
              value: "Безденица",
              label: "Безденица"
            },
            {
              value: "Белотинци",
              label: "Белотинци"
            },
            {
              value: "Благово",
              label: "Благово"
            },
            {
              value: "Винище",
              label: "Винище"
            },
            {
              value: "Вирове",
              label: "Вирове"
            },
            {
              value: "Войници",
              label: "Войници"
            },
            {
              value: "Габровница",
              label: "Габровница"
            },
            {
              value: "Горна Вереница",
              label: "Горна Вереница"
            },
            {
              value: "Горно Церовене",
              label: "Горно Церовене"
            },
            {
              value: "Доктор Йосифово",
              label: "Доктор Йосифово"
            },
            {
              value: "Долна Вереница",
              label: "Долна Вереница"
            },
            {
              value: "Долна Рикса",
              label: "Долна Рикса"
            },
            {
              value: "Долно Белотинци",
              label: "Долно Белотинци"
            },
            {
              value: "Клисурица",
              label: "Клисурица"
            },
            {
              value: "Крапчене",
              label: "Крапчене"
            },
            {
              value: "Липен",
              label: "Липен"
            },
            {
              value: "Монтана",
              label: "Монтана"
            },
            {
              value: "Николово",
              label: "Николово"
            },
            {
              value: "Славотин",
              label: "Славотин"
            },
            {
              value: "Смоляновци",
              label: "Смоляновци"
            },
            {
              value: "Стубел",
              label: "Стубел"
            },
            {
              value: "Студено буче",
              label: "Студено буче"
            },
            {
              value: "Сумер",
              label: "Сумер"
            },
            {
              value: "Трифоново",
              label: "Трифоново"
            }
          ]
        },
        {
          value: "Чипровци",
          label: "Чипровци",
          list: [
            {
              value: "Белимел",
              label: "Белимел"
            },
            {
              value: "Горна Ковачица",
              label: "Горна Ковачица"
            },
            {
              value: "Горна Лука",
              label: "Горна Лука"
            },
            {
              value: "Железна",
              label: "Железна"
            },
            {
              value: "Мартиново",
              label: "Мартиново"
            },
            {
              value: "Митровци",
              label: "Митровци"
            },
            {
              value: "Превала",
              label: "Превала"
            },
            {
              value: "Равна",
              label: "Равна"
            },
            {
              value: "Челюстница",
              label: "Челюстница"
            },
            {
              value: "Чипровци",
              label: "Чипровци"
            }
          ]
        },
        {
          value: "Якимово",
          label: "Якимово",
          list: [
            {
              value: "Долно Церовене",
              label: "Долно Церовене"
            },
            {
              value: "Дългоделци",
              label: "Дългоделци"
            },
            {
              value: "Комощица",
              label: "Комощица"
            },
            {
              value: "Якимово",
              label: "Якимово"
            }
          ]
        }
      ]
    },
    {
      value: "Пазарджик",
      label: "Пазарджик",
      list: [
        {
          value: "Батак",
          label: "Батак",
          list: [
            {
              value: "Батак",
              label: "Батак"
            },
            {
              value: "Нова махала",
              label: "Нова махала"
            },
            {
              value: "Фотиново",
              label: "Фотиново"
            }
          ]
        },
        {
          value: "Белово",
          label: "Белово",
          list: [
            {
              value: "Аканджиево",
              label: "Аканджиево"
            },
            {
              value: "Белово",
              label: "Белово"
            },
            {
              value: "Габровица",
              label: "Габровица"
            },
            {
              value: "Голямо Белово",
              label: "Голямо Белово"
            },
            {
              value: "Дъбравите",
              label: "Дъбравите"
            },
            {
              value: "Мененкьово",
              label: "Мененкьово"
            },
            {
              value: "Момина клисура",
              label: "Момина клисура"
            },
            {
              value: "Сестримо",
              label: "Сестримо"
            },
            {
              value: "Чаира",
              label: "Чаира"
            }
          ]
        },
        {
          value: "Брацигово",
          label: "Брацигово",
          list: [
            {
              value: "Брацигово",
              label: "Брацигово"
            },
            {
              value: "Бяга",
              label: "Бяга"
            },
            {
              value: "Жребичко",
              label: "Жребичко"
            },
            {
              value: "Исперихово",
              label: "Исперихово"
            },
            {
              value: "Козарско",
              label: "Козарско"
            },
            {
              value: "Равногор",
              label: "Равногор"
            },
            {
              value: "Розово",
              label: "Розово"
            }
          ]
        },
        {
          value: "Велинград",
          label: "Велинград",
          list: [
            {
              value: "Абланица",
              label: "Абланица"
            },
            {
              value: "Алендарова",
              label: "Алендарова"
            },
            {
              value: "Биркова",
              label: "Биркова"
            },
            {
              value: "Бозьова",
              label: "Бозьова"
            },
            {
              value: "Бутрева",
              label: "Бутрева"
            },
            {
              value: "Велинград",
              label: "Велинград"
            },
            {
              value: "Враненци",
              label: "Враненци"
            },
            {
              value: "Всемирци",
              label: "Всемирци"
            },
            {
              value: "Горна Биркова",
              label: "Горна Биркова"
            },
            {
              value: "Горна Дъбева",
              label: "Горна Дъбева"
            },
            {
              value: "Грашево",
              label: "Грашево"
            },
            {
              value: "Долна Дъбева",
              label: "Долна Дъбева"
            },
            {
              value: "Драгиново",
              label: "Драгиново"
            },
            {
              value: "Кандови",
              label: "Кандови"
            },
            {
              value: "Кръстава",
              label: "Кръстава"
            },
            {
              value: "Пашови",
              label: "Пашови"
            },
            {
              value: "Рохлева",
              label: "Рохлева"
            },
            {
              value: "Света Петка",
              label: "Света Петка"
            },
            {
              value: "Цветино",
              label: "Цветино"
            },
            {
              value: "Чолакова",
              label: "Чолакова"
            },
            {
              value: "Юндола",
              label: "Юндола"
            }
          ]
        },
        {
          value: "Лесичово",
          label: "Лесичово",
          list: [
            {
              value: "Боримечково",
              label: "Боримечково"
            },
            {
              value: "Динката",
              label: "Динката"
            },
            {
              value: "Калугерово",
              label: "Калугерово"
            },
            {
              value: "Лесичово",
              label: "Лесичово"
            },
            {
              value: "Памидово",
              label: "Памидово"
            },
            {
              value: "Церово",
              label: "Церово"
            },
            {
              value: "Щърково",
              label: "Щърково"
            }
          ]
        },
        {
          value: "Пазарджик",
          label: "Пазарджик",
          list: [
            {
              value: "Алеко Константиново",
              label: "Алеко Константиново"
            },
            {
              value: "Априлци",
              label: "Априлци"
            },
            {
              value: "Братаница",
              label: "Братаница"
            },
            {
              value: "Величково",
              label: "Величково"
            },
            {
              value: "Гара Пазарджик",
              label: "Гара Пазарджик"
            },
            {
              value: "Гелеменово",
              label: "Гелеменово"
            },
            {
              value: "Главиница",
              label: "Главиница"
            },
            {
              value: "Говедаре",
              label: "Говедаре"
            },
            {
              value: "Дебръщица",
              label: "Дебръщица"
            },
            {
              value: "Добровница",
              label: "Добровница"
            },
            {
              value: "Драгор",
              label: "Драгор"
            },
            {
              value: "Звъничево",
              label: "Звъничево"
            },
            {
              value: "Ивайло",
              label: "Ивайло"
            },
            {
              value: "Крали Марко",
              label: "Крали Марко"
            },
            {
              value: "Ляхово",
              label: "Ляхово"
            },
            {
              value: "Мало Конаре",
              label: "Мало Конаре"
            },
            {
              value: "Мирянци",
              label: "Мирянци"
            },
            {
              value: "Мокрище",
              label: "Мокрище"
            },
            {
              value: "Овчеполци",
              label: "Овчеполци"
            },
            {
              value: "Огняново",
              label: "Огняново"
            },
            {
              value: "Пазарджик",
              label: "Пазарджик"
            },
            {
              value: "Паталеница",
              label: "Паталеница"
            },
            {
              value: "Пищигово",
              label: "Пищигово"
            },
            {
              value: "Росен",
              label: "Росен"
            },
            {
              value: "Сарая",
              label: "Сарая"
            },
            {
              value: "Сбор",
              label: "Сбор"
            },
            {
              value: "Синитово",
              label: "Синитово"
            },
            {
              value: "Тополи дол",
              label: "Тополи дол"
            },
            {
              value: "Хаджиево",
              label: "Хаджиево"
            },
            {
              value: "Цар Асен",
              label: "Цар Асен"
            },
            {
              value: "Црънча",
              label: "Црънча"
            },
            {
              value: "Черногорово",
              label: "Черногорово"
            },
            {
              value: "Юнаците",
              label: "Юнаците"
            }
          ]
        },
        {
          value: "Панагюрище",
          label: "Панагюрище",
          list: [
            {
              value: "Баня",
              label: "Баня"
            },
            {
              value: "Бъта",
              label: "Бъта"
            },
            {
              value: "Елшица",
              label: "Елшица"
            },
            {
              value: "Левски",
              label: "Левски"
            },
            {
              value: "Оборище",
              label: "Оборище"
            },
            {
              value: "Панагюрище",
              label: "Панагюрище"
            },
            {
              value: "Панагюрски колонии",
              label: "Панагюрски колонии"
            },
            {
              value: "Поибрене",
              label: "Поибрене"
            },
            {
              value: "Попинци",
              label: "Попинци"
            },
            {
              value: "Сребриново",
              label: "Сребриново"
            }
          ]
        },
        {
          value: "Пещера",
          label: "Пещера",
          list: [
            {
              value: "Капитан Димитриево",
              label: "Капитан Димитриево"
            },
            {
              value: "Пещера",
              label: "Пещера"
            },
            {
              value: "Радилово",
              label: "Радилово"
            },
            {
              value: "Свети Константин",
              label: "Свети Константин"
            }
          ]
        },
        {
          value: "Ракитово",
          label: "Ракитово",
          list: [
            {
              value: "Дорково",
              label: "Дорково"
            },
            {
              value: "Костандово",
              label: "Костандово"
            },
            {
              value: "Ракитово",
              label: "Ракитово"
            }
          ]
        },
        {
          value: "Септември",
          label: "Септември",
          list: [
            {
              value: "Бошуля",
              label: "Бошуля"
            },
            {
              value: "Варвара",
              label: "Варвара"
            },
            {
              value: "Ветрен",
              label: "Ветрен"
            },
            {
              value: "Ветрен дол",
              label: "Ветрен дол"
            },
            {
              value: "Виноградец",
              label: "Виноградец"
            },
            {
              value: "Горно Вършило",
              label: "Горно Вършило"
            },
            {
              value: "Долно Вършило",
              label: "Долно Вършило"
            },
            {
              value: "Злокучене",
              label: "Злокучене"
            },
            {
              value: "Карабунар",
              label: "Карабунар"
            },
            {
              value: "Ковачево",
              label: "Ковачево"
            },
            {
              value: "Лозен",
              label: "Лозен"
            },
            {
              value: "Семчиново",
              label: "Семчиново"
            },
            {
              value: "Септември",
              label: "Септември"
            },
            {
              value: "Симеоновец",
              label: "Симеоновец"
            },
            {
              value: "Славовица",
              label: "Славовица"
            }
          ]
        },
        {
          value: "Стрелча",
          label: "Стрелча",
          list: [
            {
              value: "Блатница",
              label: "Блатница"
            },
            {
              value: "Дюлево",
              label: "Дюлево"
            },
            {
              value: "Свобода",
              label: "Свобода"
            },
            {
              value: "Смилец",
              label: "Смилец"
            },
            {
              value: "Стрелча",
              label: "Стрелча"
            }
          ]
        },
        {
          value: "Сърница",
          label: "Сърница",
          list: [
            {
              value: "Медени поляни",
              label: "Медени поляни"
            },
            {
              value: "Побит камък",
              label: "Побит камък"
            },
            {
              value: "Сърница",
              label: "Сърница"
            }
          ]
        }
      ]
    },
    {
      value: "Перник",
      label: "Перник",
      list: [
        {
          value: "Брезник",
          label: "Брезник",
          list: [
            {
              value: "Арзан",
              label: "Арзан"
            },
            {
              value: "Бабица",
              label: "Бабица"
            },
            {
              value: "Банище",
              label: "Банище"
            },
            {
              value: "Бегуновци",
              label: "Бегуновци"
            },
            {
              value: "Билинци",
              label: "Билинци"
            },
            {
              value: "Брезник",
              label: "Брезник"
            },
            {
              value: "Брезнишки извор",
              label: "Брезнишки извор"
            },
            {
              value: "Брусник",
              label: "Брусник"
            },
            {
              value: "Велковци",
              label: "Велковци"
            },
            {
              value: "Видрица",
              label: "Видрица"
            },
            {
              value: "Гигинци",
              label: "Гигинци"
            },
            {
              value: "Гоз",
              label: "Гоз"
            },
            {
              value: "Горна Секирна",
              label: "Горна Секирна"
            },
            {
              value: "Горни Романци",
              label: "Горни Романци"
            },
            {
              value: "Гърло",
              label: "Гърло"
            },
            {
              value: "Долна Секирна",
              label: "Долна Секирна"
            },
            {
              value: "Долни Романци",
              label: "Долни Романци"
            },
            {
              value: "Душинци",
              label: "Душинци"
            },
            {
              value: "Завала",
              label: "Завала"
            },
            {
              value: "Конска",
              label: "Конска"
            },
            {
              value: "Кошарево",
              label: "Кошарево"
            },
            {
              value: "Красава",
              label: "Красава"
            },
            {
              value: "Кривонос",
              label: "Кривонос"
            },
            {
              value: "Муртинци",
              label: "Муртинци"
            },
            {
              value: "Непразненци",
              label: "Непразненци"
            },
            {
              value: "Ноевци",
              label: "Ноевци"
            },
            {
              value: "Озърновци",
              label: "Озърновци"
            },
            {
              value: "Ребро",
              label: "Ребро"
            },
            {
              value: "Режанци",
              label: "Режанци"
            },
            {
              value: "Ръжавец",
              label: "Ръжавец"
            },
            {
              value: "Садовик",
              label: "Садовик"
            },
            {
              value: "Слаковци",
              label: "Слаковци"
            },
            {
              value: "Сопица",
              label: "Сопица"
            },
            {
              value: "Станьовци",
              label: "Станьовци"
            },
            {
              value: "Ярославци",
              label: "Ярославци"
            }
          ]
        },
        {
          value: "Земен",
          label: "Земен",
          list: [
            {
              value: "Беренде",
              label: "Беренде"
            },
            {
              value: "Блатешница",
              label: "Блатешница"
            },
            {
              value: "Враня стена",
              label: "Враня стена"
            },
            {
              value: "Габров дол",
              label: "Габров дол"
            },
            {
              value: "Горна Врабча",
              label: "Горна Врабча"
            },
            {
              value: "Горна Глоговица",
              label: "Горна Глоговица"
            },
            {
              value: "Дивля",
              label: "Дивля"
            },
            {
              value: "Долна Врабча",
              label: "Долна Врабча"
            },
            {
              value: "Еловдол",
              label: "Еловдол"
            },
            {
              value: "Жабляно",
              label: "Жабляно"
            },
            {
              value: "Земен",
              label: "Земен"
            },
            {
              value: "Калотинци",
              label: "Калотинци"
            },
            {
              value: "Мурено",
              label: "Мурено"
            },
            {
              value: "Одраница",
              label: "Одраница"
            },
            {
              value: "Падине",
              label: "Падине"
            },
            {
              value: "Пещера",
              label: "Пещера"
            },
            {
              value: "Раянци",
              label: "Раянци"
            },
            {
              value: "Смиров дол",
              label: "Смиров дол"
            }
          ]
        },
        {
          value: "Ковачевци",
          label: "Ковачевци",
          list: [
            {
              value: "Егълница",
              label: "Егълница"
            },
            {
              value: "Калище",
              label: "Калище"
            },
            {
              value: "Ковачевци",
              label: "Ковачевци"
            },
            {
              value: "Косача",
              label: "Косача"
            },
            {
              value: "Лобош",
              label: "Лобош"
            },
            {
              value: "Ракиловци",
              label: "Ракиловци"
            },
            {
              value: "Светля",
              label: "Светля"
            },
            {
              value: "Сирищник",
              label: "Сирищник"
            },
            {
              value: "Слатино",
              label: "Слатино"
            },
            {
              value: "Чепино",
              label: "Чепино"
            }
          ]
        },
        {
          value: "Перник",
          label: "Перник",
          list: [
            {
              value: "Батановци",
              label: "Батановци"
            },
            {
              value: "Богданов дол",
              label: "Богданов дол"
            },
            {
              value: "Боснек",
              label: "Боснек"
            },
            {
              value: "Вискяр",
              label: "Вискяр"
            },
            {
              value: "Витановци",
              label: "Витановци"
            },
            {
              value: "Големо Бучино",
              label: "Големо Бучино"
            },
            {
              value: "Дивотино",
              label: "Дивотино"
            },
            {
              value: "Драгичево",
              label: "Драгичево"
            },
            {
              value: "Зидарци",
              label: "Зидарци"
            },
            {
              value: "Кладница",
              label: "Кладница"
            },
            {
              value: "Кралевдол",
              label: "Кралевдол"
            },
            {
              value: "Лесковец",
              label: "Лесковец"
            },
            {
              value: "Люлин",
              label: "Люлин"
            },
            {
              value: "Мещица",
              label: "Мещица"
            },
            {
              value: "Перник",
              label: "Перник"
            },
            {
              value: "Планиница",
              label: "Планиница"
            },
            {
              value: "Радуй",
              label: "Радуй"
            },
            {
              value: "Расник",
              label: "Расник"
            },
            {
              value: "Рударци",
              label: "Рударци"
            },
            {
              value: "Селищен дол",
              label: "Селищен дол"
            },
            {
              value: "Студена",
              label: "Студена"
            },
            {
              value: "Черна гора",
              label: "Черна гора"
            },
            {
              value: "Чуйпетльово",
              label: "Чуйпетльово"
            },
            {
              value: "Ярджиловци",
              label: "Ярджиловци"
            }
          ]
        },
        {
          value: "Радомир",
          label: "Радомир",
          list: [
            {
              value: "Байкалско",
              label: "Байкалско"
            },
            {
              value: "Беланица",
              label: "Беланица"
            },
            {
              value: "Бобораци",
              label: "Бобораци"
            },
            {
              value: "Борнарево",
              label: "Борнарево"
            },
            {
              value: "Владимир",
              label: "Владимир"
            },
            {
              value: "Горна Диканя",
              label: "Горна Диканя"
            },
            {
              value: "Гълъбник",
              label: "Гълъбник"
            },
            {
              value: "Дебели лаг",
              label: "Дебели лаг"
            },
            {
              value: "Долна Диканя",
              label: "Долна Диканя"
            },
            {
              value: "Долни Раковец",
              label: "Долни Раковец"
            },
            {
              value: "Драгомирово",
              label: "Драгомирово"
            },
            {
              value: "Дрен",
              label: "Дрен"
            },
            {
              value: "Друган",
              label: "Друган"
            },
            {
              value: "Жедна",
              label: "Жедна"
            },
            {
              value: "Житуша",
              label: "Житуша"
            },
            {
              value: "Извор",
              label: "Извор"
            },
            {
              value: "Касилаг",
              label: "Касилаг"
            },
            {
              value: "Кленовик",
              label: "Кленовик"
            },
            {
              value: "Кондофрей",
              label: "Кондофрей"
            },
            {
              value: "Копаница",
              label: "Копаница"
            },
            {
              value: "Кошарите",
              label: "Кошарите"
            },
            {
              value: "Негованци",
              label: "Негованци"
            },
            {
              value: "Николаево",
              label: "Николаево"
            },
            {
              value: "Поцърненци",
              label: "Поцърненци"
            },
            {
              value: "Прибой",
              label: "Прибой"
            },
            {
              value: "Радибош",
              label: "Радибош"
            },
            {
              value: "Радомир",
              label: "Радомир"
            },
            {
              value: "Старо село",
              label: "Старо село"
            },
            {
              value: "Стефаново",
              label: "Стефаново"
            },
            {
              value: "Углярци",
              label: "Углярци"
            },
            {
              value: "Червена могила",
              label: "Червена могила"
            },
            {
              value: "Чуковец",
              label: "Чуковец"
            }
          ]
        },
        {
          value: "Трън",
          label: "Трън",
          list: [
            {
              value: "Банкя",
              label: "Банкя"
            },
            {
              value: "Берайнци",
              label: "Берайнци"
            },
            {
              value: "Богойна",
              label: "Богойна"
            },
            {
              value: "Бохова",
              label: "Бохова"
            },
            {
              value: "Бусинци",
              label: "Бусинци"
            },
            {
              value: "Бутроинци",
              label: "Бутроинци"
            },
            {
              value: "Велиново",
              label: "Велиново"
            },
            {
              value: "Видрар",
              label: "Видрар"
            },
            {
              value: "Врабча",
              label: "Врабча"
            },
            {
              value: "Вукан",
              label: "Вукан"
            },
            {
              value: "Главановци",
              label: "Главановци"
            },
            {
              value: "Глоговица",
              label: "Глоговица"
            },
            {
              value: "Горна Мелна",
              label: "Горна Мелна"
            },
            {
              value: "Горочевци",
              label: "Горочевци"
            },
            {
              value: "Джинчовци",
              label: "Джинчовци"
            },
            {
              value: "Докьовци",
              label: "Докьовци"
            },
            {
              value: "Долна Мелна",
              label: "Долна Мелна"
            },
            {
              value: "Дълга лука",
              label: "Дълга лука"
            },
            {
              value: "Ездимирци",
              label: "Ездимирци"
            },
            {
              value: "Еловица",
              label: "Еловица"
            },
            {
              value: "Ерул",
              label: "Ерул"
            },
            {
              value: "Забел",
              label: "Забел"
            },
            {
              value: "Зелениград",
              label: "Зелениград"
            },
            {
              value: "Кожинци",
              label: "Кожинци"
            },
            {
              value: "Костуринци",
              label: "Костуринци"
            },
            {
              value: "Къшле",
              label: "Къшле"
            },
            {
              value: "Лева река",
              label: "Лева река"
            },
            {
              value: "Лешниковци",
              label: "Лешниковци"
            },
            {
              value: "Ломница",
              label: "Ломница"
            },
            {
              value: "Лялинци",
              label: "Лялинци"
            },
            {
              value: "Милкьовци",
              label: "Милкьовци"
            },
            {
              value: "Милославци",
              label: "Милославци"
            },
            {
              value: "Мракетинци",
              label: "Мракетинци"
            },
            {
              value: "Мрамор",
              label: "Мрамор"
            },
            {
              value: "Насалевци",
              label: "Насалевци"
            },
            {
              value: "Неделково",
              label: "Неделково"
            },
            {
              value: "Парамун",
              label: "Парамун"
            },
            {
              value: "Пенкьовци",
              label: "Пенкьовци"
            },
            {
              value: "Проданча",
              label: "Проданча"
            },
            {
              value: "Радово",
              label: "Радово"
            },
            {
              value: "Рани луг",
              label: "Рани луг"
            },
            {
              value: "Реяновци",
              label: "Реяновци"
            },
            {
              value: "Слишовци",
              label: "Слишовци"
            },
            {
              value: "Стайчовци",
              label: "Стайчовци"
            },
            {
              value: "Стрезимировци",
              label: "Стрезимировци"
            },
            {
              value: "Студен извор",
              label: "Студен извор"
            },
            {
              value: "Трън",
              label: "Трън"
            },
            {
              value: "Туроковци",
              label: "Туроковци"
            },
            {
              value: "Филиповци",
              label: "Филиповци"
            },
            {
              value: "Цегриловци",
              label: "Цегриловци"
            },
            {
              value: "Шипковица",
              label: "Шипковица"
            },
            {
              value: "Ярловци",
              label: "Ярловци"
            }
          ]
        }
      ]
    },
    {
      value: "Плевен",
      label: "Плевен",
      list: [
        {
          value: "Белене",
          label: "Белене",
          list: [
            {
              value: "Белене",
              label: "Белене"
            },
            {
              value: "Бяла вода",
              label: "Бяла вода"
            },
            {
              value: "Деков",
              label: "Деков"
            },
            {
              value: "Кулина вода",
              label: "Кулина вода"
            },
            {
              value: "Петокладенци",
              label: "Петокладенци"
            },
            {
              value: "Татари",
              label: "Татари"
            }
          ]
        },
        {
          value: "Гулянци",
          label: "Гулянци",
          list: [
            {
              value: "Брест",
              label: "Брест"
            },
            {
              value: "Гиген",
              label: "Гиген"
            },
            {
              value: "Гулянци",
              label: "Гулянци"
            },
            {
              value: "Долни Вит",
              label: "Долни Вит"
            },
            {
              value: "Дъбован",
              label: "Дъбован"
            },
            {
              value: "Загражден",
              label: "Загражден"
            },
            {
              value: "Искър",
              label: "Искър"
            },
            {
              value: "Крета",
              label: "Крета"
            },
            {
              value: "Ленково",
              label: "Ленково"
            },
            {
              value: "Милковица",
              label: "Милковица"
            },
            {
              value: "Сомовит",
              label: "Сомовит"
            },
            {
              value: "Шияково",
              label: "Шияково"
            }
          ]
        },
        {
          value: "Долна Митрополия",
          label: "Долна Митрополия",
          list: [
            {
              value: "Байкал",
              label: "Байкал"
            },
            {
              value: "Биволаре",
              label: "Биволаре"
            },
            {
              value: "Божурица",
              label: "Божурица"
            },
            {
              value: "Брегаре",
              label: "Брегаре"
            },
            {
              value: "Горна Митрополия",
              label: "Горна Митрополия"
            },
            {
              value: "Гостиля",
              label: "Гостиля"
            },
            {
              value: "Долна Митрополия",
              label: "Долна Митрополия"
            },
            {
              value: "Комарево",
              label: "Комарево"
            },
            {
              value: "Крушовене",
              label: "Крушовене"
            },
            {
              value: "Ореховица",
              label: "Ореховица"
            },
            {
              value: "Победа",
              label: "Победа"
            },
            {
              value: "Подем",
              label: "Подем"
            },
            {
              value: "Рибен",
              label: "Рибен"
            },
            {
              value: "Славовица",
              label: "Славовица"
            },
            {
              value: "Ставерци",
              label: "Ставерци"
            },
            {
              value: "Тръстеник",
              label: "Тръстеник"
            }
          ]
        },
        {
          value: "Долни Дъбник",
          label: "Долни Дъбник",
          list: [
            {
              value: "Бъркач",
              label: "Бъркач"
            },
            {
              value: "Горни Дъбник",
              label: "Горни Дъбник"
            },
            {
              value: "Градина",
              label: "Градина"
            },
            {
              value: "Долни Дъбник",
              label: "Долни Дъбник"
            },
            {
              value: "Крушовица",
              label: "Крушовица"
            },
            {
              value: "Петърница",
              label: "Петърница"
            },
            {
              value: "Садовец",
              label: "Садовец"
            }
          ]
        },
        {
          value: "Искър",
          label: "Искър",
          list: [
            {
              value: "Долни Луковит",
              label: "Долни Луковит"
            },
            {
              value: "Искър",
              label: "Искър"
            },
            {
              value: "Писарово",
              label: "Писарово"
            },
            {
              value: "Староселци",
              label: "Староселци"
            }
          ]
        },
        {
          value: "Кнежа",
          label: "Кнежа",
          list: [
            {
              value: "Бреница",
              label: "Бреница"
            },
            {
              value: "Еница",
              label: "Еница"
            },
            {
              value: "Кнежа",
              label: "Кнежа"
            },
            {
              value: "Лазарово",
              label: "Лазарово"
            }
          ]
        },
        {
          value: "Левски",
          label: "Левски",
          list: [
            {
              value: "Асеновци",
              label: "Асеновци"
            },
            {
              value: "Аспарухово",
              label: "Аспарухово"
            },
            {
              value: "Божурлук",
              label: "Божурлук"
            },
            {
              value: "Българене",
              label: "Българене"
            },
            {
              value: "Варана",
              label: "Варана"
            },
            {
              value: "Градище",
              label: "Градище"
            },
            {
              value: "Изгрев",
              label: "Изгрев"
            },
            {
              value: "Козар Белене",
              label: "Козар Белене"
            },
            {
              value: "Левски",
              label: "Левски"
            },
            {
              value: "Малчика",
              label: "Малчика"
            },
            {
              value: "Обнова",
              label: "Обнова"
            },
            {
              value: "Стежерово",
              label: "Стежерово"
            },
            {
              value: "Трънчовица",
              label: "Трънчовица"
            }
          ]
        },
        {
          value: "Никопол",
          label: "Никопол",
          list: [
            {
              value: "Асеново",
              label: "Асеново"
            },
            {
              value: "Бацова махала",
              label: "Бацова махала"
            },
            {
              value: "Въбел",
              label: "Въбел"
            },
            {
              value: "Дебово",
              label: "Дебово"
            },
            {
              value: "Драгаш войвода",
              label: "Драгаш войвода"
            },
            {
              value: "Евлогиево",
              label: "Евлогиево"
            },
            {
              value: "Жернов",
              label: "Жернов"
            },
            {
              value: "Лозица",
              label: "Лозица"
            },
            {
              value: "Любеново",
              label: "Любеново"
            },
            {
              value: "Муселиево",
              label: "Муселиево"
            },
            {
              value: "Никопол",
              label: "Никопол"
            },
            {
              value: "Новачене",
              label: "Новачене"
            },
            {
              value: "Санадиново",
              label: "Санадиново"
            },
            {
              value: "Черковица",
              label: "Черковица"
            }
          ]
        },
        {
          value: "Плевен",
          label: "Плевен",
          list: [
            {
              value: "Беглеж",
              label: "Беглеж"
            },
            {
              value: "Бохот",
              label: "Бохот"
            },
            {
              value: "Брестовец",
              label: "Брестовец"
            },
            {
              value: "Бръшляница",
              label: "Бръшляница"
            },
            {
              value: "Буковлък",
              label: "Буковлък"
            },
            {
              value: "Върбица",
              label: "Върбица"
            },
            {
              value: "Горталово",
              label: "Горталово"
            },
            {
              value: "Гривица",
              label: "Гривица"
            },
            {
              value: "Дисевица",
              label: "Дисевица"
            },
            {
              value: "Коиловци",
              label: "Коиловци"
            },
            {
              value: "Къртожабене",
              label: "Къртожабене"
            },
            {
              value: "Къшин",
              label: "Къшин"
            },
            {
              value: "Ласкар",
              label: "Ласкар"
            },
            {
              value: "Мечка",
              label: "Мечка"
            },
            {
              value: "Николаево",
              label: "Николаево"
            },
            {
              value: "Опанец",
              label: "Опанец"
            },
            {
              value: "Пелишат",
              label: "Пелишат"
            },
            {
              value: "Плевен",
              label: "Плевен"
            },
            {
              value: "Радишево",
              label: "Радишево"
            },
            {
              value: "Ралево",
              label: "Ралево"
            },
            {
              value: "Славяново",
              label: "Славяново"
            },
            {
              value: "Тодорово",
              label: "Тодорово"
            },
            {
              value: "Тученица",
              label: "Тученица"
            },
            {
              value: "Търнене",
              label: "Търнене"
            },
            {
              value: "Ясен",
              label: "Ясен"
            }
          ]
        },
        {
          value: "Пордим",
          label: "Пордим",
          list: [
            {
              value: "Борислав",
              label: "Борислав"
            },
            {
              value: "Вълчитрън",
              label: "Вълчитрън"
            },
            {
              value: "Згалево",
              label: "Згалево"
            },
            {
              value: "Каменец",
              label: "Каменец"
            },
            {
              value: "Катерица",
              label: "Катерица"
            },
            {
              value: "Одърне",
              label: "Одърне"
            },
            {
              value: "Пордим",
              label: "Пордим"
            },
            {
              value: "Тотлебен",
              label: "Тотлебен"
            }
          ]
        },
        {
          value: "Червен бряг",
          label: "Червен бряг",
          list: [
            {
              value: "Бресте",
              label: "Бресте"
            },
            {
              value: "Глава",
              label: "Глава"
            },
            {
              value: "Горник",
              label: "Горник"
            },
            {
              value: "Девенци",
              label: "Девенци"
            },
            {
              value: "Койнаре",
              label: "Койнаре"
            },
            {
              value: "Лепица",
              label: "Лепица"
            },
            {
              value: "Радомирци",
              label: "Радомирци"
            },
            {
              value: "Ракита",
              label: "Ракита"
            },
            {
              value: "Реселец",
              label: "Реселец"
            },
            {
              value: "Рупци",
              label: "Рупци"
            },
            {
              value: "Сухаче",
              label: "Сухаче"
            },
            {
              value: "Телиш",
              label: "Телиш"
            },
            {
              value: "Червен бряг",
              label: "Червен бряг"
            },
            {
              value: "Чомаковци",
              label: "Чомаковци"
            }
          ]
        }
      ]
    },
    {
      value: "Пловдив",
      label: "Пловдив",
      list: [
        {
          value: "Асеновград",
          label: "Асеновград",
          list: [
            {
              value: "Асеновград",
              label: "Асеновград"
            },
            {
              value: "Бачково",
              label: "Бачково"
            },
            {
              value: "Бор",
              label: "Бор"
            },
            {
              value: "Боянци",
              label: "Боянци"
            },
            {
              value: "Врата",
              label: "Врата"
            },
            {
              value: "Горнослав",
              label: "Горнослав"
            },
            {
              value: "Добростан",
              label: "Добростан"
            },
            {
              value: "Долнослав",
              label: "Долнослав"
            },
            {
              value: "Жълт камък",
              label: "Жълт камък"
            },
            {
              value: "Златовръх",
              label: "Златовръх"
            },
            {
              value: "Избеглии",
              label: "Избеглии"
            },
            {
              value: "Козаново",
              label: "Козаново"
            },
            {
              value: "Конуш",
              label: "Конуш"
            },
            {
              value: "Косово",
              label: "Косово"
            },
            {
              value: "Леново",
              label: "Леново"
            },
            {
              value: "Лясково",
              label: "Лясково"
            },
            {
              value: "Мостово",
              label: "Мостово"
            },
            {
              value: "Мулдава",
              label: "Мулдава"
            },
            {
              value: "Нареченски бани",
              label: "Нареченски бани"
            },
            {
              value: "Новаково",
              label: "Новаково"
            },
            {
              value: "Нови извор",
              label: "Нови извор"
            },
            {
              value: "Орешец",
              label: "Орешец"
            },
            {
              value: "Патриарх Евтимово",
              label: "Патриарх Евтимово"
            },
            {
              value: "Сини връх",
              label: "Сини връх"
            },
            {
              value: "Стоево",
              label: "Стоево"
            },
            {
              value: "Тополово",
              label: "Тополово"
            },
            {
              value: "Три могили",
              label: "Три могили"
            },
            {
              value: "Узуново",
              label: "Узуново"
            },
            {
              value: "Червен",
              label: "Червен"
            }
          ]
        },
        {
          value: "Брезово",
          label: "Брезово",
          list: [
            {
              value: "Бабек",
              label: "Бабек"
            },
            {
              value: "Борец",
              label: "Борец"
            },
            {
              value: "Брезово",
              label: "Брезово"
            },
            {
              value: "Върбен",
              label: "Върбен"
            },
            {
              value: "Дрангово",
              label: "Дрангово"
            },
            {
              value: "Зелениково",
              label: "Зелениково"
            },
            {
              value: "Златосел",
              label: "Златосел"
            },
            {
              value: "Отец Кирилово",
              label: "Отец Кирилово"
            },
            {
              value: "Пъдарско",
              label: "Пъдарско"
            },
            {
              value: "Розовец",
              label: "Розовец"
            },
            {
              value: "Свежен",
              label: "Свежен"
            },
            {
              value: "Стрелци",
              label: "Стрелци"
            },
            {
              value: "Сърнегор",
              label: "Сърнегор"
            },
            {
              value: "Тюркмен",
              label: "Тюркмен"
            },
            {
              value: "Чехларе",
              label: "Чехларе"
            },
            {
              value: "Чоба",
              label: "Чоба"
            }
          ]
        },
        {
          value: "Калояново",
          label: "Калояново",
          list: [
            {
              value: "Бегово",
              label: "Бегово"
            },
            {
              value: "Главатар",
              label: "Главатар"
            },
            {
              value: "Горна махала",
              label: "Горна махала"
            },
            {
              value: "Долна махала",
              label: "Долна махала"
            },
            {
              value: "Дуванлии",
              label: "Дуванлии"
            },
            {
              value: "Дълго поле",
              label: "Дълго поле"
            },
            {
              value: "Житница",
              label: "Житница"
            },
            {
              value: "Иван Вазово",
              label: "Иван Вазово"
            },
            {
              value: "Калояново",
              label: "Калояново"
            },
            {
              value: "Отец Паисиево",
              label: "Отец Паисиево"
            },
            {
              value: "Песнопой",
              label: "Песнопой"
            },
            {
              value: "Ръжево",
              label: "Ръжево"
            },
            {
              value: "Ръжево Конаре",
              label: "Ръжево Конаре"
            },
            {
              value: "Сухозем",
              label: "Сухозем"
            },
            {
              value: "Черноземен",
              label: "Черноземен"
            }
          ]
        },
        {
          value: "Карлово",
          label: "Карлово",
          list: [
            {
              value: "Баня",
              label: "Баня"
            },
            {
              value: "Бегунци",
              label: "Бегунци"
            },
            {
              value: "Богдан",
              label: "Богдан"
            },
            {
              value: "Васил Левски",
              label: "Васил Левски"
            },
            {
              value: "Ведраре",
              label: "Ведраре"
            },
            {
              value: "Войнягово",
              label: "Войнягово"
            },
            {
              value: "Горни Домлян",
              label: "Горни Домлян"
            },
            {
              value: "Домлян",
              label: "Домлян"
            },
            {
              value: "Дъбене",
              label: "Дъбене"
            },
            {
              value: "Иганово",
              label: "Иганово"
            },
            {
              value: "Калофер",
              label: "Калофер"
            },
            {
              value: "Каравелово",
              label: "Каравелово"
            },
            {
              value: "Карлово",
              label: "Карлово"
            },
            {
              value: "Климент",
              label: "Климент"
            },
            {
              value: "Клисура",
              label: "Клисура"
            },
            {
              value: "Куртово",
              label: "Куртово"
            },
            {
              value: "Кърнаре",
              label: "Кърнаре"
            },
            {
              value: "Марино поле",
              label: "Марино поле"
            },
            {
              value: "Московец",
              label: "Московец"
            },
            {
              value: "Мраченик",
              label: "Мраченик"
            },
            {
              value: "Певците",
              label: "Певците"
            },
            {
              value: "Пролом",
              label: "Пролом"
            },
            {
              value: "Розино",
              label: "Розино"
            },
            {
              value: "Слатина",
              label: "Слатина"
            },
            {
              value: "Соколица",
              label: "Соколица"
            },
            {
              value: "Столетово",
              label: "Столетово"
            },
            {
              value: "Христо Даново",
              label: "Христо Даново"
            }
          ]
        },
        {
          value: "Кричим",
          label: "Кричим",
          list: [
            {
              value: "Кричим",
              label: "Кричим"
            }
          ]
        },
        {
          value: "Куклен",
          label: "Куклен",
          list: [
            {
              value: "Гълъбово",
              label: "Гълъбово"
            },
            {
              value: "Добралък",
              label: "Добралък"
            },
            {
              value: "Куклен",
              label: "Куклен"
            },
            {
              value: "Руен",
              label: "Руен"
            },
            {
              value: "Цар Калоян",
              label: "Цар Калоян"
            },
            {
              value: "Яврово",
              label: "Яврово"
            }
          ]
        },
        {
          value: "Лъки",
          label: "Лъки",
          list: [
            {
              value: "Балкан махала",
              label: "Балкан махала"
            },
            {
              value: "Белица",
              label: "Белица"
            },
            {
              value: "Борово",
              label: "Борово"
            },
            {
              value: "Джурково",
              label: "Джурково"
            },
            {
              value: "Дряново",
              label: "Дряново"
            },
            {
              value: "Здравец",
              label: "Здравец"
            },
            {
              value: "Крушово",
              label: "Крушово"
            },
            {
              value: "Лъкавица",
              label: "Лъкавица"
            },
            {
              value: "Лъки",
              label: "Лъки"
            },
            {
              value: "Манастир",
              label: "Манастир"
            },
            {
              value: "Югово",
              label: "Югово"
            }
          ]
        },
        {
          value: "Марица",
          label: "Марица",
          list: [
            {
              value: "Бенковски",
              label: "Бенковски"
            },
            {
              value: "Войводиново",
              label: "Войводиново"
            },
            {
              value: "Воисил",
              label: "Воисил"
            },
            {
              value: "Граф Игнатиево",
              label: "Граф Игнатиево"
            },
            {
              value: "Динк",
              label: "Динк"
            },
            {
              value: "Желязно",
              label: "Желязно"
            },
            {
              value: "Калековец",
              label: "Калековец"
            },
            {
              value: "Костиево",
              label: "Костиево"
            },
            {
              value: "Крислово",
              label: "Крислово"
            },
            {
              value: "Маноле",
              label: "Маноле"
            },
            {
              value: "Манолско Конаре",
              label: "Манолско Конаре"
            },
            {
              value: "Радиново",
              label: "Радиново"
            },
            {
              value: "Рогош",
              label: "Рогош"
            },
            {
              value: "Скутаре",
              label: "Скутаре"
            },
            {
              value: "Строево",
              label: "Строево"
            },
            {
              value: "Трилистник",
              label: "Трилистник"
            },
            {
              value: "Труд",
              label: "Труд"
            },
            {
              value: "Царацово",
              label: "Царацово"
            },
            {
              value: "Ясно поле",
              label: "Ясно поле"
            }
          ]
        },
        {
          value: "Перущица",
          label: "Перущица",
          list: [
            {
              value: "Перущица",
              label: "Перущица"
            }
          ]
        },
        {
          value: "Пловдив",
          label: "Пловдив",
          list: [
            {
              value: "Пловдив",
              label: "Пловдив"
            }
          ]
        },
        {
          value: "Първомай",
          label: "Първомай",
          list: [
            {
              value: "Брягово",
              label: "Брягово"
            },
            {
              value: "Буково",
              label: "Буково"
            },
            {
              value: "Бяла река",
              label: "Бяла река"
            },
            {
              value: "Виница",
              label: "Виница"
            },
            {
              value: "Воден",
              label: "Воден"
            },
            {
              value: "Градина",
              label: "Градина"
            },
            {
              value: "Добри дол",
              label: "Добри дол"
            },
            {
              value: "Драгойново",
              label: "Драгойново"
            },
            {
              value: "Дълбок извор",
              label: "Дълбок извор"
            },
            {
              value: "Езерово",
              label: "Езерово"
            },
            {
              value: "Искра",
              label: "Искра"
            },
            {
              value: "Караджалово",
              label: "Караджалово"
            },
            {
              value: "Крушево",
              label: "Крушево"
            },
            {
              value: "Поройна",
              label: "Поройна"
            },
            {
              value: "Православен",
              label: "Православен"
            },
            {
              value: "Първомай",
              label: "Първомай"
            },
            {
              value: "Татарево",
              label: "Татарево"
            }
          ]
        },
        {
          value: "Раковски",
          label: "Раковски",
          list: [
            {
              value: "Белозем",
              label: "Белозем"
            },
            {
              value: "Болярино",
              label: "Болярино"
            },
            {
              value: "Момино село",
              label: "Момино село"
            },
            {
              value: "Раковски",
              label: "Раковски"
            },
            {
              value: "Стряма",
              label: "Стряма"
            },
            {
              value: "Чалъкови",
              label: "Чалъкови"
            },
            {
              value: "Шишманци",
              label: "Шишманци"
            }
          ]
        },
        {
          value: "Родопи",
          label: "Родопи",
          list: [
            {
              value: "Белащица",
              label: "Белащица"
            },
            {
              value: "Бойково",
              label: "Бойково"
            },
            {
              value: "Браниполе",
              label: "Браниполе"
            },
            {
              value: "Брестник",
              label: "Брестник"
            },
            {
              value: "Брестовица",
              label: "Брестовица"
            },
            {
              value: "Дедово",
              label: "Дедово"
            },
            {
              value: "Златитрап",
              label: "Златитрап"
            },
            {
              value: "Извор",
              label: "Извор"
            },
            {
              value: "Кадиево",
              label: "Кадиево"
            },
            {
              value: "Крумово",
              label: "Крумово"
            },
            {
              value: "Лилково",
              label: "Лилково"
            },
            {
              value: "Марково",
              label: "Марково"
            },
            {
              value: "Оризари",
              label: "Оризари"
            },
            {
              value: "Първенец",
              label: "Първенец"
            },
            {
              value: "Ситово",
              label: "Ситово"
            },
            {
              value: "Скобелево",
              label: "Скобелево"
            },
            {
              value: "Устина",
              label: "Устина"
            },
            {
              value: "Храбрино",
              label: "Храбрино"
            },
            {
              value: "Цалапица",
              label: "Цалапица"
            },
            {
              value: "Чурен",
              label: "Чурен"
            },
            {
              value: "Ягодово",
              label: "Ягодово"
            }
          ]
        },
        {
          value: "Садово",
          label: "Садово",
          list: [
            {
              value: "Ахматово",
              label: "Ахматово"
            },
            {
              value: "Богданица",
              label: "Богданица"
            },
            {
              value: "Болярци",
              label: "Болярци"
            },
            {
              value: "Караджово",
              label: "Караджово"
            },
            {
              value: "Катуница",
              label: "Катуница"
            },
            {
              value: "Кочево",
              label: "Кочево"
            },
            {
              value: "Милево",
              label: "Милево"
            },
            {
              value: "Моминско",
              label: "Моминско"
            },
            {
              value: "Поповица",
              label: "Поповица"
            },
            {
              value: "Садово",
              label: "Садово"
            },
            {
              value: "Селци",
              label: "Селци"
            },
            {
              value: "Чешнегирово",
              label: "Чешнегирово"
            }
          ]
        },
        {
          value: "Сопот",
          label: "Сопот",
          list: [
            {
              value: "Анево",
              label: "Анево"
            },
            {
              value: "Сопот",
              label: "Сопот"
            }
          ]
        },
        {
          value: "Стаболийски",
          label: "Стаболийски",
          list: [
            {
              value: "Триводици",
              label: "Триводици"
            }
          ]
        },
        {
          value: "Стамболийски",
          label: "Стамболийски",
          list: [
            {
              value: "Йоаким Груево",
              label: "Йоаким Груево"
            },
            {
              value: "Куртово Конаре",
              label: "Куртово Конаре"
            },
            {
              value: "Ново село",
              label: "Ново село"
            },
            {
              value: "Стамболийски",
              label: "Стамболийски"
            }
          ]
        },
        {
          value: "Съединение",
          label: "Съединение",
          list: [
            {
              value: "Голям чардак",
              label: "Голям чардак"
            },
            {
              value: "Драгомир",
              label: "Драгомир"
            },
            {
              value: "Любен",
              label: "Любен"
            },
            {
              value: "Малък чардак",
              label: "Малък чардак"
            },
            {
              value: "Найден Герово",
              label: "Найден Герово"
            },
            {
              value: "Неделево",
              label: "Неделево"
            },
            {
              value: "Правище",
              label: "Правище"
            },
            {
              value: "Съединение",
              label: "Съединение"
            },
            {
              value: "Царимир",
              label: "Царимир"
            },
            {
              value: "Церетелево",
              label: "Церетелево"
            }
          ]
        },
        {
          value: "Хисаря",
          label: "Хисаря",
          list: [
            {
              value: "Беловица",
              label: "Беловица"
            },
            {
              value: "Красново",
              label: "Красново"
            },
            {
              value: "Кръстевич",
              label: "Кръстевич"
            },
            {
              value: "Мало Крушево",
              label: "Мало Крушево"
            },
            {
              value: "Михилци",
              label: "Михилци"
            },
            {
              value: "Мътеница",
              label: "Мътеница"
            },
            {
              value: "Ново Железаре",
              label: "Ново Железаре"
            },
            {
              value: "Паничери",
              label: "Паничери"
            },
            {
              value: "Старо Железаре",
              label: "Старо Железаре"
            },
            {
              value: "Старосел",
              label: "Старосел"
            },
            {
              value: "Хисаря",
              label: "Хисаря"
            },
            {
              value: "Черничево",
              label: "Черничево"
            }
          ]
        }
      ]
    },
    {
      value: "Разград",
      label: "Разград",
      list: [
        {
          value: "Завет",
          label: "Завет",
          list: [
            {
              value: "Брестовене",
              label: "Брестовене"
            },
            {
              value: "Веселец",
              label: "Веселец"
            },
            {
              value: "Завет",
              label: "Завет"
            },
            {
              value: "Иван Шишманово",
              label: "Иван Шишманово"
            },
            {
              value: "Острово",
              label: "Острово"
            },
            {
              value: "Прелез",
              label: "Прелез"
            },
            {
              value: "Сушево",
              label: "Сушево"
            }
          ]
        },
        {
          value: "Исперих",
          label: "Исперих",
          list: [
            {
              value: "Белинци",
              label: "Белинци"
            },
            {
              value: "Бърдоква",
              label: "Бърдоква"
            },
            {
              value: "Вазово",
              label: "Вазово"
            },
            {
              value: "Голям Поровец",
              label: "Голям Поровец"
            },
            {
              value: "Делчево",
              label: "Делчево"
            },
            {
              value: "Драгомъж",
              label: "Драгомъж"
            },
            {
              value: "Духовец",
              label: "Духовец"
            },
            {
              value: "Йонково",
              label: "Йонково"
            },
            {
              value: "Исперих",
              label: "Исперих"
            },
            {
              value: "Китанчево",
              label: "Китанчево"
            },
            {
              value: "Конево",
              label: "Конево"
            },
            {
              value: "Къпиновци",
              label: "Къпиновци"
            },
            {
              value: "Лудогорци",
              label: "Лудогорци"
            },
            {
              value: "Лъвино",
              label: "Лъвино"
            },
            {
              value: "Малко Йонково",
              label: "Малко Йонково"
            },
            {
              value: "Малък Поровец",
              label: "Малък Поровец"
            },
            {
              value: "Печеница",
              label: "Печеница"
            },
            {
              value: "Подайва",
              label: "Подайва"
            },
            {
              value: "Райнино",
              label: "Райнино"
            },
            {
              value: "Свещари",
              label: "Свещари"
            },
            {
              value: "Средоселци",
              label: "Средоселци"
            },
            {
              value: "Старо селище",
              label: "Старо селище"
            },
            {
              value: "Тодорово",
              label: "Тодорово"
            },
            {
              value: "Яким Груево",
              label: "Яким Груево"
            }
          ]
        },
        {
          value: "Кубрат",
          label: "Кубрат",
          list: [
            {
              value: "Беловец",
              label: "Беловец"
            },
            {
              value: "Бисерци",
              label: "Бисерци"
            },
            {
              value: "Божурово",
              label: "Божурово"
            },
            {
              value: "Горичево",
              label: "Горичево"
            },
            {
              value: "Задруга",
              label: "Задруга"
            },
            {
              value: "Звънарци",
              label: "Звънарци"
            },
            {
              value: "Каменово",
              label: "Каменово"
            },
            {
              value: "Кубрат",
              label: "Кубрат"
            },
            {
              value: "Медовене",
              label: "Медовене"
            },
            {
              value: "Мъдрево",
              label: "Мъдрево"
            },
            {
              value: "Равно",
              label: "Равно"
            },
            {
              value: "Савин",
              label: "Савин"
            },
            {
              value: "Севар",
              label: "Севар"
            },
            {
              value: "Сеслав",
              label: "Сеслав"
            },
            {
              value: "Тертер",
              label: "Тертер"
            },
            {
              value: "Точилари",
              label: "Точилари"
            },
            {
              value: "Юпер",
              label: "Юпер"
            }
          ]
        },
        {
          value: "Лозница",
          label: "Лозница",
          list: [
            {
              value: "Бели Лом",
              label: "Бели Лом"
            },
            {
              value: "Веселина",
              label: "Веселина"
            },
            {
              value: "Гороцвет",
              label: "Гороцвет"
            },
            {
              value: "Градина",
              label: "Градина"
            },
            {
              value: "Каменар",
              label: "Каменар"
            },
            {
              value: "Крояч",
              label: "Крояч"
            },
            {
              value: "Ловско",
              label: "Ловско"
            },
            {
              value: "Лозница",
              label: "Лозница"
            },
            {
              value: "Манастирско",
              label: "Манастирско"
            },
            {
              value: "Манастирци",
              label: "Манастирци"
            },
            {
              value: "Сейдол",
              label: "Сейдол"
            },
            {
              value: "Синя вода",
              label: "Синя вода"
            },
            {
              value: "Студенец",
              label: "Студенец"
            },
            {
              value: "Трапище",
              label: "Трапище"
            },
            {
              value: "Тръбач",
              label: "Тръбач"
            },
            {
              value: "Чудомир",
              label: "Чудомир"
            }
          ]
        },
        {
          value: "Разград",
          label: "Разград",
          list: [
            {
              value: "Балкански",
              label: "Балкански"
            },
            {
              value: "Благоево",
              label: "Благоево"
            },
            {
              value: "Гецово",
              label: "Гецово"
            },
            {
              value: "Дряновец",
              label: "Дряновец"
            },
            {
              value: "Дянково",
              label: "Дянково"
            },
            {
              value: "Киченица",
              label: "Киченица"
            },
            {
              value: "Липник",
              label: "Липник"
            },
            {
              value: "Мортагоново",
              label: "Мортагоново"
            },
            {
              value: "Недоклан",
              label: "Недоклан"
            },
            {
              value: "Осенец",
              label: "Осенец"
            },
            {
              value: "Островче",
              label: "Островче"
            },
            {
              value: "Побит камък",
              label: "Побит камък"
            },
            {
              value: "Пороище",
              label: "Пороище"
            },
            {
              value: "Просторно",
              label: "Просторно"
            },
            {
              value: "Радинград",
              label: "Радинград"
            },
            {
              value: "Разград",
              label: "Разград"
            },
            {
              value: "Раковски",
              label: "Раковски"
            },
            {
              value: "Стражец",
              label: "Стражец"
            },
            {
              value: "Топчии",
              label: "Топчии"
            },
            {
              value: "Ушинци",
              label: "Ушинци"
            },
            {
              value: "Черковна",
              label: "Черковна"
            },
            {
              value: "Ясеновец",
              label: "Ясеновец"
            }
          ]
        },
        {
          value: "Самуил",
          label: "Самуил",
          list: [
            {
              value: "Богданци",
              label: "Богданци"
            },
            {
              value: "Богомилци",
              label: "Богомилци"
            },
            {
              value: "Владимировци",
              label: "Владимировци"
            },
            {
              value: "Голям извор",
              label: "Голям извор"
            },
            {
              value: "Голяма вода",
              label: "Голяма вода"
            },
            {
              value: "Желязковец",
              label: "Желязковец"
            },
            {
              value: "Здравец",
              label: "Здравец"
            },
            {
              value: "Кара Михал",
              label: "Кара Михал"
            },
            {
              value: "Кривица",
              label: "Кривица"
            },
            {
              value: "Ножарово",
              label: "Ножарово"
            },
            {
              value: "Пчелина",
              label: "Пчелина"
            },
            {
              value: "Самуил",
              label: "Самуил"
            },
            {
              value: "Хума",
              label: "Хума"
            },
            {
              value: "Хърсово",
              label: "Хърсово"
            }
          ]
        },
        {
          value: "Цар Калоян",
          label: "Цар Калоян",
          list: [
            {
              value: "Езерче",
              label: "Езерче"
            },
            {
              value: "Костанденец",
              label: "Костанденец"
            },
            {
              value: "Цар Калоян",
              label: "Цар Калоян"
            }
          ]
        }
      ]
    },
    {
      value: "Русе",
      label: "Русе",
      list: [
        {
          value: "Борово",
          label: "Борово",
          list: [
            {
              value: "Батин",
              label: "Батин"
            },
            {
              value: "Борово",
              label: "Борово"
            },
            {
              value: "Брестовица",
              label: "Брестовица"
            },
            {
              value: "Волово",
              label: "Волово"
            },
            {
              value: "Горно Абланово",
              label: "Горно Абланово"
            },
            {
              value: "Екзарх Йосиф",
              label: "Екзарх Йосиф"
            },
            {
              value: "Обретеник",
              label: "Обретеник"
            }
          ]
        },
        {
          value: "Бяла",
          label: "Бяла",
          list: [
            {
              value: "Бистренци",
              label: "Бистренци"
            },
            {
              value: "Босилковци",
              label: "Босилковци"
            },
            {
              value: "Ботров",
              label: "Ботров"
            },
            {
              value: "Бяла",
              label: "Бяла"
            },
            {
              value: "Гара Бяла",
              label: "Гара Бяла"
            },
            {
              value: "Дряновец",
              label: "Дряновец"
            },
            {
              value: "Копривец",
              label: "Копривец"
            },
            {
              value: "Лом Черковна",
              label: "Лом Черковна"
            },
            {
              value: "Пейчиново",
              label: "Пейчиново"
            },
            {
              value: "Пет кладенци",
              label: "Пет кладенци"
            },
            {
              value: "Полско Косово",
              label: "Полско Косово"
            },
            {
              value: "Стърмен",
              label: "Стърмен"
            }
          ]
        },
        {
          value: "Ветово",
          label: "Ветово",
          list: [
            {
              value: "Бъзън",
              label: "Бъзън"
            },
            {
              value: "Ветово",
              label: "Ветово"
            },
            {
              value: "Глоджево",
              label: "Глоджево"
            },
            {
              value: "Кривня",
              label: "Кривня"
            },
            {
              value: "Писанец",
              label: "Писанец"
            },
            {
              value: "Сеново",
              label: "Сеново"
            },
            {
              value: "Смирненски",
              label: "Смирненски"
            }
          ]
        },
        {
          value: "Две могили",
          label: "Две могили",
          list: [
            {
              value: "Баниска",
              label: "Баниска"
            },
            {
              value: "Батишница",
              label: "Батишница"
            },
            {
              value: "Бъзовец",
              label: "Бъзовец"
            },
            {
              value: "Две могили",
              label: "Две могили"
            },
            {
              value: "Каран Върбовка",
              label: "Каран Върбовка"
            },
            {
              value: "Кацелово",
              label: "Кацелово"
            },
            {
              value: "Могилино",
              label: "Могилино"
            },
            {
              value: "Острица",
              label: "Острица"
            },
            {
              value: "Пепелина",
              label: "Пепелина"
            },
            {
              value: "Помен",
              label: "Помен"
            },
            {
              value: "Чилнов",
              label: "Чилнов"
            },
            {
              value: "Широково",
              label: "Широково"
            }
          ]
        },
        {
          value: "Иваново",
          label: "Иваново",
          list: [
            {
              value: "Божичен",
              label: "Божичен"
            },
            {
              value: "Иваново",
              label: "Иваново"
            },
            {
              value: "Кошов",
              label: "Кошов"
            },
            {
              value: "Красен",
              label: "Красен"
            },
            {
              value: "Мечка",
              label: "Мечка"
            },
            {
              value: "Нисово",
              label: "Нисово"
            },
            {
              value: "Пиргово",
              label: "Пиргово"
            },
            {
              value: "Сваленик",
              label: "Сваленик"
            },
            {
              value: "Табачка",
              label: "Табачка"
            },
            {
              value: "Тръстеник",
              label: "Тръстеник"
            },
            {
              value: "Церовец",
              label: "Церовец"
            },
            {
              value: "Червен",
              label: "Червен"
            },
            {
              value: "Щръклево",
              label: "Щръклево"
            }
          ]
        },
        {
          value: "Русе",
          label: "Русе",
          list: [
            {
              value: "Басарбово",
              label: "Басарбово"
            },
            {
              value: "Долно Абланово",
              label: "Долно Абланово"
            },
            {
              value: "Мартен",
              label: "Мартен"
            },
            {
              value: "Николово",
              label: "Николово"
            },
            {
              value: "Ново село",
              label: "Ново село"
            },
            {
              value: "Просена",
              label: "Просена"
            },
            {
              value: "Русе",
              label: "Русе"
            },
            {
              value: "Сандрово",
              label: "Сандрово"
            },
            {
              value: "Семерджиево",
              label: "Семерджиево"
            },
            {
              value: "Тетово",
              label: "Тетово"
            },
            {
              value: "Хотанца",
              label: "Хотанца"
            },
            {
              value: "Червена вода",
              label: "Червена вода"
            },
            {
              value: "Ястребово",
              label: "Ястребово"
            }
          ]
        },
        {
          value: "Сливо поле",
          label: "Сливо поле",
          list: [
            {
              value: "Бабово",
              label: "Бабово"
            },
            {
              value: "Борисово",
              label: "Борисово"
            },
            {
              value: "Бръшлен",
              label: "Бръшлен"
            },
            {
              value: "Голямо Враново",
              label: "Голямо Враново"
            },
            {
              value: "Кошарна",
              label: "Кошарна"
            },
            {
              value: "Малко Враново",
              label: "Малко Враново"
            },
            {
              value: "Ряхово",
              label: "Ряхово"
            },
            {
              value: "Сливо поле",
              label: "Сливо поле"
            },
            {
              value: "Стамболово",
              label: "Стамболово"
            },
            {
              value: "Черешово",
              label: "Черешово"
            },
            {
              value: "Юделник",
              label: "Юделник"
            }
          ]
        },
        {
          value: "Ценово",
          label: "Ценово",
          list: [
            {
              value: "Белцов",
              label: "Белцов"
            },
            {
              value: "Беляново",
              label: "Беляново"
            },
            {
              value: "Джулюница",
              label: "Джулюница"
            },
            {
              value: "Долна Студена",
              label: "Долна Студена"
            },
            {
              value: "Караманово",
              label: "Караманово"
            },
            {
              value: "Кривина",
              label: "Кривина"
            },
            {
              value: "Новград",
              label: "Новград"
            },
            {
              value: "Пиперково",
              label: "Пиперково"
            },
            {
              value: "Ценово",
              label: "Ценово"
            }
          ]
        }
      ]
    },
    {
      value: "Силистра",
      label: "Силистра",
      list: [
        {
          value: "Алфатар",
          label: "Алфатар",
          list: [
            {
              value: "Алеково",
              label: "Алеково"
            },
            {
              value: "Алфатар",
              label: "Алфатар"
            },
            {
              value: "Бистра",
              label: "Бистра"
            },
            {
              value: "Васил Левски",
              label: "Васил Левски"
            },
            {
              value: "Кутловица",
              label: "Кутловица"
            },
            {
              value: "Цар Асен",
              label: "Цар Асен"
            },
            {
              value: "Чуковец",
              label: "Чуковец"
            }
          ]
        },
        {
          value: "Главиница",
          label: "Главиница",
          list: [
            {
              value: "Бащино",
              label: "Бащино"
            },
            {
              value: "Богданци",
              label: "Богданци"
            },
            {
              value: "Вълкан",
              label: "Вълкан"
            },
            {
              value: "Главиница",
              label: "Главиница"
            },
            {
              value: "Дичево",
              label: "Дичево"
            },
            {
              value: "Долно Ряхово",
              label: "Долно Ряхово"
            },
            {
              value: "Зарица",
              label: "Зарица"
            },
            {
              value: "Зафирово",
              label: "Зафирово"
            },
            {
              value: "Звенимир",
              label: "Звенимир"
            },
            {
              value: "Зебил",
              label: "Зебил"
            },
            {
              value: "Калугерене",
              label: "Калугерене"
            },
            {
              value: "Коларово",
              label: "Коларово"
            },
            {
              value: "Косара",
              label: "Косара"
            },
            {
              value: "Листец",
              label: "Листец"
            },
            {
              value: "Малък Преславец",
              label: "Малък Преславец"
            },
            {
              value: "Ножарево",
              label: "Ножарево"
            },
            {
              value: "Осен",
              label: "Осен"
            },
            {
              value: "Падина",
              label: "Падина"
            },
            {
              value: "Подлес",
              label: "Подлес"
            },
            {
              value: "Сокол",
              label: "Сокол"
            },
            {
              value: "Стефан Караджа",
              label: "Стефан Караджа"
            },
            {
              value: "Суходол",
              label: "Суходол"
            },
            {
              value: "Черногор",
              label: "Черногор"
            }
          ]
        },
        {
          value: "Дулово",
          label: "Дулово",
          list: [
            {
              value: "Боил",
              label: "Боил"
            },
            {
              value: "Водно",
              label: "Водно"
            },
            {
              value: "Вокил",
              label: "Вокил"
            },
            {
              value: "Върбино",
              label: "Върбино"
            },
            {
              value: "Грънчарово",
              label: "Грънчарово"
            },
            {
              value: "Долец",
              label: "Долец"
            },
            {
              value: "Дулово",
              label: "Дулово"
            },
            {
              value: "Златоклас",
              label: "Златоклас"
            },
            {
              value: "Козяк",
              label: "Козяк"
            },
            {
              value: "Колобър",
              label: "Колобър"
            },
            {
              value: "Межден",
              label: "Межден"
            },
            {
              value: "Овен",
              label: "Овен"
            },
            {
              value: "Окорш",
              label: "Окорш"
            },
            {
              value: "Орешене",
              label: "Орешене"
            },
            {
              value: "Паисиево",
              label: "Паисиево"
            },
            {
              value: "Полковник Таслаково",
              label: "Полковник Таслаково"
            },
            {
              value: "Поройно",
              label: "Поройно"
            },
            {
              value: "Правда",
              label: "Правда"
            },
            {
              value: "Прохлада",
              label: "Прохлада"
            },
            {
              value: "Раздел",
              label: "Раздел"
            },
            {
              value: "Руйно",
              label: "Руйно"
            },
            {
              value: "Секулово",
              label: "Секулово"
            },
            {
              value: "Скала",
              label: "Скала"
            },
            {
              value: "Черковна",
              label: "Черковна"
            },
            {
              value: "Черник",
              label: "Черник"
            },
            {
              value: "Чернолик",
              label: "Чернолик"
            },
            {
              value: "Яребица",
              label: "Яребица"
            }
          ]
        },
        {
          value: "Кайнарджа",
          label: "Кайнарджа",
          list: [
            {
              value: "Войново",
              label: "Войново"
            },
            {
              value: "Голеш",
              label: "Голеш"
            },
            {
              value: "Господиново",
              label: "Господиново"
            },
            {
              value: "Давидово",
              label: "Давидово"
            },
            {
              value: "Добруджанка",
              label: "Добруджанка"
            },
            {
              value: "Зарник",
              label: "Зарник"
            },
            {
              value: "Кайнарджа",
              label: "Кайнарджа"
            },
            {
              value: "Каменци",
              label: "Каменци"
            },
            {
              value: "Краново",
              label: "Краново"
            },
            {
              value: "Полковник Чолаково",
              label: "Полковник Чолаково"
            },
            {
              value: "Попрусаново",
              label: "Попрусаново"
            },
            {
              value: "Посев",
              label: "Посев"
            },
            {
              value: "Светослав",
              label: "Светослав"
            },
            {
              value: "Средище",
              label: "Средище"
            },
            {
              value: "Стрелково",
              label: "Стрелково"
            }
          ]
        },
        {
          value: "Силистра",
          label: "Силистра",
          list: [
            {
              value: "Айдемир",
              label: "Айдемир"
            },
            {
              value: "Бабук",
              label: "Бабук"
            },
            {
              value: "Богорово",
              label: "Богорово"
            },
            {
              value: "Брадвари",
              label: "Брадвари"
            },
            {
              value: "Българка",
              label: "Българка"
            },
            {
              value: "Ветрен",
              label: "Ветрен"
            },
            {
              value: "Главан",
              label: "Главан"
            },
            {
              value: "Йорданово",
              label: "Йорданово"
            },
            {
              value: "Казимир",
              label: "Казимир"
            },
            {
              value: "Калипетрово",
              label: "Калипетрово"
            },
            {
              value: "Полковник Ламбриново",
              label: "Полковник Ламбриново"
            },
            {
              value: "Попкралево",
              label: "Попкралево"
            },
            {
              value: "Професор Иширково",
              label: "Професор Иширково"
            },
            {
              value: "Силистра",
              label: "Силистра"
            },
            {
              value: "Смилец",
              label: "Смилец"
            },
            {
              value: "Срацимир",
              label: "Срацимир"
            },
            {
              value: "Сребърна",
              label: "Сребърна"
            },
            {
              value: "Сърпово",
              label: "Сърпово"
            },
            {
              value: "Ценович",
              label: "Ценович"
            }
          ]
        },
        {
          value: "Ситово",
          label: "Ситово",
          list: [
            {
              value: "Босна",
              label: "Босна"
            },
            {
              value: "Гарван",
              label: "Гарван"
            },
            {
              value: "Добротица",
              label: "Добротица"
            },
            {
              value: "Ирник",
              label: "Ирник"
            },
            {
              value: "Искра",
              label: "Искра"
            },
            {
              value: "Любен",
              label: "Любен"
            },
            {
              value: "Нова Попина",
              label: "Нова Попина"
            },
            {
              value: "Поляна",
              label: "Поляна"
            },
            {
              value: "Попина",
              label: "Попина"
            },
            {
              value: "Ситово",
              label: "Ситово"
            },
            {
              value: "Слатина",
              label: "Слатина"
            },
            {
              value: "Ястребна",
              label: "Ястребна"
            }
          ]
        },
        {
          value: "Тутракан",
          label: "Тутракан",
          list: [
            {
              value: "Антимово",
              label: "Антимово"
            },
            {
              value: "Белица",
              label: "Белица"
            },
            {
              value: "Бреница",
              label: "Бреница"
            },
            {
              value: "Варненци",
              label: "Варненци"
            },
            {
              value: "Дунавец",
              label: "Дунавец"
            },
            {
              value: "Нова Черна",
              label: "Нова Черна"
            },
            {
              value: "Пожарево",
              label: "Пожарево"
            },
            {
              value: "Преславци",
              label: "Преславци"
            },
            {
              value: "Старо Село",
              label: "Старо Село"
            },
            {
              value: "Сяново",
              label: "Сяново"
            },
            {
              value: "Тутракан",
              label: "Тутракан"
            },
            {
              value: "Търновци",
              label: "Търновци"
            },
            {
              value: "Цар Самуил",
              label: "Цар Самуил"
            },
            {
              value: "Царев дол",
              label: "Царев дол"
            },
            {
              value: "Шуменци",
              label: "Шуменци"
            }
          ]
        }
      ]
    },
    {
      value: "Сливен",
      label: "Сливен",
      list: [
        {
          value: "Котел",
          label: "Котел",
          list: [
            {
              value: "Боринци",
              label: "Боринци"
            },
            {
              value: "Братан",
              label: "Братан"
            },
            {
              value: "Градец",
              label: "Градец"
            },
            {
              value: "Дъбова",
              label: "Дъбова"
            },
            {
              value: "Жеравна",
              label: "Жеравна"
            },
            {
              value: "Катунище",
              label: "Катунище"
            },
            {
              value: "Кипилово",
              label: "Кипилово"
            },
            {
              value: "Котел",
              label: "Котел"
            },
            {
              value: "Малко село",
              label: "Малко село"
            },
            {
              value: "Медвен",
              label: "Медвен"
            },
            {
              value: "Мокрен",
              label: "Мокрен"
            },
            {
              value: "Нейково",
              label: "Нейково"
            },
            {
              value: "Орлово",
              label: "Орлово"
            },
            {
              value: "Остра могила",
              label: "Остра могила"
            },
            {
              value: "Пъдарево",
              label: "Пъдарево"
            },
            {
              value: "Седларево",
              label: "Седларево"
            },
            {
              value: "Соколарци",
              label: "Соколарци"
            },
            {
              value: "Стрелци",
              label: "Стрелци"
            },
            {
              value: "Тича",
              label: "Тича"
            },
            {
              value: "Топузево",
              label: "Топузево"
            },
            {
              value: "Филаретово",
              label: "Филаретово"
            },
            {
              value: "Ябланово",
              label: "Ябланово"
            }
          ]
        },
        {
          value: "Нова Загора",
          label: "Нова Загора",
          list: [
            {
              value: "Асеновец",
              label: "Асеновец"
            },
            {
              value: "Баня",
              label: "Баня"
            },
            {
              value: "Богданово",
              label: "Богданово"
            },
            {
              value: "Брястово",
              label: "Брястово"
            },
            {
              value: "Бял кладенец",
              label: "Бял кладенец"
            },
            {
              value: "Дядово",
              label: "Дядово"
            },
            {
              value: "Езеро",
              label: "Езеро"
            },
            {
              value: "Еленово",
              label: "Еленово"
            },
            {
              value: "Загорци",
              label: "Загорци"
            },
            {
              value: "Каменово",
              label: "Каменово"
            },
            {
              value: "Караново",
              label: "Караново"
            },
            {
              value: "Коньово",
              label: "Коньово"
            },
            {
              value: "Кортен",
              label: "Кортен"
            },
            {
              value: "Крива круша",
              label: "Крива круша"
            },
            {
              value: "Любенец",
              label: "Любенец"
            },
            {
              value: "Любенова махала",
              label: "Любенова махала"
            },
            {
              value: "Млекарево",
              label: "Млекарево"
            },
            {
              value: "Научене",
              label: "Научене"
            },
            {
              value: "Нова Загора",
              label: "Нова Загора"
            },
            {
              value: "Новоселец",
              label: "Новоселец"
            },
            {
              value: "Омарчево",
              label: "Омарчево"
            },
            {
              value: "Пет могили",
              label: "Пет могили"
            },
            {
              value: "Питово",
              label: "Питово"
            },
            {
              value: "Полско Пъдарево",
              label: "Полско Пъдарево"
            },
            {
              value: "Прохорово",
              label: "Прохорово"
            },
            {
              value: "Радево",
              label: "Радево"
            },
            {
              value: "Радецки",
              label: "Радецки"
            },
            {
              value: "Сокол",
              label: "Сокол"
            },
            {
              value: "Стоил Войвода",
              label: "Стоил Войвода"
            },
            {
              value: "Събрано",
              label: "Събрано"
            },
            {
              value: "Съдиево",
              label: "Съдиево"
            },
            {
              value: "Съдийско поле",
              label: "Съдийско поле"
            },
            {
              value: "Ценино",
              label: "Ценино"
            }
          ]
        },
        {
          value: "Сливен",
          label: "Сливен",
          list: [
            {
              value: "Биково",
              label: "Биково"
            },
            {
              value: "Бинкос",
              label: "Бинкос"
            },
            {
              value: "Блатец",
              label: "Блатец"
            },
            {
              value: "Божевци",
              label: "Божевци"
            },
            {
              value: "Бозаджии",
              label: "Бозаджии"
            },
            {
              value: "Бяла",
              label: "Бяла"
            },
            {
              value: "Въглен",
              label: "Въглен"
            },
            {
              value: "Гавраилово",
              label: "Гавраилово"
            },
            {
              value: "Гергевец",
              label: "Гергевец"
            },
            {
              value: "Глуфишево",
              label: "Глуфишево"
            },
            {
              value: "Глушник",
              label: "Глушник"
            },
            {
              value: "Голямо Чочовени",
              label: "Голямо Чочовени"
            },
            {
              value: "Горно Александрово",
              label: "Горно Александрово"
            },
            {
              value: "Градско",
              label: "Градско"
            },
            {
              value: "Драгоданово",
              label: "Драгоданово"
            },
            {
              value: "Желю войвода",
              label: "Желю войвода"
            },
            {
              value: "Зайчари",
              label: "Зайчари"
            },
            {
              value: "Злати Войвода",
              label: "Злати Войвода"
            },
            {
              value: "Изгрев",
              label: "Изгрев"
            },
            {
              value: "Ичера",
              label: "Ичера"
            },
            {
              value: "Калояново",
              label: "Калояново"
            },
            {
              value: "Камен",
              label: "Камен"
            },
            {
              value: "Карандила",
              label: "Карандила"
            },
            {
              value: "Кермен",
              label: "Кермен"
            },
            {
              value: "Ковачите",
              label: "Ковачите"
            },
            {
              value: "Крушаре",
              label: "Крушаре"
            },
            {
              value: "Малко Чочовени",
              label: "Малко Чочовени"
            },
            {
              value: "Мечкарево",
              label: "Мечкарево"
            },
            {
              value: "Младово",
              label: "Младово"
            },
            {
              value: "Николаево",
              label: "Николаево"
            },
            {
              value: "Новачево",
              label: "Новачево"
            },
            {
              value: "Панаретовци",
              label: "Панаретовци"
            },
            {
              value: "Раково",
              label: "Раково"
            },
            {
              value: "Самуилово",
              label: "Самуилово"
            },
            {
              value: "Селиминово",
              label: "Селиминово"
            },
            {
              value: "Скобелево",
              label: "Скобелево"
            },
            {
              value: "Сливен",
              label: "Сливен"
            },
            {
              value: "Сливенски мин. бани",
              label: "Сливенски мин. бани"
            },
            {
              value: "Сотиря",
              label: "Сотиря"
            },
            {
              value: "Средорек",
              label: "Средорек"
            },
            {
              value: "Стара река",
              label: "Стара река"
            },
            {
              value: "Старо село",
              label: "Старо село"
            },
            {
              value: "Струпец",
              label: "Струпец"
            },
            {
              value: "Тополчане",
              label: "Тополчане"
            },
            {
              value: "Трапоклово",
              label: "Трапоклово"
            },
            {
              value: "Чинтулово",
              label: "Чинтулово"
            },
            {
              value: "Чокоба",
              label: "Чокоба"
            }
          ]
        },
        {
          value: "Твърдица",
          label: "Твърдица",
          list: [
            {
              value: "Близнец",
              label: "Близнец"
            },
            {
              value: "Боров дол",
              label: "Боров дол"
            },
            {
              value: "Бяла паланка",
              label: "Бяла паланка"
            },
            {
              value: "Жълт бряг",
              label: "Жълт бряг"
            },
            {
              value: "Оризари",
              label: "Оризари"
            },
            {
              value: "Сборище",
              label: "Сборище"
            },
            {
              value: "Сърцево",
              label: "Сърцево"
            },
            {
              value: "Твърдица",
              label: "Твърдица"
            },
            {
              value: "Червенаково",
              label: "Червенаково"
            },
            {
              value: "Шивачево",
              label: "Шивачево"
            }
          ]
        }
      ]
    },
    {
      value: "Смолян",
      label: "Смолян",
      list: [
        {
          value: "Баните",
          label: "Баните",
          list: [
            {
              value: "Баните",
              label: "Баните"
            },
            {
              value: "Босилково",
              label: "Босилково"
            },
            {
              value: "Вишнево",
              label: "Вишнево"
            },
            {
              value: "Вълчан дол",
              label: "Вълчан дол"
            },
            {
              value: "Глогино",
              label: "Глогино"
            },
            {
              value: "Гълъбово",
              label: "Гълъбово"
            },
            {
              value: "Давидково",
              label: "Давидково"
            },
            {
              value: "Две тополи",
              label: "Две тополи"
            },
            {
              value: "Дебеляново",
              label: "Дебеляново"
            },
            {
              value: "Дрянка",
              label: "Дрянка"
            },
            {
              value: "Загражден",
              label: "Загражден"
            },
            {
              value: "Кръстатица",
              label: "Кръстатица"
            },
            {
              value: "Малка Арда",
              label: "Малка Арда"
            },
            {
              value: "Малко Крушево",
              label: "Малко Крушево"
            },
            {
              value: "Оряховец",
              label: "Оряховец"
            },
            {
              value: "Планинско",
              label: "Планинско"
            },
            {
              value: "Рибен дол",
              label: "Рибен дол"
            },
            {
              value: "Сливка",
              label: "Сливка"
            },
            {
              value: "Стърница",
              label: "Стърница"
            },
            {
              value: "Траве",
              label: "Траве"
            }
          ]
        },
        {
          value: "Борино",
          label: "Борино",
          list: [
            {
              value: "Борино",
              label: "Борино"
            },
            {
              value: "Буйново",
              label: "Буйново"
            },
            {
              value: "Кожари",
              label: "Кожари"
            },
            {
              value: "Чала",
              label: "Чала"
            },
            {
              value: "Ягодина",
              label: "Ягодина"
            }
          ]
        },
        {
          value: "Девин",
          label: "Девин",
          list: [
            {
              value: "Беден",
              label: "Беден"
            },
            {
              value: "Брезе",
              label: "Брезе"
            },
            {
              value: "Водни пад",
              label: "Водни пад"
            },
            {
              value: "Грохотно",
              label: "Грохотно"
            },
            {
              value: "Гьоврен",
              label: "Гьоврен"
            },
            {
              value: "Девин",
              label: "Девин"
            },
            {
              value: "Жребево",
              label: "Жребево"
            },
            {
              value: "Кестен",
              label: "Кестен"
            },
            {
              value: "Лясково",
              label: "Лясково"
            },
            {
              value: "Михалково",
              label: "Михалково"
            },
            {
              value: "Осиково",
              label: "Осиково"
            },
            {
              value: "Селча",
              label: "Селча"
            },
            {
              value: "Стоманево",
              label: "Стоманево"
            },
            {
              value: "Тешел",
              label: "Тешел"
            },
            {
              value: "Триград",
              label: "Триград"
            },
            {
              value: "Чуруково",
              label: "Чуруково"
            }
          ]
        },
        {
          value: "Доспат",
          label: "Доспат",
          list: [
            {
              value: "Барутин",
              label: "Барутин"
            },
            {
              value: "Бръщен",
              label: "Бръщен"
            },
            {
              value: "Доспат",
              label: "Доспат"
            },
            {
              value: "Змеица",
              label: "Змеица"
            },
            {
              value: "Късак",
              label: "Късак"
            },
            {
              value: "Любча",
              label: "Любча"
            },
            {
              value: "Црънча",
              label: "Црънча"
            },
            {
              value: "Чавдар",
              label: "Чавдар"
            }
          ]
        },
        {
          value: "Златоград",
          label: "Златоград",
          list: [
            {
              value: "Аламовци",
              label: "Аламовци"
            },
            {
              value: "Долен",
              label: "Долен"
            },
            {
              value: "Ерма река",
              label: "Ерма река"
            },
            {
              value: "Златоград",
              label: "Златоград"
            },
            {
              value: "Кушла",
              label: "Кушла"
            },
            {
              value: "Пресока",
              label: "Пресока"
            },
            {
              value: "Старцево",
              label: "Старцево"
            },
            {
              value: "Страшимир",
              label: "Страшимир"
            },
            {
              value: "Цацаровци",
              label: "Цацаровци"
            }
          ]
        },
        {
          value: "Мадан",
          label: "Мадан",
          list: [
            {
              value: "Арпаджик",
              label: "Арпаджик"
            },
            {
              value: "Борика",
              label: "Борика"
            },
            {
              value: "Бориново",
              label: "Бориново"
            },
            {
              value: "Боровина",
              label: "Боровина"
            },
            {
              value: "Букова поляна",
              label: "Букова поляна"
            },
            {
              value: "Буково",
              label: "Буково"
            },
            {
              value: "Вехтино",
              label: "Вехтино"
            },
            {
              value: "Високите",
              label: "Високите"
            },
            {
              value: "Вранинци",
              label: "Вранинци"
            },
            {
              value: "Върба",
              label: "Върба"
            },
            {
              value: "Върбина",
              label: "Върбина"
            },
            {
              value: "Въргов дол",
              label: "Въргов дол"
            },
            {
              value: "Габрина",
              label: "Габрина"
            },
            {
              value: "Галище",
              label: "Галище"
            },
            {
              value: "Дирало",
              label: "Дирало"
            },
            {
              value: "Долие",
              label: "Долие"
            },
            {
              value: "Касапско",
              label: "Касапско"
            },
            {
              value: "Кориите",
              label: "Кориите"
            },
            {
              value: "Крайна",
              label: "Крайна"
            },
            {
              value: "Крушев дол",
              label: "Крушев дол"
            },
            {
              value: "Купен",
              label: "Купен"
            },
            {
              value: "Леска",
              label: "Леска"
            },
            {
              value: "Лещак",
              label: "Лещак"
            },
            {
              value: "Ливаде",
              label: "Ливаде"
            },
            {
              value: "Ловци",
              label: "Ловци"
            },
            {
              value: "Мадан",
              label: "Мадан"
            },
            {
              value: "Миле",
              label: "Миле"
            },
            {
              value: "Митовска",
              label: "Митовска"
            },
            {
              value: "Мъглища",
              label: "Мъглища"
            },
            {
              value: "Петров дол",
              label: "Петров дол"
            },
            {
              value: "Печинска",
              label: "Печинска"
            },
            {
              value: "Планинци",
              label: "Планинци"
            },
            {
              value: "Равнил",
              label: "Равнил"
            },
            {
              value: "Равнища",
              label: "Равнища"
            },
            {
              value: "Равно нивище",
              label: "Равно нивище"
            },
            {
              value: "Рустан",
              label: "Рустан"
            },
            {
              value: "Средногорци",
              label: "Средногорци"
            },
            {
              value: "Стайчин дол",
              label: "Стайчин дол"
            },
            {
              value: "Студена",
              label: "Студена"
            },
            {
              value: "Тънкото",
              label: "Тънкото"
            },
            {
              value: "Уручевци",
              label: "Уручевци"
            },
            {
              value: "Цирка",
              label: "Цирка"
            },
            {
              value: "Чурка",
              label: "Чурка"
            },
            {
              value: "Шаренска",
              label: "Шаренска"
            }
          ]
        },
        {
          value: "Неделино",
          label: "Неделино",
          list: [
            {
              value: "Бурево",
              label: "Бурево"
            },
            {
              value: "Върли дол",
              label: "Върли дол"
            },
            {
              value: "Върлино",
              label: "Върлино"
            },
            {
              value: "Гърнати",
              label: "Гърнати"
            },
            {
              value: "Диманово",
              label: "Диманово"
            },
            {
              value: "Дуня",
              label: "Дуня"
            },
            {
              value: "Еленка",
              label: "Еленка"
            },
            {
              value: "Изгрев",
              label: "Изгрев"
            },
            {
              value: "Козарка",
              label: "Козарка"
            },
            {
              value: "Кочани",
              label: "Кочани"
            },
            {
              value: "Крайна",
              label: "Крайна"
            },
            {
              value: "Кундево",
              label: "Кундево"
            },
            {
              value: "Неделино",
              label: "Неделино"
            },
            {
              value: "Оградна",
              label: "Оградна"
            },
            {
              value: "Средец",
              label: "Средец"
            },
            {
              value: "Тънка бара",
              label: "Тънка бара"
            }
          ]
        },
        {
          value: "Рудозем",
          label: "Рудозем",
          list: [
            {
              value: "Боево",
              label: "Боево"
            },
            {
              value: "Борие",
              label: "Борие"
            },
            {
              value: "Бреза",
              label: "Бреза"
            },
            {
              value: "Бърчево",
              label: "Бърчево"
            },
            {
              value: "Бяла река",
              label: "Бяла река"
            },
            {
              value: "Витина",
              label: "Витина"
            },
            {
              value: "Войкова лъка",
              label: "Войкова лъка"
            },
            {
              value: "Грамаде",
              label: "Грамаде"
            },
            {
              value: "Добрева череша",
              label: "Добрева череша"
            },
            {
              value: "Дъбова",
              label: "Дъбова"
            },
            {
              value: "Елховец",
              label: "Елховец"
            },
            {
              value: "Иваново",
              label: "Иваново"
            },
            {
              value: "Кокорци",
              label: "Кокорци"
            },
            {
              value: "Коритата",
              label: "Коритата"
            },
            {
              value: "Мочуре",
              label: "Мочуре"
            },
            {
              value: "Оглед",
              label: "Оглед"
            },
            {
              value: "Пловдивци",
              label: "Пловдивци"
            },
            {
              value: "Поляна",
              label: "Поляна"
            },
            {
              value: "Рибница",
              label: "Рибница"
            },
            {
              value: "Рудозем",
              label: "Рудозем"
            },
            {
              value: "Сопотот",
              label: "Сопотот"
            },
            {
              value: "Чепинци",
              label: "Чепинци"
            }
          ]
        },
        {
          value: "Смолян",
          label: "Смолян",
          list: [
            {
              value: "Алиговска",
              label: "Алиговска"
            },
            {
              value: "Арда",
              label: "Арда"
            },
            {
              value: "Баблон",
              label: "Баблон"
            },
            {
              value: "Белев дол",
              label: "Белев дол"
            },
            {
              value: "Билянска",
              label: "Билянска"
            },
            {
              value: "Бориково",
              label: "Бориково"
            },
            {
              value: "Бостина",
              label: "Бостина"
            },
            {
              value: "Буката",
              label: "Буката"
            },
            {
              value: "Букаците",
              label: "Букаците"
            },
            {
              value: "Виево",
              label: "Виево"
            },
            {
              value: "Влахово",
              label: "Влахово"
            },
            {
              value: "Водата",
              label: "Водата"
            },
            {
              value: "Вълчан",
              label: "Вълчан"
            },
            {
              value: "Върбово",
              label: "Върбово"
            },
            {
              value: "Габрица",
              label: "Габрица"
            },
            {
              value: "Гела",
              label: "Гела"
            },
            {
              value: "Гоздевица",
              label: "Гоздевица"
            },
            {
              value: "Горна Арда",
              label: "Горна Арда"
            },
            {
              value: "Горово",
              label: "Горово"
            },
            {
              value: "Градът",
              label: "Градът"
            },
            {
              value: "Гращица",
              label: "Гращица"
            },
            {
              value: "Гудевица",
              label: "Гудевица"
            },
            {
              value: "Димово",
              label: "Димово"
            },
            {
              value: "Дунево",
              label: "Дунево"
            },
            {
              value: "Еленска",
              label: "Еленска"
            },
            {
              value: "Ельово",
              label: "Ельово"
            },
            {
              value: "Заевите",
              label: "Заевите"
            },
            {
              value: "Змиево",
              label: "Змиево"
            },
            {
              value: "Исьовци",
              label: "Исьовци"
            },
            {
              value: "Катраница",
              label: "Катраница"
            },
            {
              value: "Киселичево",
              label: "Киселичево"
            },
            {
              value: "Кокорково",
              label: "Кокорково"
            },
            {
              value: "Кошница",
              label: "Кошница"
            },
            {
              value: "Кремене",
              label: "Кремене"
            },
            {
              value: "Кукувица",
              label: "Кукувица"
            },
            {
              value: "Кутела",
              label: "Кутела"
            },
            {
              value: "Левочево",
              label: "Левочево"
            },
            {
              value: "Липец",
              label: "Липец"
            },
            {
              value: "Лъка",
              label: "Лъка"
            },
            {
              value: "Люлка",
              label: "Люлка"
            },
            {
              value: "Магарджица",
              label: "Магарджица"
            },
            {
              value: "Милково",
              label: "Милково"
            },
            {
              value: "Могилица",
              label: "Могилица"
            },
            {
              value: "Момчиловци",
              label: "Момчиловци"
            },
            {
              value: "Мугла",
              label: "Мугла"
            },
            {
              value: "Надарци",
              label: "Надарци"
            },
            {
              value: "Орешица",
              label: "Орешица"
            },
            {
              value: "Остри пазлак",
              label: "Остри пазлак"
            },
            {
              value: "Петково",
              label: "Петково"
            },
            {
              value: "Пещера",
              label: "Пещера"
            },
            {
              value: "Писаница",
              label: "Писаница"
            },
            {
              value: "Подвис",
              label: "Подвис"
            },
            {
              value: "Полковник Серафимово",
              label: "Полковник Серафимово"
            },
            {
              value: "Попрелка",
              label: "Попрелка"
            },
            {
              value: "Потока",
              label: "Потока"
            },
            {
              value: "Равнината",
              label: "Равнината"
            },
            {
              value: "Река",
              label: "Река"
            },
            {
              value: "Речани",
              label: "Речани"
            },
            {
              value: "Ровина",
              label: "Ровина"
            },
            {
              value: "Селище",
              label: "Селище"
            },
            {
              value: "Сивино",
              label: "Сивино"
            },
            {
              value: "Славейно",
              label: "Славейно"
            },
            {
              value: "Сливово",
              label: "Сливово"
            },
            {
              value: "Смилян",
              label: "Смилян"
            },
            {
              value: "Смолян",
              label: "Смолян"
            },
            {
              value: "Соколовци",
              label: "Соколовци"
            },
            {
              value: "Солища",
              label: "Солища"
            },
            {
              value: "Средок",
              label: "Средок"
            },
            {
              value: "Стикъл",
              label: "Стикъл"
            },
            {
              value: "Стойките",
              label: "Стойките"
            },
            {
              value: "Стража",
              label: "Стража"
            },
            {
              value: "Сърнино",
              label: "Сърнино"
            },
            {
              value: "Тикале",
              label: "Тикале"
            },
            {
              value: "Требище",
              label: "Требище"
            },
            {
              value: "Турян",
              label: "Турян"
            },
            {
              value: "Търън",
              label: "Търън"
            },
            {
              value: "Ухловица",
              label: "Ухловица"
            },
            {
              value: "Фатово",
              label: "Фатово"
            },
            {
              value: "Хасовица",
              label: "Хасовица"
            },
            {
              value: "Чамла",
              label: "Чамла"
            },
            {
              value: "Чеплетен",
              label: "Чеплетен"
            },
            {
              value: "Черешките",
              label: "Черешките"
            },
            {
              value: "Черешово",
              label: "Черешово"
            },
            {
              value: "Черешовска река",
              label: "Черешовска река"
            },
            {
              value: "Чокманово",
              label: "Чокманово"
            },
            {
              value: "Чучур",
              label: "Чучур"
            },
            {
              value: "Широка лъка",
              label: "Широка лъка"
            }
          ]
        },
        {
          value: "Чепеларе",
          label: "Чепеларе",
          list: [
            {
              value: "Богутево",
              label: "Богутево"
            },
            {
              value: "Дряновец",
              label: "Дряновец"
            },
            {
              value: "Забърдо",
              label: "Забърдо"
            },
            {
              value: "Зорница",
              label: "Зорница"
            },
            {
              value: "Лилеково",
              label: "Лилеково"
            },
            {
              value: "Малево",
              label: "Малево"
            },
            {
              value: "Орехово",
              label: "Орехово"
            },
            {
              value: "Острица",
              label: "Острица"
            },
            {
              value: "Павелско",
              label: "Павелско"
            },
            {
              value: "Пампорово",
              label: "Пампорово"
            },
            {
              value: "Проглед",
              label: "Проглед"
            },
            {
              value: "Студенец",
              label: "Студенец"
            },
            {
              value: "Хвойна",
              label: "Хвойна"
            },
            {
              value: "Чепеларе",
              label: "Чепеларе"
            }
          ]
        }
      ]
    },
    {
      value: "София",
      label: "София",
      list: [
        {
          value: "Антон",
          label: "Антон",
          list: [
            {
              value: "Антон",
              label: "Антон"
            }
          ]
        },
        {
          value: "Божурище",
          label: "Божурище",
          list: [
            {
              value: "Божурище",
              label: "Божурище"
            },
            {
              value: "Гурмазово",
              label: "Гурмазово"
            },
            {
              value: "Делян",
              label: "Делян"
            },
            {
              value: "Златуша",
              label: "Златуша"
            },
            {
              value: "Мала Раковица",
              label: "Мала Раковица"
            },
            {
              value: "Пожарево",
              label: "Пожарево"
            },
            {
              value: "Пролеша",
              label: "Пролеша"
            },
            {
              value: "Росоман",
              label: "Росоман"
            },
            {
              value: "Хераково",
              label: "Хераково"
            },
            {
              value: "Храбърско",
              label: "Храбърско"
            }
          ]
        },
        {
          value: "Ботевград",
          label: "Ботевград",
          list: [
            {
              value: "Боженица",
              label: "Боженица"
            },
            {
              value: "Ботевград",
              label: "Ботевград"
            },
            {
              value: "Врачеш",
              label: "Врачеш"
            },
            {
              value: "Гурково",
              label: "Гурково"
            },
            {
              value: "Елов Дол",
              label: "Елов Дол"
            },
            {
              value: "Краево",
              label: "Краево"
            },
            {
              value: "Липница",
              label: "Липница"
            },
            {
              value: "Литаково",
              label: "Литаково"
            },
            {
              value: "Новачене",
              label: "Новачене"
            },
            {
              value: "Радотина",
              label: "Радотина"
            },
            {
              value: "Рашково",
              label: "Рашково"
            },
            {
              value: "Скравена",
              label: "Скравена"
            },
            {
              value: "Трудовец",
              label: "Трудовец"
            }
          ]
        },
        {
          value: "Годеч",
          label: "Годеч",
          list: [
            {
              value: "Бракьовци",
              label: "Бракьовци"
            },
            {
              value: "Букоровци",
              label: "Букоровци"
            },
            {
              value: "Бърля",
              label: "Бърля"
            },
            {
              value: "Връдловци",
              label: "Връдловци"
            },
            {
              value: "Върбница",
              label: "Върбница"
            },
            {
              value: "Гинци",
              label: "Гинци"
            },
            {
              value: "Годеч",
              label: "Годеч"
            },
            {
              value: "Голеш",
              label: "Голеш"
            },
            {
              value: "Губеш",
              label: "Губеш"
            },
            {
              value: "Каленовци",
              label: "Каленовци"
            },
            {
              value: "Комщица",
              label: "Комщица"
            },
            {
              value: "Лопушня",
              label: "Лопушня"
            },
            {
              value: "Мургаш",
              label: "Мургаш"
            },
            {
              value: "Равна",
              label: "Равна"
            },
            {
              value: "Разбоище",
              label: "Разбоище"
            },
            {
              value: "Ропот",
              label: "Ропот"
            },
            {
              value: "Смолча",
              label: "Смолча"
            },
            {
              value: "Станинци",
              label: "Станинци"
            },
            {
              value: "Туден",
              label: "Туден"
            },
            {
              value: "Шума",
              label: "Шума"
            }
          ]
        },
        {
          value: "Горна Малина",
          label: "Горна Малина",
          list: [
            {
              value: "Априлово",
              label: "Априлово"
            },
            {
              value: "Байлово",
              label: "Байлово"
            },
            {
              value: "Белопопци",
              label: "Белопопци"
            },
            {
              value: "Гайтанево",
              label: "Гайтанево"
            },
            {
              value: "Горна Малина",
              label: "Горна Малина"
            },
            {
              value: "Горно Камарци",
              label: "Горно Камарци"
            },
            {
              value: "Долна Малина",
              label: "Долна Малина"
            },
            {
              value: "Долно Камарци",
              label: "Долно Камарци"
            },
            {
              value: "Макоцево",
              label: "Макоцево"
            },
            {
              value: "Негушево",
              label: "Негушево"
            },
            {
              value: "Осоица",
              label: "Осоица"
            },
            {
              value: "Саранци",
              label: "Саранци"
            },
            {
              value: "Стъргел",
              label: "Стъргел"
            },
            {
              value: "Чеканчево",
              label: "Чеканчево"
            }
          ]
        },
        {
          value: "Долна баня",
          label: "Долна баня",
          list: [
            {
              value: "Долна баня",
              label: "Долна баня"
            },
            {
              value: "Свети Спас",
              label: "Свети Спас"
            }
          ]
        },
        {
          value: "Драгоман",
          label: "Драгоман",
          list: [
            {
              value: "Беренде",
              label: "Беренде"
            },
            {
              value: "Беренде извор",
              label: "Беренде извор"
            },
            {
              value: "Василовци",
              label: "Василовци"
            },
            {
              value: "Вишан",
              label: "Вишан"
            },
            {
              value: "Владиславци",
              label: "Владиславци"
            },
            {
              value: "Габер",
              label: "Габер"
            },
            {
              value: "Големо Малово",
              label: "Големо Малово"
            },
            {
              value: "Горно Село",
              label: "Горно Село"
            },
            {
              value: "Грълска падина",
              label: "Грълска падина"
            },
            {
              value: "Долна Невля",
              label: "Долна Невля"
            },
            {
              value: "Долно ново село",
              label: "Долно ново село"
            },
            {
              value: "Драгоил",
              label: "Драгоил"
            },
            {
              value: "Драгоман",
              label: "Драгоман"
            },
            {
              value: "Дреатин",
              label: "Дреатин"
            },
            {
              value: "Калотина",
              label: "Калотина"
            },
            {
              value: "Камбелевци",
              label: "Камбелевци"
            },
            {
              value: "Круша",
              label: "Круша"
            },
            {
              value: "Летница",
              label: "Летница"
            },
            {
              value: "Липинци",
              label: "Липинци"
            },
            {
              value: "Мало Малово",
              label: "Мало Малово"
            },
            {
              value: "Мина Болшевик",
              label: "Мина Болшевик"
            },
            {
              value: "Начево",
              label: "Начево"
            },
            {
              value: "Неделище",
              label: "Неделище"
            },
            {
              value: "Несла",
              label: "Несла"
            },
            {
              value: "Ново бърдо",
              label: "Ново бърдо"
            },
            {
              value: "Прекръсте",
              label: "Прекръсте"
            },
            {
              value: "Раяновци",
              label: "Раяновци"
            },
            {
              value: "Табан",
              label: "Табан"
            },
            {
              value: "Цацаровци",
              label: "Цацаровци"
            },
            {
              value: "Цръклевци",
              label: "Цръклевци"
            },
            {
              value: "Чеканец",
              label: "Чеканец"
            },
            {
              value: "Чепърлинци",
              label: "Чепърлинци"
            },
            {
              value: "Чорул",
              label: "Чорул"
            },
            {
              value: "Чуковезер",
              label: "Чуковезер"
            },
            {
              value: "Ялботина",
              label: "Ялботина"
            }
          ]
        },
        {
          value: "Елин Пелин",
          label: "Елин Пелин",
          list: [
            {
              value: "Богданлия",
              label: "Богданлия"
            },
            {
              value: "Габра",
              label: "Габра"
            },
            {
              value: "Гара Елин Пелин",
              label: "Гара Елин Пелин"
            },
            {
              value: "Голема Раковица",
              label: "Голема Раковица"
            },
            {
              value: "Григорево",
              label: "Григорево"
            },
            {
              value: "Доганово",
              label: "Доганово"
            },
            {
              value: "Елешница",
              label: "Елешница"
            },
            {
              value: "Елин Пелин",
              label: "Елин Пелин"
            },
            {
              value: "Караполци",
              label: "Караполци"
            },
            {
              value: "Крушовица",
              label: "Крушовица"
            },
            {
              value: "Лесново",
              label: "Лесново"
            },
            {
              value: "Мусачево",
              label: "Мусачево"
            },
            {
              value: "Нови хан",
              label: "Нови хан"
            },
            {
              value: "Огняново",
              label: "Огняново"
            },
            {
              value: "Петково",
              label: "Петково"
            },
            {
              value: "Потоп",
              label: "Потоп"
            },
            {
              value: "Равно Поле",
              label: "Равно Поле"
            },
            {
              value: "Столник",
              label: "Столник"
            },
            {
              value: "Чурек",
              label: "Чурек"
            }
          ]
        },
        {
          value: "Етрополе",
          label: "Етрополе",
          list: [
            {
              value: "Бойковец",
              label: "Бойковец"
            },
            {
              value: "Брусен",
              label: "Брусен"
            },
            {
              value: "Горунака",
              label: "Горунака"
            },
            {
              value: "Етрополе",
              label: "Етрополе"
            },
            {
              value: "Лопян",
              label: "Лопян"
            },
            {
              value: "Лъга",
              label: "Лъга"
            },
            {
              value: "Малки Искър",
              label: "Малки Искър"
            },
            {
              value: "Оселна",
              label: "Оселна"
            },
            {
              value: "Рибарица",
              label: "Рибарица"
            },
            {
              value: "Ямна",
              label: "Ямна"
            }
          ]
        },
        {
          value: "Златица",
          label: "Златица",
          list: [
            {
              value: "Златица",
              label: "Златица"
            },
            {
              value: "Карлиево",
              label: "Карлиево"
            },
            {
              value: "Петрич",
              label: "Петрич"
            },
            {
              value: "Църквище",
              label: "Църквище"
            }
          ]
        },
        {
          value: "Ихтиман",
          label: "Ихтиман",
          list: [
            {
              value: "Бальовци",
              label: "Бальовци"
            },
            {
              value: "Банчовци",
              label: "Банчовци"
            },
            {
              value: "Белица",
              label: "Белица"
            },
            {
              value: "Богдановци",
              label: "Богдановци"
            },
            {
              value: "Боерица",
              label: "Боерица"
            },
            {
              value: "Борика",
              label: "Борика"
            },
            {
              value: "Бузяковци",
              label: "Бузяковци"
            },
            {
              value: "Бърдо",
              label: "Бърдо"
            },
            {
              value: "Вакарел",
              label: "Вакарел"
            },
            {
              value: "Венковец",
              label: "Венковец"
            },
            {
              value: "Веринско",
              label: "Веринско"
            },
            {
              value: "Джамузовци",
              label: "Джамузовци"
            },
            {
              value: "Живково",
              label: "Живково"
            },
            {
              value: "Ихтиман",
              label: "Ихтиман"
            },
            {
              value: "Костадинкино",
              label: "Костадинкино"
            },
            {
              value: "Любница",
              label: "Любница"
            },
            {
              value: "Мечковци",
              label: "Мечковци"
            },
            {
              value: "Мирово",
              label: "Мирово"
            },
            {
              value: "Мухово",
              label: "Мухово"
            },
            {
              value: "Нейкьовец",
              label: "Нейкьовец"
            },
            {
              value: "Пановци",
              label: "Пановци"
            },
            {
              value: "Пауново",
              label: "Пауново"
            },
            {
              value: "Полянци",
              label: "Полянци"
            },
            {
              value: "Поповци",
              label: "Поповци"
            },
            {
              value: "Ръжана",
              label: "Ръжана"
            },
            {
              value: "Селянин",
              label: "Селянин"
            },
            {
              value: "Стамболово",
              label: "Стамболово"
            },
            {
              value: "Черньово",
              label: "Черньово"
            }
          ]
        },
        {
          value: "Копривщица",
          label: "Копривщица",
          list: [
            {
              value: "Копривщица",
              label: "Копривщица"
            }
          ]
        },
        {
          value: "Костенец",
          label: "Костенец",
          list: [
            {
              value: "Голак",
              label: "Голак"
            },
            {
              value: "Горна Василица",
              label: "Горна Василица"
            },
            {
              value: "Долна Василица",
              label: "Долна Василица"
            },
            {
              value: "Костенец",
              label: "Костенец"
            },
            {
              value: "Костенец",
              label: "Костенец"
            },
            {
              value: "Момин проход",
              label: "Момин проход"
            },
            {
              value: "Очуша",
              label: "Очуша"
            },
            {
              value: "Подгорие",
              label: "Подгорие"
            },
            {
              value: "Пчелин",
              label: "Пчелин"
            }
          ]
        },
        {
          value: "Костинброд",
          label: "Костинброд",
          list: [
            {
              value: "Безден",
              label: "Безден"
            },
            {
              value: "Богьовци",
              label: "Богьовци"
            },
            {
              value: "Бучин проход",
              label: "Бучин проход"
            },
            {
              value: "Голяновци",
              label: "Голяновци"
            },
            {
              value: "Градец",
              label: "Градец"
            },
            {
              value: "Драговищица",
              label: "Драговищица"
            },
            {
              value: "Дреново",
              label: "Дреново"
            },
            {
              value: "Дръмша",
              label: "Дръмша"
            },
            {
              value: "Костинброд",
              label: "Костинброд"
            },
            {
              value: "Опицвет",
              label: "Опицвет"
            },
            {
              value: "Петърч",
              label: "Петърч"
            },
            {
              value: "Понор",
              label: "Понор"
            },
            {
              value: "Царичина",
              label: "Царичина"
            },
            {
              value: "Чибаовци",
              label: "Чибаовци"
            }
          ]
        },
        {
          value: "Мирково",
          label: "Мирково",
          list: [
            {
              value: "Бенковски",
              label: "Бенковски"
            },
            {
              value: "Брестака",
              label: "Брестака"
            },
            {
              value: "Буново",
              label: "Буново"
            },
            {
              value: "Илинден",
              label: "Илинден"
            },
            {
              value: "Каменица",
              label: "Каменица"
            },
            {
              value: "Мирково",
              label: "Мирково"
            },
            {
              value: "Плъзище",
              label: "Плъзище"
            },
            {
              value: "Преспа",
              label: "Преспа"
            },
            {
              value: "Смолско",
              label: "Смолско"
            },
            {
              value: "Хвърчил",
              label: "Хвърчил"
            },
            {
              value: "Черковище",
              label: "Черковище"
            }
          ]
        },
        {
          value: "Пирдоп",
          label: "Пирдоп",
          list: [
            {
              value: "Душанци",
              label: "Душанци"
            },
            {
              value: "Пирдоп",
              label: "Пирдоп"
            }
          ]
        },
        {
          value: "Правец",
          label: "Правец",
          list: [
            {
              value: "Видраре",
              label: "Видраре"
            },
            {
              value: "Джурово",
              label: "Джурово"
            },
            {
              value: "Калугерово",
              label: "Калугерово"
            },
            {
              value: "Манаселска река",
              label: "Манаселска река"
            },
            {
              value: "Осиковица",
              label: "Осиковица"
            },
            {
              value: "Осиковска Лъкавица",
              label: "Осиковска Лъкавица"
            },
            {
              value: "Правец",
              label: "Правец"
            },
            {
              value: "Правешка Лъкавица",
              label: "Правешка Лъкавица"
            },
            {
              value: "Равнище",
              label: "Равнище"
            },
            {
              value: "Разлив",
              label: "Разлив"
            },
            {
              value: "Своде",
              label: "Своде"
            }
          ]
        },
        {
          value: "Самоков",
          label: "Самоков",
          list: [
            {
              value: "Алино",
              label: "Алино"
            },
            {
              value: "Бели Искър",
              label: "Бели Искър"
            },
            {
              value: "Белчин",
              label: "Белчин"
            },
            {
              value: "Белчински бани",
              label: "Белчински бани"
            },
            {
              value: "Боровец",
              label: "Боровец"
            },
            {
              value: "Говедарци",
              label: "Говедарци"
            },
            {
              value: "Горни Окол",
              label: "Горни Окол"
            },
            {
              value: "Гуцал",
              label: "Гуцал"
            },
            {
              value: "Долни Окол",
              label: "Долни Окол"
            },
            {
              value: "Доспей",
              label: "Доспей"
            },
            {
              value: "Драгушиново",
              label: "Драгушиново"
            },
            {
              value: "Злокучене",
              label: "Злокучене"
            },
            {
              value: "Клисура",
              label: "Клисура"
            },
            {
              value: "Ковачевци",
              label: "Ковачевци"
            },
            {
              value: "Лисец",
              label: "Лисец"
            },
            {
              value: "Маджаре",
              label: "Маджаре"
            },
            {
              value: "Мала църква",
              label: "Мала църква"
            },
            {
              value: "Марица",
              label: "Марица"
            },
            {
              value: "Ново село",
              label: "Ново село"
            },
            {
              value: "Поповяне",
              label: "Поповяне"
            },
            {
              value: "Продановци",
              label: "Продановци"
            },
            {
              value: "Радуил",
              label: "Радуил"
            },
            {
              value: "Райово",
              label: "Райово"
            },
            {
              value: "Рельово",
              label: "Рельово"
            },
            {
              value: "Самоков",
              label: "Самоков"
            },
            {
              value: "Шипочане",
              label: "Шипочане"
            },
            {
              value: "Широки дол",
              label: "Широки дол"
            },
            {
              value: "Яребковица",
              label: "Яребковица"
            },
            {
              value: "Ярлово",
              label: "Ярлово"
            }
          ]
        },
        {
          value: "Своге",
          label: "Своге",
          list: [
            {
              value: "Бакьово",
              label: "Бакьово"
            },
            {
              value: "Батулия",
              label: "Батулия"
            },
            {
              value: "Бов",
              label: "Бов"
            },
            {
              value: "Брезе",
              label: "Брезе"
            },
            {
              value: "Брезовдол",
              label: "Брезовдол"
            },
            {
              value: "Буковец",
              label: "Буковец"
            },
            {
              value: "Владо Тричков",
              label: "Владо Тричков"
            },
            {
              value: "Габровница",
              label: "Габровница"
            },
            {
              value: "Гара Бов",
              label: "Гара Бов"
            },
            {
              value: "Гара Лакатник",
              label: "Гара Лакатник"
            },
            {
              value: "Губислав",
              label: "Губислав"
            },
            {
              value: "Добравица",
              label: "Добравица"
            },
            {
              value: "Добърчин",
              label: "Добърчин"
            },
            {
              value: "Дружево",
              label: "Дружево"
            },
            {
              value: "Еленов дол",
              label: "Еленов дол"
            },
            {
              value: "Желен",
              label: "Желен"
            },
            {
              value: "Завидовци",
              label: "Завидовци"
            },
            {
              value: "Заноге",
              label: "Заноге"
            },
            {
              value: "Заселе",
              label: "Заселе"
            },
            {
              value: "Зимевица",
              label: "Зимевица"
            },
            {
              value: "Искрец",
              label: "Искрец"
            },
            {
              value: "Искрец санаториум",
              label: "Искрец санаториум"
            },
            {
              value: "Лакатник",
              label: "Лакатник"
            },
            {
              value: "Левище",
              label: "Левище"
            },
            {
              value: "Лесковдол",
              label: "Лесковдол"
            },
            {
              value: "Луково",
              label: "Луково"
            },
            {
              value: "Манастирище",
              label: "Манастирище"
            },
            {
              value: "Миланово",
              label: "Миланово"
            },
            {
              value: "Огоя",
              label: "Огоя"
            },
            {
              value: "Оплетня",
              label: "Оплетня"
            },
            {
              value: "Осеновлаг",
              label: "Осеновлаг"
            },
            {
              value: "Реброво",
              label: "Реброво"
            },
            {
              value: "Редина",
              label: "Редина"
            },
            {
              value: "Свидня",
              label: "Свидня"
            },
            {
              value: "Своге",
              label: "Своге"
            },
            {
              value: "Томпсън",
              label: "Томпсън"
            },
            {
              value: "Церецел",
              label: "Церецел"
            },
            {
              value: "Церово",
              label: "Церово"
            },
            {
              value: "Ябланица",
              label: "Ябланица"
            }
          ]
        },
        {
          value: "Сливница",
          label: "Сливница",
          list: [
            {
              value: "Алдомировци",
              label: "Алдомировци"
            },
            {
              value: "Бахалин",
              label: "Бахалин"
            },
            {
              value: "Братушково",
              label: "Братушково"
            },
            {
              value: "Бърложница",
              label: "Бърложница"
            },
            {
              value: "Гургулят",
              label: "Гургулят"
            },
            {
              value: "Гълъбовци",
              label: "Гълъбовци"
            },
            {
              value: "Драготинци",
              label: "Драготинци"
            },
            {
              value: "Извор",
              label: "Извор"
            },
            {
              value: "Пищане",
              label: "Пищане"
            },
            {
              value: "Повалиръж",
              label: "Повалиръж"
            },
            {
              value: "Радуловци",
              label: "Радуловци"
            },
            {
              value: "Ракита",
              label: "Ракита"
            },
            {
              value: "Сливница",
              label: "Сливница"
            }
          ]
        },
        {
          value: "София",
          label: "София",
          list: [
            {
              value: "Район Банкя",
              label: "Район Банкя",
              list: [
                {
                  value: "Банкя",
                  label: "Банкя"
                },
                {
                  value: "Иваняне",
                  label: "Иваняне"
                },
                {
                  value: "Клисура",
                  label: "Клисура"
                }
              ]
            },
            {
              value: "Район Витоша",
              label: "Район Витоша",
              list: [
                {
                  value: "Владая",
                  label: "Владая"
                },
                {
                  value: "Мърчаево",
                  label: "Мърчаево"
                }
              ]
            },
            {
              value: "Район Връбница",
              label: "Район Връбница",
              list: [
                {
                  value: "Волуяк",
                  label: "Волуяк"
                }
              ]
            },
            {
              value: "Район Възраждане",
              label: "Район Възраждане",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
            {
              value: "Район Изгрев",
              label: "Район Изгрев",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
            {
              value: "Район Илинден",
              label: "Район Илинден",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
            {
              value: "Район Искър",
              label: "Район Искър",
              list: [
                {
                  value: "Бусманци",
                  label: "Бусманци"
                }
              ]
            },
            {
              value: "Район Красна поляна",
              label: "Район Красна поляна",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
            {
              value: "Район Красно село",
              label: "Район Красно село",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
            {
              value: "Район Кремиковци",
              label: "Район Кремиковци",
              list: [
                {
                  value: "Бухово",
                  label: "Бухово"
                },
                {
                  value: "Горни Богров",
                  label: "Горни Богров"
                },
                {
                  value: "Долни Богров",
                  label: "Долни Богров"
                },
                {
                  value: "Желява",
                  label: "Желява"
                },
                {
                  value: "Яна",
                  label: "Яна"
                }
              ]
            },
            {
              value: "Район Лозенец",
              label: "Район Лозенец",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
            {
              value: "Район Люлин",
              label: "Район Люлин",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
            {
              value: "Район Младост",
              label: "Район Младост",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
            {
              value: "Район Надежда",
              label: "Район Надежда",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
            {
              value: "Район Нови Искър",
              label: "Район Нови Искър",
              list: [
                {
                  value: "Балша",
                  label: "Балша"
                },
                {
                  value: "Войняговци",
                  label: "Войняговци"
                },
                {
                  value: "Доброславци",
                  label: "Доброславци"
                },
                {
                  value: "Житен",
                  label: "Житен"
                },
                {
                  value: "Кубратово",
                  label: "Кубратово"
                },
                {
                  value: "Кътина",
                  label: "Кътина"
                },
                {
                  value: "Локорско",
                  label: "Локорско"
                },
                {
                  value: "Мировяне",
                  label: "Мировяне"
                },
                {
                  value: "Мрамор",
                  label: "Мрамор"
                },
                {
                  value: "Негован",
                  label: "Негован"
                },
                {
                  value: "Нови Искър",
                  label: "Нови Искър"
                },
                {
                  value: "Подгумер",
                  label: "Подгумер"
                },
                {
                  value: "Световрачене",
                  label: "Световрачене"
                },
                {
                  value: "Чепинци",
                  label: "Чепинци"
                }
              ]
            },
            {
              value: "Район Оборище",
              label: "Район Оборище",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
            {
              value: "Район Овча Купел",
              label: "Район Овча Купел",
              list: [
                {
                  value: "Мало Бучино",
                  label: "Мало Бучино"
                }
              ]
            },
            {
              value: "Район Панчарево",
              label: "Район Панчарево",
              list: [
                {
                  value: "Бистрица",
                  label: "Бистрица"
                },
                {
                  value: "Герман",
                  label: "Герман"
                },
                {
                  value: "Долни Пасарел",
                  label: "Долни Пасарел"
                },
                {
                  value: "Железница",
                  label: "Железница"
                },
                {
                  value: "Казичене",
                  label: "Казичене"
                },
                {
                  value: "Кокаляне",
                  label: "Кокаляне"
                },
                {
                  value: "Кривина",
                  label: "Кривина"
                },
                {
                  value: "Лозен",
                  label: "Лозен"
                },
                {
                  value: "Панчарево",
                  label: "Панчарево"
                },
                {
                  value: "Плана",
                  label: "Плана"
                }
              ]
            },
            {
              value: "Район Подуяне",
              label: "Район Подуяне",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },            
            {
              value: "Район Сердика",
              label: "Район Сердика",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
            {
              value: "Район Слатина",
              label: "Район Слатина",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
            {
              value: "Район Средец",
              label: "Район Средец",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
            {
              value: "Район Студентски",
              label: "Район Студентски",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
            {
              value: "Район Триадица",
              label: "Район Триадица",
              list: [
                {
                  value: "София",
                  label: "София"
                }
              ]
            },
          ]
        },
        {
          value: "Чавдар",
          label: "Чавдар",
          list: [
            {
              value: "Чавдар",
              label: "Чавдар"
            }
          ]
        },
        {
          value: "Челопеч",
          label: "Челопеч",
          list: [
            {
              value: "Челопеч",
              label: "Челопеч"
            }
          ]
        }
      ]
    },
    {
      value: "Стара Загора",
      label: "Стара Загора",
      list: [
        {
          value: "Братя  Даскалови",
          label: "Братя  Даскалови",
          list: [
            {
              value: "Горно Белево",
              label: "Горно Белево"
            }
          ]
        },
        {
          value: "Братя Даскалови",
          label: "Братя Даскалови",
          list: [
            {
              value: "Братя Даскалови",
              label: "Братя Даскалови"
            },
            {
              value: "Верен",
              label: "Верен"
            },
            {
              value: "Голям дол",
              label: "Голям дол"
            },
            {
              value: "Горно ново село",
              label: "Горно ново село"
            },
            {
              value: "Гранит",
              label: "Гранит"
            },
            {
              value: "Долно ново село",
              label: "Долно ново село"
            },
            {
              value: "Колю Мариново",
              label: "Колю Мариново"
            },
            {
              value: "Малко Дряново",
              label: "Малко Дряново"
            },
            {
              value: "Малък дол",
              label: "Малък дол"
            },
            {
              value: "Марково",
              label: "Марково"
            },
            {
              value: "Медово",
              label: "Медово"
            },
            {
              value: "Мирово",
              label: "Мирово"
            },
            {
              value: "Найденово",
              label: "Найденово"
            },
            {
              value: "Опълченец",
              label: "Опълченец"
            },
            {
              value: "Оризово",
              label: "Оризово"
            },
            {
              value: "Партизанин",
              label: "Партизанин"
            },
            {
              value: "Плодовитово",
              label: "Плодовитово"
            },
            {
              value: "Православ",
              label: "Православ"
            },
            {
              value: "Славянин",
              label: "Славянин"
            },
            {
              value: "Съединение",
              label: "Съединение"
            },
            {
              value: "Сърневец",
              label: "Сърневец"
            },
            {
              value: "Черна гора",
              label: "Черна гора"
            }
          ]
        },
        {
          value: "Гурково",
          label: "Гурково",
          list: [
            {
              value: "Гурково",
              label: "Гурково"
            },
            {
              value: "Димовци",
              label: "Димовци"
            },
            {
              value: "Златирът",
              label: "Златирът"
            },
            {
              value: "Конаре",
              label: "Конаре"
            },
            {
              value: "Лява река",
              label: "Лява река"
            },
            {
              value: "Паничерево",
              label: "Паничерево"
            },
            {
              value: "Пчелиново",
              label: "Пчелиново"
            }
          ]
        },
        {
          value: "Гълъбово",
          label: "Гълъбово",
          list: [
            {
              value: "Априлово",
              label: "Априлово"
            },
            {
              value: "Великово",
              label: "Великово"
            },
            {
              value: "Главан",
              label: "Главан"
            },
            {
              value: "Гълъбово",
              label: "Гълъбово"
            },
            {
              value: "Искрица",
              label: "Искрица"
            },
            {
              value: "Медникарово",
              label: "Медникарово"
            },
            {
              value: "Мусачево",
              label: "Мусачево"
            },
            {
              value: "Мъдрец",
              label: "Мъдрец"
            },
            {
              value: "Обручище",
              label: "Обручище"
            },
            {
              value: "Помощник",
              label: "Помощник"
            },
            {
              value: "Разделна",
              label: "Разделна"
            }
          ]
        },
        {
          value: "Казанлък",
          label: "Казанлък",
          list: [
            {
              value: "Бузовград",
              label: "Бузовград"
            },
            {
              value: "Голямо Дряново",
              label: "Голямо Дряново"
            },
            {
              value: "Горно Изворово",
              label: "Горно Изворово"
            },
            {
              value: "Горно Черковище",
              label: "Горно Черковище"
            },
            {
              value: "Долно Изворово",
              label: "Долно Изворово"
            },
            {
              value: "Дунавци",
              label: "Дунавци"
            },
            {
              value: "Енина",
              label: "Енина"
            },
            {
              value: "Казанлък",
              label: "Казанлък"
            },
            {
              value: "Копринка",
              label: "Копринка"
            },
            {
              value: "Крън",
              label: "Крън"
            },
            {
              value: "Кънчево",
              label: "Кънчево"
            },
            {
              value: "Овощник",
              label: "Овощник"
            },
            {
              value: "Розово",
              label: "Розово"
            },
            {
              value: "Ръжена",
              label: "Ръжена"
            },
            {
              value: "Средногорово",
              label: "Средногорово"
            },
            {
              value: "Хаджи Димитрово",
              label: "Хаджи Димитрово"
            },
            {
              value: "Черганово",
              label: "Черганово"
            },
            {
              value: "Шейново",
              label: "Шейново"
            },
            {
              value: "Шипка",
              label: "Шипка"
            },
            {
              value: "Ясеново",
              label: "Ясеново"
            }
          ]
        },
        {
          value: "Мъглиж",
          label: "Мъглиж",
          list: [
            {
              value: "Борущица",
              label: "Борущица"
            },
            {
              value: "Брестова",
              label: "Брестова"
            },
            {
              value: "Бънзарето",
              label: "Бънзарето"
            },
            {
              value: "Ветрен",
              label: "Ветрен"
            },
            {
              value: "Дворище",
              label: "Дворище"
            },
            {
              value: "Дъбово",
              label: "Дъбово"
            },
            {
              value: "Държавен",
              label: "Държавен"
            },
            {
              value: "Жерговец",
              label: "Жерговец"
            },
            {
              value: "Жълтопоп",
              label: "Жълтопоп"
            },
            {
              value: "Зимница",
              label: "Зимница"
            },
            {
              value: "Мъглиж",
              label: "Мъглиж"
            },
            {
              value: "Радунци",
              label: "Радунци"
            },
            {
              value: "Радунци санаториум",
              label: "Радунци санаториум"
            },
            {
              value: "Селце",
              label: "Селце"
            },
            {
              value: "Сливито",
              label: "Сливито"
            },
            {
              value: "Тулово",
              label: "Тулово"
            },
            {
              value: "Шаново",
              label: "Шаново"
            },
            {
              value: "Юлиево",
              label: "Юлиево"
            },
            {
              value: "Яворовец",
              label: "Яворовец"
            },
            {
              value: "Ягода",
              label: "Ягода"
            }
          ]
        },
        {
          value: "Николаево",
          label: "Николаево",
          list: [
            {
              value: "Едрево",
              label: "Едрево"
            },
            {
              value: "Елхово",
              label: "Елхово"
            },
            {
              value: "Николаево",
              label: "Николаево"
            },
            {
              value: "Нова махала",
              label: "Нова махала"
            }
          ]
        },
        {
          value: "Опан",
          label: "Опан",
          list: [
            {
              value: "Бащино",
              label: "Бащино"
            },
            {
              value: "Бял извор",
              label: "Бял извор"
            },
            {
              value: "Бяло поле",
              label: "Бяло поле"
            },
            {
              value: "Васил Левски",
              label: "Васил Левски"
            },
            {
              value: "Венец",
              label: "Венец"
            },
            {
              value: "Княжевско",
              label: "Княжевско"
            },
            {
              value: "Кравино",
              label: "Кравино"
            },
            {
              value: "Опан",
              label: "Опан"
            },
            {
              value: "Пъстрен",
              label: "Пъстрен"
            },
            {
              value: "Средец",
              label: "Средец"
            },
            {
              value: "Столетово",
              label: "Столетово"
            },
            {
              value: "Тракия",
              label: "Тракия"
            },
            {
              value: "Ястребово",
              label: "Ястребово"
            }
          ]
        },
        {
          value: "Павел баня",
          label: "Павел баня",
          list: [
            {
              value: "Александрово",
              label: "Александрово"
            },
            {
              value: "Асен",
              label: "Асен"
            },
            {
              value: "Виден",
              label: "Виден"
            },
            {
              value: "Габарево",
              label: "Габарево"
            },
            {
              value: "Горно Сахране",
              label: "Горно Сахране"
            },
            {
              value: "Долно Сахране",
              label: "Долно Сахране"
            },
            {
              value: "Манолово",
              label: "Манолово"
            },
            {
              value: "Осетеново",
              label: "Осетеново"
            },
            {
              value: "Павел баня",
              label: "Павел баня"
            },
            {
              value: "Скобелево",
              label: "Скобелево"
            },
            {
              value: "Турия",
              label: "Турия"
            },
            {
              value: "Тъжа",
              label: "Тъжа"
            },
            {
              value: "Търничени",
              label: "Търничени"
            }
          ]
        },
        {
          value: "Раднево",
          label: "Раднево",
          list: [
            {
              value: "Бели бряг",
              label: "Бели бряг"
            },
            {
              value: "Боздуганово",
              label: "Боздуганово"
            },
            {
              value: "Българене",
              label: "Българене"
            },
            {
              value: "Гледачево",
              label: "Гледачево"
            },
            {
              value: "Даскал Атанасово",
              label: "Даскал Атанасово"
            },
            {
              value: "Диня",
              label: "Диня"
            },
            {
              value: "Землен",
              label: "Землен"
            },
            {
              value: "Знаменосец",
              label: "Знаменосец"
            },
            {
              value: "Ковач",
              label: "Ковач"
            },
            {
              value: "Ковачево",
              label: "Ковачево"
            },
            {
              value: "Коларово",
              label: "Коларово"
            },
            {
              value: "Константиновец",
              label: "Константиновец"
            },
            {
              value: "Любеново",
              label: "Любеново"
            },
            {
              value: "Маца",
              label: "Маца"
            },
            {
              value: "Овчарци",
              label: "Овчарци"
            },
            {
              value: "Полски Градец",
              label: "Полски Градец"
            },
            {
              value: "Раднево",
              label: "Раднево"
            },
            {
              value: "Рисиманово",
              label: "Рисиманово"
            },
            {
              value: "Свободен",
              label: "Свободен"
            },
            {
              value: "Сърнево",
              label: "Сърнево"
            },
            {
              value: "Тихомирово",
              label: "Тихомирово"
            },
            {
              value: "Тополяне",
              label: "Тополяне"
            },
            {
              value: "Трояново",
              label: "Трояново"
            },
            {
              value: "Трънково",
              label: "Трънково"
            }
          ]
        },
        {
          value: "Стара Загора",
          label: "Стара Загора",
          list: [
            {
              value: "Арнаутито",
              label: "Арнаутито"
            },
            {
              value: "Бенковски",
              label: "Бенковски"
            },
            {
              value: "Богомилово",
              label: "Богомилово"
            },
            {
              value: "Борилово",
              label: "Борилово"
            },
            {
              value: "Борово",
              label: "Борово"
            },
            {
              value: "Братя Кунчеви",
              label: "Братя Кунчеви"
            },
            {
              value: "Бъдеще",
              label: "Бъдеще"
            },
            {
              value: "Воденичарово",
              label: "Воденичарово"
            },
            {
              value: "Горно Ботево",
              label: "Горно Ботево"
            },
            {
              value: "Дълбоки",
              label: "Дълбоки"
            },
            {
              value: "Еленино",
              label: "Еленино"
            },
            {
              value: "Елхово",
              label: "Елхово"
            },
            {
              value: "Загоре",
              label: "Загоре"
            },
            {
              value: "Змейово",
              label: "Змейово"
            },
            {
              value: "Казанка",
              label: "Казанка"
            },
            {
              value: "Калитиново",
              label: "Калитиново"
            },
            {
              value: "Калояновец",
              label: "Калояновец"
            },
            {
              value: "Кирилово",
              label: "Кирилово"
            },
            {
              value: "Козаревец",
              label: "Козаревец"
            },
            {
              value: "Колена",
              label: "Колена"
            },
            {
              value: "Ловец",
              label: "Ловец"
            },
            {
              value: "Лозен",
              label: "Лозен"
            },
            {
              value: "Люляк",
              label: "Люляк"
            },
            {
              value: "Лясково",
              label: "Лясково"
            },
            {
              value: "Маджерито",
              label: "Маджерито"
            },
            {
              value: "Малка Верея",
              label: "Малка Верея"
            },
            {
              value: "Малко Кадиево",
              label: "Малко Кадиево"
            },
            {
              value: "Михайлово",
              label: "Михайлово"
            },
            {
              value: "Могила",
              label: "Могила"
            },
            {
              value: "Ново село",
              label: "Ново село"
            },
            {
              value: "Оряховица",
              label: "Оряховица"
            },
            {
              value: "Остра могила",
              label: "Остра могила"
            },
            {
              value: "Памукчии",
              label: "Памукчии"
            },
            {
              value: "Петрово",
              label: "Петрово"
            },
            {
              value: "Плоска могила",
              label: "Плоска могила"
            },
            {
              value: "Подслон",
              label: "Подслон"
            },
            {
              value: "Преславен",
              label: "Преславен"
            },
            {
              value: "Пряпорец",
              label: "Пряпорец"
            },
            {
              value: "Пшеничево",
              label: "Пшеничево"
            },
            {
              value: "Пъстрово",
              label: "Пъстрово"
            },
            {
              value: "Ракитница",
              label: "Ракитница"
            },
            {
              value: "Руманя",
              label: "Руманя"
            },
            {
              value: "Самуилово",
              label: "Самуилово"
            },
            {
              value: "Сладък кладенец",
              label: "Сладък кладенец"
            },
            {
              value: "Стара Загора",
              label: "Стара Загора"
            },
            {
              value: "Старозагорски бани",
              label: "Старозагорски бани"
            },
            {
              value: "Стрелец",
              label: "Стрелец"
            },
            {
              value: "Сулица",
              label: "Сулица"
            },
            {
              value: "Хан Аспарухово",
              label: "Хан Аспарухово"
            },
            {
              value: "Християново",
              label: "Християново"
            },
            {
              value: "Хрищени",
              label: "Хрищени"
            }
          ]
        },
        {
          value: "Чирпан",
          label: "Чирпан",
          list: [
            {
              value: "Винарово",
              label: "Винарово"
            },
            {
              value: "Воловарово",
              label: "Воловарово"
            },
            {
              value: "Гита",
              label: "Гита"
            },
            {
              value: "Димитриево",
              label: "Димитриево"
            },
            {
              value: "Държава",
              label: "Държава"
            },
            {
              value: "Зетьово",
              label: "Зетьово"
            },
            {
              value: "Златна ливада",
              label: "Златна ливада"
            },
            {
              value: "Изворово",
              label: "Изворово"
            },
            {
              value: "Малко Тръново",
              label: "Малко Тръново"
            },
            {
              value: "Могилово",
              label: "Могилово"
            },
            {
              value: "Осларка",
              label: "Осларка"
            },
            {
              value: "Рупките",
              label: "Рупките"
            },
            {
              value: "Свобода",
              label: "Свобода"
            },
            {
              value: "Спасово",
              label: "Спасово"
            },
            {
              value: "Средно градище",
              label: "Средно градище"
            },
            {
              value: "Стоян Заимово",
              label: "Стоян Заимово"
            },
            {
              value: "Целина",
              label: "Целина"
            },
            {
              value: "Ценово",
              label: "Ценово"
            },
            {
              value: "Чирпан",
              label: "Чирпан"
            },
            {
              value: "Яворово",
              label: "Яворово"
            },
            {
              value: "Яздач",
              label: "Яздач"
            }
          ]
        }
      ]
    },
    {
      value: "Търговище",
      label: "Търговище",
      list: [
        {
          value: "Антоново",
          label: "Антоново",
          list: [
            {
              value: "Антоново",
              label: "Антоново"
            },
            {
              value: "Банковец",
              label: "Банковец"
            },
            {
              value: "Богомолско",
              label: "Богомолско"
            },
            {
              value: "Букак",
              label: "Букак"
            },
            {
              value: "Великовци",
              label: "Великовци"
            },
            {
              value: "Вельово",
              label: "Вельово"
            },
            {
              value: "Глашатай",
              label: "Глашатай"
            },
            {
              value: "Голямо Доляне",
              label: "Голямо Доляне"
            },
            {
              value: "Горна Златица",
              label: "Горна Златица"
            },
            {
              value: "Греевци",
              label: "Греевци"
            },
            {
              value: "Девино",
              label: "Девино"
            },
            {
              value: "Длъжка поляна",
              label: "Длъжка поляна"
            },
            {
              value: "Добротица",
              label: "Добротица"
            },
            {
              value: "Долна Златица",
              label: "Долна Златица"
            },
            {
              value: "Дъбравица",
              label: "Дъбравица"
            },
            {
              value: "Изворово",
              label: "Изворово"
            },
            {
              value: "Калнище",
              label: "Калнище"
            },
            {
              value: "Капище",
              label: "Капище"
            },
            {
              value: "Китино",
              label: "Китино"
            },
            {
              value: "Коноп",
              label: "Коноп"
            },
            {
              value: "Крайполе",
              label: "Крайполе"
            },
            {
              value: "Крушолак",
              label: "Крушолак"
            },
            {
              value: "Къпинец",
              label: "Къпинец"
            },
            {
              value: "Кьосевци",
              label: "Кьосевци"
            },
            {
              value: "Любичево",
              label: "Любичево"
            },
            {
              value: "Малка Черковна",
              label: "Малка Черковна"
            },
            {
              value: "Малоградец",
              label: "Малоградец"
            },
            {
              value: "Манушевци",
              label: "Манушевци"
            },
            {
              value: "Мечово",
              label: "Мечово"
            },
            {
              value: "Милино",
              label: "Милино"
            },
            {
              value: "Моравица",
              label: "Моравица"
            },
            {
              value: "Моравка",
              label: "Моравка"
            },
            {
              value: "Орач",
              label: "Орач"
            },
            {
              value: "Пиринец",
              label: "Пиринец"
            },
            {
              value: "Поройно",
              label: "Поройно"
            },
            {
              value: "Присойна",
              label: "Присойна"
            },
            {
              value: "Пчелно",
              label: "Пчелно"
            },
            {
              value: "Равно село",
              label: "Равно село"
            },
            {
              value: "Разделци",
              label: "Разделци"
            },
            {
              value: "Свирчово",
              label: "Свирчово"
            },
            {
              value: "Свободица",
              label: "Свободица"
            },
            {
              value: "Семерци",
              label: "Семерци"
            },
            {
              value: "Слънчовец",
              label: "Слънчовец"
            },
            {
              value: "Стара речка",
              label: "Стара речка"
            },
            {
              value: "Старчище",
              label: "Старчище"
            },
            {
              value: "Стеврек",
              label: "Стеврек"
            },
            {
              value: "Стойново",
              label: "Стойново"
            },
            {
              value: "Стройновци",
              label: "Стройновци"
            },
            {
              value: "Таймище",
              label: "Таймище"
            },
            {
              value: "Тиховец",
              label: "Тиховец"
            },
            {
              value: "Трескавец",
              label: "Трескавец"
            },
            {
              value: "Халваджийско",
              label: "Халваджийско"
            },
            {
              value: "Чеканци",
              label: "Чеканци"
            },
            {
              value: "Черна вода",
              label: "Черна вода"
            },
            {
              value: "Черни бряг",
              label: "Черни бряг"
            },
            {
              value: "Шишковица",
              label: "Шишковица"
            },
            {
              value: "Язовец",
              label: "Язовец"
            },
            {
              value: "Яребично",
              label: "Яребично"
            },
            {
              value: "Ястребино",
              label: "Ястребино"
            }
          ]
        },
        {
          value: "Омуртаг",
          label: "Омуртаг",
          list: [
            {
              value: "Беломорци",
              label: "Беломорци"
            },
            {
              value: "Българаново",
              label: "Българаново"
            },
            {
              value: "Великденче",
              label: "Великденче"
            },
            {
              value: "Величка",
              label: "Величка"
            },
            {
              value: "Веренци",
              label: "Веренци"
            },
            {
              value: "Веселец",
              label: "Веселец"
            },
            {
              value: "Висок",
              label: "Висок"
            },
            {
              value: "Врани кон",
              label: "Врани кон"
            },
            {
              value: "Голямо църквище",
              label: "Голямо църквище"
            },
            {
              value: "Горна Хубавка",
              label: "Горна Хубавка"
            },
            {
              value: "Горно Козарево",
              label: "Горно Козарево"
            },
            {
              value: "Горно Новково",
              label: "Горно Новково"
            },
            {
              value: "Горско село",
              label: "Горско село"
            },
            {
              value: "Долна Хубавка",
              label: "Долна Хубавка"
            },
            {
              value: "Долно Козарево",
              label: "Долно Козарево"
            },
            {
              value: "Долно Новково",
              label: "Долно Новково"
            },
            {
              value: "Звездица",
              label: "Звездица"
            },
            {
              value: "Зелена морава",
              label: "Зелена морава"
            },
            {
              value: "Змейно",
              label: "Змейно"
            },
            {
              value: "Илийно",
              label: "Илийно"
            },
            {
              value: "Камбурово",
              label: "Камбурово"
            },
            {
              value: "Кестеново",
              label: "Кестеново"
            },
            {
              value: "Козма Презвитер",
              label: "Козма Презвитер"
            },
            {
              value: "Красноселци",
              label: "Красноселци"
            },
            {
              value: "Могилец",
              label: "Могилец"
            },
            {
              value: "Обител",
              label: "Обител"
            },
            {
              value: "Омуртаг",
              label: "Омуртаг"
            },
            {
              value: "Панайот Хитово",
              label: "Панайот Хитово"
            },
            {
              value: "Паничино",
              label: "Паничино"
            },
            {
              value: "Петрино",
              label: "Петрино"
            },
            {
              value: "Плъстина",
              label: "Плъстина"
            },
            {
              value: "Птичево",
              label: "Птичево"
            },
            {
              value: "Пъдарино",
              label: "Пъдарино"
            },
            {
              value: "Първан",
              label: "Първан"
            },
            {
              value: "Росица",
              label: "Росица"
            },
            {
              value: "Рътлина",
              label: "Рътлина"
            },
            {
              value: "Станец",
              label: "Станец"
            },
            {
              value: "Тъпчилещово",
              label: "Тъпчилещово"
            },
            {
              value: "Угледно",
              label: "Угледно"
            },
            {
              value: "Царевци",
              label: "Царевци"
            },
            {
              value: "Церовище",
              label: "Церовище"
            },
            {
              value: "Чернокапци",
              label: "Чернокапци"
            }
          ]
        },
        {
          value: "Опака",
          label: "Опака",
          list: [
            {
              value: "Голямо градище",
              label: "Голямо градище"
            },
            {
              value: "Горско Абланово",
              label: "Горско Абланово"
            },
            {
              value: "Гърчиново",
              label: "Гърчиново"
            },
            {
              value: "Крепча",
              label: "Крепча"
            },
            {
              value: "Люблен",
              label: "Люблен"
            },
            {
              value: "Опака",
              label: "Опака"
            }
          ]
        },
        {
          value: "Попово",
          label: "Попово",
          list: [
            {
              value: "Априлово",
              label: "Априлово"
            },
            {
              value: "Баба Тонка",
              label: "Баба Тонка"
            },
            {
              value: "Берковски",
              label: "Берковски"
            },
            {
              value: "Бракница",
              label: "Бракница"
            },
            {
              value: "Водица",
              label: "Водица"
            },
            {
              value: "Гагово",
              label: "Гагово"
            },
            {
              value: "Глогинка",
              label: "Глогинка"
            },
            {
              value: "Горица",
              label: "Горица"
            },
            {
              value: "Долец",
              label: "Долец"
            },
            {
              value: "Долна Кабда",
              label: "Долна Кабда"
            },
            {
              value: "Дриново",
              label: "Дриново"
            },
            {
              value: "Еленово",
              label: "Еленово"
            },
            {
              value: "Заветно",
              label: "Заветно"
            },
            {
              value: "Зараево",
              label: "Зараево"
            },
            {
              value: "Захари Стояново",
              label: "Захари Стояново"
            },
            {
              value: "Звезда",
              label: "Звезда"
            },
            {
              value: "Иванча",
              label: "Иванча"
            },
            {
              value: "Кардам",
              label: "Кардам"
            },
            {
              value: "Ковачевец",
              label: "Ковачевец"
            },
            {
              value: "Козица",
              label: "Козица"
            },
            {
              value: "Конак",
              label: "Конак"
            },
            {
              value: "Ломци",
              label: "Ломци"
            },
            {
              value: "Манастирица",
              label: "Манастирица"
            },
            {
              value: "Марчино",
              label: "Марчино"
            },
            {
              value: "Медовина",
              label: "Медовина"
            },
            {
              value: "Осиково",
              label: "Осиково"
            },
            {
              value: "Паламарца",
              label: "Паламарца"
            },
            {
              value: "Помощица",
              label: "Помощица"
            },
            {
              value: "Попово",
              label: "Попово"
            },
            {
              value: "Посабина",
              label: "Посабина"
            },
            {
              value: "Садина",
              label: "Садина"
            },
            {
              value: "Светлен",
              label: "Светлен"
            },
            {
              value: "Славяново",
              label: "Славяново"
            },
            {
              value: "Тръстика",
              label: "Тръстика"
            },
            {
              value: "Цар Асен",
              label: "Цар Асен"
            }
          ]
        },
        {
          value: "Търговище",
          label: "Търговище",
          list: [
            {
              value: "Алваново",
              label: "Алваново"
            },
            {
              value: "Александрово",
              label: "Александрово"
            },
            {
              value: "Баячево",
              label: "Баячево"
            },
            {
              value: "Бистра",
              label: "Бистра"
            },
            {
              value: "Божурка",
              label: "Божурка"
            },
            {
              value: "Братово",
              label: "Братово"
            },
            {
              value: "Буйново",
              label: "Буйново"
            },
            {
              value: "Буховци",
              label: "Буховци"
            },
            {
              value: "Вардун",
              label: "Вардун"
            },
            {
              value: "Васил Левски",
              label: "Васил Левски"
            },
            {
              value: "Гара Търговище",
              label: "Гара Търговище"
            },
            {
              value: "Голямо ново",
              label: "Голямо ново"
            },
            {
              value: "Голямо Соколово",
              label: "Голямо Соколово"
            },
            {
              value: "Горна Кабда",
              label: "Горна Кабда"
            },
            {
              value: "Давидово",
              label: "Давидово"
            },
            {
              value: "Драгановец",
              label: "Драгановец"
            },
            {
              value: "Дралфа",
              label: "Дралфа"
            },
            {
              value: "Дългач",
              label: "Дългач"
            },
            {
              value: "Здравец",
              label: "Здравец"
            },
            {
              value: "Копрец",
              label: "Копрец"
            },
            {
              value: "Кошничари",
              label: "Кошничари"
            },
            {
              value: "Кралево",
              label: "Кралево"
            },
            {
              value: "Кръшно",
              label: "Кръшно"
            },
            {
              value: "Лиляк",
              label: "Лиляк"
            },
            {
              value: "Ловец",
              label: "Ловец"
            },
            {
              value: "Макариополско",
              label: "Макариополско"
            },
            {
              value: "Маково",
              label: "Маково"
            },
            {
              value: "Миладиновци",
              label: "Миладиновци"
            },
            {
              value: "Мировец",
              label: "Мировец"
            },
            {
              value: "Момино",
              label: "Момино"
            },
            {
              value: "Надарево",
              label: "Надарево"
            },
            {
              value: "Овчарово",
              label: "Овчарово"
            },
            {
              value: "Осен",
              label: "Осен"
            },
            {
              value: "Острец",
              label: "Острец"
            },
            {
              value: "Пайдушко",
              label: "Пайдушко"
            },
            {
              value: "Певец",
              label: "Певец"
            },
            {
              value: "Подгорица",
              label: "Подгорица"
            },
            {
              value: "Преселец",
              label: "Преселец"
            },
            {
              value: "Пресиян",
              label: "Пресиян"
            },
            {
              value: "Пресяк",
              label: "Пресяк"
            },
            {
              value: "Пробуда",
              label: "Пробуда"
            },
            {
              value: "Пролаз",
              label: "Пролаз"
            },
            {
              value: "Разбойна",
              label: "Разбойна"
            },
            {
              value: "Ралица",
              label: "Ралица"
            },
            {
              value: "Росина",
              label: "Росина"
            },
            {
              value: "Руец",
              label: "Руец"
            },
            {
              value: "Стража",
              label: "Стража"
            },
            {
              value: "Съединение",
              label: "Съединение"
            },
            {
              value: "Твърдинци",
              label: "Твърдинци"
            },
            {
              value: "Търговище",
              label: "Търговище"
            },
            {
              value: "Търновца",
              label: "Търновца"
            },
            {
              value: "Цветница",
              label: "Цветница"
            },
            {
              value: "Черковна",
              label: "Черковна"
            }
          ]
        }
      ]
    },
    {
      value: "Хасково",
      label: "Хасково",
      list: [
        {
          value: "Димитровград",
          label: "Димитровград",
          list: [
            {
              value: "Бодрово",
              label: "Бодрово"
            },
            {
              value: "Брод",
              label: "Брод"
            },
            {
              value: "Бряст",
              label: "Бряст"
            },
            {
              value: "Великан",
              label: "Великан"
            },
            {
              value: "Воден",
              label: "Воден"
            },
            {
              value: "Върбица",
              label: "Върбица"
            },
            {
              value: "Голямо Асеново",
              label: "Голямо Асеново"
            },
            {
              value: "Горски извор",
              label: "Горски извор"
            },
            {
              value: "Димитровград",
              label: "Димитровград"
            },
            {
              value: "Длъгнево",
              label: "Длъгнево"
            },
            {
              value: "Добрич",
              label: "Добрич"
            },
            {
              value: "Долно Белево",
              label: "Долно Белево"
            },
            {
              value: "Здравец",
              label: "Здравец"
            },
            {
              value: "Златополе",
              label: "Златополе"
            },
            {
              value: "Каснаково",
              label: "Каснаково"
            },
            {
              value: "Крепост",
              label: "Крепост"
            },
            {
              value: "Крум",
              label: "Крум"
            },
            {
              value: "Малко Асеново",
              label: "Малко Асеново"
            },
            {
              value: "Меричлери",
              label: "Меричлери"
            },
            {
              value: "Радиево",
              label: "Радиево"
            },
            {
              value: "Райново",
              label: "Райново"
            },
            {
              value: "Светлина",
              label: "Светлина"
            },
            {
              value: "Скобелево",
              label: "Скобелево"
            },
            {
              value: "Сталево",
              label: "Сталево"
            },
            {
              value: "Странско",
              label: "Странско"
            },
            {
              value: "Черногорово",
              label: "Черногорово"
            },
            {
              value: "Ябълково",
              label: "Ябълково"
            }
          ]
        },
        {
          value: "Ивайловград",
          label: "Ивайловград",
          list: [
            {
              value: "Бели дол",
              label: "Бели дол"
            },
            {
              value: "Белополци",
              label: "Белополци"
            },
            {
              value: "Белополяне",
              label: "Белополяне"
            },
            {
              value: "Ботурче",
              label: "Ботурче"
            },
            {
              value: "Брусино",
              label: "Брусино"
            },
            {
              value: "Бубино",
              label: "Бубино"
            },
            {
              value: "Бялградец",
              label: "Бялградец"
            },
            {
              value: "Ветрушка",
              label: "Ветрушка"
            },
            {
              value: "Вис",
              label: "Вис"
            },
            {
              value: "Глумово",
              label: "Глумово"
            },
            {
              value: "Гнездаре",
              label: "Гнездаре"
            },
            {
              value: "Горно Луково",
              label: "Горно Луково"
            },
            {
              value: "Горноселци",
              label: "Горноселци"
            },
            {
              value: "Горско",
              label: "Горско"
            },
            {
              value: "Гугутка",
              label: "Гугутка"
            },
            {
              value: "Долно Луково",
              label: "Долно Луково"
            },
            {
              value: "Долноселци",
              label: "Долноселци"
            },
            {
              value: "Драбишна",
              label: "Драбишна"
            },
            {
              value: "Железари",
              label: "Железари"
            },
            {
              value: "Железино",
              label: "Железино"
            },
            {
              value: "Ивайловград",
              label: "Ивайловград"
            },
            {
              value: "Казак",
              label: "Казак"
            },
            {
              value: "Камилски дол",
              label: "Камилски дол"
            },
            {
              value: "Карловско",
              label: "Карловско"
            },
            {
              value: "Кобилино",
              label: "Кобилино"
            },
            {
              value: "Кондово",
              label: "Кондово"
            },
            {
              value: "Конници",
              label: "Конници"
            },
            {
              value: "Костилково",
              label: "Костилково"
            },
            {
              value: "Ламбух",
              label: "Ламбух"
            },
            {
              value: "Ленско",
              label: "Ленско"
            },
            {
              value: "Мандрица",
              label: "Мандрица"
            },
            {
              value: "Меден бук",
              label: "Меден бук"
            },
            {
              value: "Нова ливада",
              label: "Нова ливада"
            },
            {
              value: "Одринци",
              label: "Одринци"
            },
            {
              value: "Орешино",
              label: "Орешино"
            },
            {
              value: "Пашкул",
              label: "Пашкул"
            },
            {
              value: "Планинец",
              label: "Планинец"
            },
            {
              value: "Плевун",
              label: "Плевун"
            },
            {
              value: "Покрован",
              label: "Покрован"
            },
            {
              value: "Попско",
              label: "Попско"
            },
            {
              value: "Пъстроок",
              label: "Пъстроок"
            },
            {
              value: "Розино",
              label: "Розино"
            },
            {
              value: "Сборино",
              label: "Сборино"
            },
            {
              value: "Свирачи",
              label: "Свирачи"
            },
            {
              value: "Сив кладенец",
              label: "Сив кладенец"
            },
            {
              value: "Славеево",
              label: "Славеево"
            },
            {
              value: "Соколенци",
              label: "Соколенци"
            },
            {
              value: "Хухла",
              label: "Хухла"
            },
            {
              value: "Черни рид",
              label: "Черни рид"
            },
            {
              value: "Черничино",
              label: "Черничино"
            },
            {
              value: "Чучулига",
              label: "Чучулига"
            }
          ]
        },
        {
          value: "Любимец",
          label: "Любимец",
          list: [
            {
              value: "Белица",
              label: "Белица"
            },
            {
              value: "Васково",
              label: "Васково"
            },
            {
              value: "Вълче поле",
              label: "Вълче поле"
            },
            {
              value: "Георги Добрево",
              label: "Георги Добрево"
            },
            {
              value: "Дъбовец",
              label: "Дъбовец"
            },
            {
              value: "Йерусалимово",
              label: "Йерусалимово"
            },
            {
              value: "Лозен",
              label: "Лозен"
            },
            {
              value: "Любимец",
              label: "Любимец"
            },
            {
              value: "Малко градище",
              label: "Малко градище"
            },
            {
              value: "Оряхово",
              label: "Оряхово"
            }
          ]
        },
        {
          value: "Маджарово",
          label: "Маджарово",
          list: [
            {
              value: "Бориславци",
              label: "Бориславци"
            },
            {
              value: "Брусевци",
              label: "Брусевци"
            },
            {
              value: "Габерово",
              label: "Габерово"
            },
            {
              value: "Голяма долина",
              label: "Голяма долина"
            },
            {
              value: "Горни Главанак",
              label: "Горни Главанак"
            },
            {
              value: "Горно поле",
              label: "Горно поле"
            },
            {
              value: "Долни Главанак",
              label: "Долни Главанак"
            },
            {
              value: "Долно Съдиево",
              label: "Долно Съдиево"
            },
            {
              value: "Ефрем",
              label: "Ефрем"
            },
            {
              value: "Златоустово",
              label: "Златоустово"
            },
            {
              value: "Маджарово",
              label: "Маджарово"
            },
            {
              value: "Малки Воден",
              label: "Малки Воден"
            },
            {
              value: "Малко Брягово",
              label: "Малко Брягово"
            },
            {
              value: "Малко Попово",
              label: "Малко Попово"
            },
            {
              value: "Румелия",
              label: "Румелия"
            },
            {
              value: "Ръженово",
              label: "Ръженово"
            },
            {
              value: "Селска поляна",
              label: "Селска поляна"
            },
            {
              value: "Сеноклас",
              label: "Сеноклас"
            },
            {
              value: "Тополово",
              label: "Тополово"
            }
          ]
        },
        {
          value: "Минерални бани",
          label: "Минерални бани",
          list: [
            {
              value: "Ангел войвода",
              label: "Ангел войвода"
            },
            {
              value: "Боян Ботево",
              label: "Боян Ботево"
            },
            {
              value: "Брястово",
              label: "Брястово"
            },
            {
              value: "Винево",
              label: "Винево"
            },
            {
              value: "Караманци",
              label: "Караманци"
            },
            {
              value: "Колец",
              label: "Колец"
            },
            {
              value: "Минерални бани",
              label: "Минерални бани"
            },
            {
              value: "Сираково",
              label: "Сираково"
            },
            {
              value: "Спахиево",
              label: "Спахиево"
            },
            {
              value: "Сусам",
              label: "Сусам"
            },
            {
              value: "Сърница",
              label: "Сърница"
            },
            {
              value: "Татарево",
              label: "Татарево"
            }
          ]
        },
        {
          value: "Свиленград",
          label: "Свиленград",
          list: [
            {
              value: "Варник",
              label: "Варник"
            },
            {
              value: "Гара Свиленград",
              label: "Гара Свиленград"
            },
            {
              value: "Генералово",
              label: "Генералово"
            },
            {
              value: "Дервишка могила",
              label: "Дервишка могила"
            },
            {
              value: "Димитровче",
              label: "Димитровче"
            },
            {
              value: "Капитан Андреево",
              label: "Капитан Андреево"
            },
            {
              value: "Костур",
              label: "Костур"
            },
            {
              value: "Левка",
              label: "Левка"
            },
            {
              value: "Лисово",
              label: "Лисово"
            },
            {
              value: "Маточина",
              label: "Маточина"
            },
            {
              value: "Мезек",
              label: "Мезек"
            },
            {
              value: "Михалич",
              label: "Михалич"
            },
            {
              value: "Младиново",
              label: "Младиново"
            },
            {
              value: "Момково",
              label: "Момково"
            },
            {
              value: "Мустрак",
              label: "Мустрак"
            },
            {
              value: "Пашово",
              label: "Пашово"
            },
            {
              value: "Пъстрогор",
              label: "Пъстрогор"
            },
            {
              value: "Равна гора",
              label: "Равна гора"
            },
            {
              value: "Райкова могила",
              label: "Райкова могила"
            },
            {
              value: "Свиленград",
              label: "Свиленград"
            },
            {
              value: "Сива река",
              label: "Сива река"
            },
            {
              value: "Сладун",
              label: "Сладун"
            },
            {
              value: "Студена",
              label: "Студена"
            },
            {
              value: "Чернодъб",
              label: "Чернодъб"
            },
            {
              value: "Щит",
              label: "Щит"
            }
          ]
        },
        {
          value: "Симеоновград",
          label: "Симеоновград",
          list: [
            {
              value: "Гара Симеоновград",
              label: "Гара Симеоновград"
            },
            {
              value: "Дряново",
              label: "Дряново"
            },
            {
              value: "Калугерово",
              label: "Калугерово"
            },
            {
              value: "Константиново",
              label: "Константиново"
            },
            {
              value: "Навъсен",
              label: "Навъсен"
            },
            {
              value: "Пясъчево",
              label: "Пясъчево"
            },
            {
              value: "Свирково",
              label: "Свирково"
            },
            {
              value: "Симеоновград",
              label: "Симеоновград"
            },
            {
              value: "Троян",
              label: "Троян"
            },
            {
              value: "Тянево",
              label: "Тянево"
            }
          ]
        },
        {
          value: "Стамболово",
          label: "Стамболово",
          list: [
            {
              value: "Балкан",
              label: "Балкан"
            },
            {
              value: "Бял кладенец",
              label: "Бял кладенец"
            },
            {
              value: "Воденци",
              label: "Воденци"
            },
            {
              value: "Войводенец",
              label: "Войводенец"
            },
            {
              value: "Гледка",
              label: "Гледка"
            },
            {
              value: "Голобрадово",
              label: "Голобрадово"
            },
            {
              value: "Голям извор",
              label: "Голям извор"
            },
            {
              value: "Долно Ботево",
              label: "Долно Ботево"
            },
            {
              value: "Долно поле",
              label: "Долно поле"
            },
            {
              value: "Долно Черковище",
              label: "Долно Черковище"
            },
            {
              value: "Жълти бряг",
              label: "Жълти бряг"
            },
            {
              value: "Зимовина",
              label: "Зимовина"
            },
            {
              value: "Кладенец",
              label: "Кладенец"
            },
            {
              value: "Кралево",
              label: "Кралево"
            },
            {
              value: "Лясковец",
              label: "Лясковец"
            },
            {
              value: "Маджари",
              label: "Маджари"
            },
            {
              value: "Малък извор",
              label: "Малък извор"
            },
            {
              value: "Поповец",
              label: "Поповец"
            },
            {
              value: "Пчелари",
              label: "Пчелари"
            },
            {
              value: "Пътниково",
              label: "Пътниково"
            },
            {
              value: "Рабово",
              label: "Рабово"
            },
            {
              value: "Светослав",
              label: "Светослав"
            },
            {
              value: "Силен",
              label: "Силен"
            },
            {
              value: "Стамболово",
              label: "Стамболово"
            },
            {
              value: "Тънково",
              label: "Тънково"
            },
            {
              value: "Царева поляна",
              label: "Царева поляна"
            }
          ]
        },
        {
          value: "Тополовград",
          label: "Тополовград",
          list: [
            {
              value: "Българска поляна",
              label: "Българска поляна"
            },
            {
              value: "Владимирово",
              label: "Владимирово"
            },
            {
              value: "Доброселец",
              label: "Доброселец"
            },
            {
              value: "Каменна река",
              label: "Каменна река"
            },
            {
              value: "Капитан Петко войвода",
              label: "Капитан Петко войвода"
            },
            {
              value: "Княжево",
              label: "Княжево"
            },
            {
              value: "Мрамор",
              label: "Мрамор"
            },
            {
              value: "Орешник",
              label: "Орешник"
            },
            {
              value: "Орлов дол",
              label: "Орлов дол"
            },
            {
              value: "Планиново",
              label: "Планиново"
            },
            {
              value: "Присадец",
              label: "Присадец"
            },
            {
              value: "Радовец",
              label: "Радовец"
            },
            {
              value: "Сакарци",
              label: "Сакарци"
            },
            {
              value: "Светлина",
              label: "Светлина"
            },
            {
              value: "Синапово",
              label: "Синапово"
            },
            {
              value: "Срем",
              label: "Срем"
            },
            {
              value: "Тополовград",
              label: "Тополовград"
            },
            {
              value: "Устрем",
              label: "Устрем"
            },
            {
              value: "Филипово",
              label: "Филипово"
            },
            {
              value: "Хлябово",
              label: "Хлябово"
            },
            {
              value: "Чукарово",
              label: "Чукарово"
            }
          ]
        },
        {
          value: "Харманли",
          label: "Харманли",
          list: [
            {
              value: "Бисер",
              label: "Бисер"
            },
            {
              value: "Богомил",
              label: "Богомил"
            },
            {
              value: "Болярски извор",
              label: "Болярски извор"
            },
            {
              value: "Браница",
              label: "Браница"
            },
            {
              value: "Българин",
              label: "Българин"
            },
            {
              value: "Върбово",
              label: "Върбово"
            },
            {
              value: "Доситеево",
              label: "Доситеево"
            },
            {
              value: "Дрипчево",
              label: "Дрипчево"
            },
            {
              value: "Иваново",
              label: "Иваново"
            },
            {
              value: "Изворово",
              label: "Изворово"
            },
            {
              value: "Коларово",
              label: "Коларово"
            },
            {
              value: "Лешниково",
              label: "Лешниково"
            },
            {
              value: "Надежден",
              label: "Надежден"
            },
            {
              value: "Овчарово",
              label: "Овчарово"
            },
            {
              value: "Орешец",
              label: "Орешец"
            },
            {
              value: "Остър камък",
              label: "Остър камък"
            },
            {
              value: "Поляново",
              label: "Поляново"
            },
            {
              value: "Преславец",
              label: "Преславец"
            },
            {
              value: "Рогозиново",
              label: "Рогозиново"
            },
            {
              value: "Славяново",
              label: "Славяново"
            },
            {
              value: "Смирненци",
              label: "Смирненци"
            },
            {
              value: "Харманли",
              label: "Харманли"
            },
            {
              value: "Черепово",
              label: "Черепово"
            },
            {
              value: "Черна могила",
              label: "Черна могила"
            },
            {
              value: "Шишманово",
              label: "Шишманово"
            }
          ]
        },
        {
          value: "Хасково",
          label: "Хасково",
          list: [
            {
              value: "Александрово",
              label: "Александрово"
            },
            {
              value: "Брягово",
              label: "Брягово"
            },
            {
              value: "Войводово",
              label: "Войводово"
            },
            {
              value: "Въгларово",
              label: "Въгларово"
            },
            {
              value: "Гарваново",
              label: "Гарваново"
            },
            {
              value: "Големанци",
              label: "Големанци"
            },
            {
              value: "Горно Войводино",
              label: "Горно Войводино"
            },
            {
              value: "Гълъбец",
              label: "Гълъбец"
            },
            {
              value: "Динево",
              label: "Динево"
            },
            {
              value: "Долно Войводино",
              label: "Долно Войводино"
            },
            {
              value: "Долно Големанци",
              label: "Долно Големанци"
            },
            {
              value: "Елена",
              label: "Елена"
            },
            {
              value: "Зорница",
              label: "Зорница"
            },
            {
              value: "Клокотница",
              label: "Клокотница"
            },
            {
              value: "Книжовник",
              label: "Книжовник"
            },
            {
              value: "Козлец",
              label: "Козлец"
            },
            {
              value: "Конуш",
              label: "Конуш"
            },
            {
              value: "Корен",
              label: "Корен"
            },
            {
              value: "Криво поле",
              label: "Криво поле"
            },
            {
              value: "Любеново",
              label: "Любеново"
            },
            {
              value: "Малево",
              label: "Малево"
            },
            {
              value: "Манастир",
              label: "Манастир"
            },
            {
              value: "Мандра",
              label: "Мандра"
            },
            {
              value: "Маслиново",
              label: "Маслиново"
            },
            {
              value: "Момино",
              label: "Момино"
            },
            {
              value: "Николово",
              label: "Николово"
            },
            {
              value: "Нова Надежда",
              label: "Нова Надежда"
            },
            {
              value: "Орлово",
              label: "Орлово"
            },
            {
              value: "Подкрепа",
              label: "Подкрепа"
            },
            {
              value: "Родопи",
              label: "Родопи"
            },
            {
              value: "Стамболийски",
              label: "Стамболийски"
            },
            {
              value: "Стойково",
              label: "Стойково"
            },
            {
              value: "Текето",
              label: "Текето"
            },
            {
              value: "Тракиец",
              label: "Тракиец"
            },
            {
              value: "Узунджово",
              label: "Узунджово"
            },
            {
              value: "Хасково",
              label: "Хасково"
            },
            {
              value: "Широка поляна",
              label: "Широка поляна"
            }
          ]
        }
      ]
    },
    {
      value: "Шумен",
      label: "Шумен",
      list: [
        {
          value: "Велики Преслав",
          label: "Велики Преслав",
          list: [
            {
              value: "Велики Преслав",
              label: "Велики Преслав"
            },
            {
              value: "Драгоево",
              label: "Драгоево"
            },
            {
              value: "Златар",
              label: "Златар"
            },
            {
              value: "Имренчево",
              label: "Имренчево"
            },
            {
              value: "Кочово",
              label: "Кочово"
            },
            {
              value: "Миланово",
              label: "Миланово"
            },
            {
              value: "Мокреш",
              label: "Мокреш"
            },
            {
              value: "Мостич",
              label: "Мостич"
            },
            {
              value: "Осмар",
              label: "Осмар"
            },
            {
              value: "Суха река",
              label: "Суха река"
            },
            {
              value: "Троица",
              label: "Троица"
            },
            {
              value: "Хан Крум",
              label: "Хан Крум"
            }
          ]
        },
        {
          value: "Венец",
          label: "Венец",
          list: [
            {
              value: "Борци",
              label: "Борци"
            },
            {
              value: "Боян",
              label: "Боян"
            },
            {
              value: "Буйновица",
              label: "Буйновица"
            },
            {
              value: "Венец",
              label: "Венец"
            },
            {
              value: "Габрица",
              label: "Габрица"
            },
            {
              value: "Денница",
              label: "Денница"
            },
            {
              value: "Дренци",
              label: "Дренци"
            },
            {
              value: "Изгрев",
              label: "Изгрев"
            },
            {
              value: "Капитан Петко",
              label: "Капитан Петко"
            },
            {
              value: "Осеновец",
              label: "Осеновец"
            },
            {
              value: "Страхилица",
              label: "Страхилица"
            },
            {
              value: "Черноглавци",
              label: "Черноглавци"
            },
            {
              value: "Ясенково",
              label: "Ясенково"
            }
          ]
        },
        {
          value: "Върбица",
          label: "Върбица",
          list: [
            {
              value: "Божурово",
              label: "Божурово"
            },
            {
              value: "Бяла река",
              label: "Бяла река"
            },
            {
              value: "Върбица",
              label: "Върбица"
            },
            {
              value: "Иваново",
              label: "Иваново"
            },
            {
              value: "Конево",
              label: "Конево"
            },
            {
              value: "Крайгорци",
              label: "Крайгорци"
            },
            {
              value: "Кьолмен",
              label: "Кьолмен"
            },
            {
              value: "Ловец",
              label: "Ловец"
            },
            {
              value: "Маломир",
              label: "Маломир"
            },
            {
              value: "Менгишево",
              label: "Менгишево"
            },
            {
              value: "Методиево",
              label: "Методиево"
            },
            {
              value: "Нова бяла река",
              label: "Нова бяла река"
            },
            {
              value: "Станянци",
              label: "Станянци"
            },
            {
              value: "Сушина",
              label: "Сушина"
            },
            {
              value: "Тушовица",
              label: "Тушовица"
            },
            {
              value: "Чернооково",
              label: "Чернооково"
            }
          ]
        },
        {
          value: "Каолиново",
          label: "Каолиново",
          list: [
            {
              value: "Браничево",
              label: "Браничево"
            },
            {
              value: "Гусла",
              label: "Гусла"
            },
            {
              value: "Дойранци",
              label: "Дойранци"
            },
            {
              value: "Долина",
              label: "Долина"
            },
            {
              value: "Загориче",
              label: "Загориче"
            },
            {
              value: "Каолиново",
              label: "Каолиново"
            },
            {
              value: "Климент",
              label: "Климент"
            },
            {
              value: "Лиси връх",
              label: "Лиси връх"
            },
            {
              value: "Лятно",
              label: "Лятно"
            },
            {
              value: "Наум",
              label: "Наум"
            },
            {
              value: "Омарчево",
              label: "Омарчево"
            },
            {
              value: "Пристое",
              label: "Пристое"
            },
            {
              value: "Сини вир",
              label: "Сини вир"
            },
            {
              value: "Средковец",
              label: "Средковец"
            },
            {
              value: "Тодор Икономово",
              label: "Тодор Икономово"
            },
            {
              value: "Тъкач",
              label: "Тъкач"
            }
          ]
        },
        {
          value: "Каспичан",
          label: "Каспичан",
          list: [
            {
              value: "Върбяне",
              label: "Върбяне"
            },
            {
              value: "Златна нива",
              label: "Златна нива"
            },
            {
              value: "Каспичан",
              label: "Каспичан"
            },
            {
              value: "Каспичан",
              label: "Каспичан"
            },
            {
              value: "Косово",
              label: "Косово"
            },
            {
              value: "Кюлевча",
              label: "Кюлевча"
            },
            {
              value: "Марково",
              label: "Марково"
            },
            {
              value: "Могила",
              label: "Могила"
            },
            {
              value: "Плиска",
              label: "Плиска"
            }
          ]
        },
        {
          value: "Никола Козлево",
          label: "Никола Козлево",
          list: [
            {
              value: "Векилски",
              label: "Векилски"
            },
            {
              value: "Вълнари",
              label: "Вълнари"
            },
            {
              value: "Каравелово",
              label: "Каравелово"
            },
            {
              value: "Красен дол",
              label: "Красен дол"
            },
            {
              value: "Крива река",
              label: "Крива река"
            },
            {
              value: "Никола Козлево",
              label: "Никола Козлево"
            },
            {
              value: "Пет могили",
              label: "Пет могили"
            },
            {
              value: "Ружица",
              label: "Ружица"
            },
            {
              value: "Хърсово",
              label: "Хърсово"
            },
            {
              value: "Цани Гинчево",
              label: "Цани Гинчево"
            },
            {
              value: "Църквица",
              label: "Църквица"
            }
          ]
        },
        {
          value: "Нови пазар",
          label: "Нови пазар",
          list: [
            {
              value: "Беджене",
              label: "Беджене"
            },
            {
              value: "Войвода",
              label: "Войвода"
            },
            {
              value: "Енево",
              label: "Енево"
            },
            {
              value: "Жилино",
              label: "Жилино"
            },
            {
              value: "Зайчино ореше",
              label: "Зайчино ореше"
            },
            {
              value: "Избул",
              label: "Избул"
            },
            {
              value: "Мировци",
              label: "Мировци"
            },
            {
              value: "Нови пазар",
              label: "Нови пазар"
            },
            {
              value: "Памукчии",
              label: "Памукчии"
            },
            {
              value: "Писарево",
              label: "Писарево"
            },
            {
              value: "Правенци",
              label: "Правенци"
            },
            {
              value: "Преселка",
              label: "Преселка"
            },
            {
              value: "Сечище",
              label: "Сечище"
            },
            {
              value: "Стан",
              label: "Стан"
            },
            {
              value: "Стоян Михайловски",
              label: "Стоян Михайловски"
            },
            {
              value: "Тръница",
              label: "Тръница"
            }
          ]
        },
        {
          value: "Смядово",
          label: "Смядово",
          list: [
            {
              value: "Александрово",
              label: "Александрово"
            },
            {
              value: "Бял бряг",
              label: "Бял бряг"
            },
            {
              value: "Веселиново",
              label: "Веселиново"
            },
            {
              value: "Желъд",
              label: "Желъд"
            },
            {
              value: "Кълново",
              label: "Кълново"
            },
            {
              value: "Ново Янково",
              label: "Ново Янково"
            },
            {
              value: "Риш",
              label: "Риш"
            },
            {
              value: "Смядово",
              label: "Смядово"
            },
            {
              value: "Черни връх",
              label: "Черни връх"
            },
            {
              value: "Янково",
              label: "Янково"
            }
          ]
        },
        {
          value: "Хитрино",
          label: "Хитрино",
          list: [
            {
              value: "Байково",
              label: "Байково"
            },
            {
              value: "Близнаци",
              label: "Близнаци"
            },
            {
              value: "Висока поляна",
              label: "Висока поляна"
            },
            {
              value: "Върбак",
              label: "Върбак"
            },
            {
              value: "Гара Хитрино",
              label: "Гара Хитрино"
            },
            {
              value: "Длъжко",
              label: "Длъжко"
            },
            {
              value: "Добри Войниково",
              label: "Добри Войниково"
            },
            {
              value: "Единаковци",
              label: "Единаковци"
            },
            {
              value: "Живково",
              label: "Живково"
            },
            {
              value: "Звегор",
              label: "Звегор"
            },
            {
              value: "Иглика",
              label: "Иглика"
            },
            {
              value: "Калино",
              label: "Калино"
            },
            {
              value: "Каменяк",
              label: "Каменяк"
            },
            {
              value: "Развигорово",
              label: "Развигорово"
            },
            {
              value: "Сливак",
              label: "Сливак"
            },
            {
              value: "Становец",
              label: "Становец"
            },
            {
              value: "Студеница",
              label: "Студеница"
            },
            {
              value: "Тервел",
              label: "Тервел"
            },
            {
              value: "Тимарево",
              label: "Тимарево"
            },
            {
              value: "Трем",
              label: "Трем"
            },
            {
              value: "Черна",
              label: "Черна"
            }
          ]
        },
        {
          value: "Шумен",
          label: "Шумен",
          list: [
            {
              value: "Белокопитово",
              label: "Белокопитово"
            },
            {
              value: "Благово",
              label: "Благово"
            },
            {
              value: "Васил Друмев",
              label: "Васил Друмев"
            },
            {
              value: "Велино",
              label: "Велино"
            },
            {
              value: "Ветрище",
              label: "Ветрище"
            },
            {
              value: "Вехтово",
              label: "Вехтово"
            },
            {
              value: "Градище",
              label: "Градище"
            },
            {
              value: "Дибич",
              label: "Дибич"
            },
            {
              value: "Друмево",
              label: "Друмево"
            },
            {
              value: "Ивански",
              label: "Ивански"
            },
            {
              value: "Илия Блъсково",
              label: "Илия Блъсково"
            },
            {
              value: "Кладенец",
              label: "Кладенец"
            },
            {
              value: "Коньовец",
              label: "Коньовец"
            },
            {
              value: "Костена река",
              label: "Костена река"
            },
            {
              value: "Лозево",
              label: "Лозево"
            },
            {
              value: "Мадара",
              label: "Мадара"
            },
            {
              value: "Мараш",
              label: "Мараш"
            },
            {
              value: "Новосел",
              label: "Новосел"
            },
            {
              value: "Овчарово",
              label: "Овчарово"
            },
            {
              value: "Панайот Волово",
              label: "Панайот Волово"
            },
            {
              value: "Радко Димитриево",
              label: "Радко Димитриево"
            },
            {
              value: "Салманово",
              label: "Салманово"
            },
            {
              value: "Средня",
              label: "Средня"
            },
            {
              value: "Струино",
              label: "Струино"
            },
            {
              value: "Царев брод",
              label: "Царев брод"
            },
            {
              value: "Черенча",
              label: "Черенча"
            },
            {
              value: "Шумен",
              label: "Шумен"
            }
          ]
        }
      ]
    },
    {
      value: "Ямбол",
      label: "Ямбол",
      list: [
        {
          value: "Болярово",
          label: "Болярово",
          list: [
            {
              value: "Болярово",
              label: "Болярово"
            },
            {
              value: "Воден",
              label: "Воден"
            },
            {
              value: "Вълчи извор",
              label: "Вълчи извор"
            },
            {
              value: "Голямо Крушево",
              label: "Голямо Крушево"
            },
            {
              value: "Горска поляна",
              label: "Горска поляна"
            },
            {
              value: "Денница",
              label: "Денница"
            },
            {
              value: "Дъбово",
              label: "Дъбово"
            },
            {
              value: "Златиница",
              label: "Златиница"
            },
            {
              value: "Иглика",
              label: "Иглика"
            },
            {
              value: "Камен връх",
              label: "Камен връх"
            },
            {
              value: "Крайново",
              label: "Крайново"
            },
            {
              value: "Малко Шарково",
              label: "Малко Шарково"
            },
            {
              value: "Мамарчево",
              label: "Мамарчево"
            },
            {
              value: "Оман",
              label: "Оман"
            },
            {
              value: "Попово",
              label: "Попово"
            },
            {
              value: "Ружица",
              label: "Ружица"
            },
            {
              value: "Ситово",
              label: "Ситово"
            },
            {
              value: "Стефан Караджово",
              label: "Стефан Караджово"
            },
            {
              value: "Странджа",
              label: "Странджа"
            },
            {
              value: "Шарково",
              label: "Шарково"
            }
          ]
        },
        {
          value: "Елхово",
          label: "Елхово",
          list: [
            {
              value: "Борисово",
              label: "Борисово"
            },
            {
              value: "Бояново",
              label: "Бояново"
            },
            {
              value: "Вълча поляна",
              label: "Вълча поляна"
            },
            {
              value: "Голям Дервент",
              label: "Голям Дервент"
            },
            {
              value: "Гранитово",
              label: "Гранитово"
            },
            {
              value: "Добрич",
              label: "Добрич"
            },
            {
              value: "Елхово",
              label: "Елхово"
            },
            {
              value: "Жребино",
              label: "Жребино"
            },
            {
              value: "Изгрев",
              label: "Изгрев"
            },
            {
              value: "Кирилово",
              label: "Кирилово"
            },
            {
              value: "Лалково",
              label: "Лалково"
            },
            {
              value: "Лесово",
              label: "Лесово"
            },
            {
              value: "Малко Кирилово",
              label: "Малко Кирилово"
            },
            {
              value: "Маломирово",
              label: "Маломирово"
            },
            {
              value: "Малък манастир",
              label: "Малък манастир"
            },
            {
              value: "Мелница",
              label: "Мелница"
            },
            {
              value: "Пчела",
              label: "Пчела"
            },
            {
              value: "Раздел",
              label: "Раздел"
            },
            {
              value: "Славейково",
              label: "Славейково"
            },
            {
              value: "Стройно",
              label: "Стройно"
            },
            {
              value: "Трънково",
              label: "Трънково"
            },
            {
              value: "Чернозем",
              label: "Чернозем"
            }
          ]
        },
        {
          value: "Стралджа",
          label: "Стралджа",
          list: [
            {
              value: "Александрово",
              label: "Александрово"
            },
            {
              value: "Атолово",
              label: "Атолово"
            },
            {
              value: "Богорово",
              label: "Богорово"
            },
            {
              value: "Воденичане",
              label: "Воденичане"
            },
            {
              value: "Войника",
              label: "Войника"
            },
            {
              value: "Джинот",
              label: "Джинот"
            },
            {
              value: "Зимница",
              label: "Зимница"
            },
            {
              value: "Иречеково",
              label: "Иречеково"
            },
            {
              value: "Каменец",
              label: "Каменец"
            },
            {
              value: "Леярово",
              label: "Леярово"
            },
            {
              value: "Лозенец",
              label: "Лозенец"
            },
            {
              value: "Люлин",
              label: "Люлин"
            },
            {
              value: "Маленово",
              label: "Маленово"
            },
            {
              value: "Недялско",
              label: "Недялско"
            },
            {
              value: "Палаузово",
              label: "Палаузово"
            },
            {
              value: "Поляна",
              label: "Поляна"
            },
            {
              value: "Правдино",
              label: "Правдино"
            },
            {
              value: "Първенец",
              label: "Първенец"
            },
            {
              value: "Саранско",
              label: "Саранско"
            },
            {
              value: "Стралджа",
              label: "Стралджа"
            },
            {
              value: "Тамарино",
              label: "Тамарино"
            },
            {
              value: "Чарда",
              label: "Чарда"
            }
          ]
        },
        {
          value: "Тунджа",
          label: "Тунджа",
          list: [
            {
              value: "Асеново",
              label: "Асеново"
            },
            {
              value: "Безмер",
              label: "Безмер"
            },
            {
              value: "Болярско",
              label: "Болярско"
            },
            {
              value: "Ботево",
              label: "Ботево"
            },
            {
              value: "Бояджик",
              label: "Бояджик"
            },
            {
              value: "Веселиново",
              label: "Веселиново"
            },
            {
              value: "Видинци",
              label: "Видинци"
            },
            {
              value: "Генерал Инзово",
              label: "Генерал Инзово"
            },
            {
              value: "Генерал Тошево",
              label: "Генерал Тошево"
            },
            {
              value: "Голям манастир",
              label: "Голям манастир"
            },
            {
              value: "Гълъбинци",
              label: "Гълъбинци"
            },
            {
              value: "Дражево",
              label: "Дражево"
            },
            {
              value: "Драма",
              label: "Драма"
            },
            {
              value: "Дряново",
              label: "Дряново"
            },
            {
              value: "Завой",
              label: "Завой"
            },
            {
              value: "Златари",
              label: "Златари"
            },
            {
              value: "Кабиле",
              label: "Кабиле"
            },
            {
              value: "Калчево",
              label: "Калчево"
            },
            {
              value: "Каравелово",
              label: "Каравелово"
            },
            {
              value: "Козарево",
              label: "Козарево"
            },
            {
              value: "Коневец",
              label: "Коневец"
            },
            {
              value: "Крумово",
              label: "Крумово"
            },
            {
              value: "Кукорево",
              label: "Кукорево"
            },
            {
              value: "Маломир",
              label: "Маломир"
            },
            {
              value: "Меден кладенец",
              label: "Меден кладенец"
            },
            {
              value: "Межда",
              label: "Межда"
            },
            {
              value: "Миладиновци",
              label: "Миладиновци"
            },
            {
              value: "Могила",
              label: "Могила"
            },
            {
              value: "Овчи кладенец",
              label: "Овчи кладенец"
            },
            {
              value: "Окоп",
              label: "Окоп"
            },
            {
              value: "Победа",
              label: "Победа"
            },
            {
              value: "Робово",
              label: "Робово"
            },
            {
              value: "Роза",
              label: "Роза"
            },
            {
              value: "Савино",
              label: "Савино"
            },
            {
              value: "Симеоново",
              label: "Симеоново"
            },
            {
              value: "Скалица",
              label: "Скалица"
            },
            {
              value: "Сламино",
              label: "Сламино"
            },
            {
              value: "Стара река",
              label: "Стара река"
            },
            {
              value: "Тенево",
              label: "Тенево"
            },
            {
              value: "Търнава",
              label: "Търнава"
            },
            {
              value: "Хаджидимитрово",
              label: "Хаджидимитрово"
            },
            {
              value: "Ханово",
              label: "Ханово"
            },
            {
              value: "Чарган",
              label: "Чарган"
            },
            {
              value: "Челник",
              label: "Челник"
            }
          ]
        },
        {
          value: "Ямбол",
          label: "Ямбол",
          list: [
            {
              value: "Гара Ямбол",
              label: "Гара Ямбол"
            },
            {
              value: "Ямбол",
              label: "Ямбол"
            }
          ]
        }
      ]
    }
  ];