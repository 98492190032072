import ApiClient from "../services/ApiClient";

const requestAnalyzeDataType = "REQUEST_ANALYZE_DATA";
const submitFormDataType = "SUBMIT_FORM_DATA";
const startNewAnalyzeType = "START_NEW_ANALYZE";
const completeAnalyzeDataType = "COMPLETE_ANALYZE_DATA";
const errorAnalyzeDataType = "ERROR_ANALYZE_DATA";

const initialState = {
    loading: false,
    resultData: null,
    formData: null
};

export const actionCreators = {
    analyzeData: (data) => async (dispatch) => {
        dispatch({ type: requestAnalyzeDataType });
        try {
            const response = await ApiClient.post("api/analyze", data, dispatch);
            dispatch({ type: completeAnalyzeDataType, resultData: response.data });
        } catch {
            dispatch({ type: errorAnalyzeDataType });
        }
    },
    submitData: (data, navigate) => async (dispatch) => {
        dispatch({ type: submitFormDataType, formData: data });
        navigate("/result");
    },
    startNewAnalyze: (navigate) => async (dispatch) => {
        dispatch({ type: startNewAnalyzeType });
        navigate("/");
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestAnalyzeDataType) {
        return {
            ...state,
            loading: true
        };
    }

    if (action.type === submitFormDataType) {
        return {
            ...state,
            formData: action.formData,
            resultData: null
        };
    }

    if (action.type === startNewAnalyzeType) {
        return {
            ...state,
            formData: null
        };
    }

    if (action.type === errorAnalyzeDataType) {
        return {
            ...state,
            loading: false
        };
    }

    if (action.type === completeAnalyzeDataType) {
        return {
            ...state,
            loading: false,
            resultData: action.resultData
        };
    }

    return state;
};