import React from "react";
import { TextSkeleton } from "./Skeleton";
import { useFormBehaviorContext } from "./FormBehaviorContext";
import { LoadingButton } from "./LoadingButton";

export const FormSubmitButton = props => {
	const behavior = useFormBehaviorContext();
	const { isLoading: isLoadingProp, children, disabled: disabledProp, className, isSkeleton, ...rest } = props;

	const disabled = disabledProp || behavior?.isLoading;
	const isLoading = isLoadingProp || behavior?.isLoading;

	if (isSkeleton || behavior?.isSkeleton) {
		return (<TextSkeleton className={className} height={36} />);
	}

	return (behavior?.isReadOnly
		? null
		: (
			<LoadingButton type="submit" className={className} disabled={disabled} loading={isLoading} data-test="form-submit-btn" {...rest}>
				{children}
			</LoadingButton>
		)
	);
};

export default FormSubmitButton;