import React, { useEffect, useState } from "react";
import { actionCreators } from "./store/AppState";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Typography, Paper, Grid, Box, Avatar, Button, CircularProgress } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";
import {
    FormContainer, FormTextField, FormSubmitButton, FormNumericField, selectRequiredRule,
    FormSelect, emailValidRule
} from "./form";
import {
    seats, fuel_type, car_year, usage_type, registration, client_type,
    driver_experience, current_insurance, full_insurance, installment_opt,
    engine_type, owner_type, citizenship, steering_wheel, possible_drivers, accidents
} from "./model";
import { districts } from "./districts";
import { Routes, Route, useNavigate, useLocation, matchPath } from "react-router-dom";
import { useFormContext, useWatch } from "react-hook-form";
import { padding } from "@mui/system";

const useLayoutStyles = makeStyles({ name: "Layout" })((theme, params, classes) => ({
    mainContent: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),
        [`&.${classes.mainContentCentered}`]: {
            maxWidth: "900px",
            margin: "auto",
        }
    },
    mainContentCentered: {},
}));

const useAppStyles = makeStyles({ name: "App" })((theme, params, classes) => ({
    title: {
        marginBottom: theme.spacing(5),
        [`& h1`]: {
            textAlign: "center",
            color: theme.palette.primary.main
        },
        [`& p`]: {
            textAlign: "center",
        }
    },
    formTextField: {
        width: "100%",
        marginBottom: theme.spacing(2)
    },
    formWrapper: {
        padding: theme.spacing(3, 7, 5, 7)
    },
    formSectionTitle: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        [`& h6`]: {
            color: theme.palette.secondary.main,
            borderBottom: "1px solid",
            borderColor: theme.palette.secondary.dark,
            paddingBottom: theme.spacing(2)
        }
    },
    formActions: {
        marginTop: theme.spacing(4),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    secondaryAction: {
        marginTop: theme.spacing(3)
    },
    stepsWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: theme.spacing(5)
    },
    step: {
        margin: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        [`& .${classes.stepAvatar}`]: {
            border: "2px solid",
            marginRight: theme.spacing(2),
            borderColor: theme.palette.secondary.main,
            backgroundColor: "rgba(0,0,0,0)",
            color: theme.palette.secondary.main
        },
        [`&.${classes.activeStep} .${classes.stepAvatar}`]: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText
        }
    },
    activeStep: {},
    stepAvatar: {},
    stepText: {},
    loadingIndicator: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "300px"
    },
    resultWrapper: {

    },
    resultSection: {
        padding: theme.spacing(2),
        [`& .${classes.sectionContent}`]: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            border: "2px solid",
            borderColor: theme.palette.secondary.dark,
            borderRadius: "5px",
            padding: theme.spacing(2, 2, 0, 2)
        }
    },
    sectionContent: {},
    textNegative: {
        color: theme.palette.error.main
    },
    textPositive: {
        color: theme.palette.success.main
    },
    decision: {
        display: "flex",
        alignItems: "center",
        textTransform: "uppercase",
        fontSize: "32px",
        [`& svg`]: {
            marginLeft: "3px"
        }
    },
    green: {
        color: theme.palette.success.main
    },
    red: {
        color: theme.palette.error.main
    },
    probabilityWrapper: {
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "120px",
        height: "120px",
        padding: "8px",
        border: "4px solid",
        fontSize: "32px",
        fontWeight: 500,
        marginBottom: theme.spacing(5),
        [`&.${classes.negative}`]: {
            color: "#f44336"
        },
        [`&.${classes.positive}`]: {
            color: "#66bb6a"
        }
    },
    negative: {},
    positive: {}
}));

const Layout = props => {
    const { classes } = useLayoutStyles();
    return (
        <main className={clsx(classes.mainContent, classes.mainContentCentered)}>
            {props.children}
        </main>
    );
}

const Title = props => {
    const { classes } = useAppStyles();
    return (
        <Grid container className={classes.title} justifyContent={"center"}>
            <Grid item xs={12}>
                <Typography variant="h4" component="h1">
                    ГРАЖДАНСКА ОТГОВОРНОСТ
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography paragraph={true}>Въведете данни за анализ</Typography>
            </Grid>
        </Grid>
    );
}

const Steps = props => {
    const { classes } = useAppStyles();
    let location = useLocation();

    return (
        <Box className={classes.stepsWrapper}>
            <Box className={clsx(classes.step, matchPath("/", location.pathname) && classes.activeStep)}>
                <Avatar classes={{
                    root: classes.stepAvatar
                }}>1</Avatar>
                <Typography variant="body1" className={classes.stepText}>Въпросник</Typography>
            </Box>
            <Box className={clsx(classes.step, matchPath("result", location.pathname) && classes.activeStep)}>
                <Avatar classes={{
                    root: classes.stepAvatar
                }}>2</Avatar>
                <Typography variant="body1" className={classes.stepText}>Анализ</Typography>
            </Box>
        </Box>
    );
}

const OwnerFields = props => {
    const { classes: appClasses } = useAppStyles();

    return (
        <>
            <Grid item xs={12} className={appClasses.formSectionTitle}>
                <Typography variant="h6">Данни за собственика</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="OWNER_TYPE"
                    label="Собственикът е *"
                    className={appClasses.formTextField}
                    options={owner_type}
                    defaultValue={owner_type[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете собственик.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="CITIZENSHIP"
                    label="Гражданство *"
                    className={appClasses.formTextField}
                    options={citizenship}
                    defaultValue={citizenship[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете гражданство.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormTextField
                    name="EMAIL"
                    label="Имейл"
                    rules={{
                        validate: {
                            emailValid: emailValidRule("Въведете валиден имейл.")
                        }
                    }}
                    className={appClasses.formTextField}
                />
            </Grid>
            <Grid item xs={12}>
                <FormNumericField
                    numericType="integer"
                    minValue={18}
                    name="AGE"
                    label="Възраст на собственика *"
                    rules={{ required: true }}
                    errorMessage={"Въведете възраст."}
                    className={appClasses.formTextField}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="DRIVER_EXPERIENCE"
                    label="Шофьорски стаж *"
                    className={appClasses.formTextField}
                    options={driver_experience}
                    defaultValue={driver_experience[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете шофьорски стаж.", false, -1)
                        }
                    }}
                />
            </Grid>
        </>
    );
};

const CarFields = props => {
    const { classes: appClasses } = useAppStyles();

    const selectedFuelType = useWatch({ name: "FUEL_TYPE_DESC" });
    const isElectro = selectedFuelType === "Електромобил";


    return (
        <>
            <Grid item xs={12} className={appClasses.formSectionTitle}>
                <Typography variant="h6">Данни за МПС</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormNumericField
                    numericType="decimal"
                    minValue={0}
                    name="TONAGE"
                    label="Общо тегло (тонове) *"
                    rules={{ required: true }}
                    errorMessage={"Въведете тегло."}
                    className={appClasses.formTextField}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="FUEL_TYPE_DESC"
                    label="Вид гориво *"
                    className={appClasses.formTextField}
                    options={fuel_type}
                    defaultValue={fuel_type[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете вид гориво.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="ENGINE_TYPE_DESC"
                    label="Вид на двигателя *"
                    className={appClasses.formTextField}
                    options={engine_type}
                    defaultValue={engine_type[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете вид на двигателя.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>
            {!isElectro && (<Grid item xs={12}>
                <FormNumericField
                    numericType="integer"
                    name="ENGINE_VOLUME"
                    label="Обем на двигателя (куб. см.) *"
                    rules={{ required: true }}
                    errorMessage={"Въведете обем."}
                    className={appClasses.formTextField}
                />
            </Grid>)}
            <Grid item xs={12}>
                <FormNumericField
                    numericType="integer"
                    name="ENGINE_POWER_HP"
                    label="Мощност на двигателя (конски сили) *"
                    rules={{ required: true }}
                    errorMessage={"Въведете мощност."}
                    className={appClasses.formTextField}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="SEATS"
                    label="Брой места *"
                    className={appClasses.formTextField}
                    options={seats}
                    defaultValue={seats[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете брой места.", false, -1)
                        }
                    }}
                />
            </Grid>
        </>
    );
};

const RegistrationFields = props => {
    const { classes: appClasses } = useAppStyles();
    const { formState: { isDirty }, setValue } = useFormContext();

    const selectedRegistration = useWatch({ name: "REGISTRATION", defaultValue: registration[0].value });
    const registrationObj = registration.find(x => x.value === selectedRegistration);

    const selections = useWatch({
        name: ["DISTRICT", "MUNICIPALITY", "REGION"]
    });

    selections[0] = selections[0] || registration[0].district;
    selections[1] = selections[1] || registration[0].municipality;
    selections[2] = selections[2] || registration[0].region;

    const districtObj = districts.find(x => x.value === selections[0]) || districts[0];
    const municipalities = districtObj.list;
    const municipalityObj = municipalities.find(x => x.value === selections[1]) || municipalities[0];
    const isSofia = municipalityObj.value === "София";
    let regions = [{ value: "UNKNOWN", label: "Няма намерени" }];
    let settlements = municipalityObj.list;

    if (isSofia) {
        regions = municipalityObj.list;
        const regionObj = regions.find(x => x.value === selections[2]) || regions[0];
        settlements = regionObj.list;
    }

    useEffect(() => {
        if (!isDirty) {
            return;
        }

        if (registrationObj) {
            setValue("DISTRICT", registrationObj.district, { shouldValidate: false });
            setTimeout(() => {
                setValue("MUNICIPALITY", registrationObj.municipality, { shouldValidate: false });
                if (registrationObj.region) {
                    setTimeout(() => {
                        setValue("REGION", registrationObj.region, { shouldValidate: false });
                        setTimeout(() => {
                            setValue("SETTLEMENT", registrationObj.settlement, { shouldValidate: false });
                        });
                    });
                } else {
                    setTimeout(() => {
                        setValue("SETTLEMENT", registrationObj.settlement, { shouldValidate: false });
                    });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registrationObj]);

    return (
        <>
            <Grid item xs={12} className={appClasses.formSectionTitle}>
                <Typography variant="h6">Регистрация</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="REGISTRATION"
                    label="Регистрационен номер на МПС *"
                    className={appClasses.formTextField}
                    options={registration}
                    defaultValue={registration[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете регистрационен номер.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="EFFECTIVE_YEAR"
                    label="Година на първа регистрация на МПС *"
                    className={appClasses.formTextField}
                    options={car_year}
                    defaultValue={car_year[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете година.", false, -1)
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="DISTRICT"
                    label="Област *"
                    className={appClasses.formTextField}
                    options={districts}
                    defaultValue={registration[0].district}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете област.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="MUNICIPALITY"
                    label="Община *"
                    className={appClasses.formTextField}
                    options={municipalities}
                    defaultValue={registration[0].municipality}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете община.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>
            {isSofia && (<Grid item xs={12}>
                <FormSelect
                    name="REGION"
                    label="Район *"
                    className={appClasses.formTextField}
                    options={regions}
                    defaultValue={registration[0].region}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете район.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>)}
            <Grid item xs={12}>
                <FormSelect
                    name="SETTLEMENT"
                    label="Населено място *"
                    className={appClasses.formTextField}
                    options={settlements}
                    defaultValue={registration[0].settlement}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете населено място.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="STEERING_WHEEL"
                    label="Волан *"
                    className={appClasses.formTextField}
                    options={steering_wheel}
                    defaultValue={steering_wheel[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете вид волан.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>
        </>
    );
};

const UsageFields = props => {
    const { classes: appClasses } = useAppStyles();

    return (
        <>
            <Grid item xs={12} className={appClasses.formSectionTitle}>
                <Typography variant="h6">Използване на автомобила</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="USAGE_TYPE"
                    label="Автомобилът се използва основно за *"
                    className={appClasses.formTextField}
                    options={usage_type}
                    defaultValue={usage_type[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете начин на използване.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="POSSIBLE_DRIVERS"
                    label="Автомобилът се управлява от *"
                    className={appClasses.formTextField}
                    options={possible_drivers}
                    defaultValue={possible_drivers[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете ползватели.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>
        </>
    );
};

const InsuranceFields = props => {
    const { classes: appClasses } = useAppStyles();

    return (
        <>
            <Grid item xs={12} className={appClasses.formSectionTitle}>
                <Typography variant="h6">ПТП и застраховки</Typography>
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="INSTALLMENTS"
                    label="Предпочитан брой вноски *"
                    className={appClasses.formTextField}
                    options={installment_opt}
                    defaultValue={installment_opt[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете брой вноски.", false, -1)
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="CURRENT_INSURANCE"
                    label="Текущата полица по гражданска отговорност *"
                    className={appClasses.formTextField}
                    options={current_insurance}
                    defaultValue={current_insurance[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете текущ застраховател.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="FULL_INSURANCE"
                    label="Автомобилът има ли каско? *"
                    className={appClasses.formTextField}
                    options={full_insurance}
                    defaultValue={full_insurance[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете застраховател каско.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="ACCIDENTS"
                    label="Виновно причинени ПТП през последните 3 години *"
                    className={appClasses.formTextField}
                    options={accidents}
                    defaultValue={accidents[0].value}
                    rules={{
                        validate: {
                            required: selectRequiredRule("Въведете ПТП.", false, "UNKNOWN")
                        }
                    }}
                />
            </Grid>
        </>
    );
};

const QuestionsForm = props => {
    const { formData } = props;
    const { classes: appClasses } = useAppStyles();
    const { reset } = useFormContext();

    useEffect(() => {
        if (formData) {
            reset(formData);
        }
    }, [reset, formData]);

    return (
        <Grid container className={appClasses.formWrapper} component={Paper}>
            <CarFields />
            <RegistrationFields />
            <OwnerFields />
            <UsageFields />
            <InsuranceFields />

            <Grid item xs={12} className={appClasses.formActions}>
                <FormSubmitButton>Изчисли</FormSubmitButton>
            </Grid>
        </Grid>
    );
};


const QuestionsPage = props => {
    const { submitData, formData } = props;
    const navigate = useNavigate();

    return (
        <FormContainer isReadOnly={false} handleSubmit={data => submitData(data, navigate)} isLoading={false} isSkeleton={false}>
            <QuestionsForm formData={formData} />
        </FormContainer>
    );
}

const ResultPage = props => {
    const { analyzeData, loading, formData, resultData, startNewAnalyze } = props;
    const { classes: appClasses } = useAppStyles();
    const navigate = useNavigate();

    useEffect(() => {
        if (formData) {
            analyzeData(formData);
        }
    }, [analyzeData, formData]);

    if (loading) {
        return (
            <Grid container className={appClasses.formWrapper} component={Paper}>
                <Grid item className={appClasses.loadingIndicator} xs={12}>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

    const isGoodProperty = resultData && resultData.PROPERTY.DECISION.indexOf("GOOD") >= 0;
    const isBadProperty = resultData && resultData.PROPERTY.DECISION.indexOf("BAD") >= 0;
    const isGoodNonProperty = resultData && resultData.NON_PROPERTY.DECISION.indexOf("GOOD") >= 0;
    const isBadNonProperty = resultData && resultData.NON_PROPERTY.DECISION.indexOf("BAD") >= 0;

    return (
        <Grid container className={appClasses.formWrapper} component={Paper}>
            {resultData &&
                <Grid container item className={appClasses.resultWrapper} xs={12}>
                    <Grid item xs={12} md={6} className={appClasses.resultSection}>
                        <Box className={appClasses.sectionContent}>
                            <Typography paragraph={true}>ИМУЩЕСТВЕНИ ЩЕТИ</Typography>
                            <Typography paragraph={true} className={clsx(appClasses.decision, isGoodProperty && appClasses.green, isBadProperty && appClasses.red)}>{client_type[resultData.PROPERTY.DECISION]}
                            </Typography>
                            <Typography paragraph={true}>Бонус: {resultData.PROPERTY.BONUS}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} className={appClasses.resultSection}>
                        <Box className={appClasses.sectionContent}>
                            <Typography paragraph={true}>НЕИМУЩЕСТВЕНИ ЩЕТИ</Typography>
                            <Typography paragraph={true} className={clsx(appClasses.decision, isGoodNonProperty && appClasses.green, isBadNonProperty && appClasses.red)}>{client_type[resultData.NON_PROPERTY.DECISION]}
                            </Typography>
                            <Typography paragraph={true}>Бонус: {resultData.NON_PROPERTY.BONUS}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={appClasses.resultSection}>
                        <Box className={appClasses.sectionContent}>
                            <Typography paragraph={true}>Премия: {resultData.PREMIUM}</Typography>
                            <Typography paragraph={true}>Финална премия: <Box component="span" className={clsx(resultData.FINAL_PREMIUM < resultData.PREMIUM && appClasses.green, resultData.FINAL_PREMIUM > resultData.PREMIUM && appClasses.red)}>{resultData.FINAL_PREMIUM}</Box></Typography>
                        </Box>
                    </Grid>
                </Grid>}
            <Grid item xs={12} className={appClasses.formActions}>
                <Button variant="contained" onClick={() => startNewAnalyze(navigate)}>Нов анализ</Button>
                {formData && (<Button variant="text" className={appClasses.secondaryAction} onClick={() => navigate("/")}>Назад</Button>)}
            </Grid>
        </Grid>
    );
}

const App = props => {
    const { resultData, formData, loading, analyzeData, submitData, startNewAnalyze } = props;

    return (
        <Layout>
            <Title />
            <Steps />
            <Routes>
                <Route path="/" element={<QuestionsPage submitData={submitData} formData={formData} />} />
                <Route path="result" element={<ResultPage analyzeData={analyzeData} resultData={resultData} loading={loading} formData={formData} startNewAnalyze={startNewAnalyze} />} />
            </Routes>
        </Layout>
    );
}

export default connect(
    (state) => state.app,
    (dispatch) => bindActionCreators(actionCreators, dispatch)
)(App)
