import { ExposureTwoTone } from "@mui/icons-material";

export const car_types = [
    { value: "Лек автомобил", label: "Лек автомобил" },
    { value: "Товарен автомобил", label: "Товарен автомобил" },
    { value: "Влекач", label: "Влекач" },
    { value: "Ремарке", label: "Ремарке" },
    { value: "Мотоциклет", label: "Мотоциклет" },
    { value: "Мотопед", label: "Мотопед" },
    { value: "Триколка", label: "Триколка" },
    { value: "АТВ", label: "АТВ" },
    { value: "Автобус", label: "Автобус" },
    { value: "Земеделска техника", label: "Земеделска техника" },
    { value: "Горска техника", label: "Горска техника" },
    { value: "Строителна техника", label: "Строителна техника" },
    { value: "Вътрешно заводски транспорт", label: "Вътрешно заводски транспорт" }
];

export const seats = [
    { value: 2, label: "1+1 места" },
    { value: 4, label: "3+1 места" },
    { value: 5, label: "4+1 места" },
    { value: 6, label: "5+1 места" },
    { value: 7, label: "6+1 места" },
    { value: 8, label: "7+1 места" },
    { value: 9, label: "8+1 места" }
];

export const engine_hp = [
    { value: 66, label: "до 66 кВт вкл. (до 90 к.с.)" },
    { value: 74, label: "до 74 кВт вкл. (до 101 к.с.)" },
    { value: 75, label: "до 75 кВт вкл. (до 102 к.с.)" },
    { value: 88, label: "до 88 кВт вкл. (до 120 к.с.)" },
    { value: 110, label: "до 110 кВт вкл. (до 150 к.с.)" },
    { value: 118, label: "до 118 кВт вкл. (до 160 к.с.)" },
    { value: 125, label: "до 125 кВт вкл. (до 170 к.с.)" },
    { value: 126, label: "над 125 кВт (над 170 к.с.)" },
];

export const engine_volume = [
    { value: 800, label: "до 800 куб. см." },
    { value: 900, label: "до 900 куб. см." },
    { value: 1000, label: "до 1000 куб. см." },
    { value: 1100, label: "до 1100 куб. см." },
    { value: 1200, label: "до 1200 куб. см." },
    { value: 1300, label: "до 1300 куб. см." },
    { value: 1400, label: "до 1400 куб. см." },
    { value: 1500, label: "до 1500 куб. см." },
    { value: 1600, label: "до 1600 куб. см." },
    { value: 1700, label: "до 1700 куб. см." },
    { value: 1800, label: "до 1800 куб. см." },
    { value: 1900, label: "до 1900 куб. см." },
    { value: 2000, label: "до 2000 куб. см." },
    { value: 2100, label: "до 2100 куб. см." },
    { value: 2200, label: "до 2200 куб. см." },
    { value: 2300, label: "до 2300 куб. см." },
    { value: 2400, label: "до 2400 куб. см." },
    { value: 2500, label: "до 2500 куб. см." },
    { value: 2600, label: "до 2600 куб. см." },
    { value: 3000, label: "до 3000 куб. см." },
    { value: 3200, label: "до 3200 куб. см." },
    { value: 3500, label: "до 3500 куб. см." },
    { value: 3501, label: "над 3500 куб. см." },
];

export const fuel_type = [
    { value: "Бензин", label: "Бензин" },
    { value: "Бензин и газ (или метан)", label: "Бензин и газ (или метан)" },
    { value: "Хибрид Бензин", label: "Хибрид Бензин" },
    { value: "Дизел", label: "Дизел" },
    { value: "Дизел и газ (или метан)", label: "Дизел и газ (или метан)" },
    { value: "Хибрид Дизел", label: "Хибрид Дизел" },
    { value: "Електромобил", label: "Електромобил" },
    { value: "Само газ/метан", label: "Само газ/метан" },
];

export const car_year = [
    { value: 2022, "label": "2022 г." },
    { value: 2021, "label": "2021 г." },
    { value: 2020, "label": "2020 г." },
    { value: 2019, "label": "2019 г." },
    { value: 2018, "label": "2018 г." },
    { value: 2017, "label": "2017 г." },
    { value: 2016, "label": "2016 г." },
    { value: 2015, "label": "2015 г." },
    { value: 2014, "label": "2014 г." },
    { value: 2013, "label": "2013 г." },
    { value: 2012, "label": "2012 г." },
    { value: 2011, "label": "2011 г." },
    { value: 2010, "label": "2010 г." },
    { value: 2009, "label": "2009 г." },
    { value: 2008, "label": "2008 г." },
    { value: 2007, "label": "2007 г." },
    { value: 2006, "label": "2006 г." },
    { value: 2005, "label": "2005 г." },
    { value: 2004, "label": "2004 г." },
    { value: 2003, "label": "2003 г." },
    { value: 2002, "label": "2002 г." },
    { value: 2001, "label": "2001 г." },
    { value: 2000, "label": "2000 г." },
    { value: 1999, "label": "1999 г." },
    { value: 1998, "label": "1998 г." },
    { value: 1997, "label": "1997 г." },
    { value: 1996, "label": "1996 г." },
    { value: 1995, "label": "1995 г." },
    { value: 1994, "label": "1994 г." },
    { value: 1993, "label": "1993 г." },
    { value: 1992, "label": "1992 г." },
    { value: 1991, "label": "1991 г." },
    { value: 1990, "label": "1990 г." },
    { value: 1989, "label": "1989 г." },
    { value: 1988, "label": "1988 г." },
    { value: 1987, "label": "1987 г." },
    { value: 1986, "label": "1986 г." },
    { value: 1985, "label": "1985 г." },
    { value: 1984, "label": "1984 г." },
    { value: 1983, "label": "1983 г." },
    { value: 1982, "label": "1982 г." },
    { value: 1981, "label": "1981 г." },
    { value: 1980, "label": "1980 г." },
    { value: 1979, "label": "преди 1980 г." }
];

export const usage_type = [
    { value: "Лично ползване", label: "Лично ползване" },
    { value: "Служебно ползване", label: "Служебно ползване" },
    { value: "Таксиметрови превози", label: "Таксиметрови превози" },
    { value: "Състезателен автомобил", label: "Състезателен автомобил" },
    { value: "Учебен", label: "Учебен" },
    { value: "Отдаване под наем", label: "Отдаване под наем" },
    { value: "Превоз на опасни товари", label: "Превоз на опасни товари" },
    { value: "МПС със спец. режим на движение", label: "МПС със спец. режим на движение" },
    { value: "Тестови", label: "Тестови" },
    { value: "Обществен транспорт", label: "Обществен транспорт" },
    { value: "Производствена дейност", label: "Производствена дейност" },
    { value: "Търговска дейност", label: "Търговска дейност" },
    { value: "Куриерски услуги", label: "Куриерски услуги" },
    { value: "Международен транспорт", label: "Международен транспорт" },
];

export const registration = [
    { value: "CA", label: "С, СА, СВ - София-град", district: "София", municipality: "София", region: "Район Сердика", settlement: "София" },
    { value: "B", label: "В - Варна", district: "Варна", municipality: "Варна", settlement: "Варна" },
    { value: "PB", label: "РВ - Пловдив", district: "Пловдив", municipality: "Пловдив", settlement: "Пловдив" },

    { value: "A", label: "А - Бургас", district: "Бургас", municipality: "Бургас", settlement: "Бургас" },
    { value: "E", label: "Е - Благоевград", district: "Благоевград", municipality: "Благоевград", settlement: "Благоевград" },
    { value: "BT", label: "ВТ - Велико Търново", district: "Велико Търново", municipality: "Велико Търново", settlement: "Велико Търново" },
    { value: "BH", label: "ВН - Видин", district: "Видин", municipality: "Видин", settlement: "Видин" },
    { value: "BP", label: "ВР - Враца", district: "Враца", municipality: "Враца", settlement: "Враца" },
    { value: "EB", label: "ЕВ - Габрово", district: "Габрово", municipality: "Габрово", settlement: "Габрово" },
    { value: "TX", label: "ТХ - Добрич", district: "Добрич", municipality: "Добрич", settlement: "Добрич" },
    { value: "K", label: "К - Кърджали", district: "Кърджали", municipality: "Кърджали", settlement: "Кърджали" },
    { value: "KH", label: "КН - Кюстендил", district: "Кюстендил", municipality: "Кюстендил", settlement: "Кюстендил" },
    { value: "OB", label: "ОВ - Ловеч", district: "Ловеч", municipality: "Ловеч", settlement: "Ловеч" },
    { value: "M", label: "М - Монтана", district: "Монтана", municipality: "Монтана", settlement: "Монтана" },
    { value: "PA", label: "РА - Пазарджик", district: "Пазарджик", municipality: "Пазарджик", settlement: "Пазарджик" },
    { value: "PK", label: "РК - Перник", district: "Перник", municipality: "Перник", settlement: "Перник" },
    { value: "EH", label: "ЕН - Плевен", district: "Плевен", municipality: "Плевен", settlement: "Плевен" },
    { value: "PP", label: "РР - Разград", district: "Разград", municipality: "Разград", settlement: "Разград" },
    { value: "P", label: "Р - Русе", district: "Русе", municipality: "Русе", settlement: "Русе" },
    { value: "CC", label: "СС - Силистра", district: "Силистра", municipality: "Силистра", settlement: "Силистра" },
    { value: "CH", label: "СН - Сливен", district: "Сливен", municipality: "Сливен", settlement: "Сливен" },
    { value: "CM", label: "СМ - Смолян", district: "Смолян", municipality: "Смолян", settlement: "Смолян" },
    { value: "CO", label: "СО - София-област", district: "София", municipality: "София", settlement: "София" },
    { value: "CT", label: "СТ - Стара Загора", district: "Стара Загора", municipality: "Стара Загора", settlement: "Стара Загора" },
    { value: "T", label: "Т - Търговище", district: "Търговище", municipality: "Търговище", settlement: "Търговище" },
    { value: "X", label: "Х - Хасково", district: "Хасково", municipality: "Хасково", settlement: "Хасково" },
    { value: "H", label: "Н - Шумен", district: "Шумен", municipality: "Шумен", settlement: "Шумен" },
    { value: "Y", label: "У - Ямбол", district: "Ямбол", municipality: "Ямбол", settlement: "Ямбол" }
];

export const driver_experience = [
    { value: 0, label: "под 1 година" },
    { value: 1, label: "1 година" },
    { value: 2, label: "2 години" },
    { value: 3, label: "3 години" },
    { value: 4, label: "4 години" },
    { value: 5, label: "5 години" },
    { value: 6, label: "6 години" },
    { value: 7, label: "7 години" },
    { value: 8, label: "8 години" },
    { value: 9, label: "9 години" },
    { value: 10, label: "над 10 години" }
];

export const current_insurance = [
    { value: "armeec", label: "Армеец" },
    { value: "bulstrad", label: "Булстрад" },
    { value: "bulins", label: "Бул Инс" },
    { value: "dalbog", label: "ДаллБогг" },
    { value: "generali", label: "Дженерали" },
    { value: "dzi", label: "ДЗИ" },
    { value: "euroins", label: "Евроинс" },
    { value: "levins", label: "Лев Инс" },
    { value: "obshtinska", label: "ОЗК" },
    { value: "uniqa", label: "Уника" },
    { value: "OTHER", label: "Друга компания" },
    { value: "no", label: "МПС не е имало ГО" }
];

export const full_insurance = [
    { value: "no", label: "Не, няма" },
    { value: "armeec", label: "Да, в Армеец" },
    { value: "bulstrad", label: "Да, в Булстрад" },
    { value: "bulins", label: "Да, в Бул Инс" },
    { value: "dalbog", label: "Да, в ДаллБогг" },
    { value: "dzi", label: "Да, в ДЗИ" },
    { value: "euroins", label: "Да, в Евроинс" },
    { value: "levins", label: "Да, в Лев Инс" },
    { value: "generali", label: "Да, в Дженерали" },
    { value: "obshtinska", label: "Да, в ОЗК" },
    { value: "uniqa", label: "Да, в Уника" },
    { value: "OTHER", label: "Да, в друга компания" },
];

export const tonage = [
    { value: 1.5, label: "до 1.5 т. вкл." },
    { value: 2.5, label: "до 2.5 т. вкл." },
    { value: 3.5, label: "до 3.5 т. вкл." },
    { value: 5, label: "до 5 т. вкл." },
    { value: 6, label: "до 6 т. вкл." },
    { value: 8, label: "до 8 т. вкл." },
    { value: 10, label: "до 10 т. вкл." },
    { value: 15, label: "до 15 т. вкл." },
    { value: 20, label: "до 20 т. вкл." },
    { value: 25, label: "до 24 т. вкл." },
    { value: 26, label: "над 25 т." }
];

export const installment_opt = [
    { value: 1, label: "1 вноска" },
    { value: 2, label: "2 вноски" },
    { value: 3, label: "3 вноски" },
    { value: 4, label: "4 вноски" },
];

export const engine_type = [
    { value: "Атмосферен", label: "Атмосферен" },
    { value: "Карбураторен", label: "Карбураторен" },
    { value: "Инжекцион", label: "Инжекцион" },
    { value: "Турбо", label: "Турбо" },
    { value: "OTHER", label: "Друг" },
];

export const owner_type = [
    { value: "private", label: "Частно лице" },
    { value: "legal", label: "Юридическо лице" }
];

export const citizenship = [
    { value: "BG", label: "Българско" },
    { value: "BE", label: "Белгийско" },
    { value: "FR", label: "Френско" },
    { value: "DE", label: "Германско" },
    { value: "IT", label: "Италианско" },
    { value: "LU", label: "Люксембургско" },
    { value: "NL", label: "Нидерландско" },
    { value: "D", label: "Датско" },
    { value: "IE", label: "Ирландско" },
    { value: "GR", label: "Гръцко" },
    { value: "PT", label: "Португалско" },
    { value: "ES", label: "Испанско" },
    { value: "AT", label: "Австрийско" },
    { value: "FI", label: "Финландско" },
    { value: "SE", label: "Шведско" },
    { value: "EE", label: "Естонско" },
    { value: "CY", label: "Кипърско" },
    { value: "LV", label: "Латвийско" },
    { value: "LT", label: "Литовско" },
    { value: "MT", label: "Малтийско" },
    { value: "PL", label: "Полско" },
    { value: "SK", label: "Словашко" },
    { value: "SI", label: "Словенско" },
    { value: "HU", label: "Унгарско" },
    { value: "CZ", label: "Чешко" },
    { value: "RO", label: "Румънско" },
    { value: "HR", label: "Хърватско" }
];

export const steering_wheel = [
    { value: "Ляв", label: "Ляв" },
    { value: "Десен", label: "Десен" }
];

export const possible_drivers = [
    { value: "owner", label: "собственика" },
    { value: "owner_plus", label: "собственика и лица на възраст между 25г. и 40г." },
    { value: "all", label: "всички упълномощени лица" }
];

export const accidents = [
    { value: "no", label: "не" },
    { value: "1", label: "да, през последната 1г." },
    { value: "2", label: "да, през последните 2г." },
    { value: "3", label: "да, през последните 3г." }
];

export const client_type = {
    GOOD1: "Добър",
    GOOD2: "Добър",
    INTERMEDIATE: "Стандартен",
    BAD2: "Лош",
    BAD1: "Лош"
};